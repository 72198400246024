import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../Components/Button'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import { useSelector } from '../../../Hooks/redux'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { DEFAULT_WAREHOUSES } from '../../../Util/Options'
import { warehouseSchema } from '../../../Util/validationSchema'
import SingleWarehouse from './SingleWarehouse'

function WarehouseForm({
  setValues,
  match: {
    params: { id }
  }
}) {
  const { configurations: { warehouseLocations, warehouseRacks } = {} } = useSelector(
    (state) => state.users.companyInfo
  )

  const getData = () => {
    if (id) {
      apiClient.get(`warehouses/${id}`).then(({ status, data }) => {
        if (status === 200) {
          setValues({ warehouses: [data] })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 20 }}>
          <PanelLayout title="Warehouse">
            <Panel title="Warehouse">
              <FieldArray
                name="warehouses"
                defaultValues={{ ...DEFAULT_WAREHOUSES, warehouseLocations, warehouseRacks }}>
                {SingleWarehouse}
              </FieldArray>
            </Panel>
          </PanelLayout>

          {/* Product details ends */}

          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>
            <span>or</span>
            <Link to="/app/warehouses">
              <Button>
                <ArrowLeftOutlined /> Back to warehouse list
              </Button>
            </Link>
          </div>
          {/* Invoice Information ends */}
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({ companyInfo: { configurations: { warehouseLocations, warehouseRacks } = {} } }) => ({
    warehouses: [{ ...DEFAULT_WAREHOUSES, warehouseLocations, warehouseRacks }]
  }),
  validationSchema: warehouseSchema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`warehouses/${id}`, data.warehouses[0]).then(({ status }) => {
        if (status === 200) {
          history.push('/app/warehouses')
        }
      })
    } else {
      apiClient.post('warehouses', data).then(({ status }) => {
        if (status === 201) {
          history.push('/app/warehouses')
        }
      })
    }
  }
})(WarehouseForm)
