import { Col, Row, Tabs } from 'antd'
import { findIndex, isEmpty } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import {
  getAllEmployeesByCompanyAndNetwork,
  resetEmployeeDetails,
  setSelectedEmployee
} from '../../../Actions/EmployeeActions'
import SelectBox from '../../../Components/SelectBox/SelectBox'
import '../HumanResources.scss'
import BankDetails from './BankDetails'
import ContactDetails from './ContactDetails'
import Dependents from './Dependents'
import EducationDetails from './EducationDetails'
import EmergencyDetails from './EmergencyDetails'
import InsuranceDetails from './InsuranceDetails'
import JobHistory from './JobHistory'
import OfficialDetails from './OfficialDetails'
import PassportDetails from './PassportDetails'
import PersonalDetails from './PersonalDetails'
import SalaryHistory from './SalaryHistory'
import SkillDetails from './SkillDetails'
import WorkExperience from './WorkExperience'

const { TabPane } = Tabs

export class EmployeeDetails extends React.Component {
  constructor() {
    super()
    this.state = {
      defaultTab: 'OfficialDetails'
    }
  }

  componentDidMount() {
    this.props.dispatch(getAllEmployeesByCompanyAndNetwork())
  }

  componentWillUnmount() {
    this.props.dispatch(resetEmployeeDetails())
  }

  onChangeState = (state) => {
    this.setState(state)
  }

  onChangeTab = (defaultTab) => {
    this.setState({ defaultTab })
  }

  onSelectEmployee = (employeeID) => {
    this.props.dispatch(setSelectedEmployee(employeeID))
  }

  getEmployeeOptions = () => {
    const employeeOptions = this.props.allEmployees.map((employee) => ({
      label: `${employee.name} - ${employee.employeeNo}`,
      value: employee.id
    }))

    return employeeOptions
  }

  getEmployeeByID = () => {
    const { allEmployees, selectedEmployeeID } = this.props

    if (!selectedEmployeeID) {
      return {}
    }

    const index = findIndex(allEmployees, { id: selectedEmployeeID })

    return index >= 0 ? allEmployees[index] : {}
  }

  render() {
    const { selectedEmployeeID } = this.props
    const currentEmployee = this.getEmployeeByID()

    return (
      <Row justify="center" className="inner-contents individual-employee-details">
        <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 22 }} lg={{ span: 22 }}>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }}>
              <h2 className="panel-title">
                Employee Details{' '}
                <span style={{ color: '#000' }}>
                  {!isEmpty(currentEmployee)
                    ? `(${currentEmployee?.employeeNo} - ${currentEmployee?.name})`
                    : null}
                </span>
              </h2>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 4 }}>
              <div className="form-field">
                <SelectBox
                  placeholder="Select Employee"
                  id="selectEmployee"
                  value={selectedEmployeeID}
                  options={this.getEmployeeOptions()}
                  onChangeText={this.onSelectEmployee}
                />
              </div>
            </Col>
          </Row>
          {isEmpty(currentEmployee) ? (
            <p>Please select an employee to continue...</p>
          ) : (
            <div>
              <Tabs defaultActiveKey={this.state.defaultTab} onChange={this.onChangeTab}>
                <TabPane tab="Official Details" key="OfficialDetails">
                  <OfficialDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Personal Details" key="PersonalDetails">
                  <PersonalDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Bank Details" key="BankDetails">
                  <BankDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Passport Details" key="PassportDetails">
                  <PassportDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Contact Details" key="ContactDetails">
                  <ContactDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Emergency Details" key="EmergencyDetails">
                  <EmergencyDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Insurance Details" key="InsuranceDetails">
                  <InsuranceDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Dependents" key="Dependents">
                  <Dependents currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Qualification" key="Qualification">
                  <WorkExperience currentEmployee={currentEmployee} />
                  <EducationDetails currentEmployee={currentEmployee} />
                  <SkillDetails currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Job History" key="JobHistory">
                  <JobHistory currentEmployee={currentEmployee} />
                </TabPane>
                <TabPane tab="Salary History" key="SalaryHistory">
                  <SalaryHistory currentEmployee={currentEmployee} />
                </TabPane>
              </Tabs>
            </div>
          )}
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    allEmployees: state.employee.allEmployees,
    selectedEmployeeID: state.employee.selectedEmployeeID
  }
}

export default connect(mapStateToProps)(EmployeeDetails)
