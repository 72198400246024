import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

function WarehouseFilter({ values, setValues, filterData }) {
  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field name="warehouse" label="WH" />
      </div>
      <div className="form-fields">
        <Field name="description" label="Warehouse Desc" />
      </div>
      <div className="form-fields">
        <Field name="location" label="Storage Loc." />
      </div>
      <div className="form-fields">
        <Field name="locationDescription" label="Storage Loc Desc" />
      </div>
      <div className="form-fields">
        <Field name="rack" label="Rack" />
      </div>
      <div className="form-fields">
        <Field name="rackDescription" label="Rack Desc" />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary">
          <i className="flaticon-search-interface-symbol" /> Search
        </Button>
        <Button type="reset" className="btn-glow btn-block search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    warehouse: '',
    description: '',
    location: '',
    locationDescription: '',
    rack: '',
    rackDescription: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(WarehouseFilter)
