import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TableBox from '../../../../Components/TableBox/TableBox'
import FilterLayout from '../../../../Layout/FilterLayout'
import TableLayout from '../../../../Layout/TableLayout'
import apiClient from '../../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../../Util/Util'
import PriceChargeFilter from './PriceChargeFilter'

export default function PriceCharges() {
  const [priceCharges, setPriceCharges] = useState([])
  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('priceCharges.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('price-charges', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('priceCharges.filterData', { ...params, ...data.pageData })
        setPriceCharges(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onDelete = (id) => {
    apiClient.delete(`price-charges/${id}`).then(({ status }) => {
      if (status === 204) {
        setPriceCharges(priceCharges.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-price-charge') && (
          <li>
            <Link to={`/app/edit-price-charge/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-price-charge') && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'Currency',
      dataIndex: 'currency'
    },
    {
      title: 'Price',
      dataIndex: 'price'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Price Charge',
        onClick: () => history.push('/app/add-price-charge'),
        access: 'add-price-charge'
      }}
      filterData={GET_DATA('priceCharges.filterData')}
      filter={<PriceChargeFilter onSubmit={getData} />}>
      <TableLayout title="Price Charges Overview" exportUrl="price-charges/export">
        <TableBox
          dataSource={priceCharges}
          columns={columns}
          pageData={GET_DATA('priceCharges.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
