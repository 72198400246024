import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import AccountChartFilter from './AccountChartFilter'

export default function AccountCharts() {
  const [accountCharts, setAccountCharts] = useState([])
  const history = useHistory()

  const onFilter = (params = {}) => {
    apiClient.get('account-charts/get', { params }).then(({ data }) => {
      if (data.result) {
        SET_DATA('accountCharts.filterData', { ...params, ...data.pageData })
        setAccountCharts(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('accountCharts.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('accountCharts.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Account Code',
      dataIndex: 'accountCode'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Account Group Name',
      dataIndex: 'accountGroupData',
      render: (v) => v?.name
    },
    {
      title: 'Account Group',
      dataIndex: 'accountGroup'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      ...(validateAccess('edit-account-chart') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/edit-account-chart/${row.id}`)}
              className="btn glow dropdown-toggle">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<AccountChartFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Account Chart Overview</h2>
      </div>
      <TableBox
        dataSource={accountCharts}
        columns={columns}
        pageData={GET_DATA('accountCharts.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
