import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'
import Panel from '../../../../Layout/Panel'
import PanelLayout from '../../../../Layout/PanelLayout'
import apiClient from '../../../../Util/apiClient'
import { getOperationTypes, OPERATION_TYPES } from '../../../../Util/Options'
import { operationSchema } from '../../../../Util/validationSchema'

function OperationForm({
  values,
  setValues,
  setFieldValue,
  match: {
    params: { id }
  }
}) {
  const getData = () => {
    if (id) {
      apiClient.get(`operations/${id}`).then(({ status, data }) => {
        if (status === 200) {
          setValues({ ...values, ...data })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="Operation Definition">
            <Panel title="Operation">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="type"
                      label="Type"
                      options={OPERATION_TYPES}
                      onChange={(e, v) => {
                        setFieldValue(e, v)

                        return setFieldValue('value', '')
                      }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="select" name="value" label="Value" options={getOperationTypes(values.type)} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="description" label="Description" />
                  </div>
                </Col>
              </Row>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>
            <span>or</span>
            <Link to="/app/operations">
              <Button>
                <ArrowLeftOutlined /> Back to operations
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    type: '',
    value: '',
    description: ''
  }),
  validationSchema: operationSchema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`operations/${id}`, data).then(({ status }) => {
        if (status === 200) {
          history.push('/app/operations')
        }
      })
    } else {
      apiClient.post('operations', data).then(({ status }) => {
        if (status === 201) {
          history.push('/app/operations')
        }
      })
    }
  }
})(OperationForm)
