import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import './Payment.scss'

const Payment = () => (
  <Form>
    <Header />

    {/* Breadcrum starts */}
    <section className="breadcrum-title text-center">
      <div className="container-fluid">
        <Row justify="center">
          <Col span={21}>
            <Row justify="left">
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="breadcrum-content">
                  <h1>Payment</h1>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
    {/* breadcrum ends here */}

    <section className="payment-section">
      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 17 }}>
          <h4>All set to go</h4>
          <h3>Please provide your Card details</h3>
          <Row justify="left" gutter={(2, 2)}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
              <div className="instruction">
                <div className="total-pay">
                  <span>Total to Pay</span>
                  <div className="price">24 $</div>
                </div>
                <div className="instruction-list">
                  <ul>
                    <li>
                      Your 14 days free trial ends on <span>12-Aug-2021 12:00 AM</span>
                    </li>
                    <li>
                      Your card will be charged <span>24:00 $ on 12-Aug-2021 12:00 AM</span>
                    </li>
                    <li>
                      If you wish to terminate, please do it on or before <span>12-Aug-2021 12:00 AM</span>
                    </li>
                  </ul>
                </div>
                <div className="go-back">
                  <Link to="./review-order">
                    <i className="flaticon-back" /> Go Back
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }}>
              <div className="payment-details">
                <h1>Your Payment Details</h1>
                <div className="card-information">
                  <div className="form-fields">
                    <Field name="nameontheCard" label="Name on the card" type="text" />
                  </div>
                  <div className="form-fields">
                    <Field name="cardNumber" label="Company Name" type="number" />
                  </div>
                  <Row justify="center" gutter={[20]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-fields">
                        <Field name="expiryDate" label="Expiry Date" as="date" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-fields">
                        <Field name="expiryYear" label="Year" as="date" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-fields">
                        <Field name="cvv" label="CVV" type="number" />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="enroll-buttons">
                  <ButtonBox className="ant-btn-primary" type="primary">
                    CONFIRM CARD DETAILS
                  </ButtonBox>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>

    <Footer />
  </Form>
)
export default withFormik({
  mapPropsToValues: () => ({
    nameontheCard: '',
    expiryDate: '',
    expiryYear: '',
    cvv: ''
  }),
  handleSubmit: () => null
  // validationSchema: Schema
})(Payment)
