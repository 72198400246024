import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getMasterCurrencies, getOptionsByType } from '../../../../Actions/UserAction'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import FieldArray from '../../../../Components/Formik/FieldArray'
import Form from '../../../../Components/Formik/Form'
import Panel from '../../../../Layout/Panel'
import PanelLayout from '../../../../Layout/PanelLayout'
import apiClient from '../../../../Util/apiClient'
import { DEFAULT_PACKAGE_DETAILS, OPERATION_TYPES } from '../../../../Util/Options'
import { convertSelectOptions } from '../../../../Util/Util'
import { offerSchema } from '../../../../Util/validationSchema'
import SinglePackageDetails from './SinglePackageDetails'

function OfferForm({
  values,
  setValues,
  setFieldValue,
  submitForm,
  match: {
    params: { id }
  },
  history
}) {
  const [quotations, setQuotations] = useState([])
  const [POLPOD, setPOLPOD] = useState([])
  const [operations, setOperations] = useState([])
  const [units, setUnits] = useState([])
  const [packageTypes, setPackageTypes] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [templates, setTemplates] = useState([])

  const disabled = values.status === 'Sent' || values.status === 'Approved'

  const { tax } = useSelector((state) => state.users.companyInfo)

  const getData = () => {
    apiClient.get('pol-pod/search').then(({ status, data }) => {
      if (status === 200) {
        setPOLPOD(
          data.map((item) => ({
            label: item.code,
            value: item.code,
            ...item
          }))
        )
      }
    })

    apiClient.get('packages-types/search').then(({ status, data }) => {
      if (status === 200) {
        setPackageTypes(
          data.map((item) => ({
            label: item.type,
            value: item.type,
            ...item
          }))
        )
      }
    })

    getOptionsByType({
      type: ['UnitOfMeasurement']
    }).then(({ UnitOfMeasurement = [] }) => {
      setUnits(UnitOfMeasurement)
    })

    getMasterCurrencies().then((data) => setCurrencies(data))

    handleOfferType(values.offerType)

    apiClient
      .get('customTemplates/getActive', { params: { type: 'Freight', for: 'Offer' } })
      .then(({ data }) => {
        if (data && data.result) {
          const templates = [
            { label: 'Default', value: 'Default' },
            ...convertSelectOptions(data.result, 'name', 'id')
          ]
          setTemplates(templates)
        }
      })

    if (id) {
      apiClient.get(`offers/${id}`).then(({ status, data }) => {
        if (status === 200) {
          data.template = data.template ? data.template : 'Default'
          setValues({ ...values, ...data })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleOfferType = (type) => {
    apiClient.get('operations/search', { params: { type } }).then(({ status, data }) => {
      if (status === 200) {
        setFieldValue('operations', '')
        setOperations(
          data.map((item) => ({
            label: item.value,
            value: item.value,
            ...item
          }))
        )
      }
    })
  }

  const handleQuotationSearch = (quotationNo) => {
    setFieldValue('quotationNo', quotationNo)
    apiClient.get('sales-call-entries/search', { params: { quotationNo } }).then(({ status, data }) => {
      if (status === 200) {
        setQuotations(
          data.map((item) => ({
            label: item.quotationNo,
            value: item.quotationNo,
            ...item
          }))
        )
      }
    })
  }

  const handleQuotationSelect = (name, value, { salesPerson = {}, ...option } = {}) => {
    setValues({
      ...values,
      ..._.pick(option, [
        'quotationNo',
        'clientName',
        'contactPerson',
        'street',
        'city',
        'state',
        'postalCode',
        'country',
        'phone',
        'email'
      ]),
      salesPerson: `${salesPerson.firstName} ${salesPerson.lastName} (${salesPerson.email})` || ''
    })
  }

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={22}>
          <PanelLayout title="Offer Definition">
            <Panel title="Offer">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={8} className="d-flex align-items-center">
                  <Field
                    as="radio-group"
                    name="offerType"
                    label="Offer Type"
                    options={OPERATION_TYPES}
                    onChange={(name, value) => {
                      setFieldValue(name, value)
                      handleOfferType(value)
                    }}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="auto-complete"
                      name="quotationNo"
                      label="Quotation No"
                      onSearch={handleQuotationSearch}
                      onChange={handleQuotationSelect}
                      options={quotations}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="clientName" label="Client Name" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="contactPerson" label="Contact Person" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="street" label="Street" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="city" label="City" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="state" label="State" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="postalCode" label="Postal Code" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="country" label="Country" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="phone" label="Phone" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="email" label="Email" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="select" name="POLPOD" label="POL/POD" options={POLPOD} disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field type="number" name="actualWeight" label="Actual Weight" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      type="number"
                      name="chargeableWeight"
                      label="Chargeable Weight"
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="requestedDate" label="Requested Date" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="completedDate" label="Completed Date" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="operations"
                      label="Operations"
                      options={operations}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="salesPerson" label="Sales Person" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="reference" label="Reference" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="specialInstruction" label="Special Instruction" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="comments" label="Comments" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="statement" label="Statement" />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Package details">
              <FieldArray
                name="packageList"
                loaderCount={8}
                additionalValues={{ options: { packageTypes, currencies, units }, disabled }}
                defaultValues={{
                  ...DEFAULT_PACKAGE_DETAILS,
                  position: (values.packageList.length + 1) * 10,
                  tax
                }}
                editable={!disabled}>
                {SinglePackageDetails}
              </FieldArray>
            </Panel>
            <Panel title="Template">
              <Row>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <div className="form-field">
                    <Field as="select" name="template" label="Template" options={templates} />
                  </div>
                </Col>
              </Row>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            <Button
              variant="primary"
              onClick={() => {
                setFieldValue('submitting', false)
                submitForm()
              }}>
              {id ? 'Update' : 'Save'}
            </Button>
            {values.status === 'Created' && (
              <>
                <span>or</span>
                <Button
                  onClick={() => {
                    setFieldValue('submitting', true)
                    submitForm()
                  }}>
                  Update and Send for approval
                </Button>
              </>
            )}
            <span>or</span>
            <Link>
              <Button onClick={() => history.goBack()}>
                <ArrowLeftOutlined /> Back to offers
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    offerType: 'Air',
    quotationNo: '',
    clientName: '',
    contactPerson: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phone: '',
    email: '',
    POLPOD: '',
    actualWeight: '',
    chargeableWeight: '',
    requestedDate: moment(),
    completedDate: '',
    operations: '',
    salesPerson: '',
    reference: '',
    specialInstruction: '',
    comments: '',
    statement: '',
    packageList: [DEFAULT_PACKAGE_DETAILS],
    template: 'Default'
  }),
  validationSchema: offerSchema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      },
      setFieldValue
    }
  ) => {
    data.template = data.template === 'Default' ? null : data.template

    if (id) {
      apiClient.put(`offers/${id}`, data).then(({ status }) => {
        if (status === 200) {
          if (data.submitting) {
            message.success('Offer Sent')
            setFieldValue('status', 'Sent')
            history.push(`/app/offers/${id}`)
          } else {
            history.push(`/app/offers/${id}`)
          }
        }
      })
    } else {
      apiClient.post('offers', data).then(({ status, data }) => {
        if (status === 201) {
          history.push(`/app/offers/${data.id}`)
        }
      })
    }
  }
})(OfferForm)
