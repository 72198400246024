import { Col, message, Row } from 'antd'
import React, { Component } from 'react'
import FooterActions from '../../../../Components/FooterActions'
import TemplateView from '../../../../Components/TemplateView/TemplateView'
import apiClient from '../../../../Util/apiClient'
import Header from '../../../Header/Header'

export default class OfferView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: []
    }

    this.getOffer()
  }

  getOffer = () => {
    apiClient.get(`offers/view/${this.props.match.params.token}`).then(({ status, data }) => {
      if (status === 200) {
        this.setState({ data: data.result })
      } else {
        this.props.history.push('/')
      }
    })
  }

  statusUpdate = (status) => {
    apiClient
      .post(`offers/update-status/${this.props.match.params.token}`, { status })
      .then(({ status, data }) => {
        if (status === 200) {
          message.success(data.message)
          this.props.history.push('/')
        }
      })
  }

  render() {
    return (
      <>
        <Header noMenu />
        <Row>
          <Col xs={24} style={{ background: '#f3f3f3' }}>
            <div style={{ paddingBottom: 70 }}>
              <TemplateView contents={this.state.data} />
            </div>
            <FooterActions
              centerActions={[
                {
                  prefix: 'flaticon-tick',
                  label: 'Accept',
                  onClick: () => this.statusUpdate('Accepted')
                },
                {
                  prefix: 'flaticon-delete',
                  label: 'Reject',
                  onClick: () => this.statusUpdate('Rejected')
                }
              ]}
            />
          </Col>
        </Row>
      </>
    )
  }
}
