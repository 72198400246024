import { SettingOutlined } from '@ant-design/icons'
import { message, Popconfirm, Popover, Tabs } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import LoaderBox from '../../../Components/LoaderBox/LoaderBox'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import Logs from '../../Logs/Logs'
import SalesQuotationFilter from './SalesQuotationFilter'

const { TabPane } = Tabs

export default function SalesQuotations() {
  const [salesQuotations, setSalesQuotations] = useState([])
  const [tabKey, setTabKey] = useState(GET_DATA('salesQuotations.tabKey') || 'Created')
  const [selectedRows, setSelectedRows] = useState([])
  const [loader, setLoader] = useState(false)
  const [viewLogs, setViewLogs] = useState(false)

  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('salesQuotations.filterData') || {}
    params = { ...filterCache, ...params }

    if (params.quotationDate) {
      params.quotationDate = moment(params.quotationDate).utc().startOf('day').toISOString()
    }

    if (tabKey !== 'All') {
      params.status = tabKey
    } else {
      params.status = undefined
    }

    apiClient.get('sales-quotations', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('salesQuotations.filterData', { ...params, ...data.pageData })
        setSalesQuotations(data.result)
        setLoader(data.result.length > 0 ? false : 'No data found')
      }
    })
  }

  useEffect(() => {
    getData()
    SET_DATA('salesQuotations.tabKey', tabKey)
  }, [tabKey])

  const onDelete = (id) => {
    apiClient.delete(`sales-quotations/${id}`).then(({ status }) => {
      if (status === 204) {
        setSalesQuotations(salesQuotations.filter((item) => item.id !== id))
      }
    })
  }

  const onSubmit = (quotationIds = selectedRows.map((val) => val.id)) => {
    apiClient.post('sales-quotations/submit', { quotationIds }).then(({ status }) => {
      if (status === 200) {
        message.success('SalesQuotations Sent')
        setSalesQuotations(salesQuotations.filter((item) => !quotationIds.includes(item.id)))
        setSelectedRows([])
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-sales-quotation') && (
          <li>
            <Link to={`/app/edit-sales-quotation/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-sales-quotation') && row.status === 'Created' && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
        {validateAccess('edit-sales-quotation') && row.status === 'Created' && (
          <li>
            <Link to="#" onClick={() => onSubmit([row.id])}>
              <i className="flaticon-tick-1" /> Submit
            </Link>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => setViewLogs(row)}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Quotation No',
      dataIndex: 'quotationNo',
      render: (v, r) => (
        <div onClick={() => history.push(`/app/sales-quotations/${r.id}`)}>
          <a>{v}</a>
        </div>
      )
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName'
    },
    {
      title: 'Billing Address',
      dataIndex: 'billingAddress',
      render: (value) =>
        _.compact([value.street, value.city, value.state, value.postalCode, value.country]).join(', ')
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Sales Quotation',
        onClick: () => history.push('/app/add-sales-quotation'),
        access: 'add-sales-quotation'
      }}
      filterData={GET_DATA('salesQuotations.filterData')}
      filter={<SalesQuotationFilter onSubmit={getData} />}>
      <ModalBox
        title="Sales Quotation Logs"
        visible={!!viewLogs}
        onCancel={() => setViewLogs(false)}
        footer={false}
        destroyOnClose>
        <Logs entityId={viewLogs?.id} entityType="Sales Quotation" />
      </ModalBox>
      <TableLayout
        title="Sales Quotations Overview"
        exportUrl="sales-quotations/export"
        detailed
        rightSection={
          selectedRows.length > 0 && (
            <>
              {tabKey === 'Created' && (
                <Button variant="primary" onClick={() => onSubmit()}>
                  Send for Approval
                </Button>
              )}
              {selectedRows.length === 1 && tabKey === 'Accepted' && (
                <Button
                  variant="primary"
                  onClick={() => history.push(`/app/add-booking/${selectedRows[0].id}`)}>
                  Create Booking
                </Button>
              )}
            </>
          )
        }>
        <Tabs activeKey={tabKey} onChange={setTabKey}>
          <TabPane tab="Created" key="Created" />
          <TabPane tab="Sent" key="Sent" />
          <TabPane tab="Accepted" key="Accepted" />
          <TabPane tab="Rejected" key="Rejected" />
          <TabPane tab="All" key="All" />
        </Tabs>
        {!loader && (
          <TableBox
            dataSource={salesQuotations}
            columns={columns}
            onSelect={setSelectedRows}
            selectedRows={selectedRows}
            pageData={GET_DATA('salesQuotations.filterData')}
            onChangePage={getData}
          />
        )}
        <LoaderBox loader={loader} />
      </TableLayout>
    </FilterLayout>
  )
}
