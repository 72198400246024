import Input from 'antd/lib/input'
import React, { FunctionComponent, memo } from 'react'
import useDebounceEffect from '../../Hooks/useDebounceEffect'
import AltInput from '../AltInput'
import { arabicRegex, InputValueType, TTextArea } from './types'

const { TextArea: TextAreaField } = Input

const TextArea: FunctionComponent<TTextArea> = ({
  rows,
  onChange,
  onChangeAlt,
  onBlur,
  label,
  error,
  required,
  value,
  delay,
  hideLabel,
  altDisabled,
  altValue,
  altInput,
  ...props
}) => {
  const [val, setValue] = useDebounceEffect((v: InputValueType) => onChange?.(props.name, v), value, delay)

  return (
    <div className="custom-input-box">
      {label && !hideLabel && (
        <label style={{ textAlign: 'left', width: 'fit-content' }}>
          {label} {required && <span className="required">*</span>}
        </label>
      )}
      {altInput && (
        <AltInput {...props} {...{ label, altDisabled, altValue, altInput, onChange: onChangeAlt }} />
      )}

      <TextAreaField
        rows={rows}
        onChange={(e) => {
          if (!props.disabled && onChange) {
            setValue(e.target.value)
          }
        }}
        onBlur={() => onBlur?.(props.name, val)}
        value={val}
        dir={arabicRegex.test(val?.toString()) ? 'rtl' : 'ltr'}
        {...props}
      />

      {error && (
        <div style={{ fontSize: 10, color: 'red', textAlign: 'right' }}>
          {error.replace(props.name, label || props.placeholder || '')}
        </div>
      )}
    </div>
  )
}

TextArea.defaultProps = {
  rows: 2,
  delay: 500
}

export default memo(TextArea)
