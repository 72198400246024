import { combineReducers } from 'redux'
import templates from '../Screens/Templates/Reducer'
import empContributionReducer from './EmpContributionReducer'
import employee from './EmployeeReducer'
import expenseClaims from './ExpenseClaimReducer'
import times from './TimeReducer'
import users from './UserReducer'

export default combineReducers({
  users,
  times,
  expenseClaims,
  empContributionReducer,
  templates,
  employee
})
