import { SettingOutlined } from '@ant-design/icons'
import { Col, message, Popconfirm, Popover, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  addEducationDetails,
  getEducationDetailsByEmployeeID,
  updateEducationDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import Form from '../../../Components/Formik/Form'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import EducationDetailsForm from './EducationDetailsForm'

const Schema = Yup.object().shape({
  level: Yup.string().required(),
  score: Yup.number().required(),
  year: Yup.date().required(),
  attachments: Yup.array()
})

const EducationDetails = (props) => {
  const [toggle, setToggle] = useState(false)
  const [isAddType, setIsAddType] = useState(true)
  const { currentEmployee, educationDetails, dispatch, values, setValues, submitForm, errors } = props

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getEducationDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const handleAddNewDetails = () => {
    if (currentEmployee?.id) {
      setToggle(true)
      setIsAddType(true)
      setValues({
        level: '',
        score: '',
        year: '',
        attachments: [],
        isAddType: true
      })
    } else {
      message.error('Please select an employee to continue!')
    }
  }

  const handleEditRow = (row) => () => {
    setToggle(true)
    setValues({ ...row, isAddType: false })
    setIsAddType(false)
  }

  const handleValueChange = (val) => {
    setValues({ ...values, ...val })
  }

  const deleteRow = (row) => () => {
    dispatch(updateEducationDetailsByEmployeeID(row.id, { status: 'Inactive' }))
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setToggle(false)
    }
  }

  const columns = [
    {
      title: 'Level',
      dataIndex: 'level'
    },
    {
      title: 'Year',
      dataIndex: 'year',
      render: (text) => moment(text).format('yyyy')
    },
    {
      title: 'GPA/Score',
      dataIndex: 'score'
    },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      render: (text) => text.map((attachment) => attachment.name).join(', ')
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              {' '}
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li onClick={handleEditRow(row)}>
          <i className="flaticon-edit-1" /> Edit
        </li>
        <li>
          <Popconfirm title="Sure to delete?" onConfirm={deleteRow(row)}>
            <i className="flaticon-delete-2" /> Delete
          </Popconfirm>
        </li>
      </ul>
    </div>
  )

  return (
    <Form>
      <PanelLayout>
        <Panel title="Education Details">
          <div className="panel-with-border">
            <Row justify="left" gutter={(12, 10)}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="mb-3 align-right">
                  <ButtonBox style={{ marginRight: 10 }} type="success" onClick={handleAddNewDetails}>
                    <i className="flaticon-plus" /> Add
                  </ButtonBox>
                  {/* <ButtonBox type="primary">
                    {' '}
                    <i className="flaticon-delete-3" /> Delete{' '}
                  </ButtonBox> */}
                </div>
                <div className="table-view">
                  <TableBox
                    columns={columns}
                    actionIndex="custom_action"
                    cardHeaderIndex="status"
                    cardFirstLabelIndex="docno"
                    dataSource={educationDetails}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Panel>
      </PanelLayout>

      <ModalBox
        title={`${isAddType ? 'Add' : 'Edit'} Education Details`}
        visible={toggle}
        onCancel={() => setToggle(false)}
        width={700}
        okText="Save"
        onOk={onSave}
        destroyOnClose>
        <EducationDetailsForm currentDetails={values} handleValueChange={handleValueChange} />
      </ModalBox>
    </Form>
  )
}

const mapStateToProps = (state) => ({
  educationDetails: state.employee.employeeDetails.educationDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      level: '',
      score: '',
      year: '',
      attachments: []
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      const { id, isAddType, ...rest } = values
      const payload = {
        level: rest.level,
        score: rest.score,
        year: rest.year,
        employee: currentEmployee.id,
        attachments: rest.attachments.filter((attachment) => attachment !== '')
      }

      if (isAddType) {
        dispatch(addEducationDetails(payload))
      } else {
        dispatch(updateEducationDetailsByEmployeeID(id, payload))
      }
    },
    validationSchema: Schema
  })(EducationDetails)
)
