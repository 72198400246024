import { Checkbox, Col, Popconfirm, Row } from 'antd'
import { withFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import TableBox from '../../../Components/TableBox/TableBox'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { MONTHS } from '../../../Util/Options'

function FinancialYears({ values: { filterPeriod, year, startMonth } }) {
  const [data, setData] = useState([])

  const getData = () => {
    apiClient
      .get('financial-years', { params: { financialYear: year.format('YYYY') } })
      .then(({ status, data }) => {
        if (status === 200) {
          setData(data)
        }
      })
  }

  useEffect(() => {
    getData()
  }, [year])

  const onSubmit = () => {
    apiClient
      .post('financial-years', { year, startMonth: moment().set('month', startMonth) })
      .then(({ status }) => {
        if (status === 201) {
          getData()
        }
      })
  }

  const updateFlag = (id) => {
    apiClient.put(`financial-years/${id}`).then(({ status }) => {
      if (status === 200) {
        getData()
      }
    })
  }

  const columns = [
    {
      title: 'Year',
      dataIndex: 'year'
    },
    {
      title: 'Period',
      dataIndex: 'period'
    },
    {
      title: 'Close?',
      dataIndex: 'closeFlag',
      render: (text, row) =>
        text === 'N' &&
        moment()
          .set({
            month: row.period - 1,
            year: row.year
          })
          .isBefore(moment()) ? (
          <Popconfirm
            title={
              <div>
                <div>Do you really want to close the period?</div>
                You can&apos;t open again
              </div>
            }
            onConfirm={() => updateFlag(row.id)}>
            <Checkbox checked={text === 'Y'}>{text}</Checkbox>
          </Popconfirm>
        ) : (
          <label className="ant-checkbox-wrapper">{text}</label>
        )
    }
  ]

  return (
    <Row justify="center" className="inner-contents">
      <Col xs={22} sm={22} md={20} lg={20}>
        <PanelLayout title="Financial year">
          <Form>
            <div className="mb-2">
              <Field as="checkbox" name="filterPeriod" label="Show Open Period" />
            </div>
            <Row gutter={[20, 10]}>
              <Col xs={8} xl={4}>
                <div className="form-field">
                  <Field as="date" name="year" label="Year" picker="year" allowClear={false} />
                </div>
              </Col>
              <Col xs={8} xl={4}>
                <div className="form-field">
                  <Field as="select" name="startMonth" label="Start Month" options={MONTHS} />
                </div>
              </Col>
              <Col className="mt-auto">
                <Popconfirm
                  title={
                    <div>
                      <div>Are you sure want to create?</div>
                      {startMonth} - {year.format('YYYY')}
                    </div>
                  }
                  onConfirm={() => onSubmit()}>
                  <Button>Generate</Button>
                </Popconfirm>
              </Col>
            </Row>
            <TableBox
              dataSource={data.filter((item) => item.closeFlag === 'N' || !filterPeriod)}
              columns={columns}
            />
          </Form>
        </PanelLayout>
      </Col>
    </Row>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    filterPeriod: false,
    year: moment(),
    startMonth: moment().format('MMM')
  }),
  handleSubmit: () => null
})(FinancialYears)
