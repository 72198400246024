import { Col, Row } from 'antd'
import React, { memo } from 'react'
import Field from '../../../../Components/Formik/Field'
import FieldArray from '../../../../Components/Formik/FieldArray'
import { DEFAULT_PACKAGE_ITEMS, LBH_UNITS, VOLUME_UNITS, WEIGHT_UNITS } from '../../../../Util/Options'
import SingleItemDetails from './SingleItemDetails'

function SinglePackageDetails({ i, tax, options, disabled, setFieldValue }) {
  return (
    <div>
      <Row gutter={[10, 5]}>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`packageList[${i}].position`} label="Position" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={4}>
          <div className="form-field">
            <Field
              as="select"
              name={`packageList[${i}].packageType`}
              label="Package Type"
              options={options.packageTypes}
              onChange={(n, v, o) => {
                setFieldValue(`packageList[${i}].length`, o?.length)
                setFieldValue(`packageList[${i}].breadth`, o?.breadth)
                setFieldValue(`packageList[${i}].height`, o?.height)
                setFieldValue(`packageList[${i}].unit`, o?.unit)
                setFieldValue(`packageList[${i}].weight`, o?.weight)
                setFieldValue(`packageList[${i}].weightUnit`, o?.weightUnit)
                setFieldValue(`packageList[${i}].volume`, o?.volume)
                setFieldValue(`packageList[${i}].volumeUnit`, o?.volumeUnit)

                return setFieldValue(n, v)
              }}
              disabled={disabled}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`packageList[${i}].length`} label="Length" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`packageList[${i}].breadth`} label="Breadth" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`packageList[${i}].height`} label="Height" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field
              as="select"
              name={`packageList[${i}].unit`}
              label="L,B,H Unit"
              options={LBH_UNITS}
              disabled={disabled}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`packageList[${i}].weight`} label="Weight" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={3}>
          <div className="form-field">
            <Field
              as="select"
              name={`packageList[${i}].weightUnit`}
              label="Weight Unit"
              options={WEIGHT_UNITS}
              disabled={disabled}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`packageList[${i}].volume`} label="Volume" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={3}>
          <div className="form-field">
            <Field
              as="select"
              name={`packageList[${i}].volumeUnit`}
              label="Volume Unit"
              options={VOLUME_UNITS}
              disabled={disabled}
            />
          </div>
        </Col>
      </Row>
      <div className="pt-3 px-4 m-4 position-relative border rounded">
        <FieldArray
          name={`packageList[${i}].items`}
          loaderCount={8}
          additionalValues={{
            parentName: `packageList[${i}].items`,
            options,
            disabled
          }}
          defaultValues={{ ...DEFAULT_PACKAGE_ITEMS, tempTax: tax, tax }}
          editable={!disabled}>
          {SingleItemDetails}
        </FieldArray>
      </div>
    </div>
  )
}

export default memo(SinglePackageDetails)
