import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoaderBox from '../../../Components/LoaderBox/LoaderBox'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import Logs from '../../Logs/Logs'
import CustomsClearanceFilter from './CustomsClearanceFilter'

const { TabPane } = Tabs

export default function CustomsClearances({ history }) {
  const [tabKey, setTabKey] = useState(GET_DATA('customsClearances.tabKey') || 'Created')
  const [customsClearances, setCustomsClearances] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [loader, setLoader] = useState(false)
  const [viewLogs, setViewLogs] = useState(false)

  const getData = (params = {}) => {
    setSelectedRows([])

    const filterCache = GET_DATA(`customsClearances${tabKey}.filterData`) || {}
    params = { ...filterCache, ...params }

    if (tabKey !== 'All') {
      params.status = tabKey
    } else {
      params.status = undefined
    }

    if (params.clearanceDate) {
      params.clearanceDate = moment(params.clearanceDate).utc().startOf('day').toISOString()
    }

    apiClient.get('customs-clearances', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA(`customsClearances${tabKey}.filterData`, { ...params, ...data.pageData })
        setCustomsClearances(data.result)
        setLoader(data.result.length > 0 ? false : 'No data found')
      }
    })
  }

  useEffect(() => {
    getData()
    SET_DATA('customsClearances.tabKey', tabKey)
  }, [tabKey])

  const onDelete = (id) => {
    apiClient.delete(`customs-clearances/${id}`).then(({ status }) => {
      if (status === 204) {
        setCustomsClearances(customsClearances.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-customs-clearance') && (
          <li>
            <Link to={`/app/edit-customs-clearance/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-customs-clearance') && (row.status === 'Created' || row.status === 'Rejected') && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-1" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => setViewLogs(row)}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Clearance No',
      dataIndex: 'clearanceNo',
      render: (text, row) => <Link to={`/app/customs-clearances/${row.id}`}>{text}</Link>
    },
    {
      title: 'Clearance Date',
      dataIndex: 'clearanceDate',
      render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Forwarder',
      dataIndex: 'forwarderName'
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierName'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Customs Clearances',
        onClick: () => history.push('/app/search-customs-clearance'),
        access: 'add-customs-clearance'
      }}
      filterData={GET_DATA(`customsClearances${tabKey}.filterData`)}
      filter={<CustomsClearanceFilter onSubmit={getData} />}>
      <ModalBox
        title="Customs Clearance"
        visible={!!viewLogs}
        onCancel={() => setViewLogs(false)}
        footer={false}
        destroyOnClose>
        <Logs entityId={viewLogs?.id} entityType="Customs Clearance" />
      </ModalBox>
      <TableLayout title="Customs Clearances / All documents" exportUrl="customs-clearances/export" detailed>
        <Tabs activeKey={tabKey} onChange={setTabKey}>
          <TabPane tab="Created" key="Created" />
          <TabPane tab="Approved" key="Approved" />
          <TabPane tab="All" key="All" />
        </Tabs>
        {!loader && (
          <TableBox
            className="px-0"
            dataSource={customsClearances}
            columns={columns}
            onSelect={setSelectedRows}
            selectedRows={selectedRows}
            pageData={GET_DATA(`customsClearances${tabKey}.filterData`)}
            onChangePage={getData}
          />
        )}
        <LoaderBox loader={loader} />
      </TableLayout>
    </FilterLayout>
  )
}
