import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Footer from '../Screens/Footer/Footer'
import Header from '../Screens/Header/Header'

function RouteWrapper(props) {
  const history = useHistory()

  history.listen(() => {
    const appView = document.getElementById('app-view')

    if (appView) {
      appView.scrollIntoView()
    }
  })
  const { screen: Comp, metaObj } = props
  const defaultMetaobject = {
    title: metaObj
      ? metaObj.title
      : 'Online e-invoicing solution provider in fastest way with Zatca Guidelines in Saudi Arabia',
    description: metaObj
      ? metaObj.description
      : 'Online e-invoicing solution provider in Saudi Arabia with step-by-step procedure using Zatca guidelines',
    keyword: metaObj
      ? metaObj.keyword
      : 'e-invoicing , Online invoice, zatca e invoicing,  einvoice solutions, e-invoice in Saudi, einvoice solutions, e-invoice in Saudi, step-by-step guideline for zatca solution provider, e-invoice guidelines for Zatca, Fastest electronic invoice solution.'
  }

  return (
    <>
      <span id="app-view" />
      <Helmet>
        <title>{defaultMetaobject.title}</title>
        <meta name="description" content={defaultMetaobject.description} />
        <meta name="keyword" content={defaultMetaobject.keyword} />
      </Helmet>
      <Header />
      <Comp {...props} />
      <Footer />
    </>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.users.lang
  }
}

export default connect(mapStateToProps)(RouteWrapper)
