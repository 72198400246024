import { withFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

const Schema = Yup.object().shape({
  client: Yup.string().required(),
  month: Yup.date().required()
})

function Filter(props) {
  const onSubmitForm = async () => {
    props.validateForm().then((err) => {
      if (_.isEmpty(err)) {
        props.onFilter(_(props.values).omitBy(_.isEmpty).value())
      }
    })
  }

  const onClear = () => {
    props.resetForm({})
    props.onFilter({})
  }

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field
            name="client"
            label="Client"
            as="paged-select"
            endPoint="clients/getAllActive"
            params={{ type: ['Customer', 'Both'] }}
          />
        </div>
        <div className="form-fields">
          <Field name="month" label="Month" as="date" picker="month" />
        </div>
        <div className="form-fields">
          <Field name="poNumber" label="Purchase Order" />
        </div>
        <div className="form-fields">
          <Field name="projectName" label="Project" />
        </div>
        <div className="form-fields">
          <Field name="employeeName" label="Employee" />
        </div>

        <div className="form-fields">
          <Button variant="secondary" className="search" onClick={onSubmitForm}>
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button className="btn-block btn-glow search" onClick={onClear}>
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    client: '',
    month: '',
    poNumber: '',
    projectName: '',
    employeeName: ''
  }),
  handleSubmit: () => null,
  validationSchema: Schema
})(Filter)
