import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { getMasterCurrencies, getOptionsByType } from '../../../../Actions/UserAction'
import Attachments from '../../../../Components/Attachments'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import FieldArray from '../../../../Components/Formik/FieldArray'
import Form from '../../../../Components/Formik/Form'
import Panel from '../../../../Layout/Panel'
import PanelLayout from '../../../../Layout/PanelLayout'
import apiClient from '../../../../Util/apiClient'
import { DEFAULT_PACKAGE_DETAILS, OPERATION_TYPES } from '../../../../Util/Options'
import { convertSelectOptions } from '../../../../Util/Util'
import { addressFieldSchema } from '../../../../Util/validationSchema'
import SinglePackageDetails from './SinglePackageDetails'

const attachmentsFor = [
  { label: 'Purchase order', value: 'Purchase order' },
  { label: 'Proforma invoice', value: 'Proforma invoice' },
  { label: 'Certificate of conformity', value: 'Certificate of conformity' },
  { label: 'Certificate of origin', value: 'Certificate of origin' },
  { label: 'Export license', value: 'Export license' },
  { label: 'Export Packing list', value: 'Export Packing list' },
  { label: 'Inspection certificate', value: 'Inspection certificate' },
  { label: 'Insurance certificate', value: 'Insurance certificate' }
]

export const Schema = addressFieldSchema.shape({
  quotationNo: Yup.string().required(message),
  clientName: Yup.string().required(message),
  contactPerson: Yup.string().required(message),
  phone: Yup.string().required(message),
  email: Yup.string().email().required(message),
  POLPOD: Yup.string().required(message),
  actualWeight: Yup.string().required(message),
  chargeableWeight: Yup.string().required(message),
  requestedDate: Yup.date().required(message),
  operations: Yup.string().required(message),
  packageList: Yup.array()
    .of(
      Yup.object().shape({
        packageType: Yup.string().required(message),
        length: Yup.number().decimal().required(message),
        breadth: Yup.number().decimal().required(message),
        height: Yup.number().decimal().required(message),
        unit: Yup.string().required(message),
        weight: Yup.number().decimal().required(message),
        weightUnit: Yup.string().required(message),
        volume: Yup.number().decimal().required(message),
        volumeUnit: Yup.string().required(message),
        items: Yup.array()
          .of(
            Yup.object().shape({
              description: Yup.string().required(message),
              quantity: Yup.number().number().required(message),
              unit: Yup.string().required(message),
              price: Yup.number().number().required(message),
              currency: Yup.string().required(message),
              charge: Yup.number().decimal(),
              taxType: Yup.string().required(message),
              tax: Yup.number()
                .decimal()
                .when('taxType', {
                  is: (taxType) => taxType === 'Normal VAT',
                  then: Yup.number().required(message)
                })
            })
          )
          .required(message)
      })
    )
    .required(message)
})

function BookingForm({
  values,
  setValues,
  setFieldValue,
  submitForm,
  match: {
    params: { id, offer }
  },
  history
}) {
  const [quotations, setQuotations] = useState([])
  const [POLPOD, setPOLPOD] = useState([])
  const [operations, setOperations] = useState([])
  const [units, setUnits] = useState({})
  const [packageTypes, setPackageTypes] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [templates, setTemplates] = useState([])

  const disabled = values.status === 'Sent' || values.status === 'Received'

  const { tax } = useSelector((state) => state.users.companyInfo)

  const getData = () => {
    apiClient.get('pol-pod/search').then(({ status, data }) => {
      if (status === 200) {
        setPOLPOD(
          data.map((item) => ({
            label: item.code,
            value: item.code,
            ...item
          }))
        )
      }
    })

    apiClient.get('packages-types/search').then(({ status, data }) => {
      if (status === 200) {
        setPackageTypes(
          data.map((item) => ({
            label: item.type,
            value: item.type,
            ...item
          }))
        )
      }
    })

    getOptionsByType({
      type: ['UnitOfMeasurement']
    }).then(({ UnitOfMeasurement = [] }) => {
      setUnits(UnitOfMeasurement)
    })

    getMasterCurrencies().then((data) => setCurrencies(data))

    handleBookingType(values.type)

    apiClient
      .get('customTemplates/getActive', { params: { type: 'Freight', for: 'Booking' } })
      .then(({ data }) => {
        if (data && data.result) {
          const templates = [
            { label: 'Default', value: 'Default' },
            ...convertSelectOptions(data.result, 'name', 'id')
          ]
          setTemplates(templates)
        }
      })

    if (id) {
      apiClient.get(`bookings/${id}`).then(({ status, data }) => {
        if (status === 200) {
          data.template = data.template ? data.template : 'Default'
          setValues({ ...values, ...data })
        } else {
          history.push('/app/bookings')
        }
      })
    } else if (offer) {
      apiClient.get(`offers/${offer}`).then(({ status, data }) => {
        if (status === 200) {
          const updatedData = _.omit(data, ['_id', 'id', 'status', 'template'])
          setValues({ ...values, ...updatedData, type: updatedData.offerType })
        } else {
          history.push('/app/bookings')
        }
      })
    } else {
      history.push('/app/bookings')
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleBookingType = (type) => {
    apiClient.get('operations/search', { params: { type } }).then(({ status, data }) => {
      if (status === 200) {
        setFieldValue('operations', '')
        setOperations(
          data.map((item) => ({
            label: item.value,
            value: item.value,
            ...item
          }))
        )
      }
    })
  }

  const handleQuotationSearch = (quotationNo) => {
    setFieldValue('quotationNo', quotationNo)
    apiClient.get('sales-call-entries/search', { params: { quotationNo } }).then(({ status, data }) => {
      if (status === 200) {
        setQuotations(
          data.map((item) => ({
            label: item.quotationNo,
            value: item.quotationNo,
            ...item
          }))
        )
      }
    })
  }

  const handleQuotationSelect = (name, value, { salesPerson = {}, ...option } = {}) => {
    setValues({
      ...values,
      ..._.pick(option, [
        'quotationNo',
        'clientName',
        'contactPerson',
        'street',
        'city',
        'state',
        'postalCode',
        'country',
        'phone',
        'email'
      ]),
      salesPerson: `${salesPerson.firstName} ${salesPerson.lastName} (${salesPerson.email})` || ''
    })
  }

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={22}>
          <PanelLayout title="Booking Definition">
            <Panel title="Booking">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={8} className="d-flex align-items-center">
                  <Field
                    as="radio-group"
                    name="type"
                    label="Booking Type"
                    options={OPERATION_TYPES}
                    onChange={(name, value) => {
                      setFieldValue(name, value)
                      handleBookingType(value)
                    }}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="auto-complete"
                      name="quotationNo"
                      label="Quotation No"
                      onSearch={handleQuotationSearch}
                      onChange={handleQuotationSelect}
                      options={quotations}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="clientName" label="Client Name" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="contactPerson" label="Contact Person" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="street" label="Street" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="city" label="City" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="state" label="State" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="postalCode" label="Postal Code" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="country" label="Country" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="phone" label="Phone" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="email" label="Email" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="select" name="POLPOD" label="POL/POD" options={POLPOD} disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field type="number" name="actualWeight" label="Actual Weight" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      type="number"
                      name="chargeableWeight"
                      label="Chargeable Weight"
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="requestedDate" label="Requested Date" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="completedDate" label="Completed Date" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="operations"
                      label="Operations"
                      options={operations}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="salesPerson" label="Sales Person" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="reference" label="Reference" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="specialInstruction" label="Special Instruction" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="comments" label="Comments" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="statement" label="Statement" />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Package details">
              <FieldArray
                name="packageList"
                loaderCount={8}
                additionalValues={{ options: { packageTypes, currencies, units }, disabled }}
                defaultValues={{
                  ...DEFAULT_PACKAGE_DETAILS,
                  position: (values.packageList.length + 1) * 10,
                  tax
                }}
                editable={!disabled}>
                {SinglePackageDetails}
              </FieldArray>
            </Panel>
            <Panel title="Template">
              <Row>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <div className="form-field">
                    <Field as="select" name="template" label="Template" options={templates} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Attachments">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="attachmentRequiredFor"
                      label="Attachments Required For"
                      mode="multiple"
                      options={attachmentsFor}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Attachments
                    name="attachments"
                    value={values.attachments || []}
                    title="Attach Files"
                    description="You can upload a maximum of 3 files, 5MB each"
                    readOnly
                    disabled
                  />
                </Col>
              </Row>
            </Panel>
          </PanelLayout>

          <div className="save-changes">
            <Button
              variant="primary"
              onClick={() => {
                setFieldValue('submitting', false)
                submitForm()
              }}>
              {id ? 'Update' : 'Save'}
            </Button>
            {values.status === 'Created' && (
              <>
                <span>or</span>
                <Button
                  onClick={() => {
                    setFieldValue('submitting', true)
                    submitForm()
                  }}>
                  Update and Send for approval
                </Button>
              </>
            )}
            <span>or</span>
            <Link to="/app/bookings">
              <Button>
                <ArrowLeftOutlined /> Back to bookings
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    type: 'Air',
    quotationNo: '',
    clientName: '',
    contactPerson: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phone: '',
    email: '',
    POLPOD: '',
    actualWeight: '',
    chargeableWeight: '',
    requestedDate: moment(),
    completedDate: '',
    operations: '',
    salesPerson: '',
    reference: '',
    specialInstruction: '',
    comments: '',
    statement: '',
    packageList: [DEFAULT_PACKAGE_DETAILS],
    attachmentRequiredFor: [],
    template: 'Default'
  }),
  validationSchema: Schema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id, offer }
        },
        history
      },
      setFieldValue
    }
  ) => {
    data.template = data.template === 'Default' ? null : data.template

    if (id) {
      apiClient.put(`bookings/${id}`, data).then(({ status }) => {
        if (status === 200) {
          if (data.submitting) {
            message.success('Booking Sent')
            setFieldValue('status', 'Sent')
            history.push(`/app/bookings/${id}`)
          } else {
            history.push(`/app/bookings/${id}`)
          }
        }
      })
    } else {
      apiClient.post('bookings', { ...data, offer }).then(({ status, data }) => {
        if (status === 201) {
          history.push(`/app/bookings/${data.id}`)
        }
      })
    }
  }
})(BookingForm)
