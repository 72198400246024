import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row, Tabs } from 'antd'
import { flatten } from 'flat'
import { withFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../Components/Button'
import Form from '../../../Components/Formik/Form'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { warehouseProductSchema } from '../../../Util/validationSchema'
import BasicData from './BasicData'
import CostInfo from './CostInfo'
import Packaging from './Packaging'
import SalesText from './SalesText'
import StockInfo from './StockInfo'

const { TabPane } = Tabs

function WarehouseProductForm({
  values,
  errors,
  setValues,
  setFieldValue,
  isSubmitting,
  match: {
    params: { id }
  }
}) {
  const [tab, setTab] = useState('basic')

  const getData = () => {
    if (id) {
      apiClient.get(`warehouse-products/${id}`).then(({ status, data }) => {
        if (status === 200) {
          setValues({ ...values, ...data })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!values.stockInfo.purchaseUnit) {
      setFieldValue('stockInfo.purchaseUnit', values.basic.unit)
    }

    if (!values.stockInfo.salesUnit) {
      setFieldValue('stockInfo.salesUnit', values.basic.unit)
    }
  }, [values.basic.unit])

  const [firstErrorInput] = Object.keys(flatten(errors))

  useEffect(() => {
    if (!_.isEmpty(errors) && !errors[tab]) {
      setTab(firstErrorInput?.substr(0, firstErrorInput.indexOf('.')))
    }
  }, [isSubmitting])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 20 }}>
          <PanelLayout title="Warehouse Products">
            <Tabs activeKey={tab} onChange={setTab}>
              <TabPane tab="Basic Data" key="basic">
                <BasicData />
              </TabPane>
              <TabPane tab="Sales Text" key="salesText">
                <SalesText />
              </TabPane>
              <TabPane tab="Stock Info" key="stockInfo">
                <StockInfo />
              </TabPane>
              <TabPane tab="Cost Info" key="costInfo">
                <CostInfo />
              </TabPane>
              <TabPane tab="Packaging" key="packaging">
                <Packaging />
              </TabPane>
            </Tabs>
          </PanelLayout>
          {/* Product details ends */}

          <div className="save-changes">
            <Button type="submit" variant="primary">
              Save
            </Button>
            <span>or</span>
            <Link to="/app/warehouse-products">
              <Button>
                <ArrowLeftOutlined /> Back to warehouse product list
              </Button>
            </Link>
          </div>
          {/* Invoice Information ends */}
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    basic: {
      materialCode: '',
      materialDescription: '',
      materialType: '',
      materialGroup: '',
      division: '',
      unit: '',
      grossWeight: '',
      netWeight: '',
      weightUnit: '',
      volume: '',
      volumeUnit: '',
      eanCode: '',
      salesTime: '',
      procurementTime: '',
      status: 'Active'
    },
    salesText: [],
    stockInfo: {
      safetyStock: '',
      reorderLevel: '',
      defaultWarehouse: '',
      defaultLocation: '',
      defaultRack: '',
      purchaseUnit: '',
      salesUnit: '',
      purchasePrice: '',
      salesPrice: ''
    },
    costInfo: {
      type: 'Standard',
      cost: '',
      movingCost: ''
    },
    packaging: {
      length: '',
      width: '',
      height: '',
      quantity: ''
    }
  }),
  validationSchema: warehouseProductSchema,
  handleSubmit: (
    values,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`warehouse-products/${id}`, values).then(({ status }) => {
        if (status === 200) {
          history.push('/app/warehouse-products')
        }
      })
    } else {
      apiClient.post('warehouse-products', values).then(({ status }) => {
        if (status === 201) {
          history.push('/app/warehouse-products')
        }
      })
    }
  }
})(WarehouseProductForm)
