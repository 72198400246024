import { FunnelPlotOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Row } from 'antd'
import React, { useEffect } from 'react'
import TableBox from '../../../Components/TableBox/TableBox'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, useStateCallback, validateAccess } from '../../../Util/Util'
import CurrencyFilter from './CurrencyFilter'

const { Sider, Content } = Layout

const Currencies = (props) => {
  const { history } = props
  const [state, setState] = useStateCallback({
    viewType: 'table',
    currencies: [],
    filterview: 'filter',
    currentPage: 1,
    limit: 10,
    filterFlag: false,
    filterQuery: {},
    total: ''
  })

  const { currencies, filterview } = state
  useEffect(() => {
    onFilter(GET_DATA('currencies.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('currencies.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  // responsive filter starts
  const responsiveFilter = () => {
    const x = document.getElementById('mobile-sider-menu')

    if (x.style.display === 'block') {
      x.style.display = 'none'
      setState({ ...state, filterview: 'filter' })
    } else {
      x.style.display = 'block'
      setState({ ...state, filterview: 'filter' })
    }
  }
  // responsive filter ends

  const onFilter = (obj = {}) => {
    apiClient.get('currencies/getAll', { params: obj }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('currencies.filterData', { ...obj, ...data.pageData })
        setState({ currencies: data.result })
      }
    })
  }

  // const getPageData = (currentPage, limit) => {
  //   const offset = getOffset(currentPage, limit)
  //   setState({ ...state, currentPage })

  //   if (!filterFlag) {
  //     getCurrencies({ limit, offset }).then((users) => {
  //       if (users.data) {
  //         setState({ ...state, users: users.data, total: users.total })
  //       }
  //     })
  //   } else {
  //     getCurrencies({ limit, offset, ...filterQuery }).then((users) => {
  //       if (users.data) {
  //         setState({ ...state, users: users.data, total: users.total })
  //       }
  //     })
  //   }
  // }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Code',
      dataIndex: 'code'
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol'
    },
    {
      title: 'Unit',
      dataIndex: 'unit'
    },
    validateAccess('edit-currency')
      ? {
          title: 'Action',
          dataIndex: 'custom_action',
          render: (text, row) => (
            <div className="btn-group">
              <button
                type="button"
                onClick={() => history.push(`/app/edit-currency/${row.id}`)}
                className="btn glow dropdown-toggle">
                {' '}
                <SettingOutlined />
              </button>
            </div>
          )
        }
      : {}
  ]

  return (
    <Layout className="income-sidebar">
      <div className="mobile-filter">
        <button type="button" className="btn btn-glow">
          {filterview === 'filter' ? (
            <FunnelPlotOutlined onClick={responsiveFilter} />
          ) : (
            <FunnelPlotOutlined onClick={responsiveFilter} />
          )}
        </button>
      </div>
      <Sider width={230} trigger={null} collapsible collapsed={false} id="mobile-sider-menu">
        {/* search filter starts  */}

        <CurrencyFilter {...props} onFilter={onFilter} />

        {/* search filter ends */}
      </Sider>
      <Layout className="site-layout">
        <Content className="site-layout-background">
          <div className="top-filter-options">
            <Row>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}>
                <h2>Currencies you will be transacting</h2>
              </Col>

              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}>
                <div className="exports-and-settings">
                  <ul>
                    <li>
                      <Button
                        type="standard"
                        className="ant-dropdown-link"
                        onClick={() => setState({ ...state, viewType: 'table' })}>
                        <i className="flaticon-table no-margin" />
                      </Button>
                    </li>
                    <li>
                      <Button
                        type="standard"
                        className="ant-dropdown-link"
                        onClick={() => setState({ ...state, viewType: 'card' })}>
                        <i className="flaticon-grid no-margin" />
                      </Button>
                    </li>

                    <li>
                      <Button type="standard" className="ant-dropdown-link">
                        <i className="flaticon-settings no-margin" />
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24, order: 3 }}
                sm={{ span: 24, order: 3 }}
                md={{ span: 24, order: 3 }}
                lg={{ span: 0, order: 3 }}>
                <div className="add-new-invoice-button">
                  <button
                    type="button"
                    onClick={() => history.push('/app/add-currency')}
                    className="btn-glow btn-block primary">
                    <i className="flaticon-plus" />
                    Add new currency
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <TableBox
            dataSource={currencies}
            columns={columns}
            pageData={GET_DATA('currencies.filterData')}
            onChangePage={onChangePage}
          />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Currencies
