import { AutoComplete as AutoCompleteField, Input } from 'antd'
import { getIn } from 'formik'
import React, { FunctionComponent, memo } from 'react'
import useDebounceEffect from '../../Hooks/useDebounceEffect'
import AltInput from '../AltInput'
import { arabicRegex, TAutoComplete } from './types'

const AutoComplete: FunctionComponent<TAutoComplete> = ({
  label,
  error,
  required,
  onChange,
  onChangeAlt,
  onSearch,
  onBlur,
  textArea,
  rows,
  style,
  value,
  delay,
  hideLabel,
  altDisabled,
  altValue,
  altInput,
  ...props
}) => {
  const [val, setValue] = useDebounceEffect((v: string | undefined) => onSearch?.(v || ''), value, delay)

  const changedValue =
    getIn(
      props.options?.find((item) => item.value === val),
      'label',
      ''
    ) || val

  return (
    <div className="custom-input-box">
      {label && !hideLabel && (
        <label style={{ textAlign: 'left', width: 'fit-content' }}>
          {label} {required && <span className="required">*</span>}
        </label>
      )}

      {altInput && (
        <AltInput {...props} {...{ label, altDisabled, altValue, altInput, onChange: onChangeAlt }} />
      )}

      <AutoCompleteField
        style={{
          width: '100%',
          ...style
        }}
        onSearch={setValue}
        onChange={(v) => {
          if (!v) {
            onChange?.(props.name, v)
          }
        }}
        onSelect={(v, option) => onChange?.(props.name, v, option)}
        filterOption={(input, option) =>
          String(option?.label)?.toLowerCase?.().indexOf(input.toLowerCase()) >= 0
        }
        onBlur={() => onBlur?.(props.name, val || '')}
        value={changedValue}
        {...props}>
        {textArea ? (
          <Input.TextArea rows={rows} dir={arabicRegex.test(changedValue?.toString()) ? 'rtl' : 'ltr'} />
        ) : (
          <Input dir={arabicRegex.test(changedValue?.toString()) ? 'rtl' : 'ltr'} />
        )}
      </AutoCompleteField>
      {error && (
        <div style={{ fontSize: 10, color: 'red', textAlign: 'right' }}>
          {error.replace(props.name, label || props.placeholder || '')}
        </div>
      )}
    </div>
  )
}

AutoComplete.defaultProps = {
  rows: 2,
  delay: 500
}

export default memo(AutoComplete)
