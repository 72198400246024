import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'

const ADDITIONAL_FIELDS_INCOMES = [
  { label: 'location', value: 'location' },
  { label: 'Premise', value: 'premise' },
  { label: 'Slip No', value: 'slipNo' },
  { label: 'Project Name', value: 'projectName' },
  { label: 'Contract No', value: 'contractNo' },
  { label: 'Contract Title', value: 'contractTitle' },
  { label: 'Description', value: 'description' },
  { label: 'Discount', value: 'discount' },
  { label: 'Retention', value: 'retention' },
  { label: 'Charge', value: 'charge' },
  { label: 'Account', value: 'account' }
]

const ADDITIONAL_FIELDS = [
  { label: 'Description', value: 'description' },
  { label: 'Discount', value: 'discount' },
  { label: 'Retention', value: 'retention' },
  { label: 'Charge', value: 'charge' },
  { label: 'Account', value: 'account' }
]

const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
]

const DIVISION_LEVEL = [
  { label: 'Header', value: 'Header' },
  { label: 'Line', value: 'Line' }
]

const Schema = Yup.object().shape({
  division: Yup.string().required(),
  timesheetTemplate: Yup.string().required(),
  incomeInvoiceApprover: Yup.array(),
  expenseInvoiceApprover: Yup.array(),
  warehouseLocations: Yup.string().required(),
  manualIncomeInvoiceNo: Yup.string().required(),
  manualExpenseInvoiceNo: Yup.string().required(),
  defaultIncomeInvoice: Yup.string().required(),
  defaultExpenseInvoice: Yup.string().required(),
  approvedIncomeCancellation: Yup.string().required(),
  approvedExpenseCancellation: Yup.string().required(),
  incomeDivisionLevel: Yup.string().required(),
  expenseDivisionLevel: Yup.string().required(),
  warehouseRacks: Yup.string().required(),
  deliveryBasedOnSales: Yup.string().required()
})

function CompanyConfigurations() {
  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="COMPANY CONFIGURATIONS">
            <Panel title="Company">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={10}>
                  <div style={{ fontSize: 14 }}>Do you have divisions in this company?</div>
                  <div style={{ fontSize: 14 }}>
                    <i>(If yes, you can create multiple divisions under company setup)</i>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="division" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Timesheet">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Select default time sheet template</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field
                      as="paged-select"
                      name="timesheetTemplate"
                      endPoint="customTemplates/getActive"
                      params={{ type: 'Timesheet' }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Do you want to enter time entries for future dates?</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="timeEntryFutureDate" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Invoice">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>
                    Select income invoice approver to manage approvals. (N/A if empty)
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field
                      as="paged-select"
                      name="incomeInvoiceApprover"
                      endPoint="users/get-active-by-company"
                      optionValue="user"
                      mode="multiple"
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>
                    Select expense invoice approver to manage approvals. (N/A if empty)
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field
                      as="paged-select"
                      name="expenseInvoiceApprover"
                      endPoint="users/get-active-by-company"
                      optionValue="user"
                      mode="multiple"
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Manual income invoice number?</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="manualIncomeInvoiceNo" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Manual expense invoice number?</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="manualExpenseInvoiceNo" options={YES_NO_OPTIONS} />
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Select income Addional fields</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="incomeAdditionalFields"
                      options={ADDITIONAL_FIELDS_INCOMES}
                      mode="multiple"
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Select expense Addional fields</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="expenseAdditionalFields"
                      options={ADDITIONAL_FIELDS}
                      mode="multiple"
                    />
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Choose Default income invoice template</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field
                      as="paged-select"
                      name="defaultIncomeInvoice"
                      endPoint="customTemplates/getActive"
                      params={{ type: 'Invoice', for: 'Income' }}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Choose Default expense invoice template</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field
                      as="paged-select"
                      name="defaultExpenseInvoice"
                      endPoint="customTemplates/getActive"
                      params={{ type: 'Invoice', for: 'Expense' }}
                    />
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>
                    Do you wants to allow cancellation of approved income invoices?
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="approvedIncomeCancellation" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>
                    Do you wants to allow cancellation of approved expense invoices?
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="approvedExpenseCancellation" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Income division level?</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="incomeDivisionLevel" options={DIVISION_LEVEL} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Expense division level?</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="expenseDivisionLevel" options={DIVISION_LEVEL} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Warehouse">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Do you want to use Warehouse locations?</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="warehouseLocations" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>Do you want to use Warehouse racks?</div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="warehouseRacks" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Sales">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div style={{ fontSize: 14 }}>
                    Delivery based on Sales Order and invoices based on deliveries?
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6}>
                  <div className="form-field">
                    <Field as="select" name="deliveryBasedOnSales" options={YES_NO_OPTIONS} />
                  </div>
                </Col>
              </Row>
            </Panel>
          </PanelLayout>

          <div className="save-changes">
            <Button type="submit" variant="primary">
              Update
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({ companyInfo: { configurations = {} } = {} }) => ({
    division: configurations?.division || 'No',
    timesheetTemplate: configurations?.timesheetTemplate || '',
    timeEntryFutureDate: configurations?.timeEntryFutureDate || 'No',
    incomeInvoiceApprover: configurations?.incomeInvoiceApprover || [],
    expenseInvoiceApprover: configurations?.expenseInvoiceApprover || [],
    manualIncomeInvoiceNo: configurations?.manualIncomeInvoiceNo || '',
    manualExpenseInvoiceNo: configurations?.manualExpenseInvoiceNo || '',
    incomeAdditionalFields: configurations?.incomeAdditionalFields || [],
    expenseAdditionalFields: configurations?.expenseAdditionalFields || [],
    defaultIncomeInvoice: configurations?.defaultIncomeInvoice || '',
    defaultExpenseInvoice: configurations?.defaultExpenseInvoice || '',
    approvedIncomeCancellation: configurations?.approvedIncomeCancellation || '',
    approvedExpenseCancellation: configurations?.approvedExpenseCancellation || '',
    incomeDivisionLevel: configurations?.incomeDivisionLevel || 'Header',
    expenseDivisionLevel: configurations?.expenseDivisionLevel || 'Header',
    warehouseLocations: configurations?.warehouseLocations || '',
    warehouseRacks: configurations?.warehouseRacks || '',
    deliveryBasedOnSales: configurations?.deliveryBasedOnSales || ''
  }),
  validationSchema: Schema,
  handleSubmit: (data, { props: { userInfo, dispatch } }) => {
    if (userInfo.company) {
      apiClient
        .put(`companies/update/${userInfo.company}`, { configurations: _(data).omitBy(_.isEmpty).value() })
        .then(({ data }) => {
          if (data && data.result) {
            dispatch({ type: 'SET_USER_REDUCER', payload: { companyInfo: data.result } })
            message.success('Configurations Updated')
          }
        })
    }
  }
})(CompanyConfigurations)
