import { Col, Row } from 'antd'
import React from 'react'
import Button from './Button'

export default function FooterActions({ leftActions = [], centerActions = [], rightActions = [] }) {
  return (
    <Row className="footer-actions">
      <Col xs={24} sm={8} md={8} lg={8} className="left-actions">
        {leftActions.map(
          (v, i) =>
            !v.dontShow && (
              <div className="action-content" key={i}>
                <Button type={v.type} variant={v.variant} onClick={v.onClick}>
                  <i className={v.prefix} /> {v.label}
                </Button>
              </div>
            )
        )}
      </Col>
      <Col xs={12} sm={8} md={8} lg={8} className="center-actions">
        {centerActions.map(
          (v, i) =>
            !v.dontShow && (
              <div className="action-content" key={i}>
                <Button type={v.type} variant={v.variant || 'primary'} onClick={v.onClick}>
                  <i className={v.prefix} /> {v.label}
                </Button>
              </div>
            )
        )}
      </Col>
      <Col xs={12} sm={8} md={8} lg={8} className="right-actions">
        {rightActions.map(
          (v, i) =>
            !v.dontShow && (
              <div className="action-content" key={i}>
                <Button type={v.type} variant={v.variant} onClick={v.onClick}>
                  <i className={v.prefix} /> {v.label}
                </Button>
              </div>
            )
        )}
      </Col>
    </Row>
  )
}
