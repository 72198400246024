import { withFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import ModalBoxFooter from '../../../Components/ModalBox/ModalBoxFooter'
import { removeEmptyKeys } from '../../../Util/Util'
import { invoiceEmailSchema } from '../../../Util/validationSchema'

function IncomeEmails(props) {
  const onSubmitForm = async () => {
    await props.submitForm()
    props.validateForm().then((err) => {
      if (_.isEmpty(err)) {
        props.onSend(removeEmptyKeys(props.values))
      }
    })
  }

  return (
    <div>
      <Form>
        {props.showEmail && (
          <div className="form-fields">
            <Field name="email" label="Email" />
          </div>
        )}
        <div className="form-fields">
          <Field as="input-chip" name="cc" label="Cc" emailValidation />
        </div>
        <div className="form-fields" style={{ position: 'absolute', bottom: 18 }}>
          <div style={{ display: 'inline-block', width: 'fit-content' }}>
            <Field as="checkbox" name="pdfAttachment" label="Attach PDF" />
          </div>
        </div>
        <div className="form-fields">
          <ModalBoxFooter onOk={onSubmitForm} okText="Send Email" onCancel={() => props.onCancel()} />
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: ({ email = '', showEmail = false }) => ({
    email,
    showEmail,
    cc: [],
    message: '',
    pdfAttachment: true
  }),
  handleSubmit: () => null,
  validationSchema: invoiceEmailSchema
})(IncomeEmails)
