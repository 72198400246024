import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  getOfficialDetailsByEmployeeID,
  updateOfficialDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import { WAGE_MODE } from '../../../Util/Options'

const Schema = Yup.object().shape({
  designation: Yup.string().required(),
  department: Yup.string().required(),
  location: Yup.string().required(),
  level: Yup.string().required(),
  pfNo: Yup.string().required(),
  roleAndResponsibility: Yup.string().required(),
  costCenter: Yup.string().required(),
  functionalArea: Yup.string().required()
})

const OfficialDetails = (props) => {
  const { currentEmployee, values, setValues, submitForm, officialDetails, dispatch, errors } = props
  const [editable, setEditable] = useState(false)

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getOfficialDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const onEdit = () => {
    if (currentEmployee?.id) {
      setValues({
        ...values,
        employeeNo: currentEmployee?.employeeNo,
        name: currentEmployee?.name,
        designation: officialDetails?.designation,
        department: officialDetails?.department,
        reporterName: currentEmployee?.reporter,
        location: officialDetails?.location,
        level: officialDetails?.level,
        pfNo: officialDetails?.pfNo,
        roleAndResponsibility: officialDetails?.roleAndResponsibility,
        role: currentEmployee?.role,
        wageType: currentEmployee?.wageType,
        timesheetViewAccess: currentEmployee?.timesheetViewAccess,
        costCenter: officialDetails?.costCenter,
        functionalArea: officialDetails?.functionalArea
      })
      setEditable(true)
    } else {
      message.error('Please select and employee to edit')
    }
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setEditable(false)
    }
  }

  return (
    <Form>
      <PanelLayout className="mb-3">
        <Panel title="Offcial Details">
          {!editable && (
            <div className="panel-with-border">
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Employee Number</span>
                  <p>{!isEmpty(currentEmployee) ? currentEmployee?.employeeNo : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Employee Name</span>
                  <p>{!isEmpty(currentEmployee) ? currentEmployee?.name : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Role</span>
                  <p>{!isEmpty(currentEmployee) ? currentEmployee?.roleName : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Wage Type</span>
                  <p>{!isEmpty(currentEmployee) ? currentEmployee?.wageType : 'N/A'}</p>
                </Col>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Timesheet View Access</span>
                  <p>{!isEmpty(currentEmployee) ? currentEmployee.timesheetViewAccess : 'N/A'}</p>
                </Col> */}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Designation</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.designation : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Department</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.department : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Reporting To</span>
                  <p>{!isEmpty(currentEmployee) ? currentEmployee?.reporterName : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Location</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.location : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Level</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.level : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>PF Number</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.pfNo : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Role and Responcibility</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.roleAndResponsibility : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Cost Center</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.costCenter : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Functional Area</span>
                  <p>{!isEmpty(officialDetails) ? officialDetails?.functionalArea : 'N/A'}</p>
                </Col>
              </Row>
            </div>
          )}
          {editable && (
            <Row justify="left" gutter={(12, 10)}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="employeeNo" label="Employee Number" type="number" disabled />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="name" label="Employee Name" type="text" disabled />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="role" label="Role" as="paged-select" endPoint="roles/get-active" disabled />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field
                    name="reporterName"
                    label="Reporting To"
                    as="paged-select"
                    endPoint="users/get-active-by-company"
                    defaultOptions={[{ label: 'Self', value: 'Self' }]}
                    optionValue="user"
                    disabled
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="wageType" label="Wage Type" as="select" options={WAGE_MODE} disabled />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field
                    name="timesheetViewAccess"
                    label="Timesheet View Access To"
                    as="paged-select"
                    mode="multiple"
                    endPoint="users/get-active-by-company"
                    optionValue="user"
                    disabled
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="designation" label="Designation" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="department" label="Department" type="text" />
                </div>
              </Col>
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="reporterName" label="Reporting To" type="text" />
                </div>
              </Col> */}
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="location" label="Location" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="level" label="Level" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="pfNo" label="PF Number" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="roleAndResponsibility" label="Role and Responcibility" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="costCenter" label="Cost Center" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="functionalArea" label="Functional Area" type="text" />
                </div>
              </Col>
            </Row>
          )}
        </Panel>
      </PanelLayout>

      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
        centerActions={[
          {
            prefix: 'flaticon-play',
            label: 'Save',
            dontShow: !editable,
            onClick: onSave
          }
        ]}
        rightActions={[
          {
            prefix: 'flaticon-edit-1',
            label: 'Edit',
            dontShow: editable,
            onClick: onEdit
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  officialDetails: state.employee.employeeDetails.officialDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      employeeNo: '',
      name: '',
      designation: '',
      department: '',
      reporterName: '',
      location: '',
      level: '',
      pfNo: '',
      roleAndResponsibility: '',
      role: '',
      wageType: '',
      timesheetViewAccess: [],
      costCenter: '',
      functionalArea: ''
    }),
    validationSchema: Schema,
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      dispatch(
        updateOfficialDetailsByEmployeeID(currentEmployee.id, {
          designation: values.designation,
          department: values.department,
          location: values.location,
          level: values.level,
          pfNo: values.pfNo,
          roleAndResponsibility: values.roleAndResponsibility,
          costCenter: values.costCenter,
          functionalArea: values.functionalArea
        })
      )
      // dispatch(
      //   updateEmployeeDetailsByEmployeeID(currentEmployee.id, {
      //     reporter: reporterName,
      //     role,
      //     wageType,
      //     timesheetViewAccess
      //   })
      // )
    }
  })(OfficialDetails)
)
