import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getActiveCurrencies } from '../../../Actions/UserAction'
import Address from '../../../Components/Address/Address'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import {
  getMasterOptions,
  getOrderNames,
  ORDER_INVOICE_STATUS_OPTIONS,
  TAX_CATEGORIES,
  TAX_TYPES
} from '../../../Util/Options'
import { amountToWords, convertSelectOptions, parseAmount } from '../../../Util/Util'
import { orderInvoiceSchema } from '../../../Util/validationSchema'
import IncomeEmail from '../../InvoiceData/Incomes/IncomeEmail'
import SingleInvoice from './SingleInvoice'

function SaveInvoice({
  match: {
    params: { id }
  },
  userInfo: { companyData },
  history,
  location,
  values,
  setValues,
  setFieldValue,
  submitForm
}) {
  const {
    editInvoice,
    currency,
    exchangeRate,
    workflowStatus,
    payTerm,
    clientData,
    companyCurrency,
    taxCategory,
    type
  } = values

  const { clientName, typeName } = getOrderNames(type)

  const [currencies, setCurrencies] = useState([])
  const [templates, setTemplates] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [contactPerson, setContactPerson] = useState({})

  const bankOptions = useMemo(
    () => convertSelectOptions(companyData.banks || [], 'bankAccountNo', 'bankAccountNo'),
    [companyData.banks]
  )

  const amount = parseFloat(_.sumBy(values.orderDetails, 'amount'))
  const discount = parseFloat(_.sumBy(values.orderDetails, 'discountValue'))
  const charge = parseFloat(_.sumBy(values.orderDetails, 'chargeValue'))
  const netAmount = parseFloat(_.sumBy(values.orderDetails, 'netAmount'))
  const taxAmount = parseFloat(_.sumBy(values.orderDetails, 'taxAmount'))
  const memoizedAmount = useMemo(() => amount, [amount])
  const memoizedDiscount = useMemo(() => discount, [discount])
  const memoizedCharge = useMemo(() => charge, [charge])
  const memoizedNetAmount = useMemo(() => netAmount, [netAmount])
  const memoizedTaxAmount = useMemo(() => taxAmount, [taxAmount])
  const grossAmount = memoizedTaxAmount + memoizedNetAmount
  const companyTotal = grossAmount * exchangeRate

  const getData = () => {
    if (id) {
      apiClient.get(`order-invoices/${id}`).then(({ status, data }) => {
        if (status === 200) {
          data.template = data.template ? data.template : 'Default'

          setDisabled(data.workflowStatus === 'Submitted' || data.workflowStatus === 'Approved')

          data.orderDetails = data.orderDetails.map((item) => ({
            orderNetAmount: item.netAmount,
            disabled: data.workflowStatus === 'Submitted' || data.workflowStatus === 'Approved',
            tempTax: item.tax,
            ...item
          }))

          const contactPerson = data.clientData.contactPersons.find((item) => item._id === data.contactPerson)

          setContactPerson(contactPerson)

          setValues({ ...values, ...data })
        }
      })
    } else {
      const { state: { orderInfo: { orderNo } = '', orderDetails = [] } = '' } = location
      const selectedBank = bankOptions.find((bank) => bank.bankCurrency === currency)
      setValues({
        ...values,
        ...{
          orderDetails: orderDetails.map((item, i) => ({
            orderNo,
            invoicePosition: (i + 1) * 10,
            invoiceQuantity: '',
            orderNetAmount: item.netAmount,
            orderInvoiceQuantity: item.toBeInvoicedQuantity,
            disabled: false,
            tempTax: item.tax,
            ..._.omit(item, ['amount', 'netAmount', 'taxAmount', 'discountValue', 'chargeValue'])
          })),
          clientEmail: contactPerson.contactEmail,
          bank: selectedBank ? selectedBank.bankAccountNo : '',
          bankData: selectedBank || {}
        }
      })

      setContactPerson(clientData.contactPersons.find((item) => item._id === values.contactPerson))
    }

    getActiveCurrencies().then((currencies) => {
      if (currencies) {
        setCurrencies(convertSelectOptions(currencies, 'name', 'code'))
      }
    })
    apiClient.get('customTemplates/getActive', { params: { type: 'OrderInvoice' } }).then(({ data }) => {
      if (data && data.result) {
        const templates = [{ label: 'Default', value: 'Default' }]
        data.result.forEach((val) => {
          templates.push({ label: val.name, value: val.id })
        })
        setTemplates(templates)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [id || type])

  useDidUpdateEffect(() => {
    setFieldValue('paymentDueDate', moment().utc().startOf('day').add(payTerm, 'days'))
  }, [payTerm])

  useDidUpdateEffect(() => {
    const selectedBank = bankOptions.find((bank) => bank.bankCurrency === currency)
    setFieldValue('bank', selectedBank ? selectedBank.bankAccountNo : '')
    setFieldValue('bankData', selectedBank || {})
  }, [currency])

  useDidUpdateEffect(() => {
    if (id) {
      setDisabled(['Submitted', 'Approved'].includes(workflowStatus))
    }
  }, [workflowStatus])

  const getExchangeRate = (baseCurrency) => {
    apiClient
      .get('exchange-rates/get-rate', {
        params: {
          baseCurrency,
          toCurrency: companyCurrency,
          date: moment(values.issueDate).format('YYYY-MM-DD')
        }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setFieldValue('exchangeRate', data.result.rate || values.exchangeRate || 1)
        }
      })
  }

  const sendMail = (emailInfo) => {
    apiClient
      .post('order-invoices/send-emails', { invoicesIds: [id], ...emailInfo, type: 'Email' })
      .then(({ status }) => {
        if (status === 200) {
          message.success('Invoices Sent')
          setShowModal(false)
        }
      })
  }

  return (
    <Form>
      <ModalBox
        title="Send Invoice to Email"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        destroyOnClose>
        <IncomeEmail
          onSend={sendMail}
          showEmail
          email={contactPerson?.contactEmail}
          onCancel={() => setShowModal(false)}
        />
      </ModalBox>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={22}>
          <PanelLayout
            title={
              <Row justify="space-between">
                <Col xs={12}>
                  <h2 className="panel-title">{typeName} Invoice</h2>
                </Col>
                <Col>
                  {id && (
                    <>
                      <Button className="mr-2" onClick={() => setShowModal(true)}>
                        <i className="m-auto fa flaticon-email-4" />
                      </Button>
                      <Link to={`/app/order-invoices/view/${id}`}>
                        <Button success>
                          <i className="flaticon-invoice-3" />
                          Preview
                        </Button>
                      </Link>
                    </>
                  )}
                </Col>
              </Row>
            }>
            <h2>{clientName} Details</h2>
            <Row gutter={[10, 10]} className="pb-4 mb-4 border-bottom">
              <Col xs={24} sm={24} md={10}>
                <div style={{ padding: '10px 0' }}>
                  <h3>
                    <b>{clientName}:</b> {clientData?.name}
                  </h3>
                  <h4>
                    <b>Email:</b> {contactPerson?.contactEmail}
                  </h4>
                  <h4>
                    <b>Phone:</b> {contactPerson?.contactPhone}
                  </h4>
                  <h4>
                    <b>Tax Type:</b> {clientData?.taxType}
                  </h4>
                  <h4>
                    <b>{clientData?.taxType} No:</b> {clientData?.taxNo}
                  </h4>
                </div>
              </Col>
              <Col xs={24} sm={12} md={7}>
                <Address addressType="shippingAddress" label="Shipping Address" disabled={disabled} />
              </Col>
              <Col xs={24} sm={12} md={7}>
                <Address addressType="billingAddress" label="Billing Address" disabled={disabled} />
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col xs={12}>
                <h2>Invoice Details</h2>
              </Col>
            </Row>
            <Row gutter={[10, 10]} className="pb-4 mb-4 border-bottom">
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field name="kind" label="Kind" disabled />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <Field
                  name="editInvoice"
                  as="checkbox"
                  style={{ position: 'absolute', left: 70 }}
                  disabled={disabled}
                />
                <div className="form-field">
                  <Field name="invoiceNo" label="Invoice No" disabled={disabled || !editInvoice} />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field name="poNo" label="PO / Reference No" disabled={disabled} />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field
                    name="currency"
                    as="select"
                    label="Currency"
                    onChange={(n, v) => {
                      getExchangeRate(v)

                      return setFieldValue(n, v)
                    }}
                    options={currencies}
                    disabled={disabled}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field name="issueDate" as="date" label="Issued Date" disabled={disabled} />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field name="taxDate" as="date" label="Tax Period" disabled={disabled} />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field
                    as="select"
                    name="taxCategory"
                    label="Tax Category"
                    options={TAX_CATEGORIES}
                    onChange={(n, v) => {
                      const taxTypes = TAX_TYPES.filter((item) => item.categories.includes(v))
                      setFieldValue(n, v)

                      setTimeout(() => {
                        setFieldValue(
                          'orderDetails',
                          values.orderDetails.map((item) => ({
                            ...item,
                            taxType: taxTypes?.length === 1 ? taxTypes[0].value : ''
                          }))
                        )
                      }, 500)
                    }}
                    disabled={disabled}
                  />
                </div>
              </Col>
            </Row>
            <Panel title="Invoice Entries">
              <FieldArray
                name="orderDetails"
                additionalValues={{ type, disabled, taxCategory, currency }}
                loaderCount={8}
                viewOnly>
                {SingleInvoice}
              </FieldArray>
            </Panel>
            <Row>
              <Col xs={24} sm={12} />
              <Col xs={24} lg={12}>
                <div className="payment-details">
                  <div className="details">
                    <Row>
                      <Col xs={12}>
                        <h3>Total Amount:</h3>
                      </Col>
                      <Col xs={12}>
                        <p>
                          {parseAmount(memoizedAmount, currency)} {currency}
                        </p>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={12}>
                        <h3>Total Discount:</h3>
                      </Col>
                      <Col xs={12}>
                        <p>
                          - {parseAmount(memoizedDiscount, currency)} {currency}
                        </p>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={12}>
                        <h3>Total Charge:</h3>
                      </Col>
                      <Col xs={12}>
                        <p>
                          + {parseAmount(memoizedCharge, currency)} {currency}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <h3>Total Net Amount:</h3>
                      </Col>
                      <Col xs={12}>
                        <p>
                          {parseAmount(memoizedNetAmount, currency)} {currency}
                        </p>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={24} sm={12}>
                        <h3>Total Tax Amount:</h3>
                      </Col>
                      <Col xs={24} sm={12}>
                        <p>
                          {parseAmount(memoizedTaxAmount, currency)} {currency}
                        </p>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={24} sm={12}>
                        <h3 className="mb-0">Total Gross:</h3>
                        <div className="mb-2">(Amount includes VAT)</div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <p>
                          {parseAmount(grossAmount, currency)} {currency}
                        </p>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={12}>
                        <h3>Total Amount to be paid:</h3>
                      </Col>
                      <Col xs={12}>
                        <p>
                          {parseAmount(grossAmount, currency)} {currency}
                        </p>
                        <div>
                          <p>
                            {amountToWords(
                              grossAmount,
                              currencies.find((cur) => cur.value === currency)
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    {companyCurrency !== currency && (
                      <>
                        <Row gutter={[16]}>
                          <Col xs={12}>
                            <h3>Exchange Rate:</h3>
                          </Col>
                          <Col xs={12}>
                            <Field
                              type="number"
                              style={{ textAlign: 'right', width: 100 }}
                              name="exchangeRate"
                              label="Exchange Rate"
                              disabled={disabled}
                              hideLabel
                            />
                          </Col>
                        </Row>
                        <Row gutter={[16]}>
                          <Col xs={12}>
                            <h3>Total Amount in company currency:</h3>
                          </Col>
                          <Col xs={12}>
                            <p>
                              {parseAmount(companyTotal, companyCurrency)} {companyCurrency}
                            </p>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className="invoice-section border-bottom">
                  <h2>Payments</h2>

                  <Row gutter={[10, 10]}>
                    <Col xs={24} sm={12} md={8} lg={3}>
                      <div className="form-field">
                        <Field name="payTerm" label="Payment Term" type="number" />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={3}>
                      <div className="form-field">
                        <Field as="date" name="sentDate" label="Sent On" />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={3}>
                      <div className="form-field">
                        <Field as="date" name="paymentDueDate" label="Due Date" />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="form-field">
                        <Field
                          as="select"
                          name="paymentType"
                          label="Payment Type"
                          options={getMasterOptions('PaymentType')}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="form-field">
                        <Field as="date" name="paymentDate" label="Payment Date" />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="form-field">
                        <Field name="paidAmount" label="Paid" />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={3}>
                      <div className="form-field">
                        <Field
                          as="select"
                          name="status"
                          label="Status"
                          options={ORDER_INVOICE_STATUS_OPTIONS}
                          disabled={workflowStatus !== 'Approved'}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8}>
                      <div className="form-field">
                        <Field as="select" name="bank" label="Bank" options={bankOptions} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <div className="invoice-section">
                  <h2>Invoice / Template Information</h2>
                  <Row gutter={[26, { xs: 8, sm: 16, md: 20, lg: 20 }]}>
                    <Col xs={24} sm={12} md={8} lg={8}>
                      <div className="form-field">
                        <Field as="select" name="template" label="Template" options={templates} />
                      </div>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={16}>
                      <div className="form-field">
                        <Field as="textarea" name="notes" label="Notes" rows="1" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </PanelLayout>
          <div className="save-changes">
            <Button
              onClick={() => {
                setFieldValue('submitting', false)
                submitForm()
              }}
              variant="primary">
              Save Invoice
            </Button>
          </div>
          <div className="save-changes">
            <Button
              onClick={() => {
                setFieldValue('submitting', false)
                submitForm()
              }}
              variant="primary">
              {id ? 'Update' : 'Save'} Invoice
            </Button>
            {workflowStatus === 'Created' && (
              <>
                <span>or</span>
                <Button
                  onClick={() => {
                    setFieldValue('submitting', true)
                    submitForm()
                  }}>
                  Update and Submit for approval
                </Button>
              </>
            )}
            <span>or</span>
            <Link to="#" onClick={() => history.goBack()}>
              <Button>
                <ArrowLeftOutlined /> Back
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({
    history,
    userInfo: { companyData },
    location: {
      state: {
        type,
        orderInfo: {
          currency,
          clientInfo,
          client,
          exchangeRate,
          billingAddress,
          shippingAddress,
          contactPerson,
          payTerm,
          poNo,
          taxCategory
        } = '',
        orderDetails = []
      } = ''
    },
    match: {
      params: { id }
    }
  }) => {
    if (!id && orderDetails.length === 0) {
      history.goBack()
    }

    return {
      kind: `${type?.capitalize()} invoice`,
      invoiceNo: '',
      issueDate: moment().utc().startOf('day'),
      taxDate: moment().utc().startOf('day'),
      clientData: clientInfo,
      client,
      contactPerson,
      clientEmail: '',
      billingAddress,
      shippingAddress,
      currency,
      exchangeRate,
      payTerm,
      type,
      poNo,
      taxCategory,
      amount: 0.0,
      discountValue: 0.0,
      chargeValue: 0.0,
      netAmount: 0.0,
      taxAmount: 0.0,
      grossAmount: 0.0,
      orderDetails: [],
      sentDate: moment().utc().startOf('day'),
      paymentDueDate: payTerm ? moment().utc().startOf('day').add(payTerm, 'days') : '',
      paymentType: clientInfo?.paymentType,
      paymentDate: '',
      paidAmount: '',
      status: 'Created',
      bank: '',
      bankData: {},
      template: 'Default',
      notes: '',
      workflowStatus: '',
      companyCurrency: companyData.currency
    }
  },
  validationSchema: orderInvoiceSchema,
  handleSubmit: (
    data,
    {
      props: {
        history,
        match: {
          params: { id }
        }
      },
      setFieldValue
    }
  ) => {
    data.template = data.template === 'Default' ? null : data.template

    data.amount = parseFloat(_.sumBy(data.orderDetails, 'amount'))
    data.discount = parseFloat(_.sumBy(data.orderDetails, 'discountValue'))
    data.charge = parseFloat(_.sumBy(data.orderDetails, 'chargeValue'))
    data.netAmount = parseFloat(_.sumBy(data.orderDetails, 'netAmount'))
    data.taxAmount = parseFloat(_.sumBy(data.orderDetails, 'taxAmount'))
    data.grossAmount = data.taxAmount + data.netAmount
    data.companyTotal = data.grossAmount * data.exchangeRate

    if (id) {
      apiClient.put(`order-invoices/${id}`, data).then(({ status }) => {
        if (status === 200) {
          if (data.submitting) {
            message.success('Invoice Submitted')
            setFieldValue('workflowStatus', 'Submitted')
            history.push(`/app/edit-invoice/${id}`)
          } else {
            history.push(`/app/order-invoices/${data.type}`)
          }
        }
      })
    } else {
      data.workflowStatus = 'Created'
      apiClient.post('order-invoices', data).then(({ status }) => {
        if (status === 201) {
          history.push(`/app/order-invoices/${data.type}`)
        }
      })
    }
  }
})(SaveInvoice)
