import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React from 'react'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'

const AssetForm = (props) => (
  <Form>
    <Row justify="center" className="inner-contents">
      <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 18 }} lg={{ span: 20 }}>
        <PanelLayout title="Asset Details" className="mb-5">
          <Panel title="Asset Details">
            <Row justify="left" gutter={(12, 10)}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="form-fields">
                  <Field name="itemName" label="Item Name" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="form-fields">
                  <Field name="itemCategory" label="Item Category" as="select" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-fields">
                  <Field name="requestedDate" label="Requested Date" type="date" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-fields">
                  <Field name="fromAndToDate" label="From and To Date" as="date-range" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-fields">
                  <Field name="status" label="Status" as="select" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="form-fields">
                  <Field name="approvalPerson" label="Approval Person" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="form-fields">
                  <Field name="issueDate" label="Issue Date" as="date" />
                </div>
              </Col>
            </Row>
          </Panel>
        </PanelLayout>
      </Col>
    </Row>

    <FooterActions
      leftActions={[
        {
          prefix: 'flaticon-back',
          label: 'Back to Asset list',
          onClick: () => props.history.push('/app/asset')
        }
      ]}
      centerActions={[
        {
          prefix: 'flaticon-play',
          label: 'Save'
        }
      ]}
    />
  </Form>
)

export default withFormik({
  mapPropsToValues: () => ({
    itemName: '',
    itemCategory: '',
    requestedDate: '',
    fromAndToDate: '',
    status: '',
    approvalPerson: '',
    issueDate: ''
  }),
  handleSubmit: () => null
  // validationSchema: Schema
})(AssetForm)
