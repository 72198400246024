import { EyeOutlined } from '@ant-design/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { ORDER_TYPES } from '../../../Util/Options'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import GoodsReceiptFilter from './GoodsReceiptFilter'

export default function GoodsReceipts() {
  const [goodsReceipts, setGoodsReceipts] = useState([])
  const history = useHistory()

  const getData = (params) => {
    const filterCache = GET_DATA('goodsReceipts.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('goods-receipts', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('goodsReceipts.filterData', { ...params, ...data.pageData })
        setGoodsReceipts(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Transaction no.',
      dataIndex: 'transactionNo'
    },
    {
      title: 'Transaction Date',
      dataIndex: 'createdAt',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType',
      render: (value) => ORDER_TYPES.find((item) => item.value === value)?.label
    },
    {
      title: 'Order No.',
      dataIndex: 'orderNo'
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      ...(validateAccess('view-goods-receipt') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/view-goods-receipt/${row.id}`)}
              className="dropdown-toggle">
              <EyeOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Goods receipt',
        onClick: () => history.push('/app/add-goods-receipt'),
        access: 'add-goods-receipt'
      }}
      filterData={GET_DATA('goodsReceipts.filterData')}
      filter={<GoodsReceiptFilter onSubmit={getData} />}>
      <TableLayout title="Goods Receipt Overview" detailed exportUrl="goods-receipts/export">
        <TableBox
          dataSource={goodsReceipts}
          columns={columns}
          pageData={GET_DATA('goodsReceipts.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
