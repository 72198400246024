import { Col, Row } from 'antd'
import { useFormikContext } from 'formik'
import React, { memo, useEffect, useState } from 'react'
import {
  getLocationsByWarehouse,
  getOptionsByType,
  getRacksByLocation,
  getWarehouseCodes
} from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'

function StockInfo() {
  const [warehouses, setWarehouses] = useState([])
  const [locations, setLocations] = useState([])
  const [racks, setRacks] = useState([])
  const [units, setUnits] = useState([])
  const { values, setFieldValue } = useFormikContext()

  const getData = () => {
    getOptionsByType({ type: 'UnitOfMeasurement' }).then(({ UnitOfMeasurement = [] }) => {
      setUnits(UnitOfMeasurement)
    })
  }

  const getWarehouses = (warehouse) => {
    getWarehouseCodes({ warehouse }).then((data) => {
      setWarehouses(data)
    })
  }

  const getLocations = (location) => {
    getLocationsByWarehouse({
      warehouse: values.stockInfo.defaultWarehouse,
      location
    }).then((data) => {
      setLocations(data)
    })
  }

  const getRacks = (rack) => {
    getRacksByLocation({
      warehouse: values.stockInfo.defaultWarehouse,
      location: values.stockInfo.defaultLocation,
      rack
    }).then((data) => {
      setRacks(data)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      <Row gutter={[20, 10]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Safety Stock" name="stockInfo.safetyStock" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Reorder Level" name="stockInfo.reorderLevel" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field
              as="auto-complete"
              label="Default WH"
              name="stockInfo.defaultWarehouse"
              options={warehouses}
              onSearch={getWarehouses}
              onChange={(n, v) => {
                setFieldValue('stockInfo.defaultLocation', '')
                setFieldValue('stockInfo.defaultRack', '')

                return setFieldValue(n, v)
              }}
            />
          </div>
        </Col>
        {values.stockInfo.defaultWarehouse && (
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <div className="form-field">
              <Field
                as="auto-complete"
                key={values.stockInfo.defaultWarehouse}
                label="Default Loc."
                name="stockInfo.defaultLocation"
                onSearch={getLocations}
                options={locations}
                onChange={(n, v) => {
                  setFieldValue('stockInfo.defaultRack', '')

                  return setFieldValue(n, v)
                }}
              />
            </div>
          </Col>
        )}
        {values.stockInfo.defaultWarehouse && values.stockInfo.defaultLocation && (
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <div className="form-field">
              <Field
                as="auto-complete"
                key={values.stockInfo.defaultWarehouse + values.stockInfo.defaultLocation}
                label="Default Rack"
                name="stockInfo.defaultRack"
                onSearch={getRacks}
                options={racks}
              />
            </div>
          </Col>
        )}
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Purchase UOM" name="stockInfo.purchaseUnit" options={units} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Sales UOM" name="stockInfo.salesUnit" options={units} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Sales Price" name="stockInfo.salesPrice" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Purchase Price" name="stockInfo.purchasePrice" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default memo(StockInfo)
