import {
  FastField as FormikFastField,
  Field as FormikField,
  FormikState,
  FormikValues,
  getIn,
  useFormikContext
} from 'formik'
import React, { memo } from 'react'
import AutoComplete from './AutoComplete'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import DateRangePicker from './DateRangePicker'
import Input from './Input'
import InputChip from './InputChip'
import PagedSelect from './PagedSelect'
import RadioGroup from './RadioGroup'
import RichText from './RichText'
import Select from './Select'
import TextArea from './TextArea'
import {
  TAutoComplete,
  TCheckbox,
  TDatePicker,
  TDateRangePicker,
  TInput,
  TInputChip,
  TRadioGroup,
  TRichText,
  TSelect,
  TTextArea
} from './types'

type TFieldAs =
  | ({
      as: 'auto-complete'
    } & TAutoComplete)
  | ({
      as: 'checkbox'
    } & TCheckbox)
  | ({
      as: 'radio-group'
    } & TRadioGroup)
  | ({
      as: 'date'
    } & TDatePicker)
  | ({
      as: 'date-range'
    } & TDateRangePicker)
  | ({
      as: 'textarea'
    } & TTextArea)
  | ({
      as: 'select'
    } & TSelect)
  | ({
      as: 'paged-select'
    } & TSelect)
  | ({
      as: 'input-chip'
    } & TInputChip)
  | ({
      as: 'rich-text'
    } & TRichText)
  | ({
      as?: 'input'
    } & Partial<TInput>)

type TField = {
  name: string
  fast?: boolean
} & TFieldAs

function Field({ name, fast, as = 'input', ...props }: TField) {
  const { values, errors, touched, setFieldValue } = useFormikContext<FormikState<FormikValues>>()

  let Component

  if (fast) {
    Component = FormikFastField
  } else {
    Component = FormikField
  }

  return (
    <Component
      as={(() => {
        switch (as) {
          case 'auto-complete':
            return AutoComplete
          case 'textarea':
            return TextArea
          case 'select':
            return Select
          case 'paged-select':
            return PagedSelect
          case 'checkbox':
            return Checkbox
          case 'radio-group':
            return RadioGroup
          case 'date':
            return DatePicker
          case 'date-range':
            return DateRangePicker
          case 'input-chip':
            return InputChip
          case 'rich-text':
            return RichText
          default:
            return Input
        }
      })()}
      name={name}
      error={getIn(touched, name) && getIn(errors, name)}
      value={getIn(values, name)}
      onChange={setFieldValue}
      onChangeAlt={setFieldValue}
      required={Boolean(getIn(errors, name))}
      {...(['auto-complete', 'textarea', 'input', 'select', 'date'].includes(as) && {
        altValue: getIn(values, `${name}Alt`)
      })}
      {...props}
    />
  )
}

export default memo(Field)
