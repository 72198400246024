import { EditOutlined } from '@ant-design/icons'
import { Col, Row, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../Components/Button'
import TableBox from '../../Components/TableBox/TableBox'
import FilterLayout from '../../Layout/FilterLayout'
import apiClient from '../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../Util/Util'
import TemplateFilter from './TemplateFilter'
import TemplateForm from './TemplateForm'

const { TabPane } = Tabs

export default function Templates() {
  const [activeTab, setActiveTab] = useState(GET_DATA('customTemplates.activeTab') || 'Payroll')
  const [templates, setTemplates] = useState([])
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)

  const history = useHistory()

  const getData = (params = {}) => {
    apiClient.get('customTemplates/getAll', { params }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('customTemplates.filterData', { ...params, ...data.pageData })
        setTemplates(data.result)
      }
    })
  }

  const onAdd = (values) => {
    const templateData = { ...values, type: activeTab }

    if (edit) {
      apiClient.put(`customTemplates/update/${edit.id}`, templateData).then(({ data }) => {
        if (data && data.result) {
          setTemplates(templates.map((t) => (t.id === data.result.id ? data.result : t)))
        }

        setOpen(false)
        setEdit(false)
      })
    } else {
      apiClient.post('customTemplates/add', templateData).then(({ data }) => {
        if (data && data.result) {
          setTemplates([...templates, data.result])
        }

        setOpen(false)
      })
    }
  }

  const onCancel = () => {
    setOpen(false)
    setEdit(false)
  }

  useEffect(() => {
    const filterCache = GET_DATA('customTemplates.filterData') || {}
    getData({ type: activeTab, ...filterCache })
  }, [])

  const onChangeTab = (activeTab) => {
    setActiveTab(activeTab)
    const filterCache = GET_DATA('customTemplates.filterData')
    getData({ ...filterCache, type: activeTab })
    SET_DATA('customTemplates.activeTab', activeTab)
  }

  const onFilter = (obj = {}) => {
    getData({ type: activeTab, ...obj })
  }

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('customTemplates.filterData')
    onFilter({ ...(filterCache || {}), ...pageData }, 'Show')
  }

  const onEdit = (data) => {
    setOpen(true)
    setEdit(data)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, r) => (
        <div onClick={() => history.push(`/app/custom-templates/${r.id}`)}>
          <a>{text}</a>
        </div>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      ...(validateAccess('edit-custom-template') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, r) => (
          <div className="btn-group">
            <Button onClick={() => onEdit(r)} className="btn-glow dropdown-toggle">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<TemplateFilter onFilter={onFilter} onOpen={() => setOpen(true)} />}>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="top-filter-options">
            <h2>Template Overview</h2>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="content-container">
            <Tabs defaultActiveKey={activeTab} onChange={onChangeTab}>
              <TabPane tab="Payroll" key="Payroll" />
              <TabPane tab="Invoice" key="Invoice" />
              <TabPane tab="Order" key="Order" />
              <TabPane tab="OrderInvoice" key="OrderInvoice" />
              <TabPane tab="Timesheet" key="Timesheet" />
              <TabPane tab="Email" key="Email" />
              <TabPane tab="Freight" key="Freight" />
            </Tabs>
            <TableBox
              dataSource={templates}
              columns={columns}
              pageData={GET_DATA('customTemplates.filterData')}
              onChangePage={onChangePage}
            />
          </div>
        </Col>
      </Row>
      {open && (
        <TemplateForm
          activeTab={activeTab}
          edit={edit}
          templates={templates}
          type={activeTab}
          open={open}
          onAdd={onAdd}
          onCancel={onCancel}
        />
      )}
    </FilterLayout>
  )
}
