import { Col, Row } from 'antd'
import React, { memo } from 'react'
import Field from '../../../Components/Formik/Field'

function SingleForwarderCharge({ name, disabled, charges, i }) {
  return (
    <Row gutter={[10, 5]}>
      <Col xs={12} sm={12} md={8}>
        <div className="form-field">
          <Field
            name={`${name}.forwarderCharges[${i}].type`}
            label="Type"
            value={name === 'supplier' ? 'Supplier' : 'Customs'}
            disabled
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8}>
        <div className="form-field">
          <Field
            as="select"
            name={`${name}.forwarderCharges[${i}].chargeType`}
            label="Values"
            options={charges}
            disabled={disabled}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8}>
        <div className="form-field">
          <Field
            type="number"
            name={`${name}.forwarderCharges[${i}].chargeValue`}
            label="Amount in Base Currency"
            disabled={disabled}
          />
        </div>
      </Col>
    </Row>
  )
}

export default memo(SingleForwarderCharge)
