import { withFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import Button from '../../Components/Button'
import Field from '../../Components/Formik/Field'
import Form from '../../Components/Formik/Form'
import { GET_DATA, removeEmptyKeys, SET_DATA, validateAccess } from '../../Util/Util'

function Filter(props) {
  const onSubmitForm = async () => {
    props.validateForm().then((err) => {
      if (_.isEmpty(err)) {
        const params = removeEmptyKeys(props.values)
        props.onFilter(params)
        SET_DATA('customTemplates.filterData', props.values)
      }
    })
  }

  const onClear = () => {
    props.resetForm({})
    props.onFilter({})
  }

  return (
    <div className="filter-section">
      {validateAccess('add-custom-template') && (
        <Button onClick={props.onOpen} variant="primary" className="btn-block">
          <i className="flaticon-plus" />
          Add New Template
        </Button>
      )}
      <Form>
        <div className="form-fields">
          <Field name="name" label="Name" />
        </div>
        <div className="form-fields">
          <Field name="description" label="Description" />
        </div>
        <div className="form-fields">
          <Button variant="secondary" className="search" onClick={onSubmitForm}>
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button className="btn-block btn-glow search" onClick={onClear}>
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => {
    const filterObj = GET_DATA('usecustomTemplatesrs.filterData')

    return {
      name: filterObj?.name || '',
      description: filterObj?.description || ''
    }
  },
  handleSubmit: () => null
})(Filter)
