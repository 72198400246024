import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Aboutus from '../Screens/Aboutus/Aboutus'
import ApplicationSupport from '../Screens/ApplicationSupport/ApplicationSupport'
import Careers from '../Screens/Careers/Careers'
import SelectCompany from '../Screens/Companies/SelectCompany'
import Contactus from '../Screens/Contactus/Contactus'
// import Payment from '../Screens/CustomerOnboarding/Payment/Payment'
// import PlanSelection from '../Screens/CustomerOnboarding/PlanSelection/PlanSelection'
// import Registration from '../Screens/CustomerOnboarding/Registration/Registration'
import VerifyCode from '../Screens/CustomerOnboarding/VerifyCode/VerifyCode'
import DataSecurity from '../Screens/DataSecurity/DataSecurity'
import DemoVideo from '../Screens/DemoVideo/DemoVideo'
import DisasterRecovery from '../Screens/DisasterRecovery/DisasterRecovery'
import EmailVerification from '../Screens/EmailVerification/EmailVerification'
import EnhancementsUpgrades from '../Screens/EnhancementsUpgrades/EnhancementsUpgrades'
// import FAQ from '../Screens/FAQ/FAQ'
import ForgotPassword from '../Screens/ForgotPassword/ForgotPassword'
import BookingView from '../Screens/FreightManagement/Export/Bookings/BookingView'
import OfferView from '../Screens/FreightManagement/Export/Offers/OfferView'
import Home from '../Screens/LandingPage/Home'
import Legal from '../Screens/Legal/Legal'
import Login from '../Screens/Login/Login'
import BusinessPartnerInvitation from '../Screens/MasterData/Clients/BusinessPartnerInvitation'
import SalesQuotationView from '../Screens/OrderManagement/SalesQuotations/SalesQuotationView'
import PrivacyPolicy from '../Screens/PrivacyPolicy/PrivacyPolicy'
import QrInfo from '../Screens/QrInfo/QrInfo'
import Register from '../Screens/Register/Register'
import Payment from '../Screens/Registration/Payment/Payment'
import Pricing from '../Screens/Registration/Pricing/Pricing'
import ReviewOrder from '../Screens/Registration/ReviewOrder/ReviewOrder'
import ResetPassword from '../Screens/ResetPassword/ResetPassword'
import TermsandConditions from '../Screens/TermsandConditions/TermsandConditions'
import InnerRoutes from './InnerRoutes'
import NotFound from './NotFound'
import RouteWrapper from './RouteWrapper'

const metadetails = {
  aboutUs: {
    title:
      'Digitze your business with cloud based ERP along with online e-invoicing (Fatoorah qr code) solution in Saudi Arabia',
    description:
      'Cloud based ERP and online e-invoicing (Fatoorah qr code) solution provider to digitize your business in 2 hours with low price',
    keyword: 'Digital Business, Cloud based ERP, e-invoice (Fatoorah qr code) in Saudi, fatoorah qr code'
  },
  pricing: {
    title: 'Low Budget online e-invoicing (Fatoorah qr code) solution with zatca procedure',
    description:
      'Safe, online, low budget e-invoicing (Fatoorah qr code) solution provider to improve your business in Saudi Arabia (KSA)',
    keyword: 'Low budget e-invoice solution, fatoorah Saudi arabia'
  }
}

class Routes extends React.PureComponent {
  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || '/'}>
        <Switch>
          <Route exact path="/" render={(props) => <RouteWrapper {...props} screen={Home} />} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route
            exact
            path="/forgot-password"
            render={(props) => <RouteWrapper {...props} screen={ForgotPassword} />}
          />
          <Route
            exact
            path="/reset-password"
            render={(props) => <RouteWrapper {...props} screen={ResetPassword} />}
          />
          <Route exact path="/email-verification" component={EmailVerification} />
          <Route
            exact
            path="/privacy-policy"
            render={(props) => <RouteWrapper {...props} screen={PrivacyPolicy} />}
          />
          <Route
            exact
            path="/terms-and-conditions"
            render={(props) => <RouteWrapper {...props} screen={TermsandConditions} />}
          />
          {/* <Route exact path="/frequently-asked-questions" component={FAQ} /> */}
          <Route exact path="/legal" component={Legal} />
          <Route
            exact
            path="/about-us"
            render={(props) => <RouteWrapper {...props} screen={Aboutus} metaObj={metadetails.aboutUs} />}
          />
          <Route exact path="/careers" render={(props) => <RouteWrapper {...props} screen={Careers} />} />
          <Route
            exact
            path="/contact-us"
            render={(props) => <RouteWrapper {...props} screen={Contactus} />}
          />
          <Route
            exact
            path="/pricing"
            render={(props) => <RouteWrapper {...props} screen={Pricing} metaObj={metadetails.pricing} />}
          />
          <Route exact path="/review-order" component={ReviewOrder} />
          <Route exact path="/select-company" component={SelectCompany} />
          <Route exact path="/business-partner-invitation/:token" component={BusinessPartnerInvitation} />
          <Route exact path="/offers/:token" component={OfferView} />
          <Route exact path="/bookings/:token" component={BookingView} />
          <Route exact path="/sales-quotations/:token" component={SalesQuotationView} />
          {/* <Route exact path="/plan-selection" component={PlanSelection} /> */}
          {/* <Route exact path="/registration" component={Registration} /> */}
          <Route exact path="/verify-code" component={VerifyCode} />
          <Route exact path="/payment" component={Payment} />
          <Route
            exact
            path="/data-security"
            render={(props) => <RouteWrapper {...props} screen={DataSecurity} />}
          />
          <Route
            exact
            path="/application-support-and-maintenance"
            render={(props) => <RouteWrapper {...props} screen={ApplicationSupport} />}
          />
          <Route
            exact
            path="/disaster-recovery"
            render={(props) => <RouteWrapper {...props} screen={DisasterRecovery} />}
          />
          <Route
            exact
            path="/enhancements-and-upgrades"
            render={(props) => <RouteWrapper {...props} screen={EnhancementsUpgrades} />}
          />
          <Route
            exact
            path="/demo-videos"
            render={(props) => <RouteWrapper {...props} screen={DemoVideo} />}
          />
          <Route exact path="/qr-info/:type/:id" component={QrInfo} />
          <Route path="/app" component={InnerRoutes} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

export default Routes
