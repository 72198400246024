import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Input as InputField } from 'antd'
import React, { FunctionComponent, memo, useState } from 'react'
import useDebounceEffect from '../../Hooks/useDebounceEffect'
import AltInput from '../AltInput'
import { arabicRegex, InputValueType, TInput } from './types'

const Input: FunctionComponent<TInput> = ({
  onChange,
  onChangeAlt,
  onBlur,
  label,
  prefix,
  suffix,
  type,
  error,
  required,
  value,
  delay,
  hideLabel,
  altDisabled,
  altValue,
  altInput,
  ...props
}) => {
  const [showText, setShowText] = useState(false)
  const [val, setValue] = useDebounceEffect((v: InputValueType) => onChange?.(props.name, v), value, delay)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!props.disabled && onChange) {
      if (type === 'number') {
        setValue(
          e.target.value ? (!Number.isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : val) : ''
        )
      } else {
        setValue(e.target.value)
      }
    }
  }

  return (
    <div className="custom-input-box">
      {label && !hideLabel && (
        <label style={{ textAlign: 'left', width: 'fit-content' }}>
          {label} {required && <span className="required">*</span>}
        </label>
      )}

      {altInput && (
        <AltInput {...props} {...{ label, altDisabled, altValue, altInput, onChange: onChangeAlt }} />
      )}

      <InputField
        type={type === 'password' ? (showText ? 'text' : 'password') : type || 'text'}
        prefix={prefix && <i className={prefix} />}
        suffix={
          type === 'password' ? (
            showText ? (
              <EyeTwoTone onClick={() => setShowText(false)} />
            ) : (
              <EyeInvisibleOutlined onClick={() => setShowText(true)} />
            )
          ) : (
            suffix
          )
        }
        onChange={handleChange}
        onBlur={() => onBlur?.(props.name, val)}
        value={val}
        dir={arabicRegex.test(val?.toString()) ? 'rtl' : 'ltr'}
        {...props}
      />

      {error && (
        <div style={{ fontSize: 10, color: 'red', textAlign: 'right' }}>
          {error.replace(props.name, label || props.placeholder || '')}
        </div>
      )}
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  delay: 500
}

export default memo(Input)
