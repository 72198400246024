import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'
import { convertSelectOptions } from '../../../Util/Util'

function FinancePostingFilter({ values, setValues, filterData, companyInfo }) {
  const showDivision = companyInfo?.configurations?.division === 'Yes'
  const [divisions, setDivisions] = useState([])
  const [transactionCodes, setTransactionCodes] = useState([])

  const getTransactionCodes = () => {
    apiClient.get('finances/get-transaction-codes').then(({ status, data }) => {
      if (status === 200) {
        setTransactionCodes(
          data.map((item) => ({ label: item.transactionCode, value: item.transactionCode }))
        )
      }
    })
  }

  const getDivisions = () => {
    apiClient.get('divisions/get-by-user').then(({ data }) => {
      if (data && data.result) {
        setDivisions(convertSelectOptions(data.result, 'name', 'id'))
      }
    })
  }

  useEffect(() => {
    setValues({ ...values, ...filterData })

    if (showDivision) {
      getDivisions()
    }

    getTransactionCodes()
  }, [])

  return (
    <div className="filter-section">
      <Form>
        {showDivision && (
          <div className="form-fields">
            <Field as="select" name="division" label="Division" options={divisions} allowClear />
          </div>
        )}
        <div className="form-fields">
          <Field as="date" name="transDateFrom" label="Transaction date from" />
        </div>
        <div className="form-fields">
          <Field as="date" name="transDateTo" label="Transaction date to" />
        </div>
        <div className="form-fields">
          <Field as="date" name="postingDateFrom" label="Posting date from" />
        </div>
        <div className="form-fields">
          <Field as="date" name="postingDateTo" label="Posting date to" />
        </div>
        <div className="form-fields">
          <Field name="transactionKey" label="Tran key" />
        </div>
        <div className="form-fields">
          <Field as="select" name="transactionCode" label="Tran Type" allowClear options={transactionCodes} />
        </div>
        <div className="form-fields">
          <Field name="transactionNo" label="Tran number" />
        </div>
        <div className="form-fields">
          <Field name="transactionPositionFrom" label="Tran position from" />
        </div>
        <div className="form-fields">
          <Field name="transactionPositionTo" label="Tran position to" />
        </div>
        <div className="form-fields">
          <Field name="parentDoc" label="Parent number" />
        </div>
        <div className="form-fields">
          <Field name="parentPositionFrom" label="Parent position from" />
        </div>
        <div className="form-fields">
          <Field name="parentPositionTo" label="Parent position to" />
        </div>
        <div className="form-fields">
          <Button type="submit" variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button type="reset" className="btn-block btn-glow search">
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    transDateFrom: '',
    transDateTo: '',
    postingDateFrom: '',
    postingDateTo: '',
    transactionKey: '',
    transactionCode: '',
    transactionNo: '',
    transactionPositionFrom: '',
    transactionPositionTo: '',
    parentDoc: '',
    parentPositionFrom: '',
    parentPositionTo: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(FinancePostingFilter)
