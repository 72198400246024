import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import clsx from 'clsx'
import _ from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import {
  getLocationsByWarehouse,
  getRacksByLocation,
  getWarehouseCodes,
  stockCheck
} from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import { getOrderNames } from '../../../Util/Options'
import { parseAmount } from '../../../Util/Util'

function SingleDelivery({
  materialCode,
  materialDescription,
  materialCodeAlt,
  materialDescriptionAlt,
  warehouse,
  location,
  price,
  stockStatus,
  deliveryValue,
  orderedQuantity,
  orderDeliveryQuantity,
  toBeDeliveredQuantity,
  i,
  type,
  disabled,
  currency,
  setFieldValue
}) {
  const { isSales, typeName, deliveryName } = getOrderNames(type)

  const [stockData, setStockData] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [locations, setLocations] = useState([])
  const [racks, setRacks] = useState([])

  const getWarehouses = (warehouse) => {
    getWarehouseCodes({ warehouse }).then((data) => {
      setWarehouses(data)
    })
  }

  const getLocations = (params) => {
    getLocationsByWarehouse(params).then((data) => {
      setLocations(data)
    })
  }

  const getRacks = (params) => {
    getRacksByLocation(params).then((data) => {
      setRacks(data)
    })
  }

  const getData = () => {
    if (isSales) {
      stockCheck({
        materialCode
      }).then((data) => {
        setStockData(data)
      })
    }
  }

  useEffect(() => {
    getData()
  }, [type])

  useDidUpdateEffect(() => {
    setFieldValue(`orderDetails[${i}].disabled`, disabled)
  }, [disabled])

  return (
    <Row gutter={[10, 5]}>
      <Col xs={12} sm={12} md={8} lg={2}>
        <div className="form-field">
          <Field name={`orderDetails[${i}].deliveryPosition`} label={`${deliveryName} Position`} disabled />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3}>
        <div className="form-field">
          <Field name={`orderDetails[${i}].orderNo`} label={`${typeName} Order no.`} disabled />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={2}>
        <div className="form-field">
          <Field name={`orderDetails[${i}].position`} label={`${typeName} Position`} disabled />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={4}>
        <div className="form-field">
          <Field
            as="textarea"
            rows={1}
            name={`orderDetails[${i}].materialCodeDesc`}
            label="Material / Description"
            value={_.compact([materialCode, materialDescription]).join(' - ')}
            disabled
            altInput
            altDisabled
            altValue={_.compact([materialCodeAlt, materialDescriptionAlt]).join(' - ')}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={2}>
        <div className="form-field">
          <Field name={`orderDetails[${i}].unit`} label="UOM" disabled />
        </div>
      </Col>
      {isSales ? (
        <>
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field
                as="select"
                name={`orderDetails[${i}].warehouse`}
                label="Warehouse"
                onChange={(n, v) => {
                  setFieldValue(`orderDetails[${i}].location`, '')
                  setFieldValue(`orderDetails[${i}].rack`, '')

                  return setFieldValue(n, v)
                }}
                options={_.unionBy(
                  stockData.map((item) => ({
                    ...item,
                    label: item.warehouse,
                    value: item.warehouse
                  })),
                  'value'
                )}
                disabled={disabled}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field
                key={warehouse}
                as="select"
                name={`orderDetails[${i}].location`}
                label="Location"
                onChange={(n, v) => {
                  setFieldValue(`orderDetails[${i}].rack`, '')

                  return setFieldValue(n, v)
                }}
                options={_.unionBy(
                  stockData
                    .filter((item) => item.warehouse === warehouse)
                    .map((item) => ({
                      ...item,
                      label: item.location,
                      value: item.location
                    })),
                  'value'
                )}
                disabled={disabled}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field
                key={warehouse + location}
                as="select"
                name={`orderDetails[${i}].rack`}
                label="Rack"
                options={stockData
                  .filter((item) => item.warehouse === warehouse && item.location === location)
                  .map((item) => ({
                    ...item,
                    label: item.rack,
                    value: item.rack
                  }))}
                disabled={disabled}
              />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field
                as="auto-complete"
                name={`orderDetails[${i}].warehouse`}
                label="Warehouse"
                options={warehouses}
                onSearch={getWarehouses}
                onChange={(n, v) => {
                  setFieldValue(`orderDetails[${i}].location`, '')
                  setFieldValue(`orderDetails[${i}].rack`, '')
                  setLocations([])

                  return setFieldValue(n, v)
                }}
                disabled={disabled}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field
                as="auto-complete"
                key={warehouse}
                name={`orderDetails[${i}].location`}
                label="Location"
                onSearch={(location) =>
                  getLocations({
                    warehouse,
                    location
                  })
                }
                options={locations}
                onChange={(n, v) => {
                  setFieldValue(`orderDetails[${i}].rack`, '')
                  setRacks([])

                  return setFieldValue(n, v)
                }}
                disabled={disabled}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field
                as="auto-complete"
                key={warehouse + location}
                name={`orderDetails[${i}].rack`}
                label="Rack"
                onSearch={(rack) =>
                  getRacks({
                    rack,
                    warehouse,
                    location
                  })
                }
                options={racks}
                disabled={disabled}
              />
            </div>
          </Col>
        </>
      )}
      <Col xs={12} sm={12} md={8} lg={2}>
        <div className="form-field">
          <Field
            type="number"
            name={`orderDetails[${i}].deliveryQuantity`}
            label={`${deliveryName} Qty`}
            onChange={(n, v) => {
              setFieldValue(`orderDetails[${i}].deliveryValue`, v ? v * price : '')
              setFieldValue(
                `orderDetails[${i}].toBeDeliveredQuantity`,
                parseInt(orderDeliveryQuantity ?? orderedQuantity, 10) - parseInt(v || 0, 10)
              )

              return setFieldValue(n, v)
            }}
            disabled={disabled}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3}>
        <div className="form-field">
          <Field as="date" name={`orderDetails[${i}].requestedDate`} label="Req. Date" disabled />
        </div>
      </Col>
      <Col xs={24}>
        <div className="line-item-footer">
          {isSales && stockStatus && (
            <div className="line-item-footer-content">
              <span className={clsx('text-white p-1', stockStatus === 'OK' ? 'bg-success' : 'bg-danger')}>
                {stockStatus === 'OK' ? <CheckOutlined /> : <CloseOutlined />}
              </span>{' '}
              <b>Stock Status</b>
            </div>
          )}
          <div className="line-item-footer-content">
            <b>Order quantity :</b> <span className="text-nowrap">{orderedQuantity}</span>
          </div>
          <div className="line-item-footer-content">
            <b>{`${deliveryName} Value`} :</b>{' '}
            <span className="text-nowrap">{parseAmount(deliveryValue, currency)}</span>
          </div>
          <div className="line-item-footer-content">
            <b>Price :</b> <span className="text-nowrap">{parseAmount(price, currency)}</span>
          </div>
          <div className="line-item-footer-content">
            <b>Open Qty :</b> <span className="text-nowrap">{toBeDeliveredQuantity}</span>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default memo(SingleDelivery)
