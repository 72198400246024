import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  getContactDetailsByEmployeeID,
  updateContactDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'

const Schema = Yup.object().shape({
  currentAddress: Yup.string().required(),
  permanentAddress: Yup.string().required(),
  postalCode: Yup.number().required(),
  homeTelephone: Yup.string().required(),
  mobile: Yup.string().required(),
  workTelephone: Yup.string().required(),
  workEmail: Yup.string().email().required(),
  otherEmail: Yup.string().email().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  country: Yup.string().required()
})

const ContactDetails = (props) => {
  const [editable, setEditable] = useState(false)
  // const [options, setOptions] = useState({})
  const { currentEmployee, contactDetails, dispatch, values, setValues, submitForm, errors } = props

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getContactDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  // const fetchDropdownValues = () => {
  //   apiClient
  //     .get('options/get-by-types', {
  //       params: { type: ['Country', 'State'] }
  //     })
  //     .then(({ data }) => {
  //       if (data && data.result) {
  //         setOptions(data.result)
  //       }
  //     })
  // }

  const onEdit = () => {
    if (currentEmployee?.id) {
      // fetchDropdownValues()
      setEditable(true)
      setValues({
        ...values,
        currentAddress: contactDetails?.currentAddress,
        permanentAddress: contactDetails?.permanentAddress,
        postalCode: contactDetails?.postalCode,
        homeTelephone: contactDetails?.homeTelephone,
        mobile: contactDetails?.mobile,
        workTelephone: contactDetails?.workTelephone,
        workEmail: contactDetails?.workEmail,
        otherEmail: contactDetails?.otherEmail,
        city: contactDetails?.city,
        state: contactDetails?.state,
        country: contactDetails?.country
      })
    } else {
      message.error('Please select and employee to edit')
    }
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setEditable(false)
    }
  }

  return (
    <Form>
      <PanelLayout>
        <Panel title="Contact Details">
          {!editable && (
            <div className="panel-with-border">
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Current Address</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.currentAddress : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Permanent Address</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.permanentAddress : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Zip / Postal Code</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.postalCode : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Home Telephone</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.homeTelephone : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Mobile</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.mobile : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Work Telephone</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.workTelephone : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Work Email</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.workEmail : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Other Email</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.otherEmail : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>City</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.city : 'N/A'}</p>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>State / Province</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.state : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Country</span>
                  <p>{!isEmpty(contactDetails) ? contactDetails.country : 'N/A'}</p>
                </Col>
              </Row>
            </div>
          )}
          {editable && (
            <div>
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="currentAddress" label="Current Address" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="permanentAddress" label="Permanent Address" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="postalCode" label="Zip / Postal Code" type="number" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="homeTelephone" label="Home Telephone" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="mobile" label="Mobile" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="workTelephone" label="Work Telephone" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="workEmail" label="Work Email" type="email" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="otherEmail" label="Other Email" type="email" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="city" label="City" type="text" />
                  </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="state" label="State / Province" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="country" label="Country" type="text" />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Panel>
      </PanelLayout>
      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
        centerActions={[
          {
            prefix: 'flaticon-play',
            label: 'Save',
            dontShow: !editable,
            onClick: onSave
          }
        ]}
        rightActions={[
          {
            prefix: 'flaticon-edit-1',
            label: 'Edit',
            dontShow: editable,
            onClick: onEdit
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  contactDetails: state.employee.employeeDetails.contactDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      currentAddress: '',
      permanentAddress: '',
      postalCode: '',
      homeTelephone: '',
      mobile: '',
      workTelephone: '',
      workEmail: '',
      otherEmail: '',
      city: '',
      state: '',
      country: ''
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      dispatch(updateContactDetailsByEmployeeID(currentEmployee.id, values))
    },
    validationSchema: Schema
  })(ContactDetails)
)
