import { GlobalOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { lang, setLanguage } from '../Translate/Translate'

const languages = [
  { label: 'English', value: 'en' },
  { label: 'Arabic', value: 'ar' }
]

function ChangeLanguage(props) {
  const [language, onSetLanguage] = useState(lang.lang)

  const onChangeLang = (lang) => {
    setLanguage(lang)
    props.dispatch({ type: 'SET_USER_REDUCER', payload: { lang } })
    document.getElementById('root').style.direction = lang === 'ar' ? 'rtl' : ''
    onSetLanguage(lang)
  }

  const tableContent = () => (
    <div className="action-buttons">
      <ul>
        {languages.map((v, i) => (
          <li key={i} onClick={() => onChangeLang(v.value)}>
            <div>{v.label}</div>
          </li>
        ))}
      </ul>
    </div>
  )

  return (
    <div className="transulate">
      <Popover placement="bottom" content={() => tableContent()} trigger="hover">
        <div className="language-transulate">
          <GlobalOutlined />
          <div className="language-selected">{language}</div>
        </div>
      </Popover>
    </div>
  )
}

export default connect()(ChangeLanguage)
