import { ArrowLeftOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../../Components/Button'
import TableBox from '../../../../Components/TableBox/TableBox'
import FilterLayout from '../../../../Layout/FilterLayout'
import PanelLayout from '../../../../Layout/PanelLayout'
import apiClient from '../../../../Util/apiClient'
import { GET_DATA, SET_DATA } from '../../../../Util/Util'
import CreateJobCreationFilter from './CreateJobCreationFilter'

export default function CreateJobCreations() {
  const [jobCreations, setJobCreations] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('createJobCreations.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('bookings/search', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('createJobCreations.filterData', params)
        setJobCreations(data)
      }
    })
  }

  useEffect(() => {
    const filterCache = GET_DATA('createJobCreations.filterData')

    if (filterCache) {
      getData(filterCache)
    }
  }, [])

  const columns = [
    {
      title: 'Booking No',
      dataIndex: 'bookingNo'
    },
    {
      title: 'Quotation No',
      dataIndex: 'quotationNo'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Client',
      dataIndex: 'clientName'
    }
  ]

  return (
    <FilterLayout
      filterData={GET_DATA('createJobCreations.filterData')}
      filter={<CreateJobCreationFilter onSubmit={getData} />}>
      <div className="inner-contents px-4">
        <PanelLayout title="Create Job Creations">
          <TableBox
            rowSelection={{
              type: 'radio',
              selectedRowKeys,
              onChange: setSelectedRowKeys
            }}
            dataSource={jobCreations}
            columns={columns}
          />
        </PanelLayout>
        <div className="save-changes">
          <Button
            variant="primary"
            disabled={selectedRowKeys.length === 0}
            onClick={() => {
              history.push({
                pathname: '/app/add-job-creation',
                state: {
                  type: GET_DATA('createJobCreations.filterData').type,
                  bookingData: jobCreations.find((item) => item.id === selectedRowKeys[0])
                }
              })
            }}>
            Create Job Creation
          </Button>
          <Link to="/app/job-creations">
            <Button>
              <ArrowLeftOutlined /> Back to job creation list
            </Button>
          </Link>
        </div>
      </div>
    </FilterLayout>
  )
}
