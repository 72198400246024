import moment from 'moment'
import * as Yup from 'yup'
import './YupMethod'

const message = '${path} required'

export const warehouseSchema = Yup.object().shape({
  warehouses: Yup.array().of(
    Yup.object().shape({
      warehouse: Yup.string().required(message),
      description: Yup.string().required(message),
      location: Yup.string().when('warehouseLocations', {
        is: (warehouseLocations) => warehouseLocations === 'Yes',
        then: Yup.string().required(message)
      }),
      locationDescription: Yup.string().when('warehouseLocations', {
        is: (warehouseLocations) => warehouseLocations === 'Yes',
        then: Yup.string().required(message)
      }),
      rack: Yup.string().when('warehouseRacks', {
        is: (warehouseRacks) => warehouseRacks === 'Yes',
        then: Yup.string().required(message)
      }),
      rackDescription: Yup.string().when('warehouseRacks', {
        is: (warehouseRacks) => warehouseRacks === 'Yes',
        then: Yup.string().required(message)
      })
    })
  )
})

export const warehouseProductSchema = Yup.object().shape({
  basic: Yup.object().shape({
    materialCode: Yup.string().required(message),
    materialDescription: Yup.string().required(message),
    materialType: Yup.string().required(message),
    materialGroup: Yup.string().required(message),
    division: Yup.string().required(message),
    unit: Yup.string().required(message),
    grossWeight: Yup.number().nullable().decimal(),
    netWeight: Yup.number().nullable().decimal(),
    volume: Yup.number().nullable().decimal(),
    salesTime: Yup.number().nullable().decimal(),
    procurementTime: Yup.number().nullable().decimal()
  }),
  stockInfo: Yup.object().shape({
    safetyStock: Yup.number().nullable().decimal(),
    reorderLevel: Yup.number().nullable().decimal(),
    purchaseUnit: Yup.string().required(message),
    salesUnit: Yup.string().required(message),
    purchasePrice: Yup.number().decimal().required(message),
    salesPrice: Yup.number().decimal().required(message)
  }),
  costInfo: Yup.object().shape({
    type: Yup.string().required(message),
    cost: Yup.number().nullable().decimal().required(message),
    movingCost: Yup.number().nullable().decimal().required(message)
  }),
  packaging: Yup.object().shape({
    length: Yup.number().nullable().decimal(),
    width: Yup.number().nullable().decimal(),
    height: Yup.number().nullable().decimal(),
    quantity: Yup.number().nullable().number()
  })
})

export const goodsReceiptSchema = Yup.object().shape({
  supplier: Yup.string().required(message),
  orderType: Yup.string().required(message),
  orderNo: Yup.string().when('orderType', {
    is: (orderType) => orderType === 'purchase',
    then: Yup.string().required(message)
  }),
  transactions: Yup.array()
    .of(
      Yup.object().shape({
        materialCode: Yup.string().required(message),
        materialDescription: Yup.string().required(message),
        unit: Yup.string().required(message),
        quantity: Yup.string().required(message),
        warehouse: Yup.string().required(message),
        location: Yup.string().when('warehouseLocations', {
          is: (warehouseLocations) => warehouseLocations === 'Yes',
          then: Yup.string().required(message)
        }),
        rack: Yup.string().when('warehouseRacks', {
          is: (warehouseRacks) => warehouseRacks === 'Yes',
          then: Yup.string().required(message)
        }),
        status: Yup.string().required(message)
      })
    )
    .required()
})

export const goodsIssueSchema = Yup.object().shape({
  requester: Yup.string().required(message),
  orderType: Yup.string().required(message),
  orderNo: Yup.string().when('orderType', {
    is: (orderType) => orderType === 'purchase',
    then: Yup.string().required(message)
  }),
  transactions: Yup.array()
    .of(
      Yup.object().shape({
        materialCode: Yup.string().required(message),
        materialDescription: Yup.string().required(message),
        unit: Yup.string().required(message),
        quantity: Yup.string().required(message),
        warehouse: Yup.string().required(message),
        location: Yup.string().when('warehouseLocations', {
          is: (warehouseLocations) => warehouseLocations === 'Yes',
          then: Yup.string().required(message)
        }),
        rack: Yup.string().when('warehouseRacks', {
          is: (warehouseRacks) => warehouseRacks === 'Yes',
          then: Yup.string().required(message)
        }),
        status: Yup.string().required(message)
      })
    )
    .required()
})

export const stockTransferSchema = Yup.object().shape({
  transactions: Yup.array()
    .of(
      Yup.object().shape({
        materialCode: Yup.string().required(message),
        materialDescription: Yup.string().required(message),
        unit: Yup.string().required(message),
        quantity: Yup.string().required(message),
        from: Yup.object().shape({
          warehouse: Yup.string().required(message),
          location: Yup.string().when('warehouseLocations', {
            is: (warehouseLocations) => warehouseLocations === 'Yes',
            then: Yup.string().required(message)
          }),
          rack: Yup.string().when('warehouseRacks', {
            is: (warehouseRacks) => warehouseRacks === 'Yes',
            then: Yup.string().required(message)
          })
        }),
        to: Yup.object().shape({
          warehouse: Yup.string().required(message),
          location: Yup.string().when('warehouseLocations', {
            is: (warehouseLocations) => warehouseLocations === 'Yes',
            then: Yup.string().required(message)
          }),
          rack: Yup.string().when('warehouseRacks', {
            is: (warehouseRacks) => warehouseRacks === 'Yes',
            then: Yup.string().required(message)
          })
        }),
        status: Yup.string().required(message)
      })
    )
    .required()
})

export const addressFieldSchema = Yup.object().shape({
  street: Yup.string().required(message),
  city: Yup.string().required(message),
  postalCode: Yup.string().required(message),
  country: Yup.string().required(message)
})

export const orderSchema = Yup.object().shape({
  orderType: Yup.string().required(message),
  orderDate: Yup.date().required(message),
  poType: Yup.string().when('type', {
    is: (type) => type === 'purchase',
    then: Yup.string().required(message)
  }),
  client: Yup.string().required(message),
  currency: Yup.string().required(message),
  exchangeRate: Yup.number().decimal().required(message),
  billingAddress: addressFieldSchema,
  shippingAddress: addressFieldSchema,
  payTerm: Yup.number()
    .number()
    .nullable()
    .when('type', {
      is: (type) => type === 'purchase',
      then: Yup.number().required(message)
    }),
  taxCategory: Yup.string().required(message),
  orderDetails: Yup.array()
    .of(
      Yup.object().shape({
        position: Yup.number().number().required(message),
        materialCodeDesc: Yup.string().required(message),
        unit: Yup.string().required(message),
        orderedQuantity: Yup.number().number().required(message),
        requestedDate: Yup.date().required(message),
        price: Yup.number().decimal().required(message),
        discount: Yup.number().decimal(),
        charge: Yup.number().decimal(),
        taxType: Yup.string().required(message),
        tax: Yup.number()
          .decimal()
          .when('taxType', {
            is: (taxType) => taxType === 'Normal VAT',
            then: Yup.number().required(message)
          })
      })
    )
    .required()
})

export const orderDeliverySchema = Yup.object().shape({
  orderDetails: Yup.array()
    .of(
      Yup.object().shape({
        warehouse: Yup.string().required(message),
        location: Yup.string().when('warehouseLocations', {
          is: (warehouseLocations) => warehouseLocations === 'Yes',
          then: Yup.string().required(message)
        }),
        rack: Yup.string().when('warehouseRacks', {
          is: (warehouseRacks) => warehouseRacks === 'Yes',
          then: Yup.string().required(message)
        }),
        deliveryQuantity: Yup.number().when('disabled', {
          is: (disabled) => !disabled,
          then: Yup.number()
            .number()
            .min(1, 'Delivery qty must be greater than or equal to ${min}')
            .max(
              Yup.ref('orderDeliveryQuantity'),
              'Delivery qty is greater than open order qty ${max}. Please check'
            )
            .required(message)
        })
      })
    )
    .required()
})

export const orderInvoiceSchema = Yup.object().shape({
  status: Yup.string().required(message),
  client: Yup.string().required(message),
  currency: Yup.string().required(message),
  issueDate: Yup.date().required(message),
  taxDate: Yup.date().required(message),
  exchangeRate: Yup.number().when(['companyCurrency', 'currency'], {
    is: (companyCurrency, currency) => companyCurrency !== currency,
    then: Yup.number().decimal().required(message)
  }),
  taxCategory: Yup.string().required(message),
  orderDetails: Yup.array()
    .of(
      Yup.object().shape({
        invoiceQuantity: Yup.number().when('disabled', {
          is: (disabled) => !disabled,
          then: Yup.number()
            .number()
            .min(1, 'Invoice qty must be greater than or equal to ${min}')
            .max(
              Yup.ref('orderInvoiceQuantity'),
              'Invoice qty is greater than open order qty ${max}. Please check'
            )
            .required(message)
        }),
        price: Yup.number().decimal().required(message),
        discount: Yup.number().decimal(),
        charge: Yup.number().decimal(),
        taxType: Yup.string().required(message),
        tax: Yup.number()
          .decimal()
          .when('taxType', {
            is: (taxType) => taxType === 'Normal VAT',
            then: Yup.number().required(message)
          })
      })
    )
    .required()
})

export const numberingSeriesSchema = Yup.object().shape({
  prefix: Yup.string().when('prefixIsDate', {
    is: true,
    then: Yup.string().dateFormat()
  }),
  startNo: Yup.number().number().required(message),
  suffix: Yup.string().when('suffixIsDate', {
    is: true,
    then: Yup.string().dateFormat()
  }),
  validFrom: Yup.date()
    .nullable()
    .when('lastGeneratedNo', {
      is: (lastGeneratedNo) => !lastGeneratedNo,
      then: Yup.date().min(moment().startOf('day').toDate(), '${path} must be greater than Today')
    })
    .max(Yup.ref('validTo'), '${path} must be lesser than Valid To')
    .required(message),
  validTo: Yup.date()
    .nullable()
    .min(Yup.ref('validFrom'), '${path} must be greater than Valid From')
    .min(new Date(), '${path} must be greater than Today')
    .required(message)
})

export const journalVoucherSchema = Yup.object().shape({
  currency: Yup.string().required(message),
  exchangeRate: Yup.string().required(message),
  accountList: Yup.array().of(
    Yup.object().shape({
      cr: Yup.object().shape({
        code: Yup.string().required(message),
        name: Yup.string().required(message),
        type: Yup.string().required(message),
        amount: Yup.number().nullable().decimal().required(message)
      }),
      dr: Yup.object().shape({
        code: Yup.string().required(message),
        name: Yup.string().required(message),
        type: Yup.string().required(message),
        amount: Yup.number().nullable().decimal().required(message)
      })
    })
  )
})

export const orderFilterSchema = Yup.object().shape(
  {
    client: Yup.string().when('clientName', {
      is: (clientName) => !clientName,
      then: Yup.string().required(message)
    }),
    clientName: Yup.string().when('client', {
      is: (client) => !client,
      then: Yup.string().required(message)
    })
  },
  ['client', 'clientName']
)

export const invoiceEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .when('showEmail', {
      is: true,
      then: Yup.string().required(message)
    })
})

export const financesSchema = Yup.object().shape({
  accountCode: Yup.string().required(message)
})

export const salesPersonSchema = Yup.object().shape({
  firstName: Yup.string().required(message),
  lastName: Yup.string().required(message),
  email: Yup.string().email().required(message),
  contact: Yup.string().required(message),
  status: Yup.string().required(message)
})

export const POLPODSchema = Yup.object().shape({
  code: Yup.string().required(message),
  description: Yup.string().required(message),
  status: Yup.string().required(message)
})

export const packagesTypeSchema = Yup.object().shape({
  type: Yup.string().required(message),
  description: Yup.string().required(message),
  unit: Yup.string().required(message),
  length: Yup.number().decimal().required(message),
  height: Yup.number().decimal().required(message),
  breadth: Yup.number().decimal().required(message),
  volume: Yup.number().decimal().required(message),
  volumeUnit: Yup.string().required(message),
  weight: Yup.number().decimal().required(message),
  weightUnit: Yup.string().required(message)
})

export const priceChargeSchema = Yup.object().shape({
  type: Yup.string().required(message),
  description: Yup.string().required(message),
  country: Yup.string().required(message),
  currency: Yup.string().required(message),
  price: Yup.number().decimal().required(message)
})

export const operationSchema = Yup.object().shape({
  type: Yup.string().required(message),
  value: Yup.string().required(message),
  description: Yup.string().required(message)
})

export const termsConditionSchema = Yup.object().shape({
  type: Yup.string().required(message),
  content: Yup.string().required(message),
  validFrom: Yup.date().required(message),
  validTo: Yup.date().required(message)
})

export const salesCallEntrySchema = addressFieldSchema.shape({
  clientName: Yup.string().required(message),
  contactPerson: Yup.string().required(message),
  phone: Yup.string().required(message),
  email: Yup.string().email().required(message),
  salesPerson: Yup.string().required(message)
})

export const offerSchema = addressFieldSchema.shape({
  quotationNo: Yup.string().required(message),
  clientName: Yup.string().required(message),
  contactPerson: Yup.string().required(message),
  phone: Yup.string().required(message),
  email: Yup.string().email().required(message),
  POLPOD: Yup.string().required(message),
  actualWeight: Yup.string().required(message),
  chargeableWeight: Yup.string().required(message),
  requestedDate: Yup.date().required(message),
  operations: Yup.string().required(message),
  packageList: Yup.array()
    .of(
      Yup.object().shape({
        packageType: Yup.string().required(message),
        length: Yup.number().decimal().required(message),
        breadth: Yup.number().decimal().required(message),
        height: Yup.number().decimal().required(message),
        unit: Yup.string().required(message),
        weight: Yup.number().decimal().required(message),
        weightUnit: Yup.string().required(message),
        volume: Yup.number().decimal().required(message),
        volumeUnit: Yup.string().required(message),
        items: Yup.array()
          .of(
            Yup.object().shape({
              description: Yup.string().required(message),
              quantity: Yup.number().number().required(message),
              unit: Yup.string().required(message),
              price: Yup.number().number().required(message),
              currency: Yup.string().required(message),
              charge: Yup.number().decimal(),
              taxType: Yup.string().required(message),
              tax: Yup.number()
                .decimal()
                .when('taxType', {
                  is: (taxType) => taxType === 'Normal VAT',
                  then: Yup.number().required(message)
                })
            })
          )
          .required(message)
      })
    )
    .required(message)
})

const customsClientCharges = Yup.object().shape({
  client: Yup.string().required(message),
  billingAddress: addressFieldSchema,
  forwarderCharges: Yup.array().of(
    Yup.object().shape({
      chargeType: Yup.string().required(message),
      chargeValue: Yup.number().decimal().required(message)
    })
  )
})

export const customsClearanceSchema = Yup.object().shape({
  clearanceDate: Yup.date().required(message),
  forwarder: customsClientCharges,
  supplier: customsClientCharges
})

export const customClearanceFilterSchema = Yup.object().shape({
  currency: Yup.string().required(message),
  client: Yup.string().required(message)
})

export const jobCreationSchema = Yup.object().shape({
  forwarder: Yup.string().required(message),
  creationDate: Yup.date().required(message),
  departureDate: Yup.date().required(message),
  arrivalDate: Yup.date().required(message),

  tos: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  flightNo: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  MAWBNo: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  airline: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  shipper: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  consignee: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  commodity: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  idNo: Yup.string().when('type', {
    is: (type) => type === 'Air',
    then: Yup.string().required(message)
  }),
  issueDate: Yup.date().when('type', {
    is: (type) => type === 'Air',
    then: Yup.date().required(message)
  }),

  vessel: Yup.string().when('type', {
    is: (type) => type === 'Sea',
    then: Yup.string().required(message)
  }),
  vesselCode: Yup.string().when('type', {
    is: (type) => type === 'Sea',
    then: Yup.string().required(message)
  }),
  voyageNo: Yup.string().when('type', {
    is: (type) => type === 'Sea',
    then: Yup.string().required(message)
  })
})

export const jobCreationFilterSchema = Yup.object().shape({
  type: Yup.string().required(message)
})

export const salesQuotationSchema = Yup.object().shape({
  quotationDate: Yup.date().required(message),
  customer: Yup.string().required(message),
  currency: Yup.string().required(message),
  exchangeRate: Yup.number().decimal().required(message),
  billingAddress: addressFieldSchema,
  shippingAddress: addressFieldSchema,
  taxCategory: Yup.string().required(message),
  orderDetails: Yup.array()
    .of(
      Yup.object().shape({
        position: Yup.number().number().required(message),
        materialCodeDesc: Yup.string().required(message),
        unit: Yup.string().required(message),
        orderedQuantity: Yup.number().number().required(message),
        requestedDate: Yup.date().required(message),
        price: Yup.number().decimal().required(message),
        discount: Yup.number().decimal(),
        charge: Yup.number().decimal(),
        taxType: Yup.string().required(message),
        tax: Yup.number()
          .decimal()
          .when('taxType', {
            is: (taxType) => taxType === 'Normal VAT',
            then: Yup.number().required(message)
          })
      })
    )
    .required()
})
