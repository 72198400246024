import { flatten, unflatten } from 'flat'
import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getOptionsByType } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

function WarehouseProductFilter({ values, setValues, filterData }) {
  const [materialTypes, setMaterialTypes] = useState([])
  const [materialGroups, setMaterialGroups] = useState([])
  const [divisions, setDivisions] = useState([])

  const getData = () => {
    getOptionsByType({
      type: ['MaterialType', 'MaterialGroup', 'Division']
    }).then(({ MaterialType = [], MaterialGroup = [], Division = [] }) => {
      setMaterialTypes(MaterialType)
      setMaterialGroups(MaterialGroup)
      setDivisions(Division)
    })
  }

  useEffect(() => {
    setValues({ basic: { ...values.basic, ...unflatten(filterData)?.basic } })
    getData()
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field name="basic.materialCode" label="Material No." />
      </div>
      <div className="form-fields">
        <Field name="basic.materialDescription" label="Material Desc." />
      </div>
      <div className="form-fields">
        <Field as="select" name="basic.materialType" label="Material Type" options={materialTypes} />
      </div>
      <div className="form-fields">
        <Field as="select" name="basic.materialGroup" label="Material Group" options={materialGroups} />
      </div>
      <div className="form-fields">
        <Field as="select" name="basic.division" label="Division" options={divisions} />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" /> Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    basic: {
      materialCode: '',
      materialDescription: '',
      materialType: '',
      materialGroup: '',
      division: ''
    }
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(flatten(values))
})(WarehouseProductFilter)
