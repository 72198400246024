import { Col, Row } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import { getOptionsByType } from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'

function CostInfo() {
  const [costingTypes, setCostingTypes] = useState([])

  const getData = () => {
    getOptionsByType({
      type: 'CostingType'
    }).then(({ CostingType = [] }) => {
      setCostingTypes(CostingType)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      <Row gutter={[20, 10]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Costing Type" name="costInfo.type" options={costingTypes} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Standard Cost" name="costInfo.cost" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Moving Avg. Cost" name="costInfo.movingCost" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default memo(CostInfo)
