import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import ProjectFilter from './ProjectFilter'

export default function Projects() {
  const [projects, setProjects] = useState([])
  const history = useHistory()

  const onFilter = (params = {}) => {
    apiClient.get('projects/getAll', { params }).then(({ data }) => {
      if (data.result) {
        SET_DATA('projects.filterData', { ...params, ...data.pageData })
        setProjects(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('projects.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('projects.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Client',
      dataIndex: 'clientName'
    },
    {
      title: 'Billable',
      dataIndex: 'billable'
    },
    {
      ...(validateAccess('edit-project') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/edit-project/${row.id}`)}
              className="btn glow dropdown-toggle">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<ProjectFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Project Overview</h2>
      </div>
      <TableBox
        dataSource={projects}
        columns={columns}
        pageData={GET_DATA('projects.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
