import React, { useEffect, useState } from 'react'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA } from '../../../Util/Util'
import StockCheckFilter from './StockCheckFilter'

export default function StockChecks() {
  const [stocks, setStocks] = useState([])

  const getStocks = (params) => {
    const filterCache = GET_DATA('stocks.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('stocks', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('stocks.filterData', { ...params, ...data.pageData })
        setStocks(data.result)
      }
    })
  }

  useEffect(() => {
    getStocks()
  }, [])

  const columns = [
    {
      title: 'Material Code',
      dataIndex: 'materialCode'
    },
    {
      title: 'Material Description',
      dataIndex: 'materialDescription'
    },
    {
      title: 'UOM',
      dataIndex: 'unit'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouse'
    },
    {
      title: 'Location',
      dataIndex: 'location'
    },
    {
      title: 'Rack',
      dataIndex: 'rack'
    },
    {
      title: 'Stock Value',
      dataIndex: 'value'
    }
  ]

  return (
    <FilterLayout
      filterData={GET_DATA('stocks.filterData')}
      filter={<StockCheckFilter onSubmit={getStocks} />}>
      <TableLayout title="Stocks Overview" exportUrl="stocks/export">
        <TableBox
          dataSource={stocks}
          columns={columns}
          pageData={GET_DATA('stocks.filterData')}
          onChangePage={getStocks}
        />
      </TableLayout>
    </FilterLayout>
  )
}
