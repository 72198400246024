import { Col, Row } from 'antd'
import React, { memo } from 'react'
import Field from '../../../Components/Formik/Field'
import { TYPE_OF_PAYMENT } from '../../../Util/Options'

function ReceiptList({ posting, i }) {
  return (
    <Row gutter={[10, 5]}>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <div className="form-field">
          <Field
            name={`payments[${i}].date`}
            label="Payment Date"
            as="date"
            disabled={posting === 'Booked'}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <div className="form-field">
          <Field
            name={`payments[${i}].amount`}
            label="Amount"
            type="number"
            disabled={posting === 'Booked'}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <div className="form-field">
          <Field
            name={`payments[${i}].typeOfPayment`}
            label="Type"
            as="select"
            options={TYPE_OF_PAYMENT}
            disabled={posting === 'Booked'}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <div className="form-field">
          <Field name={`payments[${i}].posting`} label="Posting" disabled />
        </div>
      </Col>
    </Row>
  )
}

export default memo(ReceiptList)
