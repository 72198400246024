import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import { useSelector } from '../../../Hooks/redux'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { getDefaultStockTransfer } from '../../../Util/Options'
import { stockTransferSchema } from '../../../Util/validationSchema'
import TransferForm from './TransferForm'

function StockTransferForm({
  values,
  setValues,
  match: {
    params: { id }
  }
}) {
  const { configurations: { warehouseLocations, warehouseRacks } = {} } = useSelector(
    (state) => state.users.companyInfo
  )

  const getData = () => {
    if (id) {
      apiClient.get(`stock-transfers/${id}/transactions`).then(({ status, data }) => {
        if (status === 200) {
          data.transactions = data.transactions.map((item) => ({
            ...item,
            materialCodeDesc: _.compact([item.materialCode, item.materialDescription]).join(' - ')
          }))
          setValues({ ...values, ...data, transactionDate: moment(data.createdAt) })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 20 }}>
          <PanelLayout title="Stock Transfer">
            <Panel title="Issue Header">
              <Row gutter={[20, 10]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field as="date" name="transactionDate" label="Transaction Date" disabled />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field as="textarea" name="remarks" label="Remarks" disabled={id} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Transfer materials">
              <FieldArray
                name="transactions"
                loaderCount={8}
                defaultValues={getDefaultStockTransfer({ warehouseLocations, warehouseRacks })}>
                {TransferForm}
              </FieldArray>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            {!id && (
              <>
                <Button type="submit" variant="primary">
                  Save
                </Button>
                <span>or</span>
              </>
            )}
            <Link to="/app/stock-transfers">
              <Button>
                <ArrowLeftOutlined /> Back to stock transfer list
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({ companyInfo: { configurations: { warehouseLocations, warehouseRacks } = {} } }) => ({
    transactionDate: moment().utc().startOf('day'),
    remarks: '',
    transactions: [getDefaultStockTransfer({ warehouseLocations, warehouseRacks })]
  }),
  validationSchema: stockTransferSchema,
  handleSubmit: (data, { props: { history } }) => {
    apiClient.post('stock-transfers', data).then(({ status }) => {
      if (status === 201) {
        history.push('/app/stock-transfers')
      }
    })
  }
})(StockTransferForm)
