import { Col, message, Row, Space } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getOptionsByType } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import TableBox from '../../../Components/TableBox/TableBox'
import { useSelector } from '../../../Hooks/redux'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { DEFAULT_ADDRESS_FIELDS, DEFAULT_FORWARDER_CHARGES } from '../../../Util/Options'
import { convertSelectOptions, parseAmount } from '../../../Util/Util'
import { customsClearanceSchema } from '../../../Util/validationSchema'
import ClientCharges from './ClientCharges'

function CustomsClearanceForm({
  history,
  submitForm,
  values,
  setFieldValue,
  setValues,
  match: {
    params: { id }
  }
}) {
  const [clients, setClients] = useState([])
  const [templates, setTemplates] = useState([])
  const [charges, setCharges] = useState({
    Customs: [],
    SupplierCharges: []
  })

  const companyInfo = useSelector((state) => state.users.companyInfo)

  const disabled = id && values.status !== 'Created'

  const getData = () => {
    getOptionsByType({
      type: ['Customs', 'SupplierCharges']
    }).then((charges) => {
      setCharges(charges)
    })

    apiClient
      .get('customTemplates/getActive', { params: { type: 'Order', for: 'Custom Clearance' } })
      .then(({ data }) => {
        if (data && data.result) {
          const templates = [
            { label: 'Default', value: 'Default' },
            ...convertSelectOptions(data.result, 'name', 'id')
          ]
          setTemplates(templates)
        }
      })

    apiClient.get('clients/getNames', { params: { type: 'Vendor' } }).then(({ status, data }) => {
      if (status === 200) {
        setClients(
          data.map((item) => ({
            ...item,
            label: item.name,
            value: item._id,
            contactPersons: item.contactPersons.map((item) => ({
              value: item.id,
              label: _.compact([item.contactName, item.contactEmail, item.contactPhone]).join(', '),
              ...item
            }))
          }))
        )
      }
    })

    if (id) {
      apiClient.get(`customs-clearances/${id}`).then(({ status, data }) => {
        if (status === 200) {
          data.template = data.template ? data.template : 'Default'
          data.deliveries = data.deliveries.map((delivery) => ({
            ...delivery,
            id: delivery._id
          }))
          setValues({ ...values, ...data })
        }
      })
    }
  }

  const totalCustomCharges = values.forwarder.totalCharges + values.supplier.totalCharges

  const updateDeliveries = () => {
    setFieldValue(
      'deliveries',
      values.deliveries.map((item) => {
        const basePrice = item.price * item.exchangeRate
        const baseDeliveryValue = item.deliveryValue * item.exchangeRate
        const cdAmount = totalCustomCharges * item.clearanceRate
        const importAmount = baseDeliveryValue + cdAmount
        const customPrice = importAmount / item.deliveryQuantity
        const tranCustomCharge = cdAmount / item.exchangeRate

        return {
          ...item,
          basePrice,
          baseDeliveryValue,
          cdAmount,
          importAmount,
          customPrice,
          tranCustomCharge
        }
      })
    )
  }

  useDidUpdateEffect(() => {
    updateDeliveries()
  }, [totalCustomCharges])

  useEffect(() => {
    getData()
  }, [])

  const handlePosting = (client) => {
    apiClient.post(`customs-clearances/${id}/${client}`).then(({ status, data }) => {
      if (status === 200) {
        message.success(
          `${client.capitalize?.()} Invoice ${data.invoiceNo} successfully generated and posted`
        )
        setFieldValue(`${client}InvoiceSent`, true)
      }
    })
  }

  const baseCurrency = parseFloat(_.sumBy(values.deliveries, 'baseDeliveryValue'))
  const transCurrency = parseFloat(_.sumBy(values.deliveries, 'deliveryValue'))
  const tranCustomCharge = parseFloat(_.sumBy(values.deliveries, 'tranCustomCharge'))

  const columns = [
    {
      className: 'bg-white',
      children: [
        { title: 'GR No', dataIndex: 'deliveryNo' },
        { title: 'PO No', dataIndex: 'orderNo' },
        { title: 'Material', dataIndex: 'materialCode' },
        { title: 'GR Qty', dataIndex: 'deliveryQuantity', align: 'right' }
      ]
    },
    {
      title: `Transaction Currency (${values.currency})`,
      align: 'left',
      className: 'bg-white',
      children: [
        {
          title: 'PO Price',
          dataIndex: 'price',
          className: 'customs-border',
          align: 'right',
          render: (text) => parseAmount(text, values.currency)
        },
        {
          title: 'GR Amount',
          dataIndex: 'deliveryValue',
          align: 'right',
          render: (text) => parseAmount(text, values.currency)
        },
        {
          title: 'Exchange rate',
          dataIndex: 'exchangeRate',
          align: 'right',
          render: (text) => parseAmount(text, values.currency)
        }
      ]
    },
    {
      title: `Base Currency (${companyInfo.currency})`,
      align: 'left',
      className: 'bg-white',
      children: [
        {
          title: 'PO Price',
          dataIndex: 'basePrice',
          className: 'customs-border',
          align: 'right',
          render: (text) => parseAmount(text, companyInfo.currency)
        },
        {
          title: 'GR Amount',
          dataIndex: 'baseDeliveryValue',
          align: 'right',
          render: (text) => parseAmount(text, companyInfo.currency)
        },
        {
          title: 'Clearance rate',
          dataIndex: 'clearanceRate',
          align: 'right',
          render: (text) => parseAmount(text, companyInfo.currency)
        },
        {
          title: 'CD Amount',
          dataIndex: 'cdAmount',
          align: 'right',
          render: (text) => parseAmount(text, companyInfo.currency)
        },
        {
          title: 'Import Amount',
          dataIndex: 'importAmount',
          align: 'right',
          render: (text) => parseAmount(text, companyInfo.currency)
        },
        {
          title: 'Unit Price',
          dataIndex: 'customPrice',
          align: 'right',
          render: (text) => parseAmount(text, companyInfo.currency)
        }
      ]
    }
  ]

  return (
    <Form className="custom-clearance">
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={22}>
          <PanelLayout title="Customs Clearance Definition">
            <Space size="large" direction="vertical" className="w-100">
              <Row gutter={[20, 10]}>
                {id && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field name="clearanceNo" label="Clearance No" disabled />
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="clearanceDate" label="Clearance Date" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="status" label="Status" disabled />
                  </div>
                </Col>
              </Row>
              <ClientCharges
                name="forwarder"
                disabled={disabled}
                charges={charges.Customs}
                clients={clients.filter((item) => item.forwarder)}
                {...{ id, values, setFieldValue, companyInfo }}
              />
              <ClientCharges
                name="supplier"
                disabled={disabled}
                charges={charges.SupplierCharges}
                clients={clients}
                {...{ id, values, setFieldValue, companyInfo }}
              />
              <Row gutter={[20, 20]} className="text-right">
                <Col xs={18}>
                  <h3>Total Custom Clearance Charges:</h3>
                </Col>
                <Col xs={6}>
                  <p>
                    {parseAmount(totalCustomCharges, companyInfo.currency)} {companyInfo.currency}
                  </p>
                </Col>
              </Row>
              <Panel title="Goods Receipts with Custom" bodyClassName="p-0" noBottom>
                <TableBox columns={columns} dataSource={values.deliveries} />
              </Panel>
              <Row>
                <Col xs={24} sm={12} />
                <Col xs={24} lg={12} className="text-right">
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Sub Total:</h3>
                    </Col>
                    <Col xs={6}>
                      <p>
                        {parseAmount(transCurrency, values.currency)} {values.currency}
                      </p>
                    </Col>
                    <Col xs={6}>
                      <p>
                        {parseAmount(baseCurrency, companyInfo.currency)} {companyInfo.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Total Custom Charges:</h3>
                    </Col>
                    <Col xs={6}>
                      <p>
                        {parseAmount(tranCustomCharge, values.currency)} {values.currency}
                      </p>
                    </Col>
                    <Col xs={6}>
                      <p>
                        {parseAmount(totalCustomCharges, companyInfo.currency)} {companyInfo.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Total Amount:</h3>
                    </Col>
                    <Col xs={6}>
                      <p>
                        {parseAmount(transCurrency + tranCustomCharge, values.currency)} {values.currency}
                      </p>
                    </Col>
                    <Col xs={6}>
                      <p>
                        {parseAmount(baseCurrency + totalCustomCharges, companyInfo.currency)}{' '}
                        {companyInfo.currency}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div>
                <h2>Template</h2>
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={12} md={8}>
                    <div className="form-field">
                      <Field as="select" name="template" label="Template" options={templates} />
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={16}>
                    <div className="form-field">
                      <Field as="textarea" rows={1} name="notes" label="Notes" />
                    </div>
                  </Col>
                </Row>
              </div>
              <Row gutter={[50]} justify="end">
                <Col xs={24} sm={12} md={4}>
                  <Button
                    className="btn-block"
                    variant="primary"
                    onClick={() => handlePosting('forwarder')}
                    disabled={values.status === 'Created' || values.forwarderInvoiceSent}
                    success={values.forwarderInvoiceSent}>
                    Post Forwarder Invoice
                  </Button>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <Button
                    className="btn-block"
                    variant="primary"
                    onClick={() => handlePosting('supplier')}
                    disabled={values.status === 'Created' || values.supplierInvoiceSent}
                    success={values.supplierInvoiceSent}>
                    Post Supplier Invoice
                  </Button>
                </Col>
              </Row>
            </Space>
          </PanelLayout>
          <FooterActions
            leftActions={[
              {
                prefix: 'flaticon-back',
                label: 'Back',
                onClick: () => (id ? history.push('/app/customs-clearances') : history.goBack())
              }
            ]}
            centerActions={[
              {
                prefix: 'flaticon-writing',
                label: id ? 'Update' : 'Save',
                onClick: () => {
                  setFieldValue('tempStatus', values.status === 'Approved' ? 'Updated' : 'Created')
                  submitForm()
                }
              }
            ]}
            rightActions={[
              {
                prefix: 'flaticon-security',
                label: `${id ? 'Update' : 'Save'} ${'& Approve'}`,
                onClick: () => {
                  setFieldValue('tempStatus', 'Approved')
                  submitForm()
                },
                dontShow: !(values.status === '' || values.status === 'Created')
              }
            ]}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({
    history,
    location: { state: { currency = '', deliveries = [] } = '' },
    match: {
      params: { id }
    }
  }) => {
    if (!id && deliveries.length === 0) {
      history.goBack()
    }

    return {
      clearanceDate: moment(),
      currency,
      forwarder: {
        client: '',
        billingAddress: DEFAULT_ADDRESS_FIELDS,
        shippingAddress: DEFAULT_ADDRESS_FIELDS,
        contactPerson: '',
        forwarderCharges: [DEFAULT_FORWARDER_CHARGES],
        totalCharges: 0
      },
      supplier: {
        client: deliveries[0]?.client,
        clientAlt: deliveries[0]?.clientAlt,
        billingAddress: deliveries[0]?.billingAddress,
        shippingAddress: deliveries[0]?.shippingAddress,
        contactPerson: deliveries[0]?.contactPerson,
        forwarderCharges: [DEFAULT_FORWARDER_CHARGES],
        totalCharges: 0
      },
      deliveries,
      template: 'Default',
      notes: '',
      status: 'Created'
    }
  },
  validationSchema: customsClearanceSchema,
  handleSubmit: (
    { tempStatus, ...data },
    {
      props: {
        match: {
          params: { id }
        },
        history
      },
      setFieldValue
    }
  ) => {
    data.template = data.template === 'Default' ? null : data.template
    data.clearanceDate = moment(data.clearanceDate).utc().startOf('day')
    data.status = tempStatus

    if (id) {
      apiClient.put(`customs-clearances/${id}`, data).then(({ status }) => {
        if (status === 200) {
          setFieldValue('status', data.status)
          history.push(`/app/edit-customs-clearance/${id}`)
        }
      })
    } else {
      apiClient.post('customs-clearances', data).then(({ status, data }) => {
        if (status === 201) {
          setFieldValue('clearanceNo', data.clearanceNo)
          setFieldValue('status', data.status)
          history.push(`/app/edit-customs-clearance/${data.id}`)
        }
      })
    }
  }
})(CustomsClearanceForm)
