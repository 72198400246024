import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoaderBox from '../../../../Components/LoaderBox/LoaderBox'
import ModalBox from '../../../../Components/ModalBox/ModalBox'
import TableBox from '../../../../Components/TableBox/TableBox'
import FilterLayout from '../../../../Layout/FilterLayout'
import TableLayout from '../../../../Layout/TableLayout'
import apiClient from '../../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../../Util/Util'
import Logs from '../../../Logs/Logs'
import JobCreationFilter from './JobCreationFilter'

const { TabPane } = Tabs

export default function JobCreations({ history }) {
  const [tabKey, setTabKey] = useState(GET_DATA('jobCreations.tabKey') || 'Created')
  const [jobCreations, setJobCreations] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [loader, setLoader] = useState(false)
  const [viewLogs, setViewLogs] = useState(false)

  const getData = (params = {}) => {
    setSelectedRows([])

    const filterCache = GET_DATA(`jobCreations${tabKey}.filterData`) || {}
    params = { ...filterCache, ...params }

    if (tabKey !== 'All') {
      params.status = tabKey
    } else {
      params.status = undefined
    }

    if (params.creationDate) {
      params.creationDate = moment(params.creationDate).utc().startOf('day').toISOString()
    }

    apiClient.get('job-creations', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA(`jobCreations${tabKey}.filterData`, { ...params, ...data.pageData })
        setJobCreations(data.result)
        setLoader(data.result.length > 0 ? false : 'No invoice found')
      }
    })
  }

  useEffect(() => {
    getData()
    SET_DATA('jobCreations.tabKey', tabKey)
  }, [tabKey])

  const onDelete = (id) => {
    apiClient.delete(`job-creations/${id}`).then(({ status }) => {
      if (status === 204) {
        setJobCreations(jobCreations.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-job-creation') && (
          <li>
            <Link to={`/app/edit-job-creation/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-job-creation') && (row.status === 'Created' || row.status === 'Rejected') && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-1" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => setViewLogs(row)}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Job Creation No',
      dataIndex: 'jobCreationNo',
      render: (text, row) => <Link to={`/app/job-creations/${row.id}`}>{text}</Link>
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Booking No',
      dataIndex: 'bookingNo'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Job Creations',
        onClick: () => history.push('/app/search-job-creation'),
        access: 'add-job-creation'
      }}
      filterData={GET_DATA(`jobCreations${tabKey}.filterData`)}
      filter={<JobCreationFilter onSubmit={getData} />}>
      <ModalBox
        title="Job Creation"
        visible={!!viewLogs}
        onCancel={() => setViewLogs(false)}
        footer={false}
        destroyOnClose>
        <Logs entityId={viewLogs?.id} entityType="Job Creation" />
      </ModalBox>
      <TableLayout title="Job Creations / All documents" exportUrl="job-creations/export" detailed>
        <Tabs activeKey={tabKey} onChange={setTabKey}>
          <TabPane tab="Created" key="Created" />
          <TabPane tab="Posted" key="Posted" />
          <TabPane tab="All" key="All" />
        </Tabs>
        {!loader && (
          <TableBox
            className="px-0"
            dataSource={jobCreations}
            columns={columns}
            onSelect={setSelectedRows}
            selectedRows={selectedRows}
            pageData={GET_DATA(`jobCreations${tabKey}.filterData`)}
            onChangePage={getData}
          />
        )}
        <LoaderBox loader={loader} />
      </TableLayout>
    </FilterLayout>
  )
}
