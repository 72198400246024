import { SettingOutlined } from '@ant-design/icons'
import { message, Popconfirm, Popover, Space, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Button from '../../../Components/Button'
import LoaderBox from '../../../Components/LoaderBox/LoaderBox'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { getOrderNames } from '../../../Util/Options'
import { GET_DATA, parseAmount, SET_DATA, validateAccess } from '../../../Util/Util'
import IncomeEmail from '../../InvoiceData/Incomes/IncomeEmail'
import Logs from '../../Logs/Logs'
import InvoiceListFilter from './InvoiceListFilter'

const { TabPane } = Tabs

export default function InvoiceList({ history }) {
  const { type } = useParams()

  const [tabKey, setTabKey] = useState(GET_DATA(`${type}Invoices.tabKey`) || 'Created')
  const [invoiceList, setInvoiceList] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [loader, setLoader] = useState(false)
  const { typeName, clientName } = getOrderNames(type)
  const [toggle, setToggle] = useState(false)
  const [toggleType, setToggleType] = useState(false)

  const getData = (params = {}) => {
    setSelectedRows([])

    const filterCache = GET_DATA(`${type}Invoices${tabKey}.filterData`) || {}
    params = { ...filterCache, ...params }

    if (tabKey !== 'All' && tabKey !== 'OverDue') {
      params.workflowStatus = tabKey
    }

    if (tabKey === 'OverDue') {
      params.overDue = moment().utc().endOf('day').toISOString()
    }

    ;['issueDate', 'taxDate', 'paymentDate'].forEach((item) => {
      if (params[item]) {
        params[item].forEach((item2, i) => {
          if (i === 0) {
            params[item][i] = moment(params[item][i]).utc().startOf('day').toISOString()
          } else {
            params[item][i] = moment(params[item][i]).utc().endOf('day').toISOString()
          }
        })
      }
    })
    params.type = type

    apiClient.get('order-invoices', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA(`${type}Invoices${tabKey}.filterData`, { ...params, ...data.pageData })
        setInvoiceList(data.result)
        setLoader(data.result.length > 0 ? false : 'No invoice found')
      }
    })
  }

  useEffect(() => {
    getData()
    SET_DATA(`${type}Invoices.tabKey`, tabKey)
  }, [type, tabKey])

  const delayedDays = (days) => <div>Payment Delayed days - {days}</div>

  const getLinkRecord = (text, record) => {
    if (record.status !== 'Paid' && record.paymentDueDate) {
      const days = moment().diff(moment(record.paymentDueDate), 'days')

      if (days > 0) {
        return (
          <Link to={`/app/order-invoices/view/${record.id}`}>
            <Popover placement="bottom" content={() => delayedDays(days)} trigger="hover">
              <a style={{ color: 'red' }}>{text}</a>
            </Popover>
          </Link>
        )
      }

      return <Link to={`/app/order-invoices/view/${record.id}`}>{text}</Link>
    }

    return <Link to={`/app/order-invoices/view/${record.id}`}>{text}</Link>
  }

  const showModal = (data, type) => {
    setToggle(data)
    setToggleType(type)
  }

  const handleCancel = () => {
    setToggle(false)
    setToggleType(false)
  }

  const onDelete = (id) => {
    apiClient.delete(`order-invoices/${id}`).then(({ status }) => {
      if (status === 204) {
        setInvoiceList(invoiceList.filter((item) => item.id !== id))
      }
    })
  }

  const onSubmit = (invoiceId) => {
    apiClient.put('order-invoices/submit', { invoiceId }).then(({ status }) => {
      if (status === 200) {
        message.success('Invoice Submitted')
        setInvoiceList(invoiceList.filter((item) => item.id !== invoiceId))
      }
    })
  }

  const onSendEmails = (emailInfo) => {
    let flag = true
    const selected = toggle.id ? [toggle] : selectedRows
    const invoicesIds = selected.map((val) => {
      if (val.status === 'Paid') {
        flag = false
      }

      return val.id
    })

    if (flag) {
      apiClient
        .post('order-invoices/send-emails', { invoicesIds, ...emailInfo, type: toggleType })
        .then(({ status }) => {
          if (status === 200) {
            message.success('Invoices Sent')
            const invoices = invoiceList.map((inv) => {
              if (invoicesIds.indexOf(inv.id) >= 0) {
                inv.status = 'Sent'
              }

              return inv
            })
            setInvoiceList(invoices)
            setSelectedRows([])
            handleCancel()
          }
        })
    } else {
      message.error('Cannot send to paid invoices')
    }
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/order-invoices/view/${row.id}`}>
            <i className="flaticon-eye" /> View
          </Link>
        </li>
        {validateAccess(`edit-${type}-invoice`) && (
          <li>
            <Link to={`/app/edit-invoice/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess(`edit-${type}-invoice`) &&
          (row.workflowStatus === 'Created' || row.workflowStatus === 'Rejected') && (
            <li>
              <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
                <Link to="#">
                  <i className="flaticon-delete-1" /> Delete
                </Link>
              </Popconfirm>
            </li>
          )}
        {validateAccess(`edit-${type}-invoice`) && row.workflowStatus === 'Created' && (
          <li>
            <Link to="#" onClick={() => onSubmit(row.id)}>
              <i className="flaticon-tick-1" /> Submit
            </Link>
          </li>
        )}
        {row.workflowStatus === 'Approved' && row.status !== 'Paid' && (
          <li>
            <Link to="#" onClick={() => showModal(row, 'Email')}>
              <i className="flaticon-email-1" /> Send Email
            </Link>
          </li>
        )}
        {row.workflowStatus === 'Approved' && row.status !== 'Paid' && (
          <li>
            <Link to="#" onClick={() => showModal(row, 'ReminderEmail')}>
              <i className="flaticon-email-1" /> Reminder Email
            </Link>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => showModal(row, 'Logs')}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Doc No',
      dataIndex: 'invoiceNo',
      render: (text, record) => getLinkRecord(text, record)
    },
    {
      title: 'Invoice/Issue Date',
      dataIndex: 'issueDate',
      render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
    },
    {
      title: clientName,
      dataIndex: 'clientName'
    },
    {
      title: 'PO / Reference No',
      dataIndex: 'poNo'
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
    },
    {
      title: 'Gross Amount',
      dataIndex: 'grossAmount',
      render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
    },
    {
      title: 'Due Date',
      dataIndex: 'paymentDueDate',
      render: (v) => (v ? moment(v).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Balance Amount',
      dataIndex: 'grossAmount',
      render: (v, r) => `${parseAmount((r.grossAmount || 0) - (r.paidAmount || 0), r.currency)} ${r.currency}`
    },
    {
      title: 'Approval Status',
      dataIndex: 'workflowStatus'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <Space size="middle">
          <a
            className={`status badge ${text.toLowerCase()} ant-dropdown-link status`}
            onClick={(e) => e.preventDefault()}>
            {text === 'Inprogress' ? 'In Progress' : text}
          </a>
        </Space>
      )
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: `${type?.capitalize()} invoice`,
        onClick: () => history.push(`/app/search-invoices/${type}`),
        access: `add-${type}-invoice`
      }}
      filterData={GET_DATA(`${type}Invoices${tabKey}.filterData`)}
      filter={<InvoiceListFilter key={type} onSubmit={getData} />}>
      <ModalBox title={toggleType} visible={!!toggle} onCancel={handleCancel} footer={false} destroyOnClose>
        {toggleType === 'Logs' && <Logs entityId={toggle?.id} entityType={`${typeName} Invoice`} />}
        {(toggleType === 'Email' || toggleType === 'Reminder') && (
          <IncomeEmail onSend={onSendEmails} onCancel={handleCancel} />
        )}
      </ModalBox>
      <TableLayout
        title={`${type?.capitalize()} Invoice / All documents`}
        exportUrl="order-invoices/export"
        detailed
        rightSection={
          selectedRows.length > 0 && (
            <>
              {tabKey === 'Approved' && (
                <Button variant="primary" onClick={() => showModal({}, 'Email')}>
                  Send Email
                </Button>
              )}
              {tabKey === 'Approved' && (
                <Button onClick={() => showModal({}, 'Reminder')}>Send Reminder</Button>
              )}
            </>
          )
        }>
        <Tabs activeKey={tabKey} onChange={setTabKey}>
          <TabPane tab="Created" key="Created" />
          <TabPane tab="Submitted" key="Submitted" />
          <TabPane tab="Approved" key="Approved" />
          <TabPane tab="Rejected" key="Rejected" />
          <TabPane tab="OverDue" key="OverDue" />
          <TabPane tab="All" key="All" />
        </Tabs>
        {!loader && (
          <TableBox
            className="px-0"
            dataSource={invoiceList}
            columns={columns}
            onSelect={setSelectedRows}
            selectedRows={selectedRows}
            pageData={GET_DATA(`${type}Invoices${tabKey}.filterData`)}
            onChangePage={getData}
          />
        )}
        <LoaderBox loader={loader} />
      </TableLayout>
    </FilterLayout>
  )
}
