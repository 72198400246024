import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import eInvoicing from '../../../assets/images/pricings/e-invoicing.svg'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import './ReviewOrder.scss'

const ReviewOrder = () => (
  <Form>
    <Header />

    {/* Breadcrum starts */}
    <section className="breadcrum-title text-center">
      <div className="container-fluid">
        <Row justify="center">
          <Col span={21}>
            <Row justify="left">
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="breadcrum-content">
                  <h1>Review Order</h1>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
    {/* breadcrum ends here */}

    <section className="inner-pages order-overview">
      <div className="container-fluid">
        <Row justify="center">
          <Col span={23}>
            <Row justify="center" gutter={[20]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }} lg={{ span: 13 }}>
                <p>
                  Returning Customer? <Link to="/">Click here to login</Link>
                </p>
                <div className="billing-details mb-3 pb-3">
                  <h1>Billing Details</h1>
                  <div className="form-fields">
                    <Field name="fullName" label="Full Name" type="text" />
                  </div>
                  <Row justify="center" gutter={[20]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <div className="form-fields">
                        <Field name="emailAddress" label="Email Address" type="email" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                      <div className="form-fields">
                        <Field name="phoneNumber" label="Phone Number" type="number" />
                      </div>
                    </Col>
                  </Row>
                  <div className="form-fields">
                    <Field name="companyName" label="Company Name" type="text" />
                  </div>
                  <div className="form-fields">
                    <Field as="select" name="country" label="Country / Region" allowClear />
                  </div>
                  <div className="form-fields">
                    <Field
                      name="addressLine1"
                      label="Street Address"
                      placeholder="House number and street name"
                      type="text"
                    />
                  </div>
                  <div className="form-fields">
                    <Field
                      name="addressLine2"
                      placeholder="Apartment, suite, unit, etc. (optional)"
                      type="text"
                    />
                  </div>
                  <Row justify="center" gutter={[20]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-fields">
                        <Field name="city" label="Town / City" type="text" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-fields">
                        <Field name="state" label="State" type="text" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-fields">
                        <Field name="pincode" label="Pincode" type="text" />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="create-account mb-3 pb-3">
                  <h2>Create an account?</h2>
                  <div className="form-fields">
                    <Field name="userName" label="User Name" type="text" />
                  </div>
                  <div className="form-fields">
                    <Field name="password" label="Password" placeholder="Password" type="password" />
                  </div>
                  <div className="form-fields">
                    <Field name="password" placeholder="Re-enter Password" type="password" />
                  </div>
                </div>
                <div className="action-buttons">
                  <ButtonBox type="default" variable="standard">
                    <i className="flaticon-back" /> Go Back
                  </ButtonBox>
                  <ButtonBox type="primary" variable="standard">
                    Proceed to Next <i className="ml-1 flaticon-arrow-pointing-to-right" />
                  </ButtonBox>
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <h1>Your Order</h1>
                <table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="cart_item">
                      <td>
                        <div className="product-details">
                          <img src={eInvoicing} alt="E-invoicing via Blockchai" />
                          <span>E-invoicing</span>
                        </div>
                      </td>
                      <td>2</td>
                      <td>
                        <bdi>
                          <span>₹</span>24,999.00
                        </bdi>
                      </td>
                    </tr>
                    <tr className="cart_item">
                      <td>
                        <div className="product-details">
                          <img src={eInvoicing} alt="E-invoicing via Blockchai" />
                          <span>E-invoicing</span>
                        </div>
                      </td>
                      <td>2</td>
                      <td>
                        <bdi>
                          <span>₹</span>24,999.00
                        </bdi>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="cart-subtotal">
                      <th colSpan="2">Subtotal</th>
                      <td>
                        <bdi>
                          <span>₹</span>24,999.00
                        </bdi>
                      </td>
                    </tr>

                    <tr className="order-total">
                      <th colSpan="2">Total</th>
                      <td>
                        <strong>
                          <span>
                            <bdi>
                              <span>₹</span>24,999.00
                            </bdi>
                          </span>
                        </strong>{' '}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>

    <Footer />
  </Form>
)
export default withFormik({
  mapPropsToValues: () => ({
    fullName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    pincode: '',
    country: '',

    countryOptions: []
  }),
  handleSubmit: () => null
  // validationSchema: Schema
})(ReviewOrder)
