import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { STATUS } from '../../../Util/Options'

const Schema = Yup.object().shape({
  accountCode: Yup.number().required(),
  name: Yup.string().required(),
  accountGroup: Yup.string().required(),
  status: Yup.string().required()
})

function AccountChartForm({
  setValues,
  match: {
    params: { id }
  },
  history,
  setFieldValue
}) {
  const getData = () => {
    if (id) {
      apiClient.get(`account-charts/get/${id}`).then(({ data }) => {
        if (data && data.result) {
          setValues(data.result)
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getAccountGroup = (account) => {
    apiClient.get(`account-groups/range/${account}`).then(({ data }) => {
      if (data && data.result) {
        setFieldValue('accountGroup', data.result.range)
      }
    })
  }

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="Account Chart">
            <Panel title="Account Chart">
              <Row gutter={[10, 10]}>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      label="Account Code"
                      name="accountCode"
                      onChange={(n, v) => {
                        getAccountGroup(v)

                        return setFieldValue(n, v)
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field label="Account Name" name="name" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field label="Account Group" name="accountGroup" disabled />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Row gutter={[10, 10]}>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="form-field">
                  <Field label="Status" name="status" as="select" options={STATUS} />
                </div>
              </Col>
            </Row>
          </PanelLayout>
          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>

            <Link to="#" onClick={() => history.goBack()}>
              <Button>
                <ArrowLeftOutlined /> Back
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    accountCode: '',
    name: '',
    accountGroup: '',
    status: ''
  }),
  validationSchema: Schema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`account-charts/update/${id}`, { ...data }).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/account-charts')
        }
      })
    } else {
      apiClient.post('account-charts/add', data).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/account-charts')
        }
      })
    }
  }
})(AccountChartForm)
