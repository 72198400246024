import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Input, InputNumber, message, Row, Select, Table } from 'antd'
import _differenceWith from 'lodash/differenceWith'
import _includes from 'lodash/includes'
import _isEqual from 'lodash/isEqual'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { addAndUpdatePaymaster, getPaymasterFields } from '../../../Actions/UserAction'
import { STATUS } from '../../../Util/Options'

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  fieldType,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)

  useEffect(() => {
    if (inputRef.current && editing) {
      inputRef.current.focus()

      if (fieldType === 'input' || fieldType === 'number') {
        inputRef.current.select()
      }
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }

  const toggleEditEmpty = () => {
    if (!editing && dataIndex) {
      setEditing(!editing)
      form.setFieldsValue({
        [dataIndex]: record[dataIndex]
      })
    }
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode =
      editing && fieldType === 'input' && dataIndex === 'label' ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : editing && fieldType === 'input' && dataIndex === 'key' && record.isAdded ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            },
            {
              pattern: new RegExp(/^[A-Za-z]+$/),
              message: 'whitespace and special characters are not allowed'
            }
          ]}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : editing && dataIndex && fieldType === 'number' ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}>
          <InputNumber ref={inputRef} onPressEnter={save} type="number" onBlur={save} />
        </Form.Item>
      ) : editing && dataIndex && fieldType === 'select' ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}>
          <Select options={STATUS} ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}>
          {children}
        </div>
      )
  }

  return (
    <td onClick={toggleEditEmpty} {...restProps}>
      {childNode}
    </td>
  )
}

class PaymasterFields extends React.Component {
  constructor(props) {
    super(props)
    this.columns = [
      {
        title: 'Label',
        dataIndex: 'label',
        width: '30%',
        editable: true,
        fieldType: 'input'
      },
      {
        title: 'Key',
        dataIndex: 'key',
        editable: true,
        fieldType: 'input'
      },
      {
        title: 'Length',
        dataIndex: 'length',
        editable: true,
        fieldType: 'number'
      },
      {
        title: 'Required',
        dataIndex: 'required',
        fieldType: 'checkBox',
        render: (text, row) => (
          <Checkbox
            checked={row.required}
            onChange={() => this.handleSave({ ...row, required: !row.required })}
          />
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        editable: true,
        fieldType: 'select'
      }
    ]
    this.state = {
      dataSource: [],
      orginalDs: [],
      count: 0,
      isSave: Date.now()
    }
  }

  componentDidMount() {
    this.getList()
  }

  getList = () => {
    getPaymasterFields().then((response) => {
      if (response) {
        this.setState({ dataSource: response, orginalDs: response, count: response.length })
      } else {
        this.setState({ dataSource: [], count: 0, orginalDs: [] })
      }
    })
  }

  handleAdd = () => {
    const { count, dataSource } = this.state
    const newData = {
      label: '',
      key: '',
      length: 0,
      required: false,
      status: 'InActive',
      id: count + 1,
      isAdded: true
    }
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1
    })
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.id === item.id)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData
    })
  }

  onSave = () => {
    this.setState({ isSave: Date.now() })
    const editedFields = _differenceWith(this.state.dataSource, this.state.orginalDs, _isEqual)

    console.log('editedFields', editedFields)
    const validateFieldValue = editedFields.filter((field) => field.label === '' || field.key === '')

    if (validateFieldValue.length === 0) {
      const duplicateValidation = this.state.dataSource
        .map((md) => md.key)
        .filter((val, i, iteratee) => _includes(iteratee, val, i + 1))

      if (duplicateValidation.length) {
        message.error('Please change key should be unique ')
      } else {
        addAndUpdatePaymaster(editedFields).then(() => {
          message.success('Added and update success')
          this.getList()
        })
      }
    } else {
      message.error('Fill all the fields')
    }
  }

  render() {
    const { dataSource } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell
      }
    }
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          fieldType: col.fieldType,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          isSave: this.state.isSave,
          handleSave: this.handleSave
        })
      }
    })

    return (
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 20 }}>
          <div className="panel-layout">
            <h2 className="panel-title">Employee Paymaster Fields</h2>
            <Button
              onClick={() => this.handleAdd()}
              type="primary"
              style={{
                marginBottom: 16
              }}>
              <i className="flaticon-plus" />
              Add New Field
            </Button>
            <div className="custom-background">
              {/* {viewType === 'table' && */}
              <div className="custom-table">
                <Table
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  tableLayout="fixed"
                />
              </div>
            </div>
          </div>
          <div className="save-changes">
            <Button onClick={this.onSave} type="primary">
              Save
            </Button>
            <span>or</span>
            <Link to="/app/paymaster">
              <Button type="standard">
                <ArrowLeftOutlined /> Back to paymaster
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    )
  }
}

export default PaymasterFields
