import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { getOrderNames } from '../../../Util/Options'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import Logs from '../../Logs/Logs'
import DeliveryListFilter from './DeliveryListFilter'

export default function DeliveryList({ history }) {
  const [deliveries, setDeliveries] = useState([])
  const [viewLogs, setViewLogs] = useState(false)
  const { type } = useParams()
  const { getStatus, isPurchase, typeName, deliveryName, clientName, orderAddress, deliver } =
    getOrderNames(type)

  const getData = (params = {}) => {
    const filterCache = GET_DATA(`${type}Deliveries.filterData`) || {}
    params = { ...filterCache, ...params }

    if (params.deliveryDate) {
      params.deliveryDate = moment(params.deliveryDate).utc().startOf('day').toISOString()
    }

    params.type = type

    apiClient.get('order-deliveries', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA(`${type}Deliveries.filterData`, { ...params, ...data.pageData })
        setDeliveries(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [type])

  const onDelete = (id) => {
    apiClient.delete(`order-deliveries/${id}`).then(({ status }) => {
      if (status === 204) {
        setDeliveries(deliveries.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/edit-delivery/${row.id}`}>
            <i className="flaticon-eye" /> View
          </Link>
        </li>
        {validateAccess(`edit-${type}-delivery`) && row.status === 'Draft' && (
          <li>
            <Link to={`/app/edit-delivery/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess(`edit-${type}-delivery`) && row.status === 'Draft' && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
        {validateAccess(`edit-${type}-delivery`) && row.status === 'Draft' && (
          <li>
            <Link to={`/app/edit-delivery/${row.id}`}>
              <i className="flaticon-tick-1" /> {deliver}
            </Link>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => setViewLogs(row)}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: `${deliveryName} No`,
      dataIndex: 'deliveryNo'
    },
    {
      title: clientName,
      dataIndex: 'clientName'
    },
    {
      title: `${deliveryName} Date`,
      dataIndex: 'deliveryDate',
      render: (value) => moment(value).format('YYYY-MM-DD')
    },
    {
      title: 'PO Type',
      dataIndex: 'poType',
      dontShow: !isPurchase
    },
    {
      title: orderAddress,
      dataIndex: 'billingAddress',
      render: (value) =>
        _.compact([value.street, value.city, value.state, value.postalCode, value.country]).join(', ')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => getStatus(status)
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: `${typeName} ${deliveryName}`,
        onClick: () => history.push(`/app/search-deliveries/${type}`),
        access: `add-${type}-delivery`
      }}
      filterData={GET_DATA(`${type}Deliveries.filterData`)}
      filter={<DeliveryListFilter key={type} onSubmit={getData} />}>
      <ModalBox
        title={`${typeName} ${deliveryName} Logs`}
        visible={!!viewLogs}
        onCancel={() => setViewLogs(false)}
        footer={false}
        destroyOnClose>
        <Logs entityId={viewLogs?.id} entityType={`${typeName} ${deliveryName}`} />
      </ModalBox>
      <TableLayout
        title={`${typeName} ${deliveryName} Overview`}
        detailed
        exportUrl="order-deliveries/export">
        <TableBox
          dataSource={deliveries}
          columns={columns}
          pageData={GET_DATA(`${type}Deliveries.filterData`)}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
