export const UserType = {
  GET_USER: 'GET_USER',
  GET_USERS: 'GET_USERS',
  GET_USERLISTS: 'GET_USERLISTS',
  SET_USER_REDUCER: 'SET_USER_REDUCER',
  SET_TIMER_COUNT: 'SET_TIMER_COUNT',
  SET_CURRENTLY_ADDED_DATA: 'SET_CURRENTLY_ADDED_DATA',
  SET_CURRENTLY_UPDATED_DATA: 'SET_CURRENTLY_UPDATED_DATA',
  GET_CURRENT_EDIT_DATA: 'GET_CURRENT_EDIT_DATA',

  CLEAR_SELECTED_DATA: 'CLEAR_SELECTED_DATA',
  SET_SELECTED_DATA: 'SET_SELECTED_DATA',
  SET_TIME_DATA: 'SET_TIME_DATA',
  SET_EXPENSE_CLAIM_DATA: 'SET_EXPENSE_CLAIM_DATA',

  SET_TEMPLATE_DATA: 'SET_TEMPLATE_DATA',
  CHANGE_TEMPLATE_POSITION: 'CHANGE_TEMPLATE_POSITION'
}

export const EmployeeType = {
  GET_ALL_EMPLOYEES: 'GET_ALL_EMPLOYEES',
  SET_SELECTED_EMPLOYEE: 'SET_SELECTED_EMPLOYEE',
  RESET_EMPLOYEE_DETAILS: 'RESET_EMPLOYEE_DETAILS',
  SET_OFFICIAL_DETAILS: 'SET_OFFICIAL_DETAILS',
  SET_PERSONAL_DETAILS: 'SET_PERSONAL_DETAILS',
  SET_BANK_DETAILS: 'SET_BANK_DETAILS',
  SET_PASSPORT_DETAILS: 'SET_PASSPORT_DETAILS',
  SET_CONTACT_DETAILS: 'SET_CONTACT_DETAILS',
  SET_EMERGENCY_DETAILS: 'SET_EMERGENCY_DETAILS',
  SET_INSURANCE_DETAILS: 'SET_INSURANCE_DETAILS',
  SET_DEPENDENTS: 'SET_DEPENDENTS',
  UPDATE_EMPLOYEE_DETAILS_BY_ID: 'UPDATE_EMPLOYEE_DETAILS_BY_ID',
  UPDATE_EMERGENCY_DETAILS_BY_ID: 'UPDATE_EMERGENCY_DETAILS_BY_ID',
  ADD_EMERGENCY_DETAILS: 'ADD_EMERGENCY_DETAILS',
  UPDATE_DEPENDENTS: 'UPDATE_DEPENDENTS',
  ADD_DEPENDENTS: 'ADD_DEPENDENTS',
  ADD_WORK_EXPERIENCE: 'ADD_WORK_EXPERIENCE',
  UPDATE_WORK_EXPERIENCE: 'UPDATE_WORK_EXPERIENCE',
  SET_WORK_EXPERIENCE: 'SET_WORK_EXPERIENCE',
  ADD_EDUCATION_DETAILS: 'ADD_EDUCATION_DETAILS',
  UPDATE_EDUCATION_DETAILS: 'UPDATE_EDUCATION_DETAILS',
  SET_EDUCATION_DETAILS: 'SET_EDUCATION_DETAILS',
  ADD_SKILL_DETAILS: 'ADD_SKILL_DETAILS',
  UPDATE_SKILL_DETAILS: 'UPDATE_SKILL_DETAILS',
  SET_SKILL_DETAILS: 'SET_SKILL_DETAILS',
  SET_JOB_HISTORY: 'SET_JOB_HISTORY',
  ADD_JOB_HISTORY: 'ADD_JOB_HISTORY',
  UPDATE_JOB_HISTORY: 'UPDATE_JOB_HISTORY',
  SET_SALARY_HISTORY: 'SET_SALARY_HISTORY',
  ADD_SALARY_HISTORY: 'ADD_SALARY_HISTORY',
  UPDATE_SALARY_HISTORY: 'UPDATE_SALARY_HISTORY',
  ADD_BANK_DETAILS: 'ADD_BANK_DETAILS',
  UPDATE_BANK_DETAILS: 'UPDATE_BANK_DETAILS'
}
