import { SettingOutlined } from '@ant-design/icons'
import { Col, message, Popconfirm, Popover, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  addEmergencyDetails,
  getEmergencyDetailsByEmployeeID,
  updateEmergencyDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import FooterActions from '../../../Components/FooterActions'
import Form from '../../../Components/Formik/Form'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import EmergencyDetailsForm from './EmergencyDetailsForm'

// TODO: Implement delete, add, add options for dropdowns

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  relationship: Yup.string().required(),
  homeTelephone: Yup.string().required(),
  mobile: Yup.string().required(),
  workTelephone: Yup.string().required()
})

const EmergencyDetails = (props) => {
  const [toggle, setToggle] = useState(false)
  const [isAddType, setIsAddType] = useState(true)
  const { currentEmployee, emergencyDetails, dispatch, values, setValues, submitForm, errors } = props

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getEmergencyDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const handleAddNewDetails = () => {
    if (currentEmployee?.id) {
      setToggle(true)
      setIsAddType(true)
      setValues({
        name: '',
        relationship: '',
        homeTelephone: '',
        mobile: '',
        workTelephone: '',
        isAddType: true
      })
    } else {
      message.error('Please select an employee to continue!')
    }
  }

  const handleEditRow = (row) => () => {
    setToggle(true)
    setValues({ ...row, isAddType: false })
    setIsAddType(false)
  }

  const handleValueChange = (val) => {
    setValues({ ...values, ...val })
  }

  const deleteRow = (row) => () => {
    dispatch(updateEmergencyDetailsByEmployeeID(row.id, { status: 'Inactive' }))
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setToggle(false)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Relationship',
      dataIndex: 'relationship'
    },
    {
      title: 'Home Telephone',
      dataIndex: 'homeTelephone'
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile'
    },
    {
      title: 'Work Telephone',
      dataIndex: 'workTelephone'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              {' '}
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li onClick={handleEditRow(row)}>
          <i className="flaticon-edit-1" /> Edit
        </li>
        <li>
          <Popconfirm title="Sure to delete?" onConfirm={deleteRow(row)}>
            <i className="flaticon-delete-2" /> Delete
          </Popconfirm>
        </li>
      </ul>
    </div>
  )

  return (
    <Form>
      <PanelLayout>
        <Panel title="Emergency Details">
          <div className="panel-with-border">
            <Row justify="left" gutter={(12, 10)}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="mb-3 align-right">
                  <ButtonBox style={{ marginRight: 10 }} type="success" onClick={handleAddNewDetails}>
                    <i className="flaticon-plus" /> Add
                  </ButtonBox>
                  {/* <ButtonBox type="primary">
                    {' '}
                    <i className="flaticon-delete-3" /> Delete{' '}
                  </ButtonBox> */}
                </div>
                <div className="table-view">
                  <TableBox
                    columns={columns}
                    actionIndex="custom_action"
                    cardHeaderIndex="status"
                    cardFirstLabelIndex="docno"
                    dataSource={emergencyDetails}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Panel>
      </PanelLayout>

      <ModalBox
        title={`${isAddType ? 'Add' : 'Edit'} Emergency Details`}
        visible={toggle}
        onCancel={() => setToggle(false)}
        width={800}
        okText="Save"
        onOk={onSave}
        destroyOnClose>
        <EmergencyDetailsForm currentDetails={values} handleValueChange={handleValueChange} />
      </ModalBox>

      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  emergencyDetails: state.employee.employeeDetails.emergencyDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      relationship: '',
      homeTelephone: '',
      mobile: '',
      workTelephone: ''
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      const { id, isAddType, ...rest } = values
      const payload = {
        name: rest.name,
        relationship: rest.relationship,
        homeTelephone: rest.homeTelephone,
        mobile: rest.mobile,
        workTelephone: rest.workTelephone,
        employee: currentEmployee.id
      }

      if (isAddType) {
        dispatch(addEmergencyDetails(payload))
      } else {
        dispatch(updateEmergencyDetailsByEmployeeID(id, payload))
      }
    },
    validationSchema: Schema
  })(EmergencyDetails)
)
