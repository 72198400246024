import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TableBox from '../../../../Components/TableBox/TableBox'
import FilterLayout from '../../../../Layout/FilterLayout'
import TableLayout from '../../../../Layout/TableLayout'
import apiClient from '../../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../../Util/Util'
import TermsConditionFilter from './TermsConditionFilter'

export default function TermsConditions() {
  const [termsConditions, setTermsConditions] = useState([])
  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('termsConditions.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('terms-conditions', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('termsConditions.filterData', { ...params, ...data.pageData })
        setTermsConditions(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onDelete = (id) => {
    apiClient.delete(`terms-conditions/${id}`).then(({ status }) => {
      if (status === 204) {
        setTermsConditions(termsConditions.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-terms-condition') && (
          <li>
            <Link to={`/app/edit-terms-condition/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-terms-condition') && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Document Type',
      dataIndex: 'type'
    },
    {
      title: 'Full Text',
      dataIndex: 'content',
      className: 'terms-condition-content'
    },
    {
      title: 'Valid From',
      dataIndex: 'validFrom',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: 'Valid To',
      dataIndex: 'validTo',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Terms & Conditions',
        onClick: () => history.push('/app/add-terms-condition'),
        access: 'add-terms-condition'
      }}
      filterData={GET_DATA('termsConditions.filterData')}
      filter={<TermsConditionFilter onSubmit={getData} />}>
      <TableLayout title="Terms & Conditions Overview" exportUrl="terms-conditions/export">
        <TableBox
          dataSource={termsConditions}
          columns={columns}
          pageData={GET_DATA('termsConditions.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
