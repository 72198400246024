import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  getPassportDetailsByEmployeeID,
  updatePassportDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import Attachments from '../../../Components/Attachments'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'

// TODO: For country options, add master data in db

const Schema = Yup.object().shape({
  passportNumber: Yup.string().required(),
  nameOnPassport: Yup.string().required(),
  passportValidFrom: Yup.date().required(),
  passportValidTo: Yup.date().required(),
  passportIssuedCountry: Yup.string().required(),
  visaHeldForCountry: Yup.string().required(),
  typeOfVisa: Yup.string().required(),
  visaValidFrom: Yup.date().required(),
  visaValidTo: Yup.date().required(),
  typeOfVisaEntry: Yup.string().required(),
  attachments: Yup.array(),
  company: Yup.string().required(),
  employee: Yup.string().required()
  // _id: Yup.string().required()
})

const PassportDetails = (props) => {
  const [editable, setEditable] = useState(false)
  const [options, setOptions] = useState({})

  const { currentEmployee, passportDetails, dispatch, values, setValues, submitForm, errors } = props

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getPassportDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const fetchDropdownValues = () => {
    apiClient
      .get('options/get-by-types', {
        params: { type: ['TypeOfVisa', 'TypeOfVisaEntry'] }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })
  }

  const onEdit = () => {
    if (currentEmployee?.id) {
      fetchDropdownValues()
      setEditable(true)
      setValues({
        ...values,
        employee: currentEmployee?.id,
        company: currentEmployee?.company,
        passportNumber: passportDetails?.passportNumber,
        nameOnPassport: passportDetails?.nameOnPassport,
        passportValidFrom: moment(passportDetails?.passportValidFrom).format('yyyy-MM-DD'),
        passportValidTo: moment(passportDetails?.passportValidTo).format('yyyy-MM-DD'),
        passportIssuedCountry: passportDetails?.passportIssuedCountry,
        visaHeldForCountry: passportDetails?.visaHeldForCountry,
        typeOfVisa: passportDetails?.typeOfVisa,
        visaValidFrom: moment(passportDetails?.visaValidFrom).format('yyyy-MM-DD'),
        visaValidTo: moment(passportDetails?.visaValidTo).format('yyyy-MM-DD'),
        typeOfVisaEntry: passportDetails?.typeOfVisaEntry,
        attachments: passportDetails?.attachments
      })
    } else {
      message.error('Please select and employee to edit')
    }
  }

  const onUploadAttachement = (key, attachments) => {
    setValues({ ...values, attachments: attachments.filter((attachment) => attachment !== '') })
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setEditable(false)
    }
  }

  return (
    <Form>
      <PanelLayout>
        <Panel title="Passport / Visa Details">
          {!editable && (
            <div className="panel-with-border">
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Passport Number</span>
                  <p>{!isEmpty(passportDetails) ? passportDetails.passportNumber : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Name As Passport</span>
                  <p>{!isEmpty(passportDetails) ? passportDetails.nameOnPassport : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Passport Valid From</span>
                  <p>
                    {!isEmpty(passportDetails)
                      ? moment(passportDetails.passportValidFrom).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Passport Valid To</span>
                  <p>
                    {!isEmpty(passportDetails)
                      ? moment(passportDetails.passportValidTo).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Passport issued Country</span>
                  <p>{!isEmpty(passportDetails) ? passportDetails.passportIssuedCountry : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Visa held for country</span>
                  <p>{!isEmpty(passportDetails) ? passportDetails.visaHeldForCountry : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Type of Visa</span>
                  <p>{!isEmpty(passportDetails) ? passportDetails.typeOfVisa : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Visa Valid From</span>
                  <p>
                    {!isEmpty(passportDetails)
                      ? moment(passportDetails.visaValidFrom).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Visa Valid To</span>
                  <p>
                    {!isEmpty(passportDetails)
                      ? moment(passportDetails.visaValidTo).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Type Of Visa Entry</span>
                  <p>{!isEmpty(passportDetails) ? passportDetails.typeOfVisaEntry : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Attachments</span>
                  <div className="attachments-preview">
                    <a>
                      {!isEmpty(passportDetails)
                        ? passportDetails.attachments.map((attachment) => attachment.name).join(', ')
                        : 'N/A'}
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {editable && (
            <div>
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="passportNumber" label="Passport Number" type="number" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="nameOnPassport" label="Name As Passport" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="passportValidFrom" label="Passport Valid From" as="date" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="passportValidTo" label="Passport Valid To" as="date" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field
                      name="passportIssuedCountry"
                      label="Passport issued Country"
                      type="text"
                      // options={options.Country || []}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field
                      name="visaHeldForCountry"
                      label="Visa held for country"
                      type="text"
                      // options={options.Country || []}
                    />
                  </div>
                </Col>
              </Row>
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field
                      name="typeOfVisa"
                      label="Type of Visa"
                      as="select"
                      options={options.TypeOfVisa || []}
                    />
                  </div>
                  <div className="form-field">
                    <Field name="visaValidFrom" label="Visa Valid From" as="date" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="visaValidTo" label="Visa Valid To" as="date" />
                  </div>
                  <div className="form-field">
                    <Field
                      name="typeOfVisaEntry"
                      label="Type Of Visa Entry"
                      as="select"
                      options={options.TypeOfVisaEntry || []}
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <label>Attachments</label>
                    <Attachments
                      name="attachments"
                      value={values.attachments}
                      title="Attach Files"
                      description="You can upload a maximum of 3 files, 5MB each"
                      acceptFile={['image', 'pdf']}
                      onUpload={onUploadAttachement}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Panel>
      </PanelLayout>
      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
        centerActions={[
          {
            prefix: 'flaticon-play',
            label: 'Save',
            dontShow: !editable,
            onClick: onSave
          }
        ]}
        rightActions={[
          {
            prefix: 'flaticon-edit-1',
            label: 'Edit',
            dontShow: editable,
            onClick: onEdit
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  passportDetails: state.employee.employeeDetails.passportDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      passportNumber: '',
      nameOnPassport: '',
      passportValidFrom: '',
      passportValidTo: '',
      passportIssuedCountry: '',
      visaHeldForCountry: '',
      typeOfVisa: '',
      visaValidFrom: '',
      visaValidTo: '',
      typeOfVisaEntry: '',
      attachments: [],
      employee: '',
      // _id: '',
      company: ''
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      dispatch(updatePassportDetailsByEmployeeID(currentEmployee.id, values))
    },
    validationSchema: Schema
  })(PassportDetails)
)
