import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Row, Select } from 'antd'
import clsx from 'clsx'
import _ from 'lodash'
import React, { memo, useState } from 'react'
import { useParams } from 'react-router'
import { getMaterial } from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import { getOrderNames, TAX_TYPES } from '../../../Util/Options'
import { parseAmount } from '../../../Util/Util'

const { Option } = Select

function SingleOrderDetails({
  i,
  orderedQuantity,
  price,
  amount,
  discountType,
  discount,
  discountValue,
  chargeType,
  charge,
  chargeValue,
  netAmount,
  taxAmount,
  tax,
  tempTax,
  taxType,
  deliveryStatus,
  invoiceStatus,
  toBeDeliveredQuantity,
  toBeInvoicedQuantity,
  stockStatus,
  deliveredQuantity,
  invoicedQuantity,
  materialCodeAlt,
  materialDescriptionAlt,
  setFieldValue,
  type,
  status,
  taxCategory,
  currency
}) {
  const [materials, setMaterials] = useState([])

  const { id } = useParams()
  const { isSales, getStatus, del, deliveryShortName, delivered } = getOrderNames(type)

  const disabled = id && status !== 'Created'

  const getMaterials = (material) => {
    getMaterial({ material }).then((data) => {
      setMaterials(data)
    })
  }

  useDidUpdateEffect(() => {
    if (orderedQuantity && price) {
      setFieldValue(`orderDetails[${i}].amount`, parseInt(orderedQuantity || 1, 10) * parseFloat(price || 1))
    } else {
      setFieldValue(`orderDetails[${i}].amount`, 0)
    }
  }, [orderedQuantity, price])

  useDidUpdateEffect(() => {
    let netAmount = 0
    let discountValue = 0
    let chargeValue = 0

    if (amount) {
      netAmount = parseFloat(amount || 0)

      switch (discountType) {
        case '%':
          discountValue = (netAmount * parseFloat(discount || 0)) / 100
          break
        default:
          discountValue = parseFloat(discount || 0)
          break
      }

      switch (chargeType) {
        case '%':
          chargeValue = (netAmount * parseFloat(charge || 0)) / 100
          break
        default:
          chargeValue = parseFloat(charge || 0)
          break
      }
    }

    setFieldValue(`orderDetails[${i}].discountValue`, discountValue)
    setFieldValue(`orderDetails[${i}].chargeValue`, chargeValue)
    setFieldValue(`orderDetails[${i}].netAmount`, netAmount - discountValue + chargeValue)
  }, [amount, chargeType, charge, discountType, discount])

  useDidUpdateEffect(() => {
    if (orderedQuantity) {
      setFieldValue(
        `orderDetails[${i}].toBeDeliveredQuantity`,
        parseFloat(orderedQuantity || 0) - parseFloat(deliveredQuantity || 0)
      )
      setFieldValue(
        `orderDetails[${i}].toBeInvoicedQuantity`,
        parseFloat(orderedQuantity || 0) - parseFloat(invoicedQuantity || 0)
      )
    } else {
      setFieldValue(`orderDetails[${i}].toBeDeliveredQuantity`, '')
      setFieldValue(`orderDetails[${i}].toBeInvoicedQuantity`, '')
    }
  }, [orderedQuantity])

  useDidUpdateEffect(() => {
    setFieldValue(`orderDetails[${i}].taxAmount`, (netAmount * parseFloat(tax || 0)) / 100)
  }, [netAmount, tax])

  useDidUpdateEffect(() => {
    if (taxType === 'Normal VAT') {
      setFieldValue(`orderDetails[${i}].tax`, tempTax)
    } else if (taxType) {
      setFieldValue(`orderDetails[${i}].tax`, '')
    }
  }, [taxType])

  const selectAfter = (n, v) => (
    <Select
      disabled={disabled}
      onSelect={(val) => setFieldValue(`orderDetails[${i}].${n}`, val)}
      defaultValue={v || ''}
      value={v || ''}
      className="select-after"
      showArrow={false}>
      <Option value="%">%</Option>
      <Option value="">№</Option>
    </Select>
  )

  return (
    <>
      <Row gutter={[10, 5]}>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`orderDetails[${i}].position`} label="Position" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={taxType === 'Normal VAT' ? 4 : 6}>
          <div className="form-field">
            <Field
              as="auto-complete"
              textArea
              rows={1}
              name={`orderDetails[${i}].materialCodeDesc`}
              label="Material / Description"
              onSearch={getMaterials}
              onChange={(n, v, o) => {
                setFieldValue(`orderDetails[${i}].materialCode`, o?.basic?.materialCode)
                setFieldValue(`orderDetails[${i}].materialDescription`, o?.basic?.materialDescription)
                setFieldValue(`orderDetails[${i}].materialCodeAlt`, o?.basic?.materialCodeAlt)
                setFieldValue(`orderDetails[${i}].materialDescriptionAlt`, o?.basic?.materialDescriptionAlt)
                setFieldValue(`orderDetails[${i}].unit`, o?.stockInfo[`${type}Unit`])
                setFieldValue(
                  `orderDetails[${i}].price`,
                  parseAmount(o?.stockInfo[`${type}Price`], currency, true)
                )

                return setFieldValue(n, v)
              }}
              options={materials}
              disabled={disabled}
              altInput
              altDisabled
              altValue={_.compact([materialCodeAlt, materialDescriptionAlt]).join(' - ')}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field name={`orderDetails[${i}].unit`} label="UOM" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field
              type="number"
              name={`orderDetails[${i}].orderedQuantity`}
              label="Ord. Qty"
              disabled={disabled}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={3}>
          <div className="form-field">
            <Field
              as="date"
              name={`orderDetails[${i}].requestedDate`}
              label="Req. Date"
              disabled={disabled}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2}>
          <div className="form-field">
            <Field type="number" name={`orderDetails[${i}].price`} label="Price" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2} xl={2}>
          <div className="form-field">
            <Field
              type="number"
              name={`orderDetails[${i}].discount`}
              label="Discount"
              disabled={disabled}
              addonAfter={selectAfter('discountType', discountType)}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2} xl={2}>
          <div className="form-field">
            <Field
              type="number"
              name={`orderDetails[${i}].charge`}
              label="Charge"
              disabled={disabled}
              addonAfter={selectAfter('chargeType', chargeType)}
            />
          </div>
        </Col>
        {taxType === 'Normal VAT' && (
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field name={`orderDetails[${i}].tax`} label="Tax rate" disabled={disabled} />
            </div>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={3} xl={3}>
          <div className="form-field">
            <Field
              as="select"
              name={`orderDetails[${i}].taxType`}
              label="Tax Types"
              options={TAX_TYPES.filter((item) => item.categories.includes(taxCategory))}
              disabled={disabled}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={24} xxl={13}>
          <div className="d-flex">
            {isSales && stockStatus && (
              <div className="line-item-footer-content">
                <span className={clsx('text-white p-1', stockStatus === 'OK' ? 'bg-success' : 'bg-danger')}>
                  {stockStatus === 'OK' ? <CheckOutlined /> : <CloseOutlined />}
                </span>{' '}
                <b>Stock Status</b>
              </div>
            )}
            <div className="line-item-footer-content">
              <b>{deliveryShortName} Status :</b>{' '}
              <span className="text-nowrap">{getStatus(deliveryStatus)}</span>
            </div>
            <div className="line-item-footer-content">
              <b>Invoice Status :</b> <span className="text-nowrap">{invoiceStatus}</span>
            </div>
            <div className="line-item-footer-content">
              <b>{delivered} qty :</b> <span className="text-nowrap">{deliveredQuantity}</span>
            </div>
            <div className="line-item-footer-content">
              <b>Invoiced qty :</b> <span className="text-nowrap">{invoicedQuantity}</span>
            </div>
            <div className="line-item-footer-content">
              <b>To be {del}. Qty :</b> <span className="text-nowrap">{toBeDeliveredQuantity}</span>
            </div>
            <div className="line-item-footer-content">
              <b>To be Inv. Qty :</b> <span className="text-nowrap">{toBeInvoicedQuantity}</span>
            </div>
          </div>
        </Col>
        <Col xl={24} xxl={11}>
          <div className="line-item-footer">
            <div className="line-item-footer-content">
              <b>Amount:</b> <span className="text-nowrap">{parseAmount(amount, currency)} -</span>
            </div>
            <div className="line-item-footer-content">
              <b>Discount :</b> <span className="text-nowrap">{parseAmount(discountValue, currency)} +</span>
            </div>
            <div className="line-item-footer-content">
              <b>Charge :</b> <span className="text-nowrap">{parseAmount(chargeValue, currency)} =</span>
            </div>
            <div className="line-item-footer-content">
              <b>Net :</b> <span className="text-nowrap">{parseAmount(netAmount, currency)} +</span>
            </div>
            <div className="line-item-footer-content">
              <b>Tax :</b> <span className="text-nowrap">{parseAmount(taxAmount, currency)} =</span>
            </div>
            <div className="line-item-footer-content">
              <b>Gross :</b>{' '}
              <span className="text-nowrap">{parseAmount(netAmount + taxAmount, currency)}</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default memo(SingleOrderDetails)
