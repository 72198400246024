import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getMasterCurrencies } from '../../../../Actions/UserAction'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'
import { PRICE_TYPES } from '../../../../Util/Options'

function SalesCallEntryFilter({ values, setValues, filterData }) {
  const [currencies, setCurrencies] = useState([])

  useEffect(() => {
    getMasterCurrencies().then((data) => setCurrencies(data))
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field as="select" name="type" label="Type" options={PRICE_TYPES} />
      </div>
      <div className="form-fields">
        <Field name="description" label="Description" />
      </div>
      <div className="form-fields">
        <Field name="country" label="Country" />
      </div>
      <div className="form-fields">
        <Field as="select" name="currency" label="Currency" options={currencies} />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" /> Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    type: '',
    description: '',
    country: '',
    currency: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(SalesCallEntryFilter)
