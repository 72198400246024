const MENUS = (companyInfo) => [
  {
    label: 'Company Setup',
    children: [
      {
        label: 'Company Masters',
        type: 'group',
        children: [
          {
            label: 'Companies',
            value: '/app/companies',
            access: [
              { label: 'Add', value: 'add-company' },
              { label: 'Edit', value: 'edit-company' }
            ],
            notInMenu: true
          },
          {
            label: 'Divisions',
            value: '/app/divisions',
            access: [
              { label: 'View', value: 'divisions' },
              { label: 'Add', value: 'add-division' },
              { label: 'Edit', value: 'edit-division' }
            ],
            dontShow: companyInfo?.configurations?.division !== 'Yes'
          },
          {
            label: 'Roles',
            value: '/app/roles',
            access: [
              { label: 'View', value: 'roles' },
              { label: 'Add', value: 'add-role' },
              { label: 'Edit', value: 'edit-role' }
            ]
          },
          {
            label: 'Users',
            value: '/app/users',
            access: [
              { label: 'View', value: 'users' },
              { label: 'Add', value: 'add-user' },
              { label: 'Edit', value: 'edit-user' }
            ]
          },
          {
            label: 'Currencies',
            value: '/app/currencies',
            access: [
              { label: 'View', value: 'currencies' },
              { label: 'Add', value: 'add-currency' },
              { label: 'Edit', value: 'edit-currency' }
            ]
          },
          {
            label: 'Exchange Rate',
            value: '/app/exchange-rates',
            access: [
              { label: 'View', value: 'exchange-rates' },
              { label: 'Add', value: 'add-exchange-rate' },
              { label: 'Edit', value: 'edit-exchange-rate' }
            ]
          },
          {
            label: 'Categories',
            value: '/app/categories',
            access: [
              { label: 'View', value: 'categories' },
              { label: 'Add', value: 'add-category' },
              { label: 'Edit', value: 'edit-category' }
            ]
          },
          {
            label: 'Options',
            value: '/app/options',
            access: [
              { label: 'View', value: 'options' },
              { label: 'Add', value: 'add-option' },
              { label: 'Edit', value: 'edit-option' }
            ]
          },
          {
            label: 'Custom Templates',
            value: '/app/custom-templates',
            access: [
              { label: 'View', value: 'custom-templates' },
              { label: 'Add', value: 'add-custom-template' },
              { label: 'Edit', value: 'edit-custom-template' }
            ]
          },
          {
            label: 'Master Upload',
            value: '/app/master-upload',
            access: [{ label: 'Full', value: 'master-upload' }]
          },
          {
            label: 'Numbering Series',
            value: '/app/numbering-series',
            access: [{ label: 'Full', value: 'numbering-series' }]
          },
          {
            label: 'Company Configurations',
            value: '/app/company-configurations',
            access: [{ label: 'Full', value: 'company-configurations' }]
          }
        ]
      }
    ]
  },
  {
    label: 'Service Management',
    className: 'mega-menu',
    children: [
      {
        label: 'Service Masters',
        type: 'group',
        children: [
          {
            label: 'Business Partner',
            value: '/app/clients',
            access: [
              { label: 'View', value: 'clients' },
              { label: 'Add', value: 'add-client' },
              { label: 'Edit', value: 'edit-client' }
            ]
          },
          {
            label: 'Products',
            value: '/app/products',
            access: [
              { label: 'View', value: 'products' },
              { label: 'Add', value: 'add-product' },
              { label: 'Edit', value: 'edit-product' }
            ]
          }
        ]
      },
      {
        label: 'Projects',
        type: 'group',
        children: [
          {
            label: 'Projects',
            value: '/app/projects',
            access: [
              { label: 'View', value: 'projects' },
              { label: 'Add', value: 'add-project' },
              { label: 'Edit', value: 'edit-projects' }
            ]
          },
          // {
          //   label: 'Project & employees',
          //   value: '/app/project-employees',
          //   access: [
          //     { label: 'View', value: 'project-employees' },
          //     { label: 'Add', value: 'add-project-employee' },
          //     { label: 'Edit', value: 'edit-project-employee' }
          //   ]
          // },
          {
            label: 'Project Rates',
            value: '/app/project-employee-rates',
            access: [
              { label: 'View', value: 'project-employee-rates' },
              { label: 'Add', value: 'add-project-employee-rate' },
              { label: 'Edit', value: 'edit-project-employee-rate' }
            ]
          }
        ]
      },
      {
        label: 'Time & expense claim',
        type: 'group',
        children: [
          {
            label: 'Time Entries',
            value: '/app/time-entries',
            access: [{ label: 'Full', value: 'time-entries' }]
          },
          {
            label: 'Expense Claims',
            value: '/app/expense-claims',
            access: [{ label: 'Full', value: 'expense-claims' }]
          }
        ]
      },
      {
        label: 'Reports',
        type: 'group',
        children: [
          {
            label: 'Time Reports',
            value: '/app/time-reports',
            access: [
              { label: 'View', value: 'time-reports', view: 'time-reports-view' },
              { label: 'Full', value: 'time-reports-all' }
            ]
          }
        ]
      }
    ]
  },
  {
    label: 'Logistics',
    className: 'mega-menu',
    children: [
      {
        label: 'Material Management',
        type: 'group',
        children: [
          {
            label: 'Warehouse',
            value: '/app/warehouses',
            access: [
              { label: 'View', value: 'warehouses' },
              { label: 'Add', value: 'add-warehouse' },
              { label: 'Edit', value: 'edit-warehouse' }
            ]
          },
          {
            label: 'Warehouse Products',
            value: '/app/warehouse-products',
            access: [
              { label: 'View', value: 'warehouse-products' },
              { label: 'Add', value: 'add-warehouse-product' },
              { label: 'Edit', value: 'edit-warehouse-product' }
            ]
          }
        ]
      },
      {
        label: 'Sales Management',
        type: 'group',
        children: [
          {
            label: 'Sales Quotation',
            value: '/app/sales-quotations',
            access: [
              { label: 'View', value: 'sales-quotations' },
              { label: 'Add', value: 'add-sales-quotation' },
              { label: 'Edit', value: 'edit-sales-quotation' }
            ]
          },
          {
            label: 'Sales Order',
            value: '/app/orders/sales',
            access: [
              { label: 'View', value: 'sales-orders' },
              { label: 'Add', value: 'add-sales-order' },
              { label: 'Edit', value: 'edit-sales-order' }
            ]
          },
          {
            label: 'Delivery',
            value: '/app/order-deliveries/sales',
            access: [{ label: 'Add', value: 'add-sales-deliveries' }]
          },
          {
            label: 'Invoice',
            value: '/app/order-invoices/sales',
            access: [
              { label: 'View', value: 'sales-invoices' },
              { label: 'Add', value: 'add-sales-invoice' },
              { label: 'Edit', value: 'edit-sales-invoice' }
            ]
          }
        ]
      },
      {
        label: 'Purchase Management',
        type: 'group',
        children: [
          {
            label: 'Purchase Order',
            value: '/app/orders/purchase',
            access: [
              { label: 'View', value: 'purchase-orders' },
              { label: 'Add', value: 'add-purchase-order' },
              { label: 'Edit', value: 'edit-purchase-order' }
            ]
          },
          {
            label: 'Goods Receipt',
            value: '/app/order-deliveries/purchase',
            access: [{ label: 'Add', value: 'add-purchase-receipt' }]
          },
          {
            label: 'Customs Clearance',
            value: '/app/customs-clearances',
            access: [
              { label: 'View', value: 'customs-clearances' },
              { label: 'Add', value: 'add-customs-clearance' },
              { label: 'Edit', value: 'edit-customs-clearance' }
            ]
          },
          {
            label: 'Invoice',
            value: '/app/order-invoices/purchase',
            access: [
              { label: 'View', value: 'purchase-invoices' },
              { label: 'Add', value: 'add-purchase-invoice' },
              { label: 'Edit', value: 'edit-purchase-invoice' }
            ]
          }
        ]
      },
      {
        label: 'Stock Management',
        type: 'group',
        children: [
          {
            label: 'Goods Receipts',
            value: '/app/goods-receipts',
            access: [
              { label: 'View', value: 'goods-receipts' },
              { label: 'Add', value: 'add-goods-receipt' },
              { label: 'Edit', value: 'edit-goods-receipt' }
            ]
          },
          {
            label: 'Goods Issues',
            value: '/app/goods-issues',
            access: [
              { label: 'View', value: 'goods-issues' },
              { label: 'Add', value: 'add-goods-issue' },
              { label: 'Edit', value: 'edit-goods-issue' }
            ]
          },
          {
            label: 'Stock Checks',
            value: '/app/stock-checks',
            access: [
              { label: 'View', value: 'stock-checks' },
              { label: 'Add', value: 'add-stock-check' },
              { label: 'Edit', value: 'edit-stock-check' }
            ]
          },
          {
            label: 'Stock Transfer',
            value: '/app/stock-transfers',
            access: [
              { label: 'View', value: 'stock-transfers' },
              { label: 'Add', value: 'add-stock-transfer' },
              { label: 'Edit', value: 'edit-stock-transfer' }
            ]
          }
        ]
      },
      {
        label: 'Reports',
        type: 'group',
        children: [
          {
            label: 'Material Report',
            value: '/app/material-reports',
            access: [
              { label: 'View', value: 'material-reports' },
              { label: 'Add', value: 'add-material-report' },
              { label: 'Edit', value: 'edit-material-report' }
            ]
          },
          {
            label: 'Stock Report',
            value: '/app/stock-reports',
            access: [
              { label: 'View', value: 'stock-reports' },
              { label: 'Add', value: 'add-stock-report' },
              { label: 'Edit', value: 'edit-stock-report' }
            ]
          }
        ]
      }
    ]
  },
  {
    label: 'Finance Management',
    className: 'mega-menu',
    children: [
      {
        label: 'Setup',
        type: 'group',
        children: [
          {
            label: 'Financial Year',
            value: '/app/financial-years',
            access: [{ label: 'View', value: 'financial-years' }]
          },
          {
            label: 'Account Groups',
            value: '/app/account-groups',
            access: [{ label: 'View', value: 'account-groups' }]
          },
          {
            label: 'Account Charts',
            value: '/app/account-charts',
            access: [{ label: 'View', value: 'account-charts' }]
          },
          {
            label: 'Account Setups',
            value: '/app/account-setups',
            access: [{ label: 'View', value: 'account-setups' }]
          }
        ]
      },
      {
        label: 'Receivables',
        type: 'group',
        children: [
          {
            label: 'Income List',
            value: '/app/incomes',
            access: [
              { label: 'View', value: 'incomes' },
              { label: 'Add', value: 'add-income' },
              { label: 'Edit', value: 'edit-income' }
            ]
          },
          {
            label: 'Invoice Receipt',
            value: '/app/invoice-receipts',
            access: [{ label: 'Full', value: 'invoice-receipts' }]
          },
          {
            label: 'From Timesheet',
            value: '/app/from-timesheet',
            access: [{ label: 'Full', value: 'from-timesheet' }]
          }
        ]
      },
      {
        label: 'Payables',
        type: 'group',
        children: [
          {
            label: 'Expense List',
            value: '/app/expenses',
            access: [
              { label: 'View', value: 'expenses' },
              { label: 'Add', value: 'add-expense' },
              { label: 'Edit', value: 'edit-expense' }
            ]
          },
          {
            label: 'Invoice Payments',
            value: '/app/invoice-payments',
            access: [{ label: 'Full', value: 'invoice-payments' }]
          }
        ]
      },
      {
        label: 'Uploads',
        type: 'group',
        children: [
          {
            label: 'Income Upload',
            value: '/app/upload-income-invoices',
            access: [{ label: 'Full', value: 'upload-income-invoices' }]
          },
          {
            label: 'Expense Upload',
            value: '/app/upload-expense-invoices',
            access: [{ label: 'Full', value: 'upload-expense-invoices' }]
          },
          {
            label: 'Generate Invoice',
            value: '/app/generate-invoice',
            access: [{ label: 'Full', value: 'generate-invoice' }]
          }
        ]
      },
      {
        label: 'General Ledger',
        type: 'group',
        children: [
          {
            label: 'Finance Book',
            value: '/app/finance-postings',
            access: [{ label: 'View', value: 'finance-postings' }]
          },
          {
            label: 'Journal Voucher',
            value: '/app/journal-vouchers',
            access: [
              { label: 'View', value: 'journal-vouchers' },
              { label: 'Add', value: 'add-journal-voucher' },
              { label: 'Edit', value: 'edit-journal-voucher' }
            ]
          }
        ]
      },
      {
        label: 'Reports',
        type: 'group',
        children: [
          { label: 'P&L Statement' },
          {
            label: 'Balance Sheet'
          }
        ]
      }
    ]
  },
  {
    label: 'Freight Management',
    className: 'mega-menu',
    children: [
      {
        label: 'Masters',
        type: 'group',
        children: [
          {
            label: 'Sales Person',
            value: '/app/sales-persons',
            access: [
              { label: 'View', value: 'sales-persons' },
              { label: 'Add', value: 'add-sales-person' },
              { label: 'Edit', value: 'edit-sales-person' }
            ]
          },
          {
            label: 'POL/POD',
            value: '/app/pol-pod',
            access: [
              { label: 'View', value: 'pol-pod' },
              { label: 'Add', value: 'add-pol-pod' },
              { label: 'Edit', value: 'edit-pol-pod' }
            ]
          },
          {
            label: 'Packages types',
            value: '/app/packages-types',
            access: [
              { label: 'View', value: 'packages-types' },
              { label: 'Add', value: 'add-packages-type' },
              { label: 'Edit', value: 'edit-packages-type' }
            ]
          },
          {
            label: 'Prices & Charges',
            value: '/app/price-charges',
            access: [
              { label: 'View', value: 'price-charges' },
              { label: 'Add', value: 'add-price-charge' },
              { label: 'Edit', value: 'edit-price-charge' }
            ]
          },
          {
            label: 'Operations',
            value: '/app/operations',
            access: [
              { label: 'View', value: 'operations' },
              { label: 'Add', value: 'add-operation' },
              { label: 'Edit', value: 'edit-operation' }
            ]
          },
          {
            label: 'Terms and Conditions',
            value: '/app/terms-conditions',
            access: [
              { label: 'View', value: 'terms-conditions' },
              { label: 'Add', value: 'add-terms-condition' },
              { label: 'Edit', value: 'edit-terms-condition' }
            ]
          }
        ]
      },
      {
        label: 'Quotation',
        type: 'group',
        children: [
          {
            label: 'Sales Call Entry',
            value: '/app/sales-call-entries',
            access: [
              { label: 'View', value: 'sales-call-entries' },
              { label: 'Add', value: 'add-sales-call-entry' },
              { label: 'Edit', value: 'edit-sales-call-entry' }
            ]
          }
        ]
      },
      {
        label: 'Export',
        type: 'group',
        children: [
          {
            label: 'Offer',
            value: '/app/offers',
            access: [
              { label: 'View', value: 'offers' },
              { label: 'Add', value: 'add-offer' },
              { label: 'Edit', value: 'edit-offer' }
            ]
          },
          {
            label: 'Booking Creation',
            value: '/app/bookings',
            access: [
              { label: 'View', value: 'bookings' },
              { label: 'Add', value: 'add-booking' },
              { label: 'Edit', value: 'edit-booking' }
            ]
          },
          {
            label: 'Job Creation',
            value: '/app/job-creations',
            access: [
              { label: 'View', value: 'job-creations' },
              { label: 'Add', value: 'add-job-creation' },
              { label: 'Edit', value: 'edit-job-creation' }
            ]
          }
        ]
      }
    ]
  },
  {
    label: 'E-Invoicing',
    children: [
      {
        label: 'Transmissions',
        type: 'group',
        children: [
          {
            label: 'Outbound Transmissions',
            value: '/app/outbound-transmissions',
            access: [{ label: 'Full', value: 'transmissions' }]
          },
          {
            label: 'Inbound Transmissions',
            value: '/app/inbound-transmissions',
            access: [{ label: 'Full', value: 'customer-transmissions' }]
          }
        ]
      }
    ]
  },

  {
    label: 'HR & Payroll',
    className: 'mega-menu',
    children: [
      {
        label: 'Masters',
        type: 'group',
        children: [
          {
            label: 'Employee',
            value: '/app/employees',
            access: [
              { label: 'View', value: 'employees' },
              { label: 'Add', value: 'add-employee' },
              { label: 'Edit', value: 'edit-employee' }
            ]
          },
          {
            label: 'Employee Details',
            value: '/app/employee-details',
            access: [{ label: 'View', value: 'employee-details' }]
          },
          {
            label: 'Asset',
            value: '/app/asset',
            access: [{ label: 'View', value: 'asset' }]
          },
          {
            label: 'Transfer',
            value: '/app/transfer',
            access: [{ label: 'View', value: 'transfer' }]
          },
          {
            label: 'Change Job',
            value: '/app/change-job',
            access: [{ label: 'View', value: 'change-job' }]
          },
          {
            label: 'Change Compensation',
            value: '/app/change-compensation',
            access: [{ label: 'View', value: 'change-compensation' }]
          },
          {
            label: 'Absence management',
            value: '/app/absence-management',
            access: [{ label: 'View', value: 'absence-management' }]
          },
          {
            label: 'Team Absence Application List',
            value: '/app/team-absence-application-list',
            access: [{ label: 'View', value: 'team-absence-application-list' }]
          },
          {
            label: 'Termination',
            value: '/app/termination',
            access: [{ label: 'View', value: 'termination' }]
          }
        ]
      },

      {
        label: 'Perfomance',
        type: 'group',
        children: [
          { label: 'Goals', value: '/app/goals', access: [{ label: 'View', value: 'goals' }] },
          {
            label: 'Goal Asignment',
            value: '/app/goal-assignment',
            access: [{ label: 'View', value: 'goal-assignment' }]
          },
          {
            label: 'Appraisal Review',
            value: '/app/appraisal-review',
            access: [{ label: 'View', value: 'appraisal-review' }]
          }
        ]
      },

      {
        label: 'Payroll Master',
        type: 'group',
        children: [
          { label: 'Tax Data', value: '/app/taxdata', access: [{ label: 'View', value: 'taxdata' }] },
          {
            label: 'Holiday calendar',
            value: '/app/holiday-calendar',
            access: [{ label: 'View', value: 'holiday-calendar' }]
          },
          { label: 'MWST Report', access: [{ label: 'View', value: 'MWST' }] },
          { label: 'Yearly Payroll Summary', access: [{ label: 'View', value: 'yearlyPayroll' }] }
        ]
      },
      {
        label: 'Payroll',
        type: 'group',
        children: [
          {
            label: 'Employee PayMaster',
            value: '/app/paymaster',
            access: [{ label: 'View', value: 'paymaster' }]
          },
          { label: 'Payrolls', value: '/app/payrolls', access: [{ label: 'View', value: 'payrolls' }] }
        ]
      }
    ]
  }
]
export default MENUS
