import { Col, Row } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import { getOptionsByType } from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'

function BasicData() {
  const [materialTypes, setMaterialTypes] = useState([])
  const [materialGroups, setMaterialGroups] = useState([])
  const [divisions, setDivisions] = useState([])
  const [units, setUnits] = useState([])
  const [weightUnits, setWeightUnits] = useState([])
  const [volumeUnits, setVolumeUnits] = useState([])
  const [status, setStatus] = useState([])

  const getData = () => {
    getOptionsByType({
      type: [
        'MaterialType',
        'MaterialGroup',
        'Division',
        'UnitOfMeasurement',
        'WeightUnit',
        'VolumeUnit',
        'MaterialStatus'
      ]
    }).then(
      ({
        MaterialType = [],
        MaterialGroup = [],
        Division = [],
        UnitOfMeasurement = [],
        WeightUnit = [],
        VolumeUnit = [],
        MaterialStatus = []
      }) => {
        setMaterialTypes(MaterialType)
        setMaterialGroups(MaterialGroup)
        setDivisions(Division)
        setUnits(UnitOfMeasurement)
        setWeightUnits(WeightUnit)
        setVolumeUnits(VolumeUnit)
        setStatus(MaterialStatus)
      }
    )
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      <Row gutter={[20, 10]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field label="Material Code" name="basic.materialCode" altInput />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field label="Material Desc." name="basic.materialDescription" altInput />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Material Type" name="basic.materialType" options={materialTypes} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Material Group" name="basic.materialGroup" options={materialGroups} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Division" name="basic.division" options={divisions} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="UOM" name="basic.unit" options={units} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Gross Weight" name="basic.grossWeight" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Net Weight" name="basic.netWeight" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Weight Unit" name="basic.weightUnit" options={weightUnits} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Volume" name="basic.volume" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Volume Unit" name="basic.volumeUnit" options={volumeUnits} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field label="EAN Code" name="basic.eanCode" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Sales Lead Time (days)" name="basic.salesTime" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Procurement Lead Time (days)" name="basic.procurementTime" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field as="select" label="Material Status" name="basic.status" options={status} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default memo(BasicData)
