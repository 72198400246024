import { Col, Row, Select } from 'antd'
import React, { memo } from 'react'
import Field from '../../../../Components/Formik/Field'
import useDidUpdateEffect from '../../../../Hooks/useDidUpdateEffect'
import { TAX_TYPES } from '../../../../Util/Options'
import { parseAmount } from '../../../../Util/Util'

const { Option } = Select

function SingleItemDetails({
  i,
  parentName,
  chargeType,
  quantity,
  price,
  amount,
  charge,
  chargeValue,
  netAmount,
  taxAmount,
  tax,
  tempTax,
  taxType,
  currency,
  options,
  disabled,
  setFieldValue
}) {
  useDidUpdateEffect(() => {
    if (quantity && price) {
      setFieldValue(`${parentName}[${i}].amount`, parseInt(quantity || 1, 10) * parseFloat(price || 1))
    } else {
      setFieldValue(`${parentName}[${i}].amount`, 0)
    }
  }, [quantity, price])

  useDidUpdateEffect(() => {
    let netAmount = 0
    let chargeValue = 0

    if (amount) {
      netAmount = parseFloat(amount || 0)

      switch (chargeType) {
        case '%':
          chargeValue = (netAmount * parseFloat(charge || 0)) / 100
          break
        default:
          chargeValue = parseFloat(charge || 0)
          break
      }
    }

    setFieldValue(`${parentName}[${i}].chargeValue`, chargeValue)
    setFieldValue(`${parentName}[${i}].netAmount`, netAmount + chargeValue)
  }, [amount, chargeType, charge])

  useDidUpdateEffect(() => {
    setFieldValue(`${parentName}[${i}].taxAmount`, (netAmount * parseFloat(tax || 0)) / 100)
  }, [netAmount, tax])

  useDidUpdateEffect(() => {
    if (taxType === 'Normal VAT') {
      setFieldValue(`${parentName}[${i}].tax`, tempTax)
    } else if (taxType) {
      setFieldValue(`${parentName}[${i}].tax`, '')
    }
  }, [taxType])

  const selectAfter = (n, v) => (
    <Select
      onSelect={(val) => setFieldValue(`${parentName}[${i}].${n}`, val)}
      defaultValue={v || ''}
      value={v || ''}
      className="select-after"
      showArrow={false}>
      <Option value="%">%</Option>
      <Option value="">№</Option>
    </Select>
  )

  return (
    <Row gutter={[10, 5]}>
      <Col xs={12} sm={12} md={8} lg={taxType === 'Normal VAT' ? 4 : 6}>
        <div className="form-field">
          <Field
            as="textarea"
            name={`${parentName}[${i}].description`}
            label="Description"
            rows={1}
            disabled={disabled}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={taxType === 'Normal VAT' ? 2 : 3}>
        <div className="form-field">
          <Field type="number" name={`${parentName}[${i}].quantity`} label="Quantity" disabled={disabled} />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3}>
        <div className="form-field">
          <Field
            as="select"
            name={`${parentName}[${i}].unit`}
            label="UOM"
            options={options.units}
            disabled={disabled}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3}>
        <div className="form-field">
          <Field type="number" name={`${parentName}[${i}].price`} label="Price" disabled={disabled} />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3}>
        <div className="form-field">
          <Field
            type="number"
            name={`${parentName}[${i}].charge`}
            label="Charge"
            addonAfter={selectAfter('chargeType', chargeType)}
            disabled={disabled}
          />
        </div>
      </Col>
      {taxType === 'Normal VAT' && (
        <Col xs={12} sm={12} md={8} lg={3}>
          <div className="form-field">
            <Field name={`${parentName}[${i}].tax`} label="Tax" disabled={disabled} />
          </div>
        </Col>
      )}
      <Col xs={12} sm={12} md={8} lg={3}>
        <div className="form-field">
          <Field
            as="select"
            name={`${parentName}[${i}].taxType`}
            label="Tax Type"
            options={TAX_TYPES}
            disabled={disabled}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3}>
        <div className="form-field">
          <Field
            as="select"
            name={`${parentName}[${i}].currency`}
            label="Currency"
            options={options.currencies}
            disabled={disabled}
          />
        </div>
      </Col>
      <Col xs={24}>
        <div className="line-item-footer">
          <div className="line-item-footer-content">
            <b>Amount:</b> <span className="text-nowrap">{parseAmount(amount, currency)} -</span>
          </div>
          <div className="line-item-footer-content">
            <b>Charge :</b> <span className="text-nowrap">{parseAmount(chargeValue, currency)} =</span>
          </div>
          <div className="line-item-footer-content">
            <b>Net :</b> <span className="text-nowrap">{parseAmount(netAmount, currency)} +</span>
          </div>
          <div className="line-item-footer-content">
            <b>Tax :</b> <span className="text-nowrap">{parseAmount(taxAmount, currency)} =</span>
          </div>
          <div className="line-item-footer-content">
            <b>Gross :</b> <span className="text-nowrap">{parseAmount(netAmount + taxAmount, currency)}</span>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default memo(SingleItemDetails)
