import { Col, Row } from 'antd'
import React, { memo } from 'react'
import Field from '../../../Components/Formik/Field'

function Packaging() {
  return (
    <div>
      <Row gutter={[20, 10]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Length" name="packaging.length" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Width" name="packaging.width" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Height" name="packaging.height" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <div className="form-field">
            <Field type="number" label="Qty. Per package" name="packaging.quantity" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default memo(Packaging)
