import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'
import { convertSelectOptions } from '../../../Util/Util'

const Schema = Yup.object().shape({
  user: Yup.string().required(),
  payrollTemplate: Yup.string().required()
})

function WarehouseForm({
  setValues,
  match: {
    params: { id }
  }
}) {
  const [columns, setColumns] = useState([])
  const [templateOptions, setTemplateOptions] = useState([])
  const [taxCodeOptions, setTaxCodeOptions] = useState([])

  const getFieldData = () => {
    apiClient.get('payrollBase/getActive').then(({ data }) => {
      if (data && data.result) {
        setColumns(data.result || [])
      }
    })
  }

  const getTaxData = (year) => {
    apiClient.get('tax-data/getUniqueTaxCode', { params: { year } }).then(({ data }) => {
      if (data && data.result && data.result) {
        setTaxCodeOptions(data.result.map((code) => ({ label: code, value: code })))
      }
    })
  }

  const getData = () => {
    if (id) {
      apiClient.get(`employeePayMasters/byId/${id}`).then(({ data }) => {
        if (data && data.result) {
          getTaxData(data.result.year)
          setValues(data.result)
        }
      })
    }

    apiClient.get('customTemplates/getActive', { params: { type: 'Payroll' } }).then(({ data }) => {
      if (data && data.result) {
        setTemplateOptions(convertSelectOptions(data.result || [], 'name', 'id'))
      }
    })
  }

  useEffect(() => {
    getFieldData()
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 20 }}>
          <div className="panel-layout">
            <h2 className="panel-title">Employee Paymaster Definition</h2>

            <div className="panel-design">
              <div className="panel-header">
                <h3>Employee Paymaster</h3>
              </div>
              <div className="panel-body">
                <Row gutter={[20, 10]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <div className="form-field">
                      <Field name="name" label="Employee" disabled />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <div className="form-field">
                      <Field
                        as="select"
                        name="payrollTemplate"
                        label="Template"
                        required
                        options={templateOptions}
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <div className="form-field">
                      <Field as="select" name="taxCode" label="Tax Code" options={taxCodeOptions} />
                    </div>
                  </Col>
                  {columns.map((val, ind) => (
                    <Col key={ind} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <div className="form-field">
                        <Field name={`columns.${val.key}`} label={val.label} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>

          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>
            <span>or</span>
            <Link to="/app/paymaster">
              <Button>
                <ArrowLeftOutlined /> Back to paymaster list
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    user: '',
    payrollTemplate: '',
    taxCodeOptions: ''
  }),
  validationSchema: Schema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`employeePayMasters/update/${id}`, data).then(({ data }) => {
        if (data.result) {
          history.push('/app/paymaster')
        }
      })
    }
  }
})(WarehouseForm)
