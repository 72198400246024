import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'
import { OPERATION_TYPES } from '../../../../Util/Options'

function BookingFilter({ values, setValues, filterData }) {
  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="bookingNo" label="Booking No" />
        </div>
        <div className="form-fields">
          <Field as="select" name="type" label="Booking Type" options={OPERATION_TYPES} allowClear />
        </div>
        <div className="form-fields">
          <Field name="clientName" label="Client Name" />
        </div>
        <div className="form-fields">
          <Field name="email" label="Email" />
        </div>
        <div className="form-fields">
          <Button type="submit" variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" /> Search
          </Button>
          <Button type="reset" className="btn-block btn-glow search">
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    quotationNo: '',
    type: '',
    clientName: '',
    email: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(BookingFilter)
