import { Col, Row } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../Components/Button'
import TableBox from '../../Components/TableBox/TableBox'
import Panel from '../../Layout/Panel'
import PanelLayout from '../../Layout/PanelLayout'
import apiClient from '../../Util/apiClient'

export default function StockReport() {
  const [data, setData] = useState([])
  const { name } = useSelector((state) => state.users.userInfo)

  useEffect(() => {
    apiClient.get('stocks/reports').then(({ status, data }) => {
      if (status === 200) {
        setData(data)
      }
    })
  }, [])

  const exportExcel = () => {
    apiClient.get('stocks/export-excel', { responseType: 'blob' }).then(({ status, data, headers }) => {
      if (status === 200) {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = JSON.parse(headers['content-disposition'].split('filename=')[1].split(';')[0])
        document.body.appendChild(a)
        a.click()
        a.remove()
      }
    })
  }

  const exportPdf = () => {
    apiClient.get('stocks/export-pdf', { responseType: 'blob' }).then(({ status, data, headers }) => {
      if (status === 200) {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(data)
        a.download = JSON.parse(headers['content-disposition'].split('filename=')[1].split(';')[0])
        document.body.appendChild(a)
        a.click()
        a.remove()
      }
    })
  }

  const columns = [
    {
      title: 'Material Code',
      dataIndex: 'materialCode'
    },
    {
      title: 'Material Desc',
      dataIndex: 'materialDescription'
    },
    {
      title: 'Type',
      dataIndex: 'materialType'
    },
    {
      title: 'UOM',
      dataIndex: 'unit'
    },
    { title: 'Stand. Costs', dataIndex: 'cost', align: 'right' },
    {
      title: 'Available Quantity',
      dataIndex: 'quantity',
      align: 'right'
    },
    {
      title: 'Allocated Quantity',
      dataIndex: 'allocatedQuantity',
      align: 'right'
    },
    {
      title: 'Accepted Quantity',
      dataIndex: 'acceptedQuantity',
      align: 'right'
    },
    {
      title: 'Rejected Quantity',
      dataIndex: 'rejectedQuantity',
      align: 'right'
    },
    {
      title: 'WH & Location',
      dataIndex: 'whLocation',
      render: (text, row) => `${row.warehouse}-${row.location}`
    },
    {
      title: 'Last Tran Date',
      dataIndex: 'transactionDate',
      render: (date) => (date ? moment(date).format('YYYY-MM-DD') : '-')
    }
  ]

  return (
    <Row justify="center" className="inner-contents">
      <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 20 }}>
        <PanelLayout
          title={
            <Row justify="space-between">
              <Col>
                <h2 className="panel-title">Stock Report</h2>
              </Col>
              <Col>
                <Button onClick={exportExcel}>
                  <i className="flaticon-clipboard" />
                  Excel
                </Button>
                <Button className="ml-2" onClick={exportPdf}>
                  <i className="flaticon-pdf" />
                  PDF
                </Button>
              </Col>
            </Row>
          }>
          <Panel title="Stock Report">
            <Row gutter={[20, 10]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <Row gutter={[20, 10]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    Company Code
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <b>AMPL</b>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <Row gutter={[20, 10]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    Generated Date
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <b>15.01.2019</b>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <Row gutter={[20, 10]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    Generated by
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <b>{name}</b>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <Row gutter={[20, 10]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    Material
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <b>ALL</b>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                <Row gutter={[20, 10]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    Type
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                    <b>FG</b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Panel>
          <h2>Results</h2>
          <TableBox className="px-0" dataSource={data} columns={columns} />
        </PanelLayout>
      </Col>
    </Row>
  )
}
