import { Col, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import Panel from '../../Layout/Panel'
import PanelLayout from '../../Layout/PanelLayout'
import { amountSeparator } from '../../Util/Util'

export default function QrInfo({ qrData }) {
  return (
    <Row justify="center" className="inner-contents">
      <Col xs={22} sm={22} md={20} lg={16}>
        {qrData && (
          <PanelLayout>
            <Panel title={qrData.companyData?.name}>
              <Row>
                <Col xs={12} sm={12} md={8} lg={8} className="qr-info-label">
                  Kind:
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} className="qr-info-value">
                  {qrData.kind}
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} className="qr-info-label">
                  Invoice No:
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} className="qr-info-value">
                  {qrData.invoiceNo}
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} className="qr-info-label">
                  Invoice Date:
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} className="qr-info-value">
                  {moment(qrData.issueDate).format('YYYY-MM-DD')}
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} className="qr-info-label">
                  Tax Amount:
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} className="qr-info-value">
                  {amountSeparator(qrData.taxAmount)}
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} className="qr-info-label">
                  Gross Amount:
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} className="qr-info-value">
                  {amountSeparator(qrData.grossAmount)}
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} className="qr-info-label">
                  Company tax no:
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} className="qr-info-value">
                  {qrData.companyData?.taxNo}
                </Col>
              </Row>
            </Panel>
          </PanelLayout>
        )}
      </Col>
    </Row>
  )
}
