import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import WarehouseProductFilter from './WarehouseProductFilter'

export default function WarehouseProducts() {
  const [warehouseProducts, setWarehouseProducts] = useState([])
  const history = useHistory()

  const getData = (params) => {
    const filterCache = GET_DATA('warehouseProducts.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('warehouse-products', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('warehouseProducts.filterData', { ...params, ...data.pageData })
        setWarehouseProducts(data.result.map((item) => ({ id: item.id, ...item.basic })))
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Material Code',
      dataIndex: 'materialCode'
    },
    {
      title: 'Material Description',
      dataIndex: 'materialDescription'
    },
    {
      title: 'Material Type',
      dataIndex: 'materialType'
    },
    {
      title: 'Material Group',
      dataIndex: 'materialGroup'
    },
    {
      title: 'Division',
      dataIndex: 'division'
    },
    {
      ...(validateAccess('edit-warehouse-product') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/edit-warehouse-product/${row.id}`)}
              className="btn-glow">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Warehouse Product',
        onClick: () => history.push('/app/add-warehouse-product'),
        access: 'add-warehouse-product'
      }}
      filterData={GET_DATA('warehouseProducts.filterData')}
      filter={<WarehouseProductFilter onSubmit={getData} />}>
      <TableLayout title="Warehouse Product Overview" exportUrl="warehouse-products/export">
        <TableBox
          dataSource={warehouseProducts}
          columns={columns}
          pageData={GET_DATA('warehouseProducts.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
