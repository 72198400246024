import { Col, Row } from 'antd'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
// eslint-disable-next-line import/no-named-default
import { default as CRD, default as DBT } from '../../assets/images/credit-notes.png'
import PRO from '../../assets/images/inter-company-invoice.png'
import INV from '../../assets/images/invoice.png'
import INC from '../../assets/images/proforma-invocie.png'
import { EXPENSE_KIND_OPTIONS, INCOME_KIND_OPTIONS } from '../../Util/Options'

const images = {
  Invoice: INV,
  'proforma-invoice': PRO,
  'inter-company': INC,
  'Credit-Note': CRD,
  'Debit-Note': DBT
}

class AddInvoice extends React.Component {
  onRedirect = (path) => {
    this.props.onCancel()
    setTimeout(() => {
      this.props.history.push(path)
    }, 0)
  }

  render() {
    return (
      <Row justify="center">
        <Col span={20}>
          <Row gutter={[22, 22]}>
            {(this.props.type === 'inc' ? INCOME_KIND_OPTIONS : EXPENSE_KIND_OPTIONS).map((val, i) => (
              <Col
                key={i}
                xs={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 8, order: 1 }}>
                <div className="links">
                  <Link
                    onClick={() =>
                      this.onRedirect(
                        this.props.type === 'inc'
                          ? `/app/add-income/${val.value}`
                          : `/app/add-expense/${val.value}`
                      )
                    }>
                    <img src={images[val.value]} alt="" />
                    <h4>{val.label}</h4>
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    )
  }
}

export default withRouter(AddInvoice)
