import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TableBox from '../../../../Components/TableBox/TableBox'
import FilterLayout from '../../../../Layout/FilterLayout'
import TableLayout from '../../../../Layout/TableLayout'
import apiClient from '../../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../../Util/Util'
import SalesCallEntryFilter from './SalesCallEntryFilter'

export default function SalesCallEntries() {
  const [salesCallEntries, setSalesCallEntries] = useState([])
  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('salesCallEntries.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('sales-call-entries', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('salesCallEntries.filterData', { ...params, ...data.pageData })
        setSalesCallEntries(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onDelete = (id) => {
    apiClient.delete(`sales-call-entries/${id}`).then(({ status }) => {
      if (status === 204) {
        setSalesCallEntries(salesCallEntries.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-sales-call-entry') && (
          <li>
            <Link to={`/app/edit-sales-call-entry/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-sales-call-entry') && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Quotation No',
      dataIndex: 'quotationNo'
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName'
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson'
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Sales Person',
      dataIndex: 'salesPerson'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Sales Call Entry',
        onClick: () => history.push('/app/add-sales-call-entry'),
        access: 'add-sales-call-entry'
      }}
      filterData={GET_DATA('salesCallEntries.filterData')}
      filter={<SalesCallEntryFilter onSubmit={getData} />}>
      <TableLayout title="Sales Call Entries Overview" exportUrl="sales-call-entries/export">
        <TableBox
          dataSource={salesCallEntries}
          columns={columns}
          pageData={GET_DATA('salesCallEntries.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
