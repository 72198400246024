import { Col, Row } from 'antd'
import _ from 'lodash'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getLocationsByWarehouse,
  getMaterial,
  getRacksByLocation,
  getWarehouseCodes
} from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'
import apiClient from '../../../Util/apiClient'
import { TRANSACTION_STATUS } from '../../../Util/Options'

export default function TransferForm({
  i,
  itemStatus,
  from,
  to,
  setFieldValue,
  materialCodeAlt,
  materialDescriptionAlt
}) {
  const [materials, setMaterials] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [locations, setLocations] = useState([])
  const [racks, setRacks] = useState([])
  const [stocksData, setStocksData] = useState([])

  const { id } = useParams()

  const getMaterials = (material) => {
    getMaterial({ material }).then((data) => {
      setMaterials(data)
    })
  }

  const getStock = (materialCode) => {
    apiClient.get('stocks/get-by-material', { params: { materialCode } }).then(({ status, data }) => {
      if (status === 200) {
        setStocksData(data)
      }
    })
  }

  const getWarehouses = (warehouse) => {
    getWarehouseCodes({ warehouse }).then((data) => {
      setWarehouses(data)
    })
  }

  const getLocations = (params) => {
    getLocationsByWarehouse(params).then((data) => {
      setLocations(data)
    })
  }

  const getRacks = (params) => {
    getRacksByLocation(params).then((data) => {
      setRacks(data)
    })
  }

  return (
    <Row gutter={[10, 10]}>
      <Col lg={24}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={24} md={8} lg={6}>
            <div className="form-field">
              <Field
                as="auto-complete"
                textArea
                rows={1}
                name={`transactions[${i}].materialCodeDesc`}
                label="Material / Description"
                onSearch={getMaterials}
                onChange={(n, v, o) => {
                  setFieldValue(`transactions[${i}].materialCode`, o?.basic?.materialCode)
                  setFieldValue(`transactions[${i}].materialDescription`, o?.basic?.materialDescription)
                  setFieldValue(`transactions[${i}].materialCodeAlt`, o?.basic?.materialCodeAlt)
                  setFieldValue(`transactions[${i}].materialDescriptionAlt`, o?.basic?.materialDescriptionAlt)
                  setFieldValue(`transactions[${i}].unit`, o?.basic?.unit)
                  getStock(v)

                  return setFieldValue(n, v)
                }}
                options={materials}
                altInput
                altDisabled
                altValue={_.compact([materialCodeAlt, materialDescriptionAlt]).join(' - ')}
                disabled={id}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={3} xl={2}>
            <div className="form-field">
              <Field label="UOM" name={`transactions[${i}].unit`} disabled />
            </div>
          </Col>
          <Col xs={12} sm={24} md={8} lg={4} xl={3}>
            <div className="form-field">
              <Field
                type="number"
                label="Transfer Quantity"
                name={`transactions[${i}].quantity`}
                disabled={id}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={4} xl={3}>
            <div className="form-field">
              <Field
                as="select"
                label="Status"
                name={`transactions[${i}].status`}
                options={TRANSACTION_STATUS}
                disabled={id}
              />
            </div>
          </Col>
          {id && (
            <div className="mt-auto mb-3 pl-3">
              {itemStatus === 'approved' ? (
                <span>
                  ✅<span className="pl-2">{itemStatus}</span>
                </span>
              ) : (
                <span>
                  ❌<span className="pl-2 text-danger">{itemStatus}</span>
                </span>
              )}
            </div>
          )}
        </Row>
      </Col>
      <Col sm={24} lg={12} className="border">
        <b className="text-center d-block">From</b>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={12} lg={6}>
            <div className="form-field">
              <Field
                as="select"
                label="Warehouse"
                name={`transactions[${i}].from.warehouse`}
                options={_.uniqBy(
                  stocksData.map((stock) => ({
                    label: stock.warehouse,
                    value: stock.warehouse
                  })),
                  'value'
                )}
                onChange={(n, v) => {
                  setFieldValue(`transactions[${i}].from.location`, '')
                  setFieldValue(`transactions[${i}].from.rack`, '')

                  return setFieldValue(n, v)
                }}
                disabled={id}
              />
            </div>
          </Col>
          {from?.warehouse && (
            <Col xs={24} sm={12} lg={9}>
              <div className="form-field">
                <Field
                  as="select"
                  label="Location"
                  name={`transactions[${i}].from.location`}
                  options={_.uniqBy(
                    stocksData
                      .filter((stock) => stock.warehouse === from.warehouse && stock.location)
                      .map((stock) => ({
                        label: stock.location,
                        value: stock.location
                      })),
                    'value'
                  )}
                  onChange={(n, v) => {
                    setFieldValue(`transactions[${i}].from.rack`, '')

                    return setFieldValue(n, v)
                  }}
                  disabled={id}
                />
              </div>
            </Col>
          )}
          {from?.warehouse && from?.location && (
            <Col xs={24} sm={12} lg={9}>
              <div className="form-field">
                <Field
                  as="select"
                  label="Rack"
                  name={`transactions[${i}].from.rack`}
                  options={_.uniqBy(
                    stocksData
                      .filter(
                        (stock) =>
                          stock.warehouse === from.warehouse && stock.location === from.location && stock.rack
                      )
                      .map((stock) => ({
                        label: stock.rack,
                        value: stock.rack
                      })),
                    'value'
                  )}
                  disabled={id}
                />
              </div>
            </Col>
          )}
        </Row>
      </Col>
      <Col xs={24} lg={12} className="border">
        <b className="text-center d-block">To</b>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={12} lg={6}>
            <div className="form-field">
              <Field
                as="auto-complete"
                label="Warehouse"
                name={`transactions[${i}].to.warehouse`}
                options={warehouses}
                onSearch={getWarehouses}
                onChange={(n, v) => {
                  setFieldValue(`transactions[${i}].to.location`, '')
                  setFieldValue(`transactions[${i}].to.rack`, '')

                  return setFieldValue(n, v)
                }}
                disabled={id}
              />
            </div>
          </Col>
          {to?.warehouse && (
            <Col xs={24} sm={12} lg={9}>
              <div className="form-field">
                <Field
                  as="auto-complete"
                  key={to?.warehouse}
                  label="Location"
                  name={`transactions[${i}].to.location`}
                  onSearch={(location) =>
                    getLocations({
                      warehouse: to?.warehouse,
                      location
                    })
                  }
                  options={locations}
                  onChange={(n, v) => {
                    setFieldValue(`transactions[${i}].to.rack`, '')

                    return setFieldValue(n, v)
                  }}
                  disabled={id}
                />
              </div>
            </Col>
          )}
          {to?.warehouse && to?.location && (
            <Col xs={24} sm={12} lg={9}>
              <div className="form-field">
                <Field
                  as="auto-complete"
                  key={to?.warehouse + to?.location}
                  label="Rack"
                  name={`transactions[${i}].to.rack`}
                  onSearch={(rack) =>
                    getRacks({
                      rack,
                      warehouse: to?.warehouse,
                      location: to?.location
                    })
                  }
                  options={racks}
                  disabled={id}
                />
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}
