import { FunnelPlotOutlined } from '@ant-design/icons'
import { Col, Collapse, Layout, Menu, Modal, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { deleteInvoice, getCurrencies, getInvoices, updateInvoice } from '../../../Actions/UserAction'
import LoaderBox from '../../../Components/LoaderBox/LoaderBox'
import TableBox from '../../../Components/TableBox/TableBox'
import { EXPENSE_KIND_OPTIONS, INVOICE_STATUS_OPTIONS } from '../../../Util/Options'
import { amountSeparator } from '../../../Util/Util'
import AddInvoice from '../AddInvoice'
import './Expenses.scss'
import Filter from './Filter'

const { Sider, Content } = Layout

const { Panel } = Collapse

export default class ExpenseOverview extends React.Component {
  constructor() {
    super()
    this.state = {
      filterview: 'filter',
      visible: false,
      invoices: [],
      // collapsed: false,
      // sample: '',
      // sample_select: '',
      viewType: 'table',
      sellerObj: {},
      buyerObj: {},
      loader: 'Loading Invoices..',
      currencies: []
    }
  }

  componentDidMount() {
    getCurrencies().then((currencies) => {
      if (currencies) {
        this.setState({ currencies })
      }
    })
    getInvoices({ type: 'inc' }).then((invoices) => {
      if (invoices) {
        this.setState({ invoices, loader: invoices.length > 0 ? false : 'No invoice found' })
      } else {
        this.setState({ invoices, loader: 'No invoice found' })
      }
    })
  }

  // responsive filter starts
  responsiveFilter = () => {
    const x = document.getElementById('mobile-sider-menu')

    if (x.style.display === 'block') {
      x.style.display = 'none'
      this.setState({ filterview: 'filter' })
    } else {
      x.style.display = 'block'
      this.setState({ filterview: 'filter' })
    }
  }
  // responsive filter ends

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false
    })
  }

  onDelete = (id) => {
    deleteInvoice(id).then((data) => {
      if (data) {
        this.setState({ invoices: this.state.invoices.filter((item) => item.id !== id) })
      }
    })
  }

  onUpdate = (id, status) => {
    updateInvoice(id, { status }).then((invoice) => {
      if (invoice) {
        const invoices = this.state.invoices.map((inv) => (inv.id === id ? invoice : inv))
        this.setState({ invoices })
      }
    })
  }

  renderMenu = (row) => (
    <Menu>
      {INVOICE_STATUS_OPTIONS.map((val, ind) => (
        <Menu.Item key={ind} onClick={() => this.onUpdate(row.id, val.value)}>
          <Link>{val.label}</Link>
        </Menu.Item>
      ))}
    </Menu>
  )

  onFilter = (query) => {
    getInvoices(query).then((invoices) => {
      this.setState({ invoices })
    })
  }

  onChangeState = (state) => {
    this.setState(state)
  }

  render() {
    const columns = [
      {
        title: 'Doc No',
        dataIndex: 'invoiceNumber',
        render: (text, record) => <Link to={`/app/expenses/${record.id}`}>{text}</Link>
      },
      {
        title: 'PO / Reference No',
        dataIndex: 'poNumber'
      },
      {
        title: 'Net Price',
        dataIndex: 'gross',
        render: (text, record) => `${amountSeparator(text)} ${record.currency}`
      },
      {
        title: 'Gross Price',
        dataIndex: 'gross',
        render: (text, record) => `${amountSeparator(text)} ${record.currency}`
      },
      {
        title: 'Issued Date',
        dataIndex: 'issueDate',
        render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
      },
      {
        title: 'Tax Date',
        dataIndex: 'taxDate',
        render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
      },
      {
        title: 'Payment Date',
        dataIndex: 'paymentDate',
        render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
      },
      {
        title: 'Vendor',
        dataIndex: 'sellerId',
        render: (text) => this.state.sellerObj[text]
      },
      {
        title: 'Buyer',
        dataIndex: 'buyerId',
        render: (text, record) =>
          record.kind === 'INC' ? this.state.sellerObj[text] : this.state.buyerObj[text]
      },
      {
        title: 'Status',
        dataIndex: 'status'
      }
    ]

    return (
      <>
        <Layout className="expense-sidebar">
          <div className="mobile-filter">
            <button type="button" className="btn btn-glow">
              {this.state.filterview === 'filter' ? (
                <FunnelPlotOutlined onClick={this.responsiveFilter} />
              ) : (
                <FunnelPlotOutlined onClick={this.responsiveFilter} />
              )}
            </button>
          </div>
          <Sider width={230} trigger={null} collapsible collapsed={false} id="mobile-sider-menu">
            <Filter {...this.props} onFilter={this.onFilter} onChangeState={this.onChangeState} />
          </Sider>
          <Layout className="site-layout">
            <Content className="site-layout-background">
              <div className="top-filter-options">
                <Row>
                  <Col
                    xs={{ span: 24, order: 1 }}
                    sm={{ span: 24, order: 1 }}
                    md={{ span: 12, order: 1 }}
                    lg={{ span: 12, order: 1 }}>
                    <h2>Expense / All documents</h2>
                  </Col>

                  <Col
                    xs={{ span: 24, order: 2 }}
                    sm={{ span: 24, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    lg={{ span: 12, order: 2 }}>
                    <div className="exports-and-settings">
                      <ul>
                        {/* <li>
                                                    <div className="btn-group">
                                                        <button className="btn glow dropdown-toggle" onClick={() => this.setState({ viewType: 'table' })}>
                                                            <i className="flaticon-table no-margin" />
                                                        </button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="btn-group">
                                                        <button className="btn glow dropdown-toggle" onClick={() => this.setState({ viewType: 'card' })}>
                                                            <i className="flaticon-grid no-margin" />
                                                        </button>
                                                    </div>
                                                </li> */}
                        {/* <li>
                                                    <div className="btn-group">
                                                        <button className="btn glow dropdown-toggle" onClick={this.showModal} >
                                                            <i className="flaticon-settings no-margin" /><span className="caret"></span>
                                                        </button>
                                                    </div>
                                                </li> */}
                      </ul>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24, order: 3 }}
                    sm={{ span: 24, order: 3 }}
                    md={{ span: 24, order: 3 }}
                    lg={{ span: 0, order: 3 }}>
                    <div className="add-new-invoice-button">
                      <div onClick={this.showModal} className="btn-glow btn-block primary">
                        Add new invoice
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <LoaderBox loader={this.state.loader} />
                {!this.state.loader && (
                  <div style={{ padding: '0 15px' }}>
                    <Collapse defaultActiveKey={[]}>
                      {EXPENSE_KIND_OPTIONS.map((kind, ind) => {
                        const kindInvoices = this.state.invoices.filter((val) => val.kind === kind.value)

                        if (kindInvoices.length > 0) {
                          return (
                            <Panel header={`${kind.label} (${kindInvoices.length})`} key={`kind-${ind}`}>
                              {this.state.currencies.map((cur, ind) => {
                                const currecntInvoices = kindInvoices.filter(
                                  (val) => val.currency === cur.code
                                )

                                if (currecntInvoices.length > 0) {
                                  return (
                                    <Collapse defaultActiveKey={[]}>
                                      <Panel
                                        header={`${cur.name} (${currecntInvoices.length})`}
                                        key={`cur-${ind}`}>
                                        <TableBox
                                          viewType={this.state.viewType}
                                          dataSource={currecntInvoices}
                                          columns={columns}
                                          actionIndex="custom_action"
                                          cardHeaderIndex="status"
                                          cardFirstLabelIndex="docno"
                                        />
                                      </Panel>
                                    </Collapse>
                                  )
                                }

                                return null
                              })}
                            </Panel>
                          )
                        }

                        return null
                      })}
                    </Collapse>
                  </div>
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
        <Modal
          title="Add New Expense"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          width={1000}
          footer={false}>
          <AddInvoice type="inc" onCancel={this.handleCancel} />
        </Modal>
      </>
    )
  }
}
