import { CaretDownOutlined, SettingOutlined } from '@ant-design/icons'
import { Col, Dropdown, Menu, message, Popover, Row, Space, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BlockExplorerBox from '../../../Components/BlockExplorerBox/BlockExplorerBox'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import LoaderBox from '../../../Components/LoaderBox/LoaderBox'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { INVOICE_STATUS_OPTIONS } from '../../../Util/Options'
import { amountSeparator, GET_DATA, SET_DATA } from '../../../Util/Util'
import Logs from '../../Logs/Logs'
import Filter from './Filter'

const { TabPane } = Tabs

export default function OutboundTransmissions(props) {
  const cacheData = GET_DATA('outboundTransmissions')
  const [toggle, setToggle] = useState(false)
  const [toggleType, setToggleType] = useState(false)
  const [loader, setLoader] = useState('Loading Invoices..')
  const [selectedRows, setSelectedRows] = useState(cacheData?.selectedRows || [])
  const [activeTab, setActiveTab] = useState(cacheData?.activeTab || 'Created')
  const [outboundTransmissions, setOutboundTransmissions] = useState([])
  const [blockExplorer, setBlockExplorer] = useState(false)

  useEffect(() => {
    onFilter(GET_DATA('outboundTransmissions.filterData'))
  }, [activeTab])

  const onFilter = (query = {}) => {
    const params = { ...query, transmissionStatus: activeTab }

    apiClient.get('incomes/get-outbound-transmissions', { params }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('outboundTransmissions.filterData', { ...query, ...data.pageData })
        setOutboundTransmissions(data.result)
        setLoader(false)
      } else {
        setLoader('No invoice found')
      }
    })
  }

  const showModal = (data, type) => {
    setToggle(data)
    setToggleType(type)
  }

  const handleCancel = () => {
    setToggle(false)
    setToggleType('type')
  }

  const tableContent = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/outbound-transmissions/${row.id}`}>
            <i className="flaticon-eye" /> View
          </Link>
        </li>

        <li>
          <Link to="#" onClick={() => showModal(row, 'Logs')}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const onTransmit = () => {
    if (selectedRows.length === 1) {
      const [invoice] = selectedRows
      apiClient.put(`incomes/transmit/${invoice.id}`).then(({ data }) => {
        if (data && data.result) {
          message.success('Invoice Transmitted')
          onFilter(GET_DATA('outboundTransmissions.filterData'))
          setSelectedRows([])
          setBlockExplorer(invoice)
        }
      })
    } else {
      message.error('Please select one row')
    }
  }

  const renderMenu = () => (
    <Menu>
      {INVOICE_STATUS_OPTIONS.map((val, ind) => (
        <Menu.Item key={ind}>
          <Link>{val.label}</Link>
        </Menu.Item>
      ))}
    </Menu>
  )

  const delayedDays = (days) => <div>Payment Delayed days - {days}</div>

  const getLinkRecord = (text, record) => {
    if (record.status !== 'Paid' && record.paymentDueDate) {
      const days = moment().diff(moment(record.paymentDueDate), 'days')

      if (days > 0) {
        return (
          <Link to={`/app/outbound-transmissions/${record.id}`}>
            <Popover placement="bottom" content={() => delayedDays(days)} trigger="hover">
              <a style={{ color: 'red' }}>{text}</a>
            </Popover>
          </Link>
        )
      }

      return <Link to={`/app/outbound-transmissions/${record.id}`}>{text}</Link>
    }

    return <Link to={`/app/outbound-transmissions/${record.id}`}>{text}</Link>
  }

  const onChangeTab = (activeTab) => {
    SET_DATA('outboundTransmissions.activeTab', activeTab)
    SET_DATA('outboundTransmissions.filterData', { ...GET_DATA('outboundTransmissions.filterData'), page: 1 })
    setActiveTab(activeTab)
    setSelectedRows([])
  }

  const onChangePage = (pageData) => {
    const cacheData = GET_DATA('outboundTransmissions.filterData')
    onFilter({ ...(cacheData || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Doc No',
      dataIndex: 'invoiceNo',
      render: (text, record) => getLinkRecord(text, record)
    },
    {
      title: 'PO / Reference No',
      dataIndex: 'poNo'
    },
    {
      title: 'Net Price',
      dataIndex: 'netAmount',
      render: (text, record) => `${amountSeparator(text)} ${record.currency}`
    },
    {
      title: 'Gross Price',
      dataIndex: 'grossAmount',
      render: (text, record) => `${amountSeparator(text)} ${record.currency}`
    },
    {
      title: 'Issued Date',
      dataIndex: 'issueDate',
      render: (text) => (text ? moment(text).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Tax Date',
      dataIndex: 'taxDate',
      render: (text) => (text ? moment(text).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      render: (text) => (text ? moment(text).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Customer',
      dataIndex: 'client',
      render: (text, record) => (record.clientData ? record.clientData.name : '')
    },
    {
      title: 'Approval Status',
      dataIndex: 'workflowStatus'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, row) =>
        activeTab === 'Approved' ? (
          <Space size="middle">
            <Dropdown arrow overlay={() => renderMenu(row)} trigger={['click']}>
              <a
                className={`status badge ${text.toLowerCase()} ant-dropdown-link status`}
                onClick={(e) => e.preventDefault()}>
                {text === 'Inprogress' ? 'In Progress' : text} <CaretDownOutlined />
              </a>
            </Dropdown>
          </Space>
        ) : (
          text
        )
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableContent(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout filter={<Filter {...props} onFilter={onFilter} cacheName="outboundTransmissions" />}>
      <div className="top-filter-options">
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <h2>Income / All documents</h2>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            {selectedRows.length === 1 && (
              <div style={{ float: 'right' }}>
                {activeTab === 'Created' && <ButtonBox onClick={() => onTransmit()}>Transmit</ButtonBox>}
              </div>
            )}
          </Col>
          <Col
            xs={{ span: 24, order: 3 }}
            sm={{ span: 24, order: 3 }}
            md={{ span: 24, order: 3 }}
            lg={{ span: 0, order: 3 }}>
            <div className="add-new-invoice-button">
              <div onClick={() => showModal(true, 'Add')} className="btn-glow btn-block primary">
                Add new invoice
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="income-container">
        <Tabs defaultActiveKey={activeTab} onChange={onChangeTab}>
          <TabPane tab="Created" key="Created" />
          <TabPane tab="Sent" key="Sent" />
          <TabPane tab="Approved" key="Approved" />
          <TabPane tab="Rejected" key="Rejected" />
        </Tabs>
        {!loader && (
          <TableBox
            dataSource={outboundTransmissions}
            columns={columns}
            onSelect={(selected) => {
              SET_DATA('outboundTransmissions.selectedRows', selected)
              setSelectedRows(selected)
            }}
            selectedRows={selectedRows}
            pageData={GET_DATA('outboundTransmissions.filterData')}
            onChangePage={onChangePage}
          />
        )}
        <LoaderBox loader={loader} />
      </div>
      <BlockExplorerBox layout visible={blockExplorer} onCancel={() => setBlockExplorer(false)} />

      <ModalBox title={toggleType} visible={!!toggle} onCancel={handleCancel} destroyOnClose footer={false}>
        {toggleType === 'Logs' && <Logs entityId={toggle.id} entityType="IncomeInvoice" />}
      </ModalBox>
    </FilterLayout>
  )
}
