import { SettingOutlined } from '@ant-design/icons'
import { message, Popconfirm, Popover, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../../Components/Button'
import LoaderBox from '../../../../Components/LoaderBox/LoaderBox'
import ModalBox from '../../../../Components/ModalBox/ModalBox'
import TableBox from '../../../../Components/TableBox/TableBox'
import FilterLayout from '../../../../Layout/FilterLayout'
import TableLayout from '../../../../Layout/TableLayout'
import apiClient from '../../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../../Util/Util'
import Logs from '../../../Logs/Logs'
import OfferFilter from './OfferFilter'

const { TabPane } = Tabs

export default function Offers() {
  const [offers, setOffers] = useState([])
  const [tabKey, setTabKey] = useState(GET_DATA('offers.tabKey') || 'Created')
  const [selectedRows, setSelectedRows] = useState([])
  const [loader, setLoader] = useState(false)
  const [viewLogs, setViewLogs] = useState(false)

  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('offers.filterData') || {}
    params = { ...filterCache, ...params }

    if (tabKey !== 'All') {
      params.status = tabKey
    } else {
      params.status = undefined
    }

    apiClient.get('offers', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('offers.filterData', { ...params, ...data.pageData })
        setOffers(data.result)
        setLoader(data.result.length > 0 ? false : 'No data found')
      }
    })
  }

  useEffect(() => {
    getData()
    SET_DATA('offers.tabKey', tabKey)
  }, [tabKey])

  const onDelete = (id) => {
    apiClient.delete(`offers/${id}`).then(({ status }) => {
      if (status === 204) {
        setOffers(offers.filter((item) => item.id !== id))
      }
    })
  }

  const onSubmit = (offersIds = selectedRows.map((val) => val.id)) => {
    apiClient.post('offers/submit', { offersIds }).then(({ status }) => {
      if (status === 200) {
        message.success('Offers Sent')
        setOffers(offers.filter((item) => !offersIds.includes(item.id)))
        setSelectedRows([])
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-offer') && (
          <li>
            <Link to={`/app/edit-offer/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-offer') && row.status === 'Created' && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
        {validateAccess('edit-offer') && row.status === 'Created' && (
          <li>
            <Link to="#" onClick={() => onSubmit([row.id])}>
              <i className="flaticon-tick-1" /> Submit
            </Link>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => setViewLogs(row)}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Offer No',
      dataIndex: 'offerNo',
      render: (v, r) => (
        <div onClick={() => history.push(`/app/offers/${r.id}`)}>
          <a>{v}</a>
        </div>
      )
    },
    {
      title: 'Offer Type',
      dataIndex: 'offerType'
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName'
    },
    {
      title: 'Mail ID',
      dataIndex: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Offer',
        onClick: () => history.push('/app/add-offer'),
        access: 'add-offer'
      }}
      filterData={GET_DATA('offers.filterData')}
      filter={<OfferFilter onSubmit={getData} />}>
      <ModalBox
        title="Offer Logs"
        visible={!!viewLogs}
        onCancel={() => setViewLogs(false)}
        footer={false}
        destroyOnClose>
        <Logs entityId={viewLogs?.id} entityType="Offer" />
      </ModalBox>
      <TableLayout
        title="Offers Overview"
        exportUrl="offers/export"
        detailed
        rightSection={
          selectedRows.length > 0 && (
            <>
              {tabKey === 'Created' && (
                <Button variant="primary" onClick={() => onSubmit()}>
                  Send for Approval
                </Button>
              )}
              {selectedRows.length === 1 && tabKey === 'Accepted' && (
                <Button
                  variant="primary"
                  onClick={() => history.push(`/app/add-booking/${selectedRows[0].id}`)}>
                  Create Booking
                </Button>
              )}
            </>
          )
        }>
        <Tabs activeKey={tabKey} onChange={setTabKey}>
          <TabPane tab="Created" key="Created" />
          <TabPane tab="Sent" key="Sent" />
          <TabPane tab="Accepted" key="Accepted" />
          <TabPane tab="Rejected" key="Rejected" />
          <TabPane tab="Booked" key="Booked" />
          <TabPane tab="All" key="All" />
        </Tabs>
        {!loader && (
          <TableBox
            dataSource={offers}
            columns={columns}
            onSelect={setSelectedRows}
            selectedRows={selectedRows}
            pageData={GET_DATA('offers.filterData')}
            onChangePage={getData}
          />
        )}
        <LoaderBox loader={loader} />
      </TableLayout>
    </FilterLayout>
  )
}
