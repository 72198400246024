import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, parseAmount, SET_DATA } from '../../../Util/Util'
import FinancePostingFilter from './FinancePostingFilter'

export default function FinancePostings(props) {
  const [financePostings, setFinancePostings] = useState([])

  const getData = (params) => {
    const filterCache = GET_DATA('financePostings.filterData') || {}
    params = { ...filterCache, ...params }

    Object.keys(_.pick(params, ['transDateFrom', 'transDateTo', 'postingDateFrom', 'postingDateTo'])).forEach(
      (key) => {
        if (params[key]) {
          params[key] = moment(params[key]).utc().startOf('day').toISOString()
        }
      }
    )

    apiClient.get('finance-postings', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('financePostings.filterData', { ...params, ...data.pageData })
        setFinancePostings(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Division',
      dataIndex: 'divisionData',
      render: (text) => text?.name || '',
      dontShow: props.companyInfo?.configurations?.division !== 'Yes'
    },
    {
      title: 'Tran Key',
      dataIndex: 'transactionKey'
    },
    {
      title: 'Posting Date',
      dataIndex: 'postingDate',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: 'Tran Date',
      dataIndex: 'transactionDate',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: 'Tran Type',
      dataIndex: 'transactionCode'
    },
    {
      title: 'Tran number',
      dataIndex: 'transactionNo'
    },
    {
      title: 'Tran line',
      dataIndex: 'transactionPosition'
    },
    {
      title: 'Parent Doc',
      dataIndex: 'parentDoc'
    },
    {
      title: 'Parent Line',
      dataIndex: 'parentDocPosition'
    },
    {
      title: 'Tran Amount',
      dataIndex: 'transactionAmount',
      align: 'right',
      render: (v, r) => `${parseAmount(v, r.transactionCurrency)}`
    },
    {
      title: 'Tran Currency',
      dataIndex: 'transactionCurrency'
    },
    {
      title: 'Account Code',
      dataIndex: 'accountCode'
    },
    {
      title: 'Account name',
      dataIndex: 'accountName'
    },
    {
      title: 'CR_DR',
      dataIndex: 'transactionType'
    },
    {
      title: 'Exch Rate',
      align: 'right',
      dataIndex: 'exchangeRate'
    },
    {
      title: 'Base Currency',
      dataIndex: 'baseCurrency'
    },
    {
      title: 'Base Amount',
      dataIndex: 'baseAmount',
      align: 'right',
      render: (v, r) => `${parseAmount(v, r.baseCurrency)}`
    },
    {
      title: 'Close Flag',
      dataIndex: 'closeFlag'
    }
  ]

  return (
    <FilterLayout
      filterData={GET_DATA('financePostings.filterData')}
      filter={<FinancePostingFilter {...props} onSubmit={getData} />}>
      <TableLayout title="Financial Postings Overview" exportUrl="finance-postings/export">
        <TableBox
          dataSource={financePostings}
          columns={columns}
          pageData={GET_DATA('financePostings.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
