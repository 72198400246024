import { Col, Row, Select } from 'antd'
import _ from 'lodash'
import React, { memo } from 'react'
import Field from '../../../Components/Formik/Field'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import { getOrderNames, TAX_TYPES } from '../../../Util/Options'
import { parseAmount } from '../../../Util/Util'

const { Option } = Select

function SingleInvoice({
  materialCode,
  materialDescription,
  materialCodeAlt,
  materialDescriptionAlt,
  orderedQuantity,
  orderInvoiceQuantity,
  toBeInvoicedQuantity,
  price,
  amount,
  tax,
  tempTax,
  taxType,
  netAmount,
  discountType,
  discount,
  invoiceQuantity,
  chargeType,
  charge,
  discountValue,
  chargeValue,
  type,
  disabled,
  orderNetAmount,
  toBeInvoicedAmount,
  taxAmount,
  i,
  taxCategory,
  currency,
  setFieldValue
}) {
  const { typeName, so } = getOrderNames(type)

  useDidUpdateEffect(() => {
    if (invoiceQuantity && price) {
      setFieldValue(`orderDetails[${i}].amount`, parseInt(invoiceQuantity || 1, 10) * parseFloat(price || 1))
    } else {
      setFieldValue(`orderDetails[${i}].amount`, 0)
    }
  }, [invoiceQuantity, price])

  useDidUpdateEffect(() => {
    let netAmount = 0
    let discountValue = 0
    let chargeValue = 0

    if (amount) {
      netAmount = parseFloat(amount || 0)

      const ratio = orderedQuantity / invoiceQuantity

      switch (discountType) {
        case '%':
          discountValue = (netAmount * parseFloat(discount || 0)) / 100
          break
        default:
          discountValue = parseFloat(discount || 0) / ratio
          break
      }

      switch (chargeType) {
        case '%':
          chargeValue = (netAmount * parseFloat(charge || 0)) / 100
          break
        default:
          chargeValue = parseFloat(charge || 0) / ratio
          break
      }
    }

    setFieldValue(`orderDetails[${i}].discountValue`, discountValue)
    setFieldValue(`orderDetails[${i}].chargeValue`, chargeValue)
    setFieldValue(`orderDetails[${i}].netAmount`, netAmount - discountValue + chargeValue)
    setFieldValue(
      `orderDetails[${i}].toBeInvoicedAmount`,
      toBeInvoicedQuantity
        ? toBeInvoicedQuantity * parseFloat(price || 1) - discountValue + chargeValue || ''
        : 0
    )
  }, [amount, chargeType, charge, discountType, discount])

  useDidUpdateEffect(() => {
    setFieldValue(`orderDetails[${i}].taxAmount`, (parseFloat(netAmount) * parseFloat(tax || 0)) / 100)
  }, [netAmount, tax])

  useDidUpdateEffect(() => {
    setFieldValue(`orderDetails[${i}].disabled`, disabled)
  }, [disabled])

  useDidUpdateEffect(() => {
    if (taxType === 'Normal VAT') {
      setFieldValue(`orderDetails[${i}].tax`, tempTax)
    } else {
      setFieldValue(`orderDetails[${i}].tax`, '')
    }
  }, [taxType])

  const selectAfter = (n, v) => (
    <Select
      disabled={disabled}
      onSelect={(val) => setFieldValue(`orderDetails[${i}].${n}`, val)}
      defaultValue={v || ''}
      value={v || ''}
      className="select-after"
      showArrow={false}>
      <Option value="%">%</Option>
      <Option value="">№</Option>
    </Select>
  )

  return (
    <>
      <Row gutter={[10, 5]}>
        <Col xs={12} sm={12} md={8} lg={4} xl={2}>
          <div className="form-field">
            <Field name={`orderDetails[${i}].orderNo`} label={`${typeName} order`} disabled />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={2} xl={2}>
          <div className="form-field">
            <Field name={`orderDetails[${i}].position`} label="Position" disabled />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={5} xl={4}>
          <div className="form-field">
            <Field
              as="textarea"
              rows={1}
              name={`orderDetails[${i}].deliveryDetails`}
              label="Delivery Details"
              disabled
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={taxType === 'Normal VAT' ? 4 : 6}>
          <div className="form-field">
            <Field
              as="textarea"
              rows={1}
              name={`orderDetails[${i}].materialCodeDesc`}
              label="Material / Description"
              value={_.compact([materialCode, materialDescription]).join(' - ')}
              disabled
              altInput
              altDisabled
              altValue={_.compact([materialCodeAlt, materialDescriptionAlt]).join(' - ')}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={4} xl={2}>
          <div className="form-field">
            <Field
              type="number"
              name={`orderDetails[${i}].invoiceQuantity`}
              label="Invoice Quantity"
              onChange={(n, v) => {
                setFieldValue(
                  `orderDetails[${i}].toBeInvoicedQuantity`,
                  parseInt(orderInvoiceQuantity ?? orderedQuantity, 10) - parseInt(v || 0, 10)
                )

                return setFieldValue(n, v)
              }}
              disabled={disabled}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={3} xl={2}>
          <div className="form-field">
            <Field type="number" name={`orderDetails[${i}].price`} label="Price" disabled={disabled} />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={3} xl={2}>
          <div className="form-field">
            <Field
              type="number"
              name={`orderDetails[${i}].discount`}
              label="Discount"
              disabled={disabled}
              addonAfter={selectAfter('discountType', discountType)}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={3} xl={2}>
          <div className="form-field">
            <Field
              type="number"
              name={`orderDetails[${i}].charge`}
              label="Charge"
              disabled={disabled}
              addonAfter={selectAfter('chargeType', chargeType)}
            />
          </div>
        </Col>
        {taxType === 'Normal VAT' && (
          <Col xs={12} sm={12} md={8} lg={2}>
            <div className="form-field">
              <Field name={`orderDetails[${i}].tax`} label="Tax rate" disabled={disabled} />
            </div>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={3} xl={2}>
          <div className="form-field">
            <Field
              as="select"
              name={`orderDetails[${i}].taxType`}
              label="Tax Types"
              options={TAX_TYPES.filter((item) => item.categories.includes(taxCategory))}
              disabled={disabled}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={12}>
          <div className="d-flex">
            <div className="line-item-footer-content">
              <b>{`${so} Qty`} :</b> <span className="text-nowrap">{orderedQuantity}</span>
            </div>
            <div className="line-item-footer-content">
              <b>{`${so} Net Amount`} :</b>{' '}
              <span className="text-nowrap">{parseAmount(orderNetAmount, currency)}</span>
            </div>
            <div className="line-item-footer-content">
              <b>To be Inv. Qty :</b> <span className="text-nowrap">{toBeInvoicedQuantity}</span>
            </div>
            <div className="line-item-footer-content">
              <b>To be Inv. Amount :</b>{' '}
              <span className="text-nowrap">{parseAmount(toBeInvoicedAmount, currency)}</span>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="line-item-footer">
            <div className="line-item-footer-content">
              <b>Amount:</b> <span className="text-nowrap">{parseAmount(amount, currency)} -</span>
            </div>
            <div className="line-item-footer-content">
              <b>Discount :</b> <span className="text-nowrap">{parseAmount(discountValue, currency)} +</span>
            </div>
            <div className="line-item-footer-content">
              <b>Charge :</b> <span className="text-nowrap">{parseAmount(chargeValue, currency)} =</span>
            </div>
            <div className="line-item-footer-content">
              <b>Net :</b> <span className="text-nowrap">{parseAmount(netAmount, currency)} +</span>
            </div>
            <div className="line-item-footer-content">
              <b>Tax :</b> <span className="text-nowrap">{parseAmount(taxAmount, currency)} =</span>
            </div>
            <div className="line-item-footer-content">
              <b>Gross :</b>{' '}
              <span className="text-nowrap">{parseAmount(netAmount + taxAmount, currency)}</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default memo(SingleInvoice)
