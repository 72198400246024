import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getOptionsByType } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { getOrderNames } from '../../../Util/Options'

function OrderFilter({ values, setValues, filterData }) {
  const [orderTypes, setOrderTypes] = useState([])
  const { type } = useParams()
  const { typeName, clientName } = getOrderNames(type)

  const getData = () => {
    getOptionsByType({
      type: `${typeName}OrderType`
    }).then((data) => {
      setOrderTypes(data[`${typeName}OrderType`] || [])
    })
  }

  useEffect(() => {
    setValues({ ...values, ...filterData })
    getData()
  }, [type])

  return (
    <Form>
      <div className="form-fields">
        <Field name="orderNo" label="Order No" />
      </div>
      <div className="form-fields">
        <Field name="clientName" label={`${clientName} Name`} />
      </div>
      <div className="form-fields">
        <Field as="select" name="orderType" label="Order Type" options={orderTypes} />
      </div>
      <div className="form-fields">
        <Field as="date" name="orderDate" label="Order Date" />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" />
          Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    orderNo: '',
    clientName: '',
    orderType: '',
    orderDate: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(OrderFilter)
