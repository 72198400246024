import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Form, Popconfirm } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA } from '../../../Util/Util'
import { financesSchema } from '../../../Util/validationSchema'
import FinanceFilter from './FinanceFilter'

function Finances({ values, submitForm, validateForm, setValues, resetForm }) {
  const [editingKey, setEditingKey] = useState('')
  const [finances, setFinances] = useState([])
  const isEditing = (record) => record.id === editingKey

  const getData = (params) => {
    const filterCache = GET_DATA('finances.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('finances', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('finances.filterData', { ...params, ...data.pageData })
        setFinances(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const edit = (record) => {
    setValues({ ...values, ...record })
    setEditingKey(record.id)
  }

  const save = async (id) => {
    await submitForm()
    validateForm().then((err) => {
      if (_.isEmpty(err)) {
        if (id) {
          apiClient.put(`finances/${id}`, _.pick(values, 'accountCode')).then(({ status }) => {
            if (status === 200) {
              getData()
              resetForm()
              setEditingKey('')
            }
          })
        }
      }
    })
  }

  const columns = [
    {
      title: 'Transaction Code',
      dataIndex: 'transactionCode'
    },
    {
      title: 'Transaction Name',
      dataIndex: 'transactionName'
    },
    {
      title: 'Transaction Description',
      dataIndex: 'transactionDescription'
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName'
    },
    {
      title: 'Account code',
      dataIndex: 'accountCode',
      editable: true
    },
    {
      title: 'CR/DR?',
      dataIndex: 'transactionType'
    },
    {
      title: 'Sign',
      dataIndex: 'sign'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'right',
      render: (text, record) => {
        const editable = isEditing(record)

        return editable ? (
          <div className="d-flex">
            <Button onClick={() => save(record.id)} className="btn-glow ml-auto mr-2">
              <CheckOutlined />
            </Button>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => {
                setEditingKey('')
                resetForm()
              }}>
              <Button className="btn-glow">
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <Button disabled={editingKey} onClick={() => edit(record)} className="btn-glow">
            <EditOutlined />
          </Button>
        )
      }
    }
  ].map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      render: (val, record, i) =>
        isEditing(record) ? (
          <Field key={i} name={col.dataIndex} label={col.title} {..._.pick(col, ['as', 'type'])} hideLabel />
        ) : (
          col.render?.(val, record, i) || val
        )
    }
  })

  return (
    <FilterLayout filterData={GET_DATA('finances.filterData')} filter={<FinanceFilter onSubmit={getData} />}>
      <TableLayout title="Manage Transaction Account" exportUrl="finances/export">
        <Form>
          <TableBox
            dataSource={finances}
            columns={columns}
            pageData={GET_DATA('finances.filterData')}
            onChangePage={getData}
          />
        </Form>
      </TableLayout>
    </FilterLayout>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    accountCode: ''
  }),
  validationSchema: financesSchema,
  handleSubmit: () => null
})(Finances)
