import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  getPersonalDetailsByEmployeeID,
  updatePersonalDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'

// TODO: Add options to choose from for combo boxes while editing

const Schema = Yup.object().shape({
  firstName: Yup.string().required(),
  middleName: Yup.string(),
  lastName: Yup.string().required(),
  bloodGroup: Yup.string(),
  passportNum: Yup.string().required(),
  passportValidUpto: Yup.date().required(),
  drivingLicenseNum: Yup.string().required(),
  licenseExpiryDate: Yup.date().required(),
  dateOfBirth: Yup.date().required(),
  nationality: Yup.string().required(),
  martialStatus: Yup.string().required(),
  gender: Yup.string().required(),
  panCardNum: Yup.string().required()
})

const PersonalDetails = (props) => {
  const { currentEmployee, values, setValues, submitForm, personalDetails, dispatch, errors } = props
  const [editable, setEditable] = useState(false)
  const [options, setOptions] = useState({})

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getPersonalDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const fetchDropdownValues = () => {
    apiClient
      .get('options/get-by-types', {
        params: { type: ['BloodGroup', 'Nationality', 'MaritalStatus', 'Gender'] }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })
  }

  const onEdit = () => {
    if (currentEmployee?.id) {
      fetchDropdownValues()
      setEditable(true)
      setValues({
        ...values,
        firstName: personalDetails?.firstName,
        middleName: personalDetails?.middleName,
        lastName: personalDetails?.lastName,
        bloodGroup: personalDetails?.bloodGroup,
        passportNum: personalDetails?.passportNum,
        passportValidUpto: moment(personalDetails?.passportValidUpto).format('yyyy-MM-DD'),
        drivingLicenseNum: personalDetails?.drivingLicenseNum,
        licenseExpiryDate: moment(personalDetails?.licenseExpiryDate).format('yyyy-MM-DD'),
        dateOfBirth: moment(personalDetails?.dateOfBirth).format('yyyy-MM-DD'),
        nationality: personalDetails?.nationality,
        martialStatus: personalDetails?.martialStatus,
        gender: personalDetails?.gender,
        panCardNum: personalDetails?.panCardNum
      })
    } else {
      message.error('Please select and employee to edit')
    }
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setEditable(false)
    }
  }

  return (
    <Form>
      <PanelLayout className="mb-3">
        <Panel title="Personal Details">
          {!editable && (
            <div className="panel-with-border">
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>First Name</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.firstName : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Middle Name</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.middleName : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Last name</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.lastName : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Blood Group</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.bloodGroup : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Passport Number</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.passportNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Passport Valid upto</span>
                  <p>
                    {!isEmpty(personalDetails)
                      ? moment(personalDetails?.passportValidUpto).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Driver&apos;s License Number</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.drivingLicenseNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>License Expiry Date</span>
                  <p>
                    {!isEmpty(personalDetails)
                      ? moment(personalDetails?.licenseExpiryDate).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Date of Birth</span>
                  <p>
                    {!isEmpty(personalDetails)
                      ? moment(personalDetails?.dateOfBirth).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Nationality</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.nationality : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Maritial Status</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.martialStatus : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Gender</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.gender : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                  <span>Pan Card</span>
                  <p>{!isEmpty(personalDetails) ? personalDetails?.panCardNum : 'N/A'}</p>
                </Col>
              </Row>
            </div>
          )}
          {editable && (
            <Row justify="left" gutter={(12, 10)}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="firstName" label="First Name" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="middleName" label="Middle Name" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="lastName" label="Last Name" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field
                    name="bloodGroup"
                    label="Blood Group"
                    as="select"
                    options={options.BloodGroup || []}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="passportNum" label="Passport Number" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="passportValidUpto" label="Passport Valid upto" as="date" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="drivingLicenseNum" label="Driver's License Number" type="text" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="licenseExpiryDate" label="License Expiry Date" as="date" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="dateOfBirth" label="Date of Birth" as="date" />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field
                    name="nationality"
                    label="Nationality"
                    as="select"
                    options={options.Nationality || []}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field
                    name="martialStatus"
                    label="Maritial Status"
                    as="select"
                    options={options.MaritalStatus || []}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="gender" label="Gender" as="select" options={options.Gender || []} />
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
                <div className="form-field">
                  <Field name="panCardNum" label="Pan Card" type="text" />
                </div>
              </Col>
            </Row>
          )}
        </Panel>
      </PanelLayout>
      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
        centerActions={[
          {
            prefix: 'flaticon-play',
            label: 'Save',
            dontShow: !editable,
            onClick: onSave
          }
        ]}
        rightActions={[
          {
            prefix: 'flaticon-edit-1',
            label: 'Edit',
            dontShow: editable,
            onClick: onEdit
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  personalDetails: state.employee.employeeDetails.personalDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      firstName: '',
      middleName: '',
      lastName: '',
      bloodGroup: '',
      passportNum: '',
      passportValidUpto: '',
      drivingLicenseNum: '',
      licenseExpiryDate: '',
      dateOfBirth: '',
      nationality: '',
      martialStatus: '',
      gender: '',
      panCardNum: ''
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      dispatch(updatePersonalDetailsByEmployeeID(currentEmployee.id, values))
    },
    validationSchema: Schema
  })(PersonalDetails)
)
