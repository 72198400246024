import { Col, Row } from 'antd'
import React, { memo, useState } from 'react'
import Field from '../../../Components/Formik/Field'
import apiClient from '../../../Util/apiClient'

function JournalAccount({ i, type, setFieldValue, disabled }) {
  const [accounts, setAccounts] = useState([])

  const getAccounts = (accountCode) => {
    apiClient.get('finances/get-accounts', { params: { accountCode } }).then(({ status, data }) => {
      if (status === 200) {
        setAccounts(
          data.map((item) => ({
            value: item.accountCode,
            label: item.accountCode,
            ...item
          }))
        )
      }
    })
  }

  return (
    <Col xs={24}>
      <Row gutter={[10, 5]}>
        <Col xs={24} sm={24} md={12} lg={4}>
          <div className="form-field">
            <Field name={`accountList[${i}][${type}].position`} label="Position" disabled={disabled} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4}>
          <div className="form-field">
            <Field
              as="auto-complete"
              name={`accountList[${i}][${type}].code`}
              label="Account no."
              onSearch={getAccounts}
              onChange={(n, v, o) => {
                setFieldValue(`accountList[${i}][${type}].name`, o?.accountName)

                return setFieldValue(n, v)
              }}
              options={accounts}
              disabled={disabled}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4}>
          <div className="form-field">
            <Field name={`accountList[${i}][${type}].name`} label="Account Desc" disabled />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4}>
          <div className="form-field">
            <Field name={`accountList[${i}][${type}].type`} label="Debit/Credit" disabled />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={4}>
          <div className="form-field">
            <Field
              type="number"
              name={`accountList[${i}][${type}].amount`}
              label="Amount"
              disabled={disabled}
            />
          </div>
        </Col>
      </Row>
    </Col>
  )
}

function SingleVoucher(accountProps) {
  return (
    <Row>
      <JournalAccount {...accountProps} type="cr" />
      <JournalAccount {...accountProps} type="dr" />
    </Row>
  )
}

export default memo(SingleVoucher)
