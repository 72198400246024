import { FunnelPlotOutlined } from '@ant-design/icons'
import { Col, Layout, Modal, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { getIncomeItems } from '../../../Actions/InvoiceAction'
import LoaderBox from '../../../Components/LoaderBox/LoaderBox'
import TableBox from '../../../Components/TableBox/TableBox'
import { amountSeparator } from '../../../Util/Util'
import AddInvoice from '../AddInvoice'
import Filter from './Filter'
import './Incomes.scss'

const { Sider, Content } = Layout

const expandedRowRender = (row) => {
  const columns = [
    { title: 'Product', dataIndex: 'product' },
    { title: 'Quantity', dataIndex: 'quantity' }
  ]

  return <TableBox columns={columns} dataSource={row.items || []} />
}

export default class IncomeDetailed extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      invoices: [],
      loader: 'Loading Invoices..',
      filterview: 'filter'
    }
  }

  componentDidMount() {
    getIncomeItems().then((invoices) => {
      if (invoices) {
        this.setState({
          invoices,
          loader: invoices.length > 0 ? false : 'No invoice found'
        })
      } else {
        this.setState({ invoices, loader: 'No invoice found' })
      }
    })
  }

  // responsive filter starts

  responsiveFilter = () => {
    const x = document.getElementById('mobile-sider-menu')

    if (x.style.display === 'block') {
      x.style.display = 'none'
      this.setState({ filterview: 'filter' })
    } else {
      x.style.display = 'block'
      this.setState({ filterview: 'filter' })
    }
  }
  // responsive filter ends

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false
    })
  }

  onFilter = (query) => {
    getIncomeItems(query).then((invoices) => {
      if (invoices) {
        this.setState({ invoices })
      }
    })
  }

  onChangeState = (state) => {
    this.setState(state)
  }

  render() {
    const columns = [
      {
        title: 'Doc No',
        dataIndex: 'invoiceNumber',
        render: (text, record) => <Link to={`/app/incomes/${record.id}`}>{text}</Link>
      },
      {
        title: 'Net',
        dataIndex: 'productNet',
        render: (text, record) => `${amountSeparator(text)} ${record.currency}`
      },
      {
        title: 'Gross',
        dataIndex: 'productGross',
        render: (text, record) => `${amountSeparator(text)} ${record.currency}`
      },
      {
        title: 'PO / Reference No',
        dataIndex: 'poNumber'
      },
      {
        title: 'Issued Date',
        dataIndex: 'issueDate',
        render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
      },
      {
        title: 'Tax Date',
        dataIndex: 'taxDate',
        render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
      },
      {
        title: 'Payment Date',
        dataIndex: 'paymentDate',
        render: (text) => (text && text !== '0000-00-00' ? moment(text).format('YYYY-MM-DD') : '')
      },
      {
        title: 'Customer',
        dataIndex: 'client',
        render: (text, record) => (record.clientData ? record.clientData.name : '')
      },
      {
        title: 'Status',
        dataIndex: 'status'
      }
    ]

    return (
      <>
        <Layout className="income-sidebar">
          <div className="mobile-filter">
            <button type="button" className="btn btn-glow">
              {this.state.filterview === 'filter' ? (
                <FunnelPlotOutlined onClick={this.responsiveFilter} />
              ) : (
                <FunnelPlotOutlined onClick={this.responsiveFilter} />
              )}
            </button>
          </div>
          <Sider width={230} trigger={null} collapsible collapsed={false} id="mobile-sider-menu">
            <Filter {...this.props} onFilter={this.onFilter} onChangeState={this.onChangeState} />
          </Sider>
          <Layout className="site-layout">
            <Content className="site-layout-background">
              <div className="top-filter-options">
                <Row>
                  <Col
                    xs={{ span: 24, order: 1 }}
                    sm={{ span: 24, order: 1 }}
                    md={{ span: 12, order: 1 }}
                    lg={{ span: 12, order: 1 }}>
                    <h2>Income / All documents</h2>
                  </Col>

                  <Col
                    xs={{ span: 24, order: 2 }}
                    sm={{ span: 24, order: 2 }}
                    md={{ span: 12, order: 2 }}
                    lg={{ span: 12, order: 2 }}>
                    <div className="exports-and-settings" />
                  </Col>
                  <Col
                    xs={{ span: 24, order: 3 }}
                    sm={{ span: 24, order: 3 }}
                    md={{ span: 24, order: 3 }}
                    lg={{ span: 0, order: 3 }}>
                    <div className="add-new-invoice-button">
                      <div onClick={this.showModal} className="btn-glow btn-block primary">
                        Add new invoice
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <LoaderBox loader={this.state.loader} />
                {!this.state.loader && (
                  <TableBox
                    dataSource={this.state.invoices}
                    columns={columns}
                    expandable={{ expandedRowRender }}
                  />
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
        <Modal
          title="Add New Income"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          width={1000}
          footer={false}>
          <AddInvoice type="inc" onCancel={this.handleCancel} />
        </Modal>
      </>
    )
  }
}
