import { removeEmptyKeys } from '../../Util/Util'

export const updateStyle = (style = {}) => {
  const newStyle = { ...removeEmptyKeys(style, ['border', 'borderLeft']) }

  if (newStyle.border && style.border !== 0) {
    newStyle.border = `${style.border}px solid ${style.borderColor}`
  }

  if (newStyle.borderLeft) {
    newStyle.borderLeft = `${newStyle.borderLeft}px solid ${style.borderColor}`
  }

  if (newStyle.borderRight) {
    newStyle.borderRight = `${newStyle.borderRight}px solid ${style.borderColor}`
  }

  if (newStyle.borderTop) {
    newStyle.borderTop = `${newStyle.borderTop}px solid ${style.borderColor}`
  }

  if (newStyle.borderBottom) {
    newStyle.borderBottom = `${newStyle.borderBottom}px solid ${style.borderColor}`
  }

  delete newStyle.height

  return newStyle
}

export const employeeProperties = [
  {
    name: 'Employee No',
    type: 'TextBox',
    value: '${employeeData.employeeNo}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Name',
    type: 'TextBox',
    value: '${employeeData.name}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Email',
    type: 'TextBox',
    value: '${employeeData.email}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Phone',
    type: 'TextBox',
    value: '${employeeData.phone}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee DOB',
    type: 'TextBox',
    value: '${employeeData.dob}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Social Id',
    type: 'TextBox',
    value: '${employeeData.socialId}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Street',
    type: 'TextBox',
    value: '${employeeData.street}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee City',
    type: 'TextBox',
    value: '${employeeData.city}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee State',
    type: 'TextBox',
    value: '${employeeData.stateName}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Country',
    type: 'TextBox',
    value: '${employeeData.countryName}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Postalcode',
    type: 'TextBox',
    value: '${employeeData.postalCode}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee BankName',
    type: 'TextBox',
    value: '${employeeData.bankName}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Bank Acc No',
    type: 'TextBox',
    value: '${employeeData.bankAccountNo}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee BankSwift',
    type: 'TextBox',
    value: '${employeeData.bankSwift}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  },
  {
    name: 'Employee Currency',
    type: 'TextBox',
    value: '${employeeData.currency}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Employee Properties'
  }
]

export const companyProperties = [
  {
    name: 'Company Name',
    type: 'TextBox',
    value: '${companyData.name}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Email',
    type: 'TextBox',
    value: '${companyData.email}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Phone',
    type: 'TextBox',
    value: '${companyData.phone}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company BuildingNo',
    type: 'TextBox',
    value: '${companyData.buildingNo}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Street',
    type: 'TextBox',
    value: '${companyData.street}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Additional Street',
    type: 'TextBox',
    value: '${companyData.additionalStreet}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company City',
    type: 'TextBox',
    value: '${companyData.city}',
    notEditable: true,
    group: 'Company Properties'
  },
  {
    name: 'Company State',
    type: 'TextBox',
    value: '${companyData.state}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Country',
    type: 'TextBox',
    value: '${companyData.country}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Postalcode',
    type: 'TextBox',
    value: '${companyData.postalCode}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company AdditionalNo',
    type: 'TextBox',
    value: '${companyData.additionalNo}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Neighbourhood',
    type: 'TextBox',
    value: '${companyData.neighbourhood}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Reg No',
    type: 'TextBox',
    value: '${companyData.crNo}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Company Reg No Alt',
    type: 'TextBox',
    value: '${companyData.crNoAlt}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Tax No',
    type: 'TextBox',
    value: '${companyData.taxNo}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  },
  {
    name: 'Tax No Alt',
    type: 'TextBox',
    value: '${companyData.taxNoAlt}',
    notEditable: true,
    itemFor: 'Employee',
    group: 'Company Properties'
  }
]

export const clientProperties = [
  {
    name: 'Client Name',
    type: 'TextBox',
    value: '${clientData.name}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Client Type',
    type: 'TextBox',
    value: '${clientData.type}',
    notEditable: true,
    group: 'Client Properties'
  },

  {
    name: 'Currency',
    type: 'TextBox',
    value: '${clientData.currency}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Tax Type',
    type: 'TextBox',
    value: '${clientData.taxType}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Tax No',
    type: 'TextBox',
    value: '${clientData.taxNo}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Tax No',
    type: 'TextBox',
    value: '${clientData.taxNoAlt}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Company Reg No',
    type: 'TextBox',
    value: '${clientData.crNo}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Company Reg No Alt',
    type: 'TextBox',
    value: '${clientData.crNoAlt}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping BuildingNo',
    type: 'TextBox',
    value: '${clientData.shippingAddress.buildingNo}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping Street',
    type: 'TextBox',
    value: '${clientData.shippingAddress.street}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping Additional Street',
    type: 'TextBox',
    value: '${clientData.shippingAddress.additionalStreet}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping City',
    type: 'TextBox',
    value: '${clientData.shippingAddress.city}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping State',
    type: 'TextBox',
    value: '${clientData.shippingAddress.state}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping PostalCode',
    type: 'TextBox',
    value: '${clientData.shippingAddress.postalCode}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping Country',
    type: 'TextBox',
    value: '${clientData.shippingAddress.country}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping AdditionalNo',
    type: 'TextBox',
    value: '${clientData.shippingAddress.additionalNo}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Shipping Neighbourhood',
    type: 'TextBox',
    value: '${clientData.shippingAddress.neighbourhood}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing BuildingNo',
    type: 'TextBox',
    value: '${clientData.billingAddress.buildingNo}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing Street',
    type: 'TextBox',
    value: '${clientData.billingAddress.street}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing Additional Street',
    type: 'TextBox',
    value: '${clientData.billingAddress.additionalStreet}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing City',
    type: 'TextBox',
    value: '${clientData.billingAddress.city}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing State',
    type: 'TextBox',
    value: '${clientData.billingAddress.state}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing PostalCode',
    type: 'TextBox',
    value: '${clientData.billingAddress.postalCode}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing Country',
    type: 'TextBox',
    value: '${clientData.billingAddress.country}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing AdditionalNo',
    type: 'TextBox',
    value: '${clientData.billingAddress.additionalNo}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Billing Neighbourhood',
    type: 'TextBox',
    value: '${clientData.billingAddress.neighbourhood}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Contact Person Name',
    type: 'TextBox',
    value: '${clientData.contactPerson.contactName}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Contact Person Name Alt',
    type: 'TextBox',
    value: '${clientData.contactPerson.contactNameAlt}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Contact Person Email',
    type: 'TextBox',
    value: '${clientData.contactPerson.contactEmail}',
    notEditable: true,
    group: 'Client Properties'
  },
  {
    name: 'Contact Person Phone',
    type: 'TextBox',
    value: '${clientData.contactPerson.contactPhone}',
    notEditable: true,
    group: 'Client Properties'
  }
]

export const payrollCommonProperties = [
  {
    name: 'Container',
    type: 'Container',
    group: 'Common Properties'
  },
  {
    name: 'Text Box',
    type: 'TextBox',
    group: 'Common Properties'
  },
  {
    name: 'Image',
    type: 'Image',
    group: 'Common Properties'
  },
  {
    name: 'Payroll TextBox',
    type: 'TextBox',
    itemFor: 'Employee',
    group: 'Common Properties'
  },
  {
    name: 'Underline',
    type: 'Underline',
    group: 'Common Properties'
  }
]

export const invoiceCommonProperties = [
  {
    name: 'Container',
    type: 'Container',
    group: 'Common Properties'
  },
  {
    name: 'Text Box',
    type: 'TextBox',
    group: 'Common Properties'
  },
  {
    name: 'Image',
    type: 'Image',
    group: 'Common Properties'
  },
  {
    name: 'Underline',
    type: 'Underline',
    group: 'Common Properties'
  },
  {
    name: 'Header',
    type: 'Container',
    group: 'Common Properties',
    itemRef: 'header-ref'
  },
  {
    name: 'Footer',
    type: 'Container',
    group: 'Common Properties',
    itemRef: 'footer-ref'
  },
  {
    name: 'Page Number',
    type: 'TextBox',
    value: 'PDF_PAGE_NUMBER',
    group: 'Common Properties'
  },
  {
    name: 'Total Pages',
    type: 'TextBox',
    value: 'PDF_TOTAL_PAGES',
    group: 'Common Properties'
  }
]

export const invoiceProperties = [
  {
    name: 'Kind',
    type: 'TextBox',
    value: '${invoiceData.kind}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Invoice Number',
    type: 'TextBox',
    value: '${invoiceData.invoiceNo}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'PO Number',
    type: 'TextBox',
    value: '${invoiceData.poNo}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Currency',
    type: 'TextBox',
    value: '${invoiceData.currency}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Issue Date',
    type: 'TextBox',
    value: "${formatedDate(invoiceData.issueDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Tax Date',
    type: 'TextBox',
    value: "${formatedDate(invoiceData.taxDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Tax Category',
    type: 'TextBox',
    value: '${invoiceData.taxCategory}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Amount',
    type: 'TextBox',
    value: '${invoiceData.amount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Discount Amount',
    type: 'TextBox',
    value: '${invoiceData.discountAmount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Charge Amount',
    type: 'TextBox',
    value: '${invoiceData.chargeAmount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Net Amount',
    type: 'TextBox',
    value: '${invoiceData.netAmount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Tax Amount',
    type: 'TextBox',
    value: '${invoiceData.taxAmount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Gross Amount',
    type: 'TextBox',
    value: '${invoiceData.grossAmount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Total Amount',
    type: 'TextBox',
    value: '${invoiceData.totalAmount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Amount in words',
    type: 'TextBox',
    value: '${invoiceData.amountInWords}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Amount in words Alt',
    type: 'TextBox',
    value: '${invoiceData.amountInWordsAlt}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Date of supply',
    type: 'TextBox',
    value: "${formatedDate(invoiceData.dateOfSupply, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Payment Term',
    type: 'TextBox',
    value: '${invoiceData.paymentTerm}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Sent Date',
    type: 'TextBox',
    value: "${formatedDate(invoiceData.sentDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Payment Due Date',
    type: 'TextBox',
    value: "${formatedDate(invoiceData.paymentDueDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Payment Type',
    type: 'TextBox',
    value: '${invoiceData.paymentType}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Paid Amount',
    type: 'TextBox',
    value: '${invoiceData.paidAmount}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Payment Date',
    type: 'TextBox',
    value: "${formatedDate(invoiceData.paymentDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Status',
    type: 'TextBox',
    value: '${invoiceData.status}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Bank Account No',
    type: 'TextBox',
    value: '${bankData.bankAccountNo}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Account Holder Name',
    type: 'TextBox',
    value: '${bankData.bankAccountHolderName}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Bank Name',
    type: 'TextBox',
    value: '${bankData.bankName}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Bank Swift',
    type: 'TextBox',
    value: '${bankData.bankSwift}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Bank Currency',
    type: 'TextBox',
    value: '${bankData.bankCurrency}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Bank Address',
    type: 'TextBox',
    value: '${bankData.bankAddress}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Notes',
    type: 'TextBox',
    value: '${invoiceData.notes}',
    notEditable: true,
    group: 'Invoice Properties'
  },
  {
    name: 'Qr Code',
    type: 'Image',
    value:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIQAAACECAYAAABRRIOnAAAAAklEQVR4AewaftIAAAOOSURBVO3BQa7cVgADweaD7n/ljhdZcCVAGP2xE7Mq/sLMvw4z5TBTDjPlMFMOM+UwUw4z5TBTDjPlMFMOM+UwUw4z5TBTDjPl4kNJ+CaVJ5LwhMoTSWgqLQnfpPKJw0w5zJTDTLl4mcqbkvBNSWgqLQlN5QmVNyXhTYeZcpgph5ly8cOS8ITKE0l4k0pLQlNpSWgqTyThCZWfdJgph5lymCkXfxmVO0l4QuX/5DBTDjPlMFMu/nJJuKPyNznMlMNMOcyUix+m8idTaUloSXhC5QmVP8lhphxmymGmXLwsCb+TSkvCnSQ0lZaEptKS8EQS/mSHmXKYKYeZcvEhlT+ZSktCU3kiCU+o/JccZsphphxmysWHktBUWhLepNJU7iShqdxJwhMqd5LwJpWfdJgph5lymCnxF16UhDsqd5LQVN6UhCdU7iShqdxJQlN5IglN5U2HmXKYKYeZcvEylSeScCcJT6i0JDSVloSm0pJwR+VOEp5IQlO5k4Sm8onDTDnMlMNMufhQEu6otCTcUXkiCXdUWhKayieScEflCZU7Ki0JbzrMlMNMOcyUi5ep3FF5IglNpan8JJWWhCeS0FRaEppKS8I3HWbKYaYcZsrFD0tCU2lJaCpNpSXhEyotCXdUmkpLQlO5k4Sm0pLQVFoSmsqbDjPlMFMOM+XiD5eEJ1SeUGlJaEn4RBLuJOETSWgqnzjMlMNMOcyUiy9LQlNpSWgqLQlvSsIdlZaEptKS0FRaEu6o3FFpSXjTYaYcZsphplz8ZkloKi0JTaUl4U4SmsodlZaEO0m4k4RPJKGpNJU3HWbKYaYcZkr8hf+wJDyh8okk3FF5Igl3VL7pMFMOM+UwUy4+lIRvUmkqLQlNpSXhjsodlZaEO0loKndUWhKeUPnEYaYcZsphply8TOVNSbiThKbSknBH5U4SmsoTKk8koancScKbDjPlMFMOM+XihyXhCZU3qbQkPKHSknAnCZ9QaUn4psNMOcyUw0y5+J9JQlNpKi0J36TSktCS8DsdZsphphxmysVfTqUl4QmVloSmckelJeGOSkvCmw4z5TBTDjPl4oep/CSVloQnknBH5U4SmkpLwidUvukwUw4z5TBTLl6WhG9Kwp0kNJU7Ki0JTaWptCS8KQnfdJgph5lymCnxF2b+dZgph5lymCmHmXKYKYeZcpgph5lymCmHmXKYKYeZcpgph5lymCn/AN4phS+q2U+ZAAAAAElFTkSuQmCC',
    notEditable: true,
    group: 'Invoice Properties'
  }
]

export const invoiceItemProperties = [
  {
    name: 'Invoice Item Row',
    type: 'Container',
    group: 'Invoice Item Properties',
    itemRef: 'invoice-ref'
  },
  {
    name: 'Type',
    type: 'TextBox',
    value: '${invoiceItem.type}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Product',
    type: 'TextBox',
    value: '${invoiceItem.product}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Product Alt',
    type: 'TextBox',
    value: '${invoiceItem.productAlt}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Description',
    type: 'TextBox',
    value: '${invoiceItem.description}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Description Alt',
    type: 'TextBox',
    value: '${invoiceItem.descriptionAlt}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Quantity',
    type: 'TextBox',
    value: '${invoiceItem.quantity}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Unit',
    type: 'TextBox',
    value: '${invoiceItem.unit}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Unit Price',
    type: 'TextBox',
    value: '${invoiceItem.unitPrice}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Amount',
    type: 'TextBox',
    value: '${invoiceItem.amount}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Discount',
    type: 'TextBox',
    value: '${invoiceItem.discount}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Discount Format',
    type: 'TextBox',
    value: '${invoiceItem.discountFormat}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Discount Amount',
    type: 'TextBox',
    value: '${invoiceItem.discountAmount}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Charge',
    type: 'TextBox',
    value: '${invoiceItem.charge}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Charge Format',
    type: 'TextBox',
    value: '${invoiceItem.chargeFormat}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Charge Amount',
    type: 'TextBox',
    value: '${invoiceItem.chargeAmount}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Net Amount',
    type: 'TextBox',
    value: '${invoiceItem.netAmount}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Tax',
    type: 'TextBox',
    value: '${invoiceItem.tax}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Tax Format',
    type: 'TextBox',
    value: '${invoiceItem.taxFormat}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Tax Amount',
    type: 'TextBox',
    value: '${invoiceItem.taxAmount}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Tax Type',
    type: 'TextBox',
    value: '${invoiceItem.taxType}',
    notEditable: true,
    group: 'Invoice Item Properties'
  },
  {
    name: 'Gross Amount',
    type: 'TextBox',
    value: '${invoiceItem.grossAmount}',
    notEditable: true,
    group: 'Invoice Item Properties'
  }
]

export const timeSheetCommonProperties = [
  {
    name: 'Container',
    type: 'Container',
    group: 'Common Properties'
  },
  {
    name: 'Text Box',
    type: 'TextBox',
    group: 'Common Properties'
  },
  {
    name: 'Image',
    type: 'Image',
    group: 'Common Properties'
  },
  {
    name: 'Header',
    type: 'Container',
    group: 'Common Properties',
    itemRef: 'header-ref'
  },
  {
    name: 'Footer',
    type: 'Container',
    group: 'Common Properties',
    itemRef: 'footer-ref'
  },
  {
    name: 'Page Number',
    type: 'TextBox',
    value: 'PDF_PAGE_NUMBER',
    group: 'Common Properties'
  },
  {
    name: 'Total Pages',
    type: 'TextBox',
    value: 'PDF_TOTAL_PAGES',
    group: 'Common Properties'
  }
]
export const reporterProperties = [
  {
    name: 'Reporter No',
    type: 'TextBox',
    value: '${reporterData.employeeNo}',
    notEditable: true,
    group: 'Employee Properties'
  },
  {
    name: 'Reporter Name',
    type: 'TextBox',
    value: '${reporterData.name}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Email',
    type: 'TextBox',
    value: '${reporterData.email}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Phone',
    type: 'TextBox',
    value: '${reporterData.phone}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter DOB',
    type: 'TextBox',
    value: '${reporterData.dob}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Social Id',
    type: 'TextBox',
    value: '${reporterData.socialId}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Street',
    type: 'TextBox',
    value: '${reporterData.street}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter City',
    type: 'TextBox',
    value: '${reporterData.city}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter State',
    type: 'TextBox',
    value: '${reporterData.stateName}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Country',
    type: 'TextBox',
    value: '${reporterData.countryName}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Postalcode',
    type: 'TextBox',
    value: '${reporterData.postalCode}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter BankName',
    type: 'TextBox',
    value: '${reporterData.bankName}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Bank Acc No',
    type: 'TextBox',
    value: '${reporterData.bankAccountNo}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter BankSwift',
    type: 'TextBox',
    value: '${reporterData.bankSwift}',
    notEditable: true,
    group: 'Reporter Properties'
  },
  {
    name: 'Reporter Currency',
    type: 'TextBox',
    value: '${reporterData.currency}',
    notEditable: true,
    group: 'Reporter Properties'
  }
]

export const timeSheetProperties = [
  {
    name: 'Start Date',
    type: 'TextBox',
    value: "${formatedDate(timesheetData.startDate, 'DD-MMM-YYYY')}",
    group: 'Timesheet Properties'
  },
  {
    name: 'End Date',
    type: 'TextBox',
    value: "${formatedDate(timesheetData.endDate, 'DD-MMM-YYYY')}",
    group: 'Timesheet Properties'
  },

  {
    name: 'Projects',
    type: 'Container',
    group: 'Timesheet Properties',
    itemRef: 'timesheets-projects-ref'
  },
  {
    name: 'Project Name',
    type: 'TextBox',
    value: '${project.name}',
    group: 'Timesheet Properties'
  },
  {
    name: 'Dates',
    type: 'TextBox',
    value: "${formatedDate(dateData.date, 'DD')}",
    group: 'Timesheet Properties',
    itemRef: 'timesheet-dates-ref'
  },
  {
    name: 'Times',
    type: 'TextBox',
    value: '${getTimes(dateData.dateNumber, project)}',
    group: 'Timesheet Properties',
    itemRef: 'timesheet-times-ref'
  },
  {
    name: 'Weeks',
    type: 'Container',
    group: 'Timesheet Properties',
    itemRef: 'timesheet-weeks-ref'
  },
  {
    name: 'Week start and end ',
    type: 'TextBox',
    value:
      "${formatedDate(weekData.startWeekDate, 'DD')} - ${formatedDate(weekData.endWeekDate, 'DD MMM YYYY')}",
    group: 'Timesheet Properties'
  },
  {
    name: 'Week Total Hours ',
    type: 'TextBox',
    value: '${convertMinutesToHours(weekData.totalMinutes)}',
    group: 'Timesheet Properties'
  },
  {
    name: 'Week Status ',
    type: 'TextBox',
    value: '${weekData.status}',
    group: 'Timesheet Properties'
  },

  {
    name: 'Time Entries',
    type: 'Container',
    group: 'Timesheet Properties',
    itemRef: 'timesheet-entries-ref'
  },

  {
    name: 'Total Hours ',
    type: 'TextBox',
    value: '${convertMinutesToHours(timesheetData.totalMinutes)}',
    group: 'Timesheet Properties'
  },
  {
    name: 'Billable Days',
    type: 'TextBox',
    value: '${toDecimal((timesheetData.totalMinutes/60)/8, 2)}',
    group: 'Timesheet Properties'
  }
]

export const orderProperties = [
  {
    name: 'OrderNo',
    type: 'TextBox',
    value: '${invoiceData.orderNo}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'OrderType',
    type: 'TextBox',
    value: '${invoiceData.orderType}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'OrderDate',
    type: 'TextBox',
    value: '${invoiceData.orderDate}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'CustomerNo',
    type: 'TextBox',
    value: '${invoiceData.customerNo}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'Currency',
    type: 'TextBox',
    value: '${invoiceData.currency}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'ExchangeRate',
    type: 'TextBox',
    value: '${invoiceData.exchangeRate}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'PoNo',
    type: 'TextBox',
    value: '${invoiceData.poNo}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'PoDate',
    type: 'TextBox',
    value: '${invoiceData.poDate}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'Remarks',
    type: 'TextBox',
    value: '${invoiceData.remarks}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'OrderAddress',
    type: 'TextBox',
    value: '${invoiceData.orderAddress}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'DeliveryAddress',
    type: 'TextBox',
    value: '${invoiceData.deliveryAddress}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'PayTerm',
    type: 'TextBox',
    value: '${invoiceData.payTerm}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'SalesPerson',
    type: 'TextBox',
    value: '${invoiceData.salesPerson}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'ContactPerson',
    type: 'TextBox',
    value: '${invoiceData.contactPerson}',
    notEditable: true,
    group: 'Order Properties'
  },
  {
    name: 'Tax',
    type: 'TextBox',
    value: '${invoiceData.tax}',
    notEditable: true,
    group: 'Order Properties'
  }
]

export const orderItemProperties = [
  {
    name: 'Invoice Item Row',
    type: 'Container',
    group: 'Order Item Properties',
    itemRef: 'order-ref'
  },
  {
    name: 'Position',
    type: 'TextBox',
    value: '${invoiceItem.position}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'MaterialCode',
    type: 'TextBox',
    value: '${invoiceItem.materialCode}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'MaterialDescription',
    type: 'TextBox',
    value: '${invoiceItem.materialDescription}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'Unit',
    type: 'TextBox',
    value: '${invoiceItem.unit}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'OrderedQuantity',
    type: 'TextBox',
    value: '${invoiceItem.orderedQuantity}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'RequestedDate',
    type: 'TextBox',
    value: '${invoiceItem.requestedDate}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'Price',
    type: 'TextBox',
    value: '${invoiceItem.price}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'Amount',
    type: 'TextBox',
    value: '${invoiceItem.amount}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'DiscountType',
    type: 'TextBox',
    value: '${invoiceItem.discountType}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'Discount',
    type: 'TextBox',
    value: '${invoiceItem.discount}',
    notEditable: true,
    group: 'Order Item Properties'
  },

  {
    name: 'DiscountValue',
    type: 'TextBox',
    value: '${invoiceItem.discountValue}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'ChargeType',
    type: 'TextBox',
    value: '${invoiceItem.chargeType}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'Charge',
    type: 'TextBox',
    value: '${invoiceItem.charge}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'ChargeValue',
    type: 'TextBox',
    value: '${invoiceItem.chargeValue}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'NetAmount',
    type: 'TextBox',
    value: '${invoiceItem.netAmount}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'DeliveryStatus',
    type: 'TextBox',
    value: '${invoiceItem.deliveryStatus}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'InvoiceStatus',
    type: 'TextBox',
    value: '${invoiceItem.invoiceStatus}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'DeliveredQuantity',
    type: 'TextBox',
    value: '${invoiceItem.deliveredQuantity}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'Invoiced Quantity',
    type: 'TextBox',
    value: '${invoiceItem.invoicedQuantity}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'To Be Delivered Quantity',
    type: 'TextBox',
    value: '${invoiceItem.toBeDeliveredQuantity}',
    notEditable: true,
    group: 'Order Item Properties'
  },
  {
    name: 'To Be Invoiced Quantity',
    type: 'TextBox',
    value: '${invoiceItem.toBeInvoicedQuantity}',
    notEditable: true,
    group: 'Order Item Properties'
  }
]

export const offerProperties = [
  {
    name: 'Offer No',
    type: 'TextBox',
    value: '${offerData.offerNo}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Type',
    type: 'TextBox',
    value: '${offerData.offerType}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Quotation No',
    type: 'TextBox',
    value: '${offerData.quotationNo}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Client Name',
    type: 'TextBox',
    value: '${offerData.clientName}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Contact Person',
    type: 'TextBox',
    value: '${offerData.contactPerson}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Street',
    type: 'TextBox',
    value: '${offerData.street}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'City',
    type: 'TextBox',
    value: '${offerData.city}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'State',
    type: 'TextBox',
    value: '${offerData.state}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Postal Code',
    type: 'TextBox',
    value: '${offerData.postalCode}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Country',
    type: 'TextBox',
    value: '${offerData.country}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Phone',
    type: 'TextBox',
    value: '${offerData.phone}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Email',
    type: 'TextBox',
    value: '${offerData.email}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'POLPOD',
    type: 'TextBox',
    value: '${offerData.POLPOD}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Actual Weight',
    type: 'TextBox',
    value: '${offerData.actualWeight}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Chargeable Weight',
    type: 'TextBox',
    value: '${offerData.chargeableWeight}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Requested Date',
    type: 'TextBox',
    value: "${formatedDate(offerData.requestedDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Completed Date',
    type: 'TextBox',
    value: "${formatedDate(offerData.completedDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Operations',
    type: 'TextBox',
    value: '${offerData.operations}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Sales Person',
    type: 'TextBox',
    value: '${offerData.salesPerson}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Reference',
    type: 'TextBox',
    value: '${offerData.reference}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Special Instruction',
    type: 'TextBox',
    value: '${offerData.specialInstruction}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Comments',
    type: 'TextBox',
    value: '${offerData.comments}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Statement',
    type: 'TextBox',
    value: '${offerData.statement}',
    notEditable: true,
    group: 'Offer Properties'
  },
  {
    name: 'Status',
    type: 'TextBox',
    value: '${offerData.status}',
    notEditable: true,
    group: 'Offer Properties'
  }
]

export const bookingProperties = [
  {
    name: 'Booking No',
    type: 'TextBox',
    value: '${bookingData.bookingNo}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Type',
    type: 'TextBox',
    value: '${bookingData.type}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Quotation No',
    type: 'TextBox',
    value: '${bookingData.quotationNo}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Client Name',
    type: 'TextBox',
    value: '${bookingData.clientName}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Contact Person',
    type: 'TextBox',
    value: '${bookingData.contactPerson}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Street',
    type: 'TextBox',
    value: '${bookingData.street}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'City',
    type: 'TextBox',
    value: '${bookingData.city}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'State',
    type: 'TextBox',
    value: '${bookingData.state}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Postal Code',
    type: 'TextBox',
    value: '${bookingData.postalCode}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Country',
    type: 'TextBox',
    value: '${bookingData.country}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Phone',
    type: 'TextBox',
    value: '${bookingData.phone}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Email',
    type: 'TextBox',
    value: '${bookingData.email}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'POLPOD',
    type: 'TextBox',
    value: '${bookingData.POLPOD}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Actual Weight',
    type: 'TextBox',
    value: '${bookingData.actualWeight}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Chargeable Weight',
    type: 'TextBox',
    value: '${bookingData.chargeableWeight}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Requested Date',
    type: 'TextBox',
    value: "${formatedDate(bookingData.requestedDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Completed Date',
    type: 'TextBox',
    value: "${formatedDate(bookingData.completedDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Operations',
    type: 'TextBox',
    value: '${bookingData.operations}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Sales Person',
    type: 'TextBox',
    value: '${bookingData.salesPerson}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Reference',
    type: 'TextBox',
    value: '${bookingData.reference}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Special Instruction',
    type: 'TextBox',
    value: '${bookingData.specialInstruction}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Comments',
    type: 'TextBox',
    value: '${bookingData.comments}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Statement',
    type: 'TextBox',
    value: '${bookingData.statement}',
    notEditable: true,
    group: 'Booking Properties'
  },
  {
    name: 'Status',
    type: 'TextBox',
    value: '${bookingData.status}',
    notEditable: true,
    group: 'Booking Properties'
  }
]

export const jobCreationProperties = [
  {
    name: 'Job Creation No',
    type: 'TextBox',
    value: '${jobCreationData.jobCreationNo}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Type',
    type: 'TextBox',
    value: '${jobCreationData.type}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Forwarder',
    type: 'TextBox',
    value: '${jobCreationData.forwarder}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Creation Date',
    type: 'TextBox',
    value: "${formatedDate(bookingData.creationDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Tos',
    type: 'TextBox',
    value: '${jobCreationData.tos}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Flight No',
    type: 'TextBox',
    value: '${jobCreationData.flightNo}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'MAWB No',
    type: 'TextBox',
    value: '${jobCreationData.MAWBNo}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'POLPOD',
    type: 'TextBox',
    value: '${jobCreationData.POLPOD}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Airline',
    type: 'TextBox',
    value: '${jobCreationData.airline}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Shipper',
    type: 'TextBox',
    value: '${jobCreationData.shipper}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Consignee',
    type: 'TextBox',
    value: '${jobCreationData.consignee}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Commodity',
    type: 'TextBox',
    value: '${jobCreationData.commodity}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'ID No',
    type: 'TextBox',
    value: '${jobCreationData.idNo}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Issue Date',
    type: 'TextBox',
    value: "${formatedDate(bookingData.issueDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Vessel',
    type: 'TextBox',
    value: '${jobCreationData.vessel}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Vessel Code',
    type: 'TextBox',
    value: '${jobCreationData.vesselCode}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Voyage No',
    type: 'TextBox',
    value: '${jobCreationData.voyageNo}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Departure Date',
    type: 'TextBox',
    value: "${formatedDate(bookingData.departureDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Arrival Date',
    type: 'TextBox',
    value: "${formatedDate(bookingData.arrivalDate, 'YYYY-MM-DD')}",
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Client Name',
    type: 'TextBox',
    value: '${jobCreationData.clientName}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Phone',
    type: 'TextBox',
    value: '${jobCreationData.phone}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Email',
    type: 'TextBox',
    value: '${jobCreationData.email}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Operations',
    type: 'TextBox',
    value: '${jobCreationData.operations}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Sales Person',
    type: 'TextBox',
    value: '${jobCreationData.salesPerson}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Booking No',
    type: 'TextBox',
    value: '${jobCreationData.bookingNo}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Street',
    type: 'TextBox',
    value: '${jobCreationData.address.street}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'City',
    type: 'TextBox',
    value: '${jobCreationData.address.city}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'State',
    type: 'TextBox',
    value: '${jobCreationData.address.state}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Postal Code',
    type: 'TextBox',
    value: '${jobCreationData.address.postalCode}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Country',
    type: 'TextBox',
    value: '${jobCreationData.address.country}',
    notEditable: true,
    group: 'Job Creation Properties'
  },
  {
    name: 'Status',
    type: 'TextBox',
    value: '${jobCreationData.status}',
    notEditable: true,
    group: 'Job Creation Properties'
  }
]

export const packageListProperties = [
  {
    name: 'Package List Row',
    type: 'Container',
    group: 'Package List Properties',
    itemRef: 'package-list-ref'
  },
  {
    name: 'Position',
    type: 'TextBox',
    value: '${packageData.position}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Package Type',
    type: 'TextBox',
    value: '${packageData.packageType}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Length',
    type: 'TextBox',
    value: '${packageData.length}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Breadth',
    type: 'TextBox',
    value: '${packageData.breadth}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Height',
    type: 'TextBox',
    value: '${packageData.height}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Unit',
    type: 'TextBox',
    value: '${packageData.unit}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Weight',
    type: 'TextBox',
    value: '${packageData.weight}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Weight Unit',
    type: 'TextBox',
    value: '${packageData.weightUnit}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Volume',
    type: 'TextBox',
    value: '${packageData.volume}',
    notEditable: true,
    group: 'Package List Properties'
  },
  {
    name: 'Volume Unit',
    type: 'TextBox',
    value: '${packageData.volumeUnit}',
    notEditable: true,
    group: 'Package List Properties'
  }
]

export const packageItemProperties = [
  {
    name: 'Package Item Row',
    type: 'Container',
    group: 'Package Item Properties',
    itemRef: 'package-item-ref'
  },
  {
    name: 'Description',
    type: 'TextBox',
    value: '${packageItem.description}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Quantity',
    type: 'TextBox',
    value: '${packageItem.quantity}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Unit',
    type: 'TextBox',
    value: '${packageItem.unit}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Price',
    type: 'TextBox',
    value: '${packageItem.price}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Amount',
    type: 'TextBox',
    value: '${packageItem.amount}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Net Amount',
    type: 'TextBox',
    value: '${packageItem.netAmount}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Currency',
    type: 'TextBox',
    value: '${packageItem.currency}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Charge Type',
    type: 'TextBox',
    value: '${packageItem.chargeType}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Charge',
    type: 'TextBox',
    value: '${packageItem.charge}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Charge Value',
    type: 'TextBox',
    value: '${packageItem.chargeValue}',
    notEditable: true,
    group: 'Package Item Properties'
  },

  {
    name: 'Tax Type',
    type: 'TextBox',
    value: '${packageItem.taxType}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Tax',
    type: 'TextBox',
    value: '${packageItem.tax}',
    notEditable: true,
    group: 'Package Item Properties'
  },
  {
    name: 'Tax Amount',
    type: 'TextBox',
    value: '${packageItem.taxAmount}',
    notEditable: true,
    group: 'Package Item Properties'
  }
]
