import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { ACCOUNT_SETUP_TYPES, STATUS } from '../../../Util/Options'
import { convertSelectOptions } from '../../../Util/Util'

const TYPES = [
  { label: 'DR', value: 'DR' },
  { label: 'CR', value: 'CR' },
  { label: 'DR/CR', value: 'DR/CR' }
]
const FROM = [
  { label: 'GROSS', value: 'GROSS' },
  { label: 'NET', value: 'NET' },
  { label: 'TAX', value: 'TAX' },
  { label: 'DIFF', value: 'DIFF' }
]

const Schema = Yup.object().shape({
  type: Yup.string().required(),
  code: Yup.string().required(),
  name: Yup.string().required(),
  accounts: Yup.array()
    .of(
      Yup.object().shape({
        accountCode: Yup.string().required(),
        transactionType: Yup.string().required(),
        from: Yup.string().required()
      })
    )
    .required(),
  status: Yup.string().required()
})

function AccountSetupForm({
  setValues,
  match: {
    params: { id }
  },
  values,
  history
}) {
  const [accountOptions, setAccountOptions] = useState([])

  const getData = () => {
    if (id) {
      apiClient.get(`account-setups/get/${id}`).then(({ data }) => {
        if (data && data.result) {
          setValues(data.result)
        }
      })
    }

    apiClient.get('account-charts/get-active').then(({ data }) => {
      if (data && data.result) {
        setAccountOptions(convertSelectOptions(data.result, 'name', 'accountCode'))
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="Account Setup">
            <Panel title="Account Setup">
              <Row gutter={[10, 10]}>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      label="Type"
                      name="type"
                      as="select"
                      options={ACCOUNT_SETUP_TYPES}
                      disabled={!!id}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field label="Code" name="code" disabled={!!id} />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field label="Name" name="name" />
                  </div>
                </Col>
              </Row>
            </Panel>

            <Panel title="Accounts">
              <FieldArray
                name="accounts"
                editable={values.removable !== 'No'}
                additionalValues={{ accountOptions }}
                defaultValues={{
                  accountCode: '',
                  transactionType: '',
                  from: ''
                }}>
                {AccountDetails}
              </FieldArray>
            </Panel>
            <Row gutter={[10, 10]}>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="form-field">
                  <Field label="Status" name="status" as="select" options={STATUS} />
                </div>
              </Col>
            </Row>
          </PanelLayout>
          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>

            <Link to="#" onClick={() => history.goBack()}>
              <Button>
                <ArrowLeftOutlined /> Back
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    type: '',
    code: '',
    name: '',
    accounts: [
      {
        accountCode: '',
        transactionType: '',
        from: ''
      }
    ],
    removable: 'Yes',
    status: 'Active'
  }),
  validationSchema: Schema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`account-setups/update/${id}`, { ...data }).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/account-setups')
        }
      })
    } else {
      apiClient.post('account-setups/add', data).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/account-setups')
        }
      })
    }
  }
})(AccountSetupForm)

function AccountDetails({ i, accountOptions }) {
  return (
    <Row gutter={[10, 5]}>
      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
        <div className="form-field">
          <Field
            name={`accounts[${i}].accountCode`}
            label="Account Code"
            as="select"
            options={accountOptions}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
        <div className="form-field">
          <Field
            name={`accounts[${i}].transactionType`}
            label="Transaction Type"
            as="select"
            options={TYPES}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
        <div className="form-field">
          <Field name={`accounts[${i}].from`} label="From" as="select" options={FROM} />
        </div>
      </Col>
    </Row>
  )
}
