import { Col, Row, Select } from 'antd'
import React, { memo, useEffect, useState } from 'react'
import Field from '../../../Components/Formik/Field'
import apiClient from '../../../Util/apiClient'
import { TAX_TYPES } from '../../../Util/Options'
import { parseAmount } from '../../../Util/Util'

const { Option } = Select

function InvoiceItems({
  quantity,
  unitPrice,
  discount,
  discountFormat,
  discountAmount,
  retention,
  retentionFormat,
  retentionAmount,
  charge,
  chargeFormat,
  chargeAmount,
  tax,
  taxFormat,
  taxAmount,
  netAmount,
  grossAmount,
  taxCategory,
  currency,
  disabled,
  divisions,
  options,
  lineDivision,
  i,
  setFieldValue,
  additionalFields,
  accountOptions
}) {
  const [products, setProducts] = useState([])
  const [taxTypes, setTaxTypes] = useState([])

  const onGetOptions = (v, n) => {
    setFieldValue(n, v)

    if (v !== '' && v.length < 20) {
      apiClient.get('products/get-by-name', { params: { name: v, currency } }).then(({ data }) => {
        if (data && data.result) {
          const prod = data.result.map((v) => {
            const text = `${v.name} || ${v.unitPrice} ${v.currency}`

            return { ...v, label: text, value: text }
          })
          setProducts(prod)
        } else {
          setProducts([])
        }
      })
    } else {
      setProducts([])
    }
  }

  useEffect(() => {
    if (quantity && unitPrice) {
      const amount = quantity * unitPrice
      const discAmount =
        discountFormat === '%' ? amount * (parseFloat(discount || 0) / 100) : parseFloat(discount)
      const retenAmount =
        retentionFormat === '%' ? amount * (parseFloat(retention || 0) / 100) : parseFloat(retention)
      const chrgAmount = chargeFormat === '%' ? amount * (parseFloat(charge || 0) / 100) : parseFloat(charge)
      const netAmount = amount - (discAmount || 0) + (chrgAmount || 0)
      const tAmount = taxFormat === '%' ? netAmount * (parseFloat(tax || 0) / 100) : parseFloat(tax)
      const grossAmount = netAmount + tAmount - (retenAmount || 0)
      setFieldValue(`items[${i}].amount`, amount)
      setFieldValue(`items[${i}].discountAmount`, discAmount || 0)
      setFieldValue(`items[${i}].retentionAmount`, retenAmount || 0)
      setFieldValue(`items[${i}].chargeAmount`, chrgAmount || 0)
      setFieldValue(`items[${i}].netAmount`, netAmount)
      setFieldValue(`items[${i}].taxAmount`, tAmount)
      setFieldValue(`items[${i}].grossAmount`, grossAmount)
    }
  }, [
    quantity,
    unitPrice,
    discount,
    discountFormat,
    retention,
    retentionFormat,
    charge,
    chargeFormat,
    tax,
    taxFormat
  ])

  useEffect(() => {
    if (taxCategory && taxCategory !== '') {
      const taxes = TAX_TYPES.filter((v) => v.categories.includes(taxCategory))
      setTaxTypes(taxes)
      setFieldValue(`items[${i}].taxType`, taxes[0].value)
    }
  }, [taxCategory])

  const selectAfter = (n, v) => {
    const onSelect = (val) => setFieldValue(`items[${i}].${n}`, val)

    return (
      <Select
        disabled={disabled}
        onSelect={onSelect}
        defaultValue={v || ''}
        value={v || ''}
        className="select-after"
        showArrow={false}>
        <Option value="%">%</Option>
        <Option value="">№</Option>
      </Select>
    )
  }

  const onSelectProduct = (n, data) => {
    if (data) {
      setFieldValue(`items[${i}].product`, data.name)
      setFieldValue(`items[${i}].productAlt`, data.nameAlt)
      setFieldValue(`items[${i}].description`, data.description)
      setFieldValue(`items[${i}].descriptionAlt`, data.descriptionAlt)
      setFieldValue(`items[${i}].unit`, data.unit)
      setFieldValue(`items[${i}].unitPrice`, data.unitPrice)

      if (data.discount) {
        setFieldValue(`items[${i}].discount`, data.discount)
        setFieldValue(`items[${i}].discountFormat`, data.discountFormat)
      }

      if (data.charge) {
        setFieldValue(`items[${i}].charge`, data.charge)
        setFieldValue(`items[${i}].chargeFormat`, data.chargeFormat)
      }

      if (data.tax) {
        setFieldValue(`items[${i}].tax`, data.tax)
        setFieldValue(`items[${i}].taxFormat`, data.taxFormat)
      }
    }
  }

  const extraFields = additionalFields.filter((v) =>
    ['description', 'discount', 'charge', 'retention', 'account'].includes(v)
  )
  const descriptionLength = extraFields.includes('description') ? 2 : 0
  const accountLength = extraFields.includes('account') ? 1 : 0
  const productWidth =
    11 - extraFields.length * 2 - (lineDivision ? 3 : 0) - descriptionLength - accountLength

  return (
    <Row gutter={[10, 5]}>
      {lineDivision && (
        <Col xs={12} sm={12} md={8} lg={4} xl={3}>
          <div className="form-field">
            <Field
              as="select"
              name={`items[${i}].division`}
              label="Division"
              disabled={disabled}
              options={divisions || []}
            />
          </div>
        </Col>
      )}
      <Col xs={12} sm={12} md={8} lg={4} xl={3}>
        <div className="form-field">
          <Field
            as="select"
            name={`items[${i}].type`}
            label="Type"
            disabled={disabled}
            options={options.ExpenseType || []}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={8} xl={productWidth}>
        <div className="form-field">
          <Field
            as="auto-complete"
            rows={1}
            name={`items[${i}].product`}
            label="Product"
            onSearch={(n) => onGetOptions(n, `items[${i}].product`)}
            onSelect={onSelectProduct}
            options={products}
            textArea
            disabled={disabled}
            altInput
          />
        </div>
      </Col>
      {extraFields.includes('description') && (
        <Col xs={12} sm={12} md={8} lg={8} xl={4}>
          <div className="form-field">
            <Field name={`items[${i}].description`} label="Description" as="textarea" rows={1} altInput />
          </div>
        </Col>
      )}
      <Col xs={12} sm={12} md={8} lg={4} xl={2}>
        <div className="form-field">
          <Field name={`items[${i}].quantity`} label="Quantity" disabled={disabled} />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={4} xl={2}>
        <div className="form-field">
          <Field
            name={`items[${i}].unit`}
            label="UOM"
            disabled={disabled}
            as="select"
            options={options.UnitOfMeasurement || []}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={4} xl={2}>
        <div className="form-field">
          <Field name={`items[${i}].unitPrice`} label="Price" disabled={disabled} />
        </div>
      </Col>
      {extraFields.includes('discount') && (
        <Col xs={12} sm={12} md={8} lg={4} xl={2}>
          <div className="form-field">
            <Field
              name={`items[${i}].discount`}
              label="Discount"
              disabled={disabled}
              addonAfter={selectAfter('discountFormat', discountFormat)}
            />
          </div>
        </Col>
      )}
      {extraFields.includes('charge') && (
        <Col xs={12} sm={12} md={8} lg={4} xl={2}>
          <div className="form-field">
            <Field
              name={`items[${i}].charge`}
              label="Charge"
              disabled={disabled}
              addonAfter={selectAfter('chargeFormat', chargeFormat)}
            />
          </div>
        </Col>
      )}
      {extraFields.includes('retention') && (
        <Col xs={12} sm={12} md={8} lg={4} xl={2}>
          <div className="form-field">
            <Field
              name={`items[${i}].retention`}
              label="Retention"
              disabled={disabled}
              addonAfter={selectAfter('retentionFormat', retentionFormat)}
            />
          </div>
        </Col>
      )}
      <Col xs={12} sm={12} md={8} lg={4} xl={2}>
        <div className="form-field">
          <Field
            name={`items[${i}].tax`}
            label="Tax"
            disabled={disabled}
            addonAfter={selectAfter('taxFormat', taxFormat)}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={4} xl={2}>
        <div className="form-field">
          <Field
            name={`items[${i}].taxType`}
            label="Tax Type"
            as="select"
            options={taxTypes}
            disabled={disabled}
          />
        </div>
      </Col>
      {extraFields.includes('account') && (
        <Col xs={12} sm={12} md={8} lg={4} xl={3}>
          <div className="form-field">
            <Field
              name={`items[${i}].account`}
              label="Account"
              as="select"
              options={accountOptions}
              disabled={disabled}
            />
          </div>
        </Col>
      )}
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className="line-item-footer">
          <div className="line-item-footer-content">
            <b>Amount:</b> <span className="text-nowrap">{parseAmount(quantity * unitPrice, currency)}</span>
          </div>
          {extraFields.includes('discount') && (
            <div className="line-item-footer-content">
              - <b>Discount :</b> <span className="text-nowrap">{parseAmount(discountAmount, currency)}</span>
            </div>
          )}
          {extraFields.includes('charge') && (
            <div className="line-item-footer-content">
              - <b>Charge :</b> <span className="text-nowrap">{parseAmount(chargeAmount, currency)}</span>
            </div>
          )}
          <div className="line-item-footer-content">
            = <b>Net :</b> <span className="text-nowrap">{parseAmount(netAmount, currency)}</span>
          </div>
          {extraFields.includes('retention') && (
            <div className="line-item-footer-content">
              - <b>Retention :</b>{' '}
              <span className="text-nowrap">{parseAmount(retentionAmount, currency)}</span>
            </div>
          )}
          <div className="line-item-footer-content">
            + <b>Tax :</b> <span className="text-nowrap">{parseAmount(taxAmount, currency)}</span>
          </div>
          <div className="line-item-footer-content">
            = <b>Gross :</b> <span className="text-nowrap">{parseAmount(grossAmount, currency)}</span>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default memo(InvoiceItems)
