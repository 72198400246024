import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { getLocationsByWarehouse, getWarehouseCodes } from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'

export default function SingleWarehouse({ warehouse, setFieldValue, i }) {
  const [warehouses, setWarehouses] = useState([])
  const [locations, setLocations] = useState([])

  const getWarehouses = (warehouse) => {
    getWarehouseCodes({ warehouse }).then((data) => {
      setWarehouses(data)
    })
    setFieldValue(`warehouses[${i}].warehouse`, warehouse)
  }

  const getLocations = (location) => {
    getLocationsByWarehouse({
      warehouse,
      location
    }).then((data) => {
      setLocations(data)
    })
    setFieldValue(`warehouses[${i}].location`, location)
  }

  return (
    <Row gutter={[20, 10]}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <div className="form-field">
          <Field
            as="auto-complete"
            name={`warehouses[${i}].warehouse`}
            label="WH"
            onSearch={getWarehouses}
            options={warehouses}
            onChange={(n, v, o) => {
              setFieldValue('description', o?.description)

              return setFieldValue(n, v)
            }}
          />
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <div className="form-field">
          <Field name={`warehouses[${i}].description`} label="Warehouse Description" />
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <div className="form-field">
          <Field
            as="auto-complete"
            key={warehouse}
            name={`warehouses[${i}].location`}
            label="Storage Location"
            onSearch={getLocations}
            options={locations}
            onChange={(n, v, o) => {
              setFieldValue('locationDescription', o?.locationDescription)

              return setFieldValue(n, v)
            }}
          />
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <div className="form-field">
          <Field name={`warehouses[${i}].locationDescription`} label="Storage Location Description" />
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <div className="form-field">
          <Field name={`warehouses[${i}].rack`} label="Rack" />
        </div>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <div className="form-field">
          <Field name={`warehouses[${i}].rackDescription`} label="Rack Description" />
        </div>
      </Col>
    </Row>
  )
}
