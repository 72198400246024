import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import apiClient from '../../Util/apiClient'
import InvoiceInfo from './InvoiceInfo'
import './QrInfo.scss'

export default function QrInfo(props) {
  const [qrData, setQrData] = useState(false)
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    const { type, id } = props.match.params
    apiClient.get(`qr-info/get/${type}/${id}`).then(({ data }) => {
      if (data && data.result) {
        setQrData(data.result)
      }

      setLoader(false)
    })
  }, [])

  return (
    <Row justify="center" className="inner-contents">
      {!loader ? (
        <Col xs={22} sm={22} md={20} lg={16}>
          {props.match.params.type === 'incomes' && qrData && <InvoiceInfo qrData={qrData} {...props} />}
          {props.match.params.type === 'expenses' && qrData && <InvoiceInfo qrData={qrData} {...props} />}
          {!qrData && <div className="qr-info-loader">No data found!</div>}
        </Col>
      ) : (
        <Col xs={22} sm={22} md={20} lg={16} className="qr-info-loader">
          Loading QR Info..
        </Col>
      )}
    </Row>
  )
}
