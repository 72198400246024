import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setSelectedEmployee } from '../../../Actions/EmployeeActions'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import EmployeeFilter from './EmployeeFilter'

export default function Employees() {
  const dispatch = useDispatch()
  const [employees, setEmployees] = useState([])
  const history = useHistory()

  const onFilter = (params = {}) => {
    apiClient.get('employees/get', { params }).then(({ data }) => {
      if (data.result) {
        SET_DATA('employees.filterData', { ...params, ...data.pageData })
        setEmployees(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('employees.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('employees.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const redirectToEmployeeDetails = (id) => () => {
    dispatch(setSelectedEmployee(id))
    history.push('/app/employee-details')
  }

  const columns = [
    {
      title: 'Employee No',
      dataIndex: 'employeeNo',
      render: (v, r) => (
        <div onClick={redirectToEmployeeDetails(r.id)}>
          <a>{v}</a>
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Email ID',
      dataIndex: 'email'
    },
    {
      title: 'Reporter',
      dataIndex: 'reporterName',
      render: (v) => v || 'Self'
    },
    {
      title: 'Role',
      dataIndex: 'roleName'
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      ...(validateAccess('edit-employee') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/edit-employee/${row.id}`)}
              className="btn glow dropdown-toggle">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<EmployeeFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Employee Overview</h2>
      </div>
      <TableBox
        dataSource={employees}
        columns={columns}
        pageData={GET_DATA('employees.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
