import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { getActiveCurrencies } from '../../../Actions/UserAction'
import Address from '../../../Components/Address/Address'
import AltInput from '../../../Components/AltInput'
import Attachments from '../../../Components/Attachments'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import { useSelector } from '../../../Hooks/redux'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { INVOICE_STATUS_OPTIONS, TAX_CATEGORIES } from '../../../Util/Options'
import { amountToWords, convertSelectOptions, GET_DATA, parseAmount, SET_DATA } from '../../../Util/Util'
import '../Invoice.scss'
import ExpenseItems from './ExpenseItems'
import PaymentList from './PaymentList'

const defaultAddress = {
  buildingNo: '',
  street: '',
  additionalStreet: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  additionalNo: '',
  neighbourhood: ''
}

const Schema = Yup.object().shape({
  division: Yup.string().nullable().when('headerDivision', {
    is: true,
    then: Yup.string().required()
  }),
  client: Yup.string().required(),
  currency: Yup.string().required(),
  issueDate: Yup.date().required(),
  taxDate: Yup.date().required(),
  taxCategory: Yup.string().required(),
  items: Yup.array()
    .of(
      Yup.object().shape({
        division: Yup.string().nullable().when('lineDivision', {
          is: true,
          then: Yup.string().required()
        }),
        product: Yup.string().required(),
        quantity: Yup.number().min(1, 'Invoice qty must be greater than or equal to 1'),
        unit: Yup.string().required(),
        unitPrice: Yup.number().decimal().required(),
        taxType: Yup.string().required(),
        account: Yup.string().when('extraFields', {
          is: (extraFields) => extraFields?.includes('account'),
          then: Yup.string().required()
        })
      })
    )
    .required(),
  exchangeRate: Yup.number().required(),
  payments: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.date().required(),
        amount: Yup.number().required(),
        typeOfPayment: Yup.string().required()
      })
    )
    .required()
})

function ExpenseForm({
  match: {
    params: { id }
  },
  history,
  values,
  setValues,
  setFieldValue,
  onChangeStep,
  userInfo,
  submitForm
}) {
  const {
    kind,
    clientData,
    issueDate,
    taxCategory,
    items,
    editInvoice,
    currency,
    bank,
    exchangeRate,
    workflowStatus,
    paymentTerm,
    sentDate,
    attachments,
    amountInWords,
    amountInWordsAlt
  } = values
  const companyInfo = useSelector((state) => state.users.companyInfo)
  const expenseInvoiceApprover = companyInfo?.configurations?.expenseInvoiceApprover || []

  const companyConfig = companyInfo?.configurations || {}

  const [divisions, setDivisions] = useState([])
  const [clients, setClients] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [templates, setTemplates] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [options, setOptions] = useState({})
  const [accountOptions, setAccountOptions] = useState([])

  const bankOptions = convertSelectOptions(companyInfo.banks || [], 'bankAccountNo', 'bankAccountNo')

  const getData = () => {
    const clone = GET_DATA('expenses.clone')

    if (clone || id) {
      apiClient.get(`expenses/byId/${clone || id}`).then(({ data }) => {
        if (data && data.result) {
          const omitKeys = clone ? ['_id', 'id', 'invoiceNo', 'workflowStatus'] : []
          const expenseData = _.omit(data.result, omitKeys)
          data.result.template = expenseData.template ? expenseData.template : 'Default'
          setDisabled(expenseData.workflowStatus === 'Submitted' || expenseData.workflowStatus === 'Approved')

          if (
            companyInfo?.configurations?.division === 'Yes' &&
            companyInfo?.configurations?.expenseDivisionLevel === 'Line'
          ) {
            expenseData.items = expenseData.items.map((v) => {
              v.lineDivision = true

              return v
            })
          }

          setValues({ ...values, ...expenseData })
        }
      })
    } else {
      setFieldValue('bank', bankOptions[0]?.bankAccountNo || '')
    }

    apiClient.get('divisions/get-by-user').then(({ data }) => {
      if (data && data.result) {
        setDivisions(convertSelectOptions(data.result, 'name', 'id'))
      }
    })

    apiClient.get('clients/getAllActive', { params: { type: 'Vendor' } }).then(({ data }) => {
      if (data && data.result) {
        const clientOptions = data.result.map((v) => {
          v.label = `${v.clientNo} - ${v.name}`
          v.value = v.id

          return v
        })
        setClients(clientOptions)
      }
    })

    apiClient.get('account-setups/get-active', { params: { type: kind } }).then(({ data }) => {
      if (data && data.result) {
        setAccountOptions(convertSelectOptions(data.result, 'name', 'code'))
      }
    })

    apiClient
      .get('options/get-by-types', { params: { type: ['ExpenseType', 'UnitOfMeasurement', 'PaymentType'] } })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })

    getActiveCurrencies().then((currencies) => {
      if (currencies) {
        setCurrencies(convertSelectOptions(currencies, 'name', 'code'))
      }
    })

    apiClient
      .get('customTemplates/getActive', { params: { type: 'Invoice', for: 'Expense' } })
      .then(({ data }) => {
        if (data && data.result) {
          const templates = [
            { label: 'Default', value: 'Default' },
            ...convertSelectOptions(data.result, 'name', 'id')
          ]
          setTemplates(templates)
        }
      })
  }

  useEffect(() => {
    getData()

    return () => {
      SET_DATA('expenses.clone', false)
    }
  }, [id])

  const onChangeClient = (client) => {
    if (client) {
      const clientData = clients.find((v) => v.id === client)

      if (clientData) {
        setFieldValue('clientData', clientData)
        setFieldValue('shippingAddress', clientData.shippingAddress)
        setFieldValue('billingAddress', clientData.billingAddress)
        setFieldValue('currency', clientData.currency)
        setFieldValue('paymentTerm', clientData.paymentTerm)
        setFieldValue('paymentType', clientData.paymentType)
        setFieldValue('clientCompany', clientData.clientCompany || null)
        getExchangeRate(clientData.currency, issueDate)
      }
    }
  }

  useEffect(() => {
    if (sentDate) {
      const term = paymentTerm || 0
      setFieldValue('paymentDueDate', moment(sentDate).add(term, 'days'))
    }
  }, [paymentTerm, sentDate])

  useEffect(() => {
    if (bank && bankOptions.length > 0) {
      const selectedBank = bankOptions.find((b) => b.value === bank)

      if (selectedBank) {
        setFieldValue('bankData', selectedBank || {})
      }
    }
  }, [bank])

  const getExchangeRate = (toCurrency, date) => {
    if (toCurrency && date) {
      apiClient
        .get('exchange-rates/get-rate', {
          params: { toCurrency, date: moment(date).format('YYYY-MM-DD') }
        })
        .then(({ data }) => {
          if (data && data.result && data.result.rate) {
            setFieldValue('exchangeRate', data.result.rate)
          } else {
            setFieldValue('exchangeRate', toCurrency === companyInfo.currency ? 1 : '')
          }
        })
    }
  }

  const amount = _.sumBy(items, 'amount')
  const discountAmount = _.sumBy(items, 'discountAmount')
  const retentionAmount = _.sumBy(items, 'retentionAmount')
  const chargeAmount = _.sumBy(items, 'chargeAmount')
  const netAmount = _.sumBy(items, 'netAmount')
  const taxAmount = _.sumBy(items, 'taxAmount')
  const grossAmount = _.sumBy(items, 'grossAmount')
  const paidAmount = _.sumBy(values.payments, 'amount')
  const updateAndPost = workflowStatus === 'Approved' && values.payments.find((v) => v.posting === 'Pending')
  const status = grossAmount === paidAmount ? 'Paid' : paidAmount > 0 ? 'PartiallyPaid' : 'Pending'

  const additionalFields = companyInfo?.configurations?.expenseAdditionalFields || []

  useEffect(() => {
    const currencyData = currencies.find((cur) => cur.value === currency)
    setFieldValue('amountInWords', amountToWords(parseAmount(grossAmount, currencyData, true), currencyData))
  }, [grossAmount, currency])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={22}>
          <PanelLayout title={`Expense ${kind || ''} (${workflowStatus || 'new'})`}>
            <Row gutter={[50, 10]} className="pb-4 mb-4 border-bottom">
              <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="form-field">
                  <Field
                    as="select"
                    label="Vendor"
                    name="client"
                    options={clients}
                    onChange={(n, v) => {
                      onChangeClient(v)

                      return setFieldValue(n, v)
                    }}
                    disabled={disabled}
                  />
                </div>

                {clientData && (
                  <div style={{ padding: '10px 3px' }}>
                    {clientData.taxNo && (
                      <div>
                        <b>{clientData.taxType || 'VAT'} No: </b>
                        {clientData.taxNo}
                      </div>
                    )}
                    {clientData && clientData.crNo && (
                      <div>
                        <b>CR No: </b>
                        {clientData.crNo}
                      </div>
                    )}
                  </div>
                )}
              </Col>
              <Col xs={24} sm={12} md={12} lg={6}>
                <Address addressType="shippingAddress" label="Shipping Address" disabled={disabled} />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Address addressType="billingAddress" label="Billing Address" disabled={disabled} />
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <h2>Invoice Details</h2>
              </Col>
            </Row>
            <Row gutter={[10, 10]} className="pb-4 mb-4 border-bottom">
              {values.headerDivision && companyConfig.expenseDivisionLevel === 'Header' && (
                <Col xs={24} sm={12} md={8} lg={3}>
                  <div className="form-field">
                    <Field as="select" name="division" label="Division" options={divisions} />
                  </div>
                </Col>
              )}
              <Col xs={24} sm={12} md={8} lg={values.headerDivision ? 4 : 7}>
                {companyInfo.configurations?.manualExpenseInvoiceNo === 'Yes' && (
                  <Field
                    name="editInvoice"
                    as="checkbox"
                    style={{ position: 'absolute', left: 70 }}
                    disabled={disabled}
                  />
                )}
                <div className="form-field">
                  <Field name="invoiceNo" label="Invoice No" disabled={disabled || !editInvoice} altInput />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field name="poNo" label="PO / Reference No / Date" disabled={disabled} altInput />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4}>
                <div className="form-field">
                  <Field
                    name="currency"
                    as="select"
                    label="Currency"
                    onChange={(n, v) => {
                      getExchangeRate(v, issueDate)
                      const selectedBank = bankOptions.find((b) => b.bankCurrency === v)

                      if (selectedBank) {
                        setFieldValue('bank', selectedBank ? selectedBank.bankAccountNo : '')
                      }

                      return setFieldValue(n, v)
                    }}
                    options={currencies}
                    disabled={disabled}
                    altInput
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={3}>
                <div className="form-field">
                  <Field
                    name="issueDate"
                    as="date"
                    label="Invoice / Issue Date"
                    onChange={(n, v) => {
                      getExchangeRate(currency, v)

                      return setFieldValue(n, v)
                    }}
                    disabled={disabled}
                    altInput
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={3}>
                <div className="form-field">
                  <Field name="taxDate" as="date" label="Tax Period" disabled={disabled} altInput />
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} lg={3}>
                <div className="form-field">
                  <Field
                    as="select"
                    name="taxCategory"
                    label="Tax Category"
                    options={TAX_CATEGORIES}
                    disabled={disabled}
                  />
                </div>
              </Col>
              {additionalFields.includes('location') && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="form-field">
                    <Field name="location" label="Location" altInput />
                  </div>
                </Col>
              )}
              {additionalFields.includes('premise') && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="form-field">
                    <Field name="premise" label="Premise" altInput />
                  </div>
                </Col>
              )}
              {additionalFields.includes('slipNo') && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="form-field">
                    <Field name="slipNo" label="Slip No" altInput />
                  </div>
                </Col>
              )}
              {additionalFields.includes('projectName') && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="form-field">
                    <Field name="projectName" label="Project Name" altInput />
                  </div>
                </Col>
              )}
              {additionalFields.includes('contractNo') && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="form-field">
                    <Field name="contractNo" label="Contract No" altInput />
                  </div>
                </Col>
              )}
              {additionalFields.includes('contractTitle') && (
                <Col xs={24} sm={12} md={8} lg={6}>
                  <div className="form-field">
                    <Field name="contractTitle" label="Contract Title" altInput />
                  </div>
                </Col>
              )}
            </Row>
            <Panel title="Invoice Entries">
              <FieldArray
                name="items"
                defaultValues={{
                  lineDivision:
                    companyInfo?.configurations?.division === 'Yes' &&
                    companyInfo?.configurations?.expenseDivisionLevel === 'Line',
                  division: '',
                  type: '',
                  product: '',
                  description: '',
                  quantity: 1,
                  unit: 'EA',
                  unitPrice: '',
                  discount: '',
                  discountFormat: '%',
                  retention: '',
                  retentionFormat: '%',
                  charge: '',
                  chargeFormat: '%',
                  tax: companyInfo?.tax || '',
                  taxFormat: companyInfo?.taxFormat || '%',
                  netAmount: '',
                  grossAmount: '',
                  taxType: '',
                  notes: '',
                  account: ''
                }}
                additionalValues={{
                  disabled,
                  divisions,
                  options,
                  currency,
                  taxCategory,
                  additionalFields,
                  accountOptions
                }}
                editable={!disabled}>
                {ExpenseItems}
              </FieldArray>
            </Panel>
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                className="invoice-attachment-container ">
                <Attachments
                  name="attachments"
                  value={attachments}
                  title="Attach Files"
                  description="You can upload a maximum of 3 files, 5MB each"
                  acceptFile={['image', 'pdf']}
                  onUpload={setFieldValue}
                  disabled={disabled}
                />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 20 }} md={{ span: 18 }} lg={{ span: 12 }}>
                <div className="payment-details">
                  <div className="details">
                    <Row gutter={[16]}>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <h3>Total Amount:</h3>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div>
                          {parseAmount(amount, currency)} {currency}
                        </div>
                      </Col>
                    </Row>
                    {additionalFields.includes('discount') && (
                      <Row gutter={[16]}>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <h3>Total Discount:</h3>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <div>
                            - {parseAmount(discountAmount, currency)} {currency}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {additionalFields.includes('charge') && (
                      <Row gutter={[16]}>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <h3>Total Charge:</h3>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <div>
                            + {parseAmount(chargeAmount, currency)} {currency}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={[16]}>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <h3>Total Net:</h3>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div>
                          {parseAmount(netAmount, currency)} {currency}
                        </div>
                      </Col>
                    </Row>
                    {additionalFields.includes('retention') && (
                      <Row gutter={[16]}>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <h3>Total Retention:</h3>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <div>
                            - {parseAmount(retentionAmount, currency)} {currency}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={[16]}>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <h3>Total {companyInfo.taxType || 'VAT'}:</h3>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div>
                          + {parseAmount(taxAmount, currency)} {currency}
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <h3>Total Gross:</h3>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div>
                          {parseAmount(grossAmount, currency)} {currency}
                        </div>
                        <i style={{ color: 'gray' }}>(Amount includes {companyInfo.taxType || 'VAT'})</i>
                      </Col>
                    </Row>
                    <Row gutter={[16]}>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <h3>Total Amount to be paid:</h3>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div>
                          {parseAmount(grossAmount, currency)} {currency}
                        </div>
                        <div className="d-flex justify-content-end">
                          <div style={{ width: '80%' }}>
                            <i style={{ color: 'gray' }}>{amountInWords}</i>
                          </div>
                          <div className="ml-2">
                            <AltInput
                              name="amountInWords"
                              label="Amount In Words"
                              onChange={setFieldValue}
                              altValue={amountInWordsAlt}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {companyInfo && currency && companyInfo.currency !== currency && (
                      <Row gutter={[16]}>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <h3>Exchange Rate:</h3>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <Field
                            name="exchangeRate"
                            style={{ textAlign: 'right', width: 100 }}
                            disabled={disabled}
                          />
                        </Col>
                      </Row>
                    )}
                    {companyInfo && currency && companyInfo.currency !== currency && (
                      <Row gutter={[16]}>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <h3>Total Amount in company currency:</h3>
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                          <div>
                            {parseAmount(grossAmount * exchangeRate, companyInfo.currency)}{' '}
                            {companyInfo.currency}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="invoice-section border-bottom">
                  <h2>Payments</h2>

                  <Row gutter={[10, 10]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 3 }}>
                      <div className="form-field">
                        <Field name="dateOfSupply" label="Date of supply" as="date" altInput />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 3 }}>
                      <div className="form-field">
                        <Field name="paymentTerm" label="Payment Term" type="number" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 3 }}>
                      <div className="form-field">
                        <Field as="date" name="sentDate" label="Sent On" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 3 }}>
                      <div className="form-field">
                        <Field as="date" name="paymentDueDate" label="Due Date" />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-field">
                        <Field as="select" name="bank" label="Bank" options={bankOptions} allowClear />
                      </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
                      <div className="form-field">
                        <Field
                          as="select"
                          name="status"
                          value={status}
                          label="Status"
                          disabled
                          options={INVOICE_STATUS_OPTIONS}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Panel title={`Invoice Payments - Paid Amount: ${parseAmount(paidAmount, currency)}`}>
              <FieldArray
                name="payments"
                defaultValues={{
                  date: '',
                  amount: '',
                  typeOfPayment: 'Payment',
                  posting: 'Pending',
                  new: true
                }}
                showAdd>
                {PaymentList}
              </FieldArray>
            </Panel>

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="invoice-section">
                  <h2>Invoice / Template Information</h2>
                  <Row gutter={[26, { xs: 8, sm: 16, md: 20, lg: 20 }]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                      <div className="form-field">
                        <Field as="select" name="template" label="Template" options={templates} />
                      </div>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 16 }}>
                      <div className="form-field">
                        <Field as="textarea" name="notes" label="Notes" rows="1" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </PanelLayout>
          <FooterActions
            leftActions={[
              {
                prefix: 'flaticon-back',
                label: 'Back',
                onClick: () => (onChangeStep ? onChangeStep(0) : history.goBack())
              }
            ]}
            centerActions={[
              {
                prefix: 'flaticon-writing',
                label: id ? (updateAndPost ? 'Update & Post' : 'Update') : 'Save',
                onClick: () => {
                  setFieldValue('completeWorkflow', 'No')
                  submitForm()
                }
              }
            ]}
            rightActions={[
              {
                prefix: 'flaticon-security',
                label: `${id ? 'Update &' : 'Save &'} ${
                  expenseInvoiceApprover?.includes(userInfo.id) || expenseInvoiceApprover.length === 0
                    ? 'Approve'
                    : 'Submit'
                }`,
                onClick: () => {
                  setFieldValue('completeWorkflow', 'Yes')
                  submitForm()
                },
                dontShow: !(workflowStatus === '' || workflowStatus === 'Created')
              }
            ]}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({
    match: {
      params: { kind }
    },
    companyInfo
  }) => ({
    division: '',
    client: '',
    kind,
    shippingAddress: defaultAddress,
    billingAddress: defaultAddress,
    invoiceNo: '',
    poNo: '',
    currency: '',
    issueDate: moment(),
    taxDate: moment(),
    taxCategory: 'Standard',
    items: [
      {
        lineDivision:
          companyInfo?.configurations?.division === 'Yes' &&
          companyInfo?.configurations?.expenseDivisionLevel === 'Line',
        division: '',
        type: '',
        product: '',
        description: '',
        quantity: 1,
        unit: 'EA',
        unitPrice: '',
        discount: '',
        discountFormat: '%',
        retention: '',
        retentionFormat: '%',
        charge: '',
        chargeFormat: '%',
        tax: companyInfo?.tax || '',
        taxFormat: companyInfo?.taxFormat || '%',
        netAmount: '',
        grossAmount: '',
        taxType: 'Normal VAT',
        notes: '',
        account: '',
        extraFields: companyInfo?.configurations?.expenseAdditionalFields || []
      }
    ],
    amount: 0.0,
    discountAmount: 0.0,
    retentionAmount: 0.0,
    chargeAmount: 0.0,
    netAmount: 0.0,
    taxAmount: 0.0,
    grossAmount: 0.0,

    amountInWords: '',
    amountInWordsAlt: '',

    exchangeRate: '',
    dateOfSupply: moment(),
    paymentTerm: '',
    sentDate: moment(),
    paymentDueDate: '',
    paymentType: '',
    paymentDate: '',
    paidAmount: '',
    status: 'Pending',
    payments: [],

    bank: '',
    bankData: {},
    template: companyInfo?.configurations?.defaultExpenseInvoice || 'Default',
    notes: '',
    workflowStatus: '',
    attachments: [],
    completeWorkflow: 'No',
    headerDivision:
      companyInfo?.configurations?.division === 'Yes' &&
      companyInfo?.configurations?.expenseDivisionLevel === 'Header'
  }),
  validationSchema: Schema,
  handleSubmit: (
    data,
    {
      props: {
        history,
        userInfo: { companyData },
        match: {
          params: { id }
        }
      }
    }
  ) => {
    data.division = data.division !== '' ? data.division : null
    data.amount = _.sumBy(data.items, 'amount')
    data.discountAmount = _.sumBy(data.items, 'discountAmount')
    data.retentionAmount = _.sumBy(data.items, 'retentionAmount')
    data.chargeAmount = _.sumBy(data.items, 'chargeAmount')
    data.netAmount = _.sumBy(data.items, 'netAmount')
    data.taxAmount = _.sumBy(data.items, 'taxAmount')
    data.grossAmount = _.sumBy(data.items, 'grossAmount')
    data.totalAmount = _.sumBy(data.items, 'grossAmount')
    data.paidAmount = _.sumBy(data.payments, 'amount')
    data.items = data.items.map((v, i) => {
      v.position = i + 1
      v.division = v.division !== '' ? v.division : data.division

      return v
    })
    data.companyCurrency = companyData.currency
    data.companyTotalAmount = data.grossAmount * data.exchangeRate
    data.staus =
      data.totalAmount === data.paidAmount ? 'Paid' : data.paidAmount > 0 ? 'PartiallyPaid' : 'Pending'

    data.template = data.template === 'Default' ? null : data.template

    if (data.paidAmount > data.totalAmount) {
      message.error('Paid amount cannot be greater than invoice amount')

      return true
    }

    if (id) {
      apiClient.put(`expenses/update/${id}`, data).then(({ data }) => {
        if (data && data.result) {
          history.push(`/app/expenses/${data.result.id}`)
        }
      })
    } else {
      data.workflowStatus = 'Created'
      apiClient.post('expenses/add', data).then(({ data }) => {
        if (data && data.result) {
          history.push(`/app/expenses/${data.result.id}`)
        }
      })
    }
  }
})(ExpenseForm)
