import { SettingOutlined } from '@ant-design/icons'
import { message, Popover, Space, Tabs } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { cancelExpense, submitExpense } from '../../../Actions/InvoiceAction'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import InputBox from '../../../Components/InputBox/InputBox'
import LoaderBox from '../../../Components/LoaderBox/LoaderBox'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, parseAmount, SET_DATA, validateAccess } from '../../../Util/Util'
import Logs from '../../Logs/Logs'
import AddInvoice from '../AddInvoice'
import './Expenses.scss'
import Filter from './Filter'

const { TabPane } = Tabs

export default function Expenses(props) {
  const cacheData = GET_DATA('expenses')
  const [toggle, setToggle] = useState(false)
  const [toggleType, setToggleType] = useState(false)
  const [loader, setLoader] = useState('Loading Invoices..')
  const [selectedRows, setSelectedRows] = useState(cacheData?.selectedRows || [])
  const [activeTab, setActiveTab] = useState(cacheData?.activeTab || 'Created')
  const [expenses, setExpenses] = useState([])
  const [cancelView, setCancelView] = useState(false)
  const [cancelText, setCancelText] = useState('')

  useEffect(() => {
    onFilter(GET_DATA('expenses.filterData'))
  }, [activeTab])

  const onFilter = (query = {}) => {
    const params = { ...query }

    if (activeTab !== 'All' && activeTab !== 'OverDue') {
      params.workflowStatus = activeTab
    }

    if (activeTab === 'OverDue') {
      params.overDue = moment().toISOString()
    }

    apiClient.get('expenses/get-all', { params }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('expenses.filterData', { ...params, ...data.pageData })
        setExpenses(data.result)
        setLoader(false)
      } else {
        setLoader('No invoice found')
      }
    })
  }

  const showModal = (data, type) => {
    setToggle(data)
    setToggleType(type)
  }

  const handleCancel = () => {
    setToggle(false)
    setToggleType(false)
    setCancelView(false)
  }

  const onDuplicate = (row) => {
    SET_DATA('expenses.clone', row.id)
    props.history.push(`/app/add-expense/${row.kind}`)
  }

  const tableContent = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/expenses/${row.id}`}>
            <i className="flaticon-eye" /> View
          </Link>
        </li>
        {validateAccess('edit-expense') && (
          <li>
            <Link to={`/app/edit-expense/${row.id}`}>
              <i className="flaticon-edit-1" /> {row.workflowStatus === 'Approved' ? 'Edit Payment' : 'Edit'}
            </Link>
          </li>
        )}
        {validateAccess('edit-expense') &&
          (row.workflowStatus === 'Created' || row.workflowStatus === 'Rejected') && (
            <li>
              <Link to="#" onClick={() => onDelete(row.id)}>
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </li>
          )}
        {validateAccess('edit-expense') && row.workflowStatus === 'Created' && (
          <li>
            <Link to="#" onClick={() => onSubmit(row.id)}>
              <i className="flaticon-tick-1" /> Submit
            </Link>
          </li>
        )}
        {validateAccess('edit-expense') &&
          props.companyInfo?.configurations?.approvedExpenseCancellation === 'Yes' &&
          row.workflowStatus === 'Approved' && (
            <li>
              <Link to="#" onClick={() => setCancelView(row)}>
                <i className="flaticon-email-1" /> Cancel
              </Link>
            </li>
          )}
        {validateAccess('add-expense') && (
          <li>
            <Link to="#" onClick={() => onDuplicate(row)}>
              <i className="flaticon-copy" /> Duplicate
            </Link>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => showModal(row, 'Logs')}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const onDelete = (id) => {
    apiClient.delete(`expenses/delete/${id}`).then(({ data }) => {
      if (data && data.result) {
        setExpenses(expenses.filter((item) => item.id !== id))
        message.success('Invoice Deleted')
      }
    })
  }

  const onCancelInvoice = () => {
    if (cancelText !== '') {
      cancelExpense(cancelView.id, { reason: cancelText }).then((invoice) => {
        if (invoice) {
          message.success('Invoice Cancelled and submitted for approval')
          const invoices = expenses.filter((inv) => inv.id !== cancelView.id)
          setExpenses(invoices)
          setCancelView(false)
        }
      })
    } else {
      message.error('Please enter description')
    }
  }

  const onSubmit = (id) => {
    submitExpense({ invoices: [id] }).then((invoice) => {
      if (invoice) {
        message.success('Invoice Submitted')
        setExpenses(expenses.filter((inv) => inv.id !== id))
      }
    })
  }

  const onMassSubmit = () => {
    const selectedIds = selectedRows.map((val) => val.id)

    if (selectedIds.length > 0) {
      submitExpense({ invoices: selectedIds }).then((invoice) => {
        if (invoice) {
          message.success('Invoice Submitted')
          setExpenses(expenses.filter((inv) => selectedIds.indexOf(inv.id) === -1))
        }
      })
    } else {
      message.error('Please select invoice')
    }
  }

  const delayedDays = (days) => <div>Payment Delayed days - {days}</div>

  const getLinkRecord = (text, record) => {
    if (record.status !== 'Paid' && record.paymentDueDate) {
      const days = moment().diff(moment(record.paymentDueDate), 'days')

      if (days > 0) {
        return (
          <Link to={`/app/expenses/${record.id}`}>
            <Popover placement="bottom" content={() => delayedDays(days)} trigger="hover">
              <a style={{ color: 'red' }}>{text}</a>
            </Popover>
          </Link>
        )
      }

      return <Link to={`/app/expenses/${record.id}`}>{text}</Link>
    }

    return <Link to={`/app/expenses/${record.id}`}>{text}</Link>
  }

  const onChangeTab = (activeTab) => {
    SET_DATA('expenses.activeTab', activeTab)
    SET_DATA('expenses.filterData', { ...GET_DATA('expenses.filterData'), page: 1 })
    setActiveTab(activeTab)
    setSelectedRows([])
  }

  const onChangePage = (pageData) => {
    const cacheData = GET_DATA('expenses.filterData')
    onFilter({ ...(cacheData || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Division',
      dataIndex: 'divisionData',
      render: (text) => text?.name || '',
      dontShow: !(
        props.companyInfo?.configurations?.division === 'Yes' &&
        props.companyInfo?.configurations?.incomeDivisionLevel === 'Header'
      )
    },
    {
      title: 'Doc No',
      dataIndex: 'invoiceNo',
      render: (text, record) => getLinkRecord(text, record)
    },
    {
      title: 'Invoice/Issue Date',
      dataIndex: 'issueDate',
      render: (text) => (text ? moment(text).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Vendor',
      dataIndex: 'client',
      render: (text, record) => (record.clientData ? record.clientData.name : '')
    },
    {
      title: 'PO/Reference No',
      dataIndex: 'poNo'
    },
    {
      title: 'Net Amount',
      dataIndex: 'netAmount',
      render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
    },
    {
      title: 'Gross Amount',
      dataIndex: 'grossAmount',
      render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
    },

    {
      title: 'Due Date',
      dataIndex: 'paymentDueDate',
      render: (v) => (v ? moment(v).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Balance Amount',
      dataIndex: 'grossAmount',
      render: (v, r) => `${parseAmount((r.grossAmount || 0) - (r.paidAmount || 0), r.currency)} ${r.currency}`
    },

    {
      title: 'Status',
      dataIndex: 'workflowStatus'
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      render: (text) => (
        <Space size="middle">
          <a
            className={`status badge ${text.toLowerCase()} ant-dropdown-link status`}
            onClick={(e) => e.preventDefault()}>
            {text === 'Inprogress' ? 'In Progress' : text}
          </a>
        </Space>
      )
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableContent(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Add New Expense',
        onClick: () => props.history.push('/app/add-expense/Invoice'),
        access: 'add-expense'
      }}
      filterData={GET_DATA('expenses.filterData')}
      filter={<Filter {...props} onFilter={onFilter} />}>
      <TableLayout
        title="Expense / All documents"
        exportUrl="expenses/export"
        detailed
        rightSection={
          selectedRows.length > 0 &&
          activeTab === 'Created' && <ButtonBox onClick={() => onMassSubmit()}>Send For Approval</ButtonBox>
        }>
        <Tabs defaultActiveKey={activeTab} onChange={onChangeTab}>
          <TabPane tab="Created" key="Created" />
          <TabPane tab="Submitted" key="Submitted" />
          <TabPane tab="Approved" key="Approved" />
          <TabPane tab="Rejected" key="Rejected" />
          <TabPane tab="OverDue" key="OverDue" />
          <TabPane tab="All" key="All" />
        </Tabs>
        {!loader && (
          <TableBox
            dataSource={expenses}
            columns={columns}
            onSelect={(selected) => {
              SET_DATA('expenses.selectedRows', selected)
              setSelectedRows(selected)
            }}
            selectedRows={selectedRows}
            pageData={GET_DATA('expenses.filterData')}
            onChangePage={onChangePage}
          />
        )}
        <LoaderBox loader={loader} />
      </TableLayout>
      <ModalBox
        title={toggleType}
        visible={!!toggle}
        onCancel={handleCancel}
        // width={['Add', 'Logs'].includes(toggleType) ? 1000 : false}
        destroyOnClose
        footer={false}>
        {toggleType === 'Add' && <AddInvoice type="inc" onCancel={handleCancel} />}
        {toggleType === 'Logs' && <Logs entityId={toggle.id} entityType="ExpenseInvoice" />}
      </ModalBox>
      <ModalBox
        title="Cancel Invoice"
        visible={!!cancelView}
        onOk={onCancelInvoice}
        onCancel={handleCancel}
        destroyOnClose>
        <InputBox
          label="Reason"
          value={cancelText}
          onChangeText={(cancelText) => setCancelText(cancelText)}
          textArea
        />
      </ModalBox>
    </FilterLayout>
  )
}
