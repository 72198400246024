import { Col, message, Radio, Row } from 'antd'
import { withFormik } from 'formik'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import Button from '../../Components/Button'
import Field from '../../Components/Formik/Field'
import Form from '../../Components/Formik/Form'
import Panel from '../../Layout/Panel'
import PanelLayout from '../../Layout/PanelLayout'
import apiClient from '../../Util/apiClient'
// import { TIMEZONES } from '../../Util/Options'

const Schema = Yup.object().shape({
  scheduleType: Yup.string().required(),
  date: Yup.date().when('scheduleType', {
    is: (scheduleType) => scheduleType === 'Once',
    then: Yup.date().required()
  }),
  time: Yup.date().when('scheduleType', {
    is: (scheduleType) => scheduleType !== 'Manual',
    then: Yup.date().required()
  }),
  // timezoneOffSet: Yup.string().required(),
  inputPath: Yup.string().required(),
  outputPath: Yup.string().required()
})

function Schedules({ setValues, setFieldValue, values, submitForm }) {
  const getData = () => {
    apiClient.get('schedules/get').then(({ data }) => {
      if (data && data.result) {
        setValues(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={12}>
          <PanelLayout title="Generate Invoice">
            <Panel title="Generate Invoice">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Radio.Group
                    onChange={(e) => setFieldValue('scheduleType', e.target.value)}
                    value={values.scheduleType}
                    optionType="button"
                    buttonStyle="solid">
                    <Radio value="Manual">Manual</Radio>
                    <Radio value="Once">Once</Radio>
                    <Radio value="Daily">Daily</Radio>
                  </Radio.Group>
                </Col>
                {values.scheduleType === 'Once' && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field as="date" name="date" label="Date" />
                    </div>
                  </Col>
                )}
                {values.scheduleType !== 'Manual' && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field as="date" name="time" label="At" picker="time" format="HH:mm" />
                    </div>
                  </Col>
                )}
                {/* <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="timezoneOffSet"
                      label="Time Zone"
                      options={TIMEZONES}
                      onChange={(n, v, o) => {
                        setFieldValue('timezone', o.children)

                        return setFieldValue(n, v)
                      }}
                    />
                  </div>
                </Col> */}
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="form-field">
                    <Field addonBefore="/E-Invoice/" name="inputPath" label="Input Path" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="form-field">
                    <Field addonBefore="/E-Invoice/" name="outputPath" label="Output Path" />
                  </div>
                </Col>
                {values.scheduleType === 'Manual' && (
                  <Col xs={24} sm={24} md={12} lg={24}>
                    <div className="form-field">
                      <Button variant="primary" onClick={() => submitForm()}>
                        Generate Now
                      </Button>
                    </div>
                  </Col>
                )}
              </Row>
            </Panel>
          </PanelLayout>

          <div className="save-changes">
            <Button type="submit" variant="primary">
              Update
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    scheduleType: 'Manual',
    date: '',
    time: '',
    timezone: '',
    timezoneOffSet: '',
    inputPath: '',
    outputPath: ''
  }),
  validationSchema: Schema,
  handleSubmit: (data) => {
    const date = moment(data.date).format('YYYY-MM-DD')
    const time = moment(data.time).utc().format('HHmm')
    const timeNumber = parseFloat(time)
    const obj = { ...data, date, timeNumber }
    apiClient.post('schedules/manage', obj).then(({ data }) => {
      if (data && data.result) {
        if (obj.scheduleType === 'Manual') {
          apiClient.post('schedules/generateInvoice').then(({ data }) => {
            if (data && data.result) {
              message.success('Invoice generation triggered successfully')
            }
          })
        } else {
          message.success('Schedule updated')
        }
      }
    })
  }
})(Schedules)
