import AntdButton, { ButtonProps } from 'antd/lib/button'
import { ButtonHTMLType, ButtonType } from 'antd/lib/button/button'
import clsx from 'clsx'
import React from 'react'

type TButton = {
  type?: ButtonHTMLType
  variant?: ButtonType
  success?: boolean
} & Omit<ButtonProps, 'type' | 'htmlType'>

export default function Button({
  type = 'button',
  variant = 'default',
  className,
  success,
  ...props
}: TButton) {
  return (
    <AntdButton
      className={clsx(className, success && 'ant-btn-success')}
      htmlType={type}
      type={variant as ButtonType}
      {...props}>
      {props.children}
    </AntdButton>
  )
}
