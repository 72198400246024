import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import BlockChainFlow from '../../../Components/BlockExplorerBox/BlockChainFlow'
import apiClient from '../../../Util/apiClient'
import { sanitize } from '../../../Util/Util'

export default function OutBoundTransmissionView(props) {
  const [transmission, setTransmission] = useState([])

  const getData = () => {
    if (props.match.params.id) {
      apiClient.get(`incomes/get-outbound-transmissions/${props.match.params.id}`).then(({ data }) => {
        if (data.result) {
          setTransmission(data.result)
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Row>
      <Col xs={24} sm={24} md={16} lg={18}>
        <div style={{ paddingBottom: 70 }}>
          {transmission && (
            <div
              style={{
                width: 'fit-content',
                margin: '15px auto',
                background: '#fff'
              }}>
              <div
                style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', transition: 0.3 }}
                {...sanitize(transmission.htmlContent)}
              />
            </div>
          )}
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} lg={6}>
        {transmission && transmission.transmissionSentId && (
          <div className="vertical-view">
            <div className="heading">
              <h2>Transmission details</h2>
            </div>
            <div className="more-details">
              <BlockChainFlow blockData={transmission} />
            </div>
          </div>
        )}
      </Col>
    </Row>
  )
}
