import { Col, message, Row, Space } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { getMasterCurrencies } from '../../../Actions/UserAction'
import Address from '../../../Components/Address/Address'
import Button from '../../../Components/Button'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import Select from '../../../Components/Formik/Select'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import { useSelector } from '../../../Hooks/redux'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import {
  DEFAULT_ADDRESS_FIELDS,
  DEFAULT_ORDER_DETAILS,
  TAX_CATEGORIES,
  TAX_TYPES
} from '../../../Util/Options'
import { amountToWords, convertSelectOptions, parseAmount } from '../../../Util/Util'
import { salesQuotationSchema } from '../../../Util/validationSchema'
import SingleOrderDetails from '../Orders/SingleOrderDetails'

function SalesQuotationForm({
  values,
  setValues,
  setFieldValue,
  submitForm,
  history,
  match: {
    params: { id }
  }
}) {
  const [customers, setCustomers] = useState([])
  const [templates, setTemplates] = useState([])
  const [contactPersons, setContactPersons] = useState([])
  const [clientData, setClientData] = useState('')
  const [currencies, setCurrencies] = useState([])
  const [defaultDiscount, setDefaultDiscount] = useState('')
  const [discountType, setDiscountType] = useState('')
  const [orderNo, setOrderNo] = useState('')
  const [suppliers, setSuppliers] = useState([])
  const [setSupplier, setSetSupplier] = useState('')
  const [showModel, setShowModel] = useState(false)

  const disabled = id && values.status !== 'Created'
  const { tax, ...companyInfo } = useSelector((state) => state.users.companyInfo)

  const getData = async () => {
    getMasterCurrencies().then((data) => setCurrencies(data))

    apiClient
      .get('customTemplates/getActive', { params: { type: 'Order', for: 'Sales Quotation' } })
      .then(({ data }) => {
        if (data && data.result) {
          const templates = [
            { label: 'Default', value: 'Default' },
            ...convertSelectOptions(data.result, 'name', 'id')
          ]
          setTemplates(templates)
        }
      })

    apiClient.get('clients/getNames').then(({ status, data }) => {
      if (status === 200) {
        setCustomers(
          data
            .filter((item) => item.type === 'Customer')
            .map((item) => ({
              ...item,
              label: item.name,
              value: item._id,
              contactPersons: item.contactPersons.map((item) => ({
                value: item.id,
                label: _.compact([item.contactName, item.contactEmail, item.contactPhone]).join(', '),
                ...item
              }))
            }))
        )
        setSuppliers(
          data
            .filter((item) => item.type === 'Vendor')
            .map((item) => ({
              ...item,
              label: item.name,
              value: item._id,
              contactPersons: item.contactPersons.map((item) => ({
                value: item.id,
                label: _.compact([item.contactName, item.contactEmail, item.contactPhone]).join(', '),
                ...item
              }))
            }))
        )
      }
    })

    if (id) {
      apiClient
        .get(`sales-quotations/${id}`)
        .then(({ status, data }) => {
          if (status === 200) {
            data.template = data.template ? data.template : 'Default'
            data.orderDetails = data.orderDetails.map((order) => ({
              ...order,
              tempTax: order.tax,
              materialCodeDesc: _.compact([order.materialCode, order.materialDescription]).join(' - ')
            }))
            setValues({ ...values, ...data })

            return data.status
          }
        })
        .then((status) => {
          if (status && status === 'Accepted') {
            apiClient.get(`orders/quotation/${id}`).then(({ status, data }) => {
              if (status === 200) {
                setOrderNo(data)
              }
            })
          }
        })
    } else {
      const obj = {
        orderDetails: [{ ...DEFAULT_ORDER_DETAILS, tax, tempTax: tax }]
      }

      await apiClient
        .get('terms-conditions/current', { params: { type: 'Sales Quotation' } })
        .then(({ status, data }) => {
          if (status === 200) {
            obj.termsConditions = data.content
          }
        })

      setValues({ ...values, ...obj })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleCustomer = (n, v, option = { contactPersons: [] }) => {
    setClientData(option)
    setDefaultDiscount(option.discount)
    setDiscountType(option.discountFormat)

    const primaryContacts = option.contactPersons

    setFieldValue('customerAlt', option.nameAlt)
    setFieldValue('taxCategory', option.currency === companyInfo.currency ? 'Standard' : 'Export')
    setFieldValue('currency', option.currency)
    setFieldValue('billingAddress', option.billingAddress)
    setFieldValue('shippingAddress', option.shippingAddress)
    setFieldValue('payTerm', option.paymentTerm)

    if (option.discount || option.currency !== companyInfo.currency) {
      setFieldValue(
        'orderDetails',
        values.orderDetails.map((item) => ({
          ...item,
          ...(option.discount && {
            discount: option.discount,
            discountType: option.discountFormat
          }),
          ...(option.currency !== companyInfo.currency && { taxType: 'Zero VAT' })
        }))
      )
    }

    setContactPersons(primaryContacts)

    setFieldValue('contactPerson', primaryContacts.length === 1 ? primaryContacts[0].value : '')

    return setFieldValue(n, v)
  }

  useDidUpdateEffect(() => {
    if (values.currency && companyInfo.currency && !disabled) {
      apiClient
        .get('exchange-rates/get-rate', {
          params: { baseCurrency: values.currency, toCurrency: companyInfo.currency }
        })
        .then(({ data }) => {
          if (data && data.result) {
            setFieldValue('exchangeRate', data.result.rate || values.exchangeRate || 1)
          }
        })
    }
  }, [values.currency])

  useDidUpdateEffect(() => {
    if (customers.length > 0 && values.customer) {
      const { contactPersons } = customers.find((item) => item.value === values.customer)

      setContactPersons(contactPersons)

      if (id && !clientData) {
        setClientData(customers.find((item) => item._id === values.customer))
      }
    }
  }, [values.customer, customers])

  const getTaxType = (taxCategory = values.taxCategory) => {
    const taxTypes = TAX_TYPES.filter((item) => item.categories.includes(taxCategory))

    return taxTypes?.length === 1 ? taxTypes[0].value : ''
  }

  const createOrder = () => {
    apiClient.post(`sales-quotations/${id}/sales`).then(({ status, data }) => {
      if (status === 200) {
        setOrderNo({ ...orderNo, [data.type]: data.orderNo })
        message.success('Sales Order created successfully')
      }
    })
  }

  const amount = parseFloat(_.sumBy(values.orderDetails, 'amount'))
  const discount = parseFloat(_.sumBy(values.orderDetails, 'discountValue'))
  const charge = parseFloat(_.sumBy(values.orderDetails, 'chargeValue'))
  const netAmount = parseFloat(_.sumBy(values.orderDetails, 'netAmount'))
  const taxAmount = parseFloat(_.sumBy(values.orderDetails, 'taxAmount'))
  const memoizedAmount = useMemo(() => amount, [amount])
  const memoizedDiscount = useMemo(() => discount, [discount])
  const memoizedCharge = useMemo(() => charge, [charge])
  const memoizedNetAmount = useMemo(() => netAmount, [netAmount])
  const memoizedTaxAmount = useMemo(() => taxAmount, [taxAmount])
  const grossAmount = memoizedTaxAmount + memoizedNetAmount

  return (
    <Form className="sales-quotations">
      <ModalBox
        title="Choose a Supplier"
        visible={showModel}
        onCancel={() => setShowModel(false)}
        onOk={() => {
          history.push({
            pathname: '/app/add-order/purchase',
            state: {
              quotationId: id,
              supplierId: setSupplier,
              poNo: values.quotationNo,
              ..._.pick(values, [
                'orderDetails',
                'amount',
                'discount',
                'charge',
                'netAmount',
                'taxAmount',
                'grossAmount',
                'baseAmount'
              ])
            }
          })
        }}
        destroyOnClose>
        <Select
          name="supplier"
          label="Supplier"
          value={setSupplier}
          onChange={(n, v) => {
            setSetSupplier(v)
          }}
          options={suppliers}
        />
      </ModalBox>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={22}>
          <PanelLayout title="Sales Quotation Definition">
            <Space size="large" direction="vertical" className="w-100">
              <Row gutter={[20, 10]}>
                {id && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field name="quotationNo" label="Quotation No" disabled />
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="quotationDate" label="Quotation Date" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="status" label="Status" disabled />
                  </div>
                </Col>
              </Row>
              <Row gutter={[10, 10]} className="pb-4 mb-4 border-bottom">
                <Col xs={24} sm={24} md={10}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="customer"
                      label="Customer"
                      onChange={handleCustomer}
                      options={customers}
                      altInput
                      disabled={disabled}
                    />
                  </div>
                  <div style={{ padding: '10px 0' }}>
                    <h4>
                      <b>Tax Type:</b> {clientData?.taxType}
                    </h4>
                    <h4>
                      <b>{clientData?.taxType} No:</b> {clientData?.taxNo}
                    </h4>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={7}>
                  <Address addressType="shippingAddress" label="Shipping Address" disabled={disabled} />
                </Col>
                <Col xs={24} sm={12} md={7}>
                  <Address addressType="billingAddress" label="Billing Address" disabled={disabled} />
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={12} md={6}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="currency"
                      label="Currency"
                      options={currencies}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <div className="form-field">
                    <Field type="number" name="exchangeRate" label="Exchange Rate" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <div className="form-field">
                    <Field name="poNo" label="PO no. /Ref no." disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <div className="form-field">
                    <Field as="date" name="poDate" label="PO Date" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <div className="form-field">
                    <Field name="salesPerson" label="Sales Person" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="contactPerson"
                      label="Contact Person"
                      options={contactPersons}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <div className="form-field">
                    <Field type="number" name="payTerm" label="Pay Term" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <div className="form-field">
                    <Field as="select" name="template" label="Template" options={templates} />
                  </div>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="taxCategory"
                      label="Tax Category"
                      options={TAX_CATEGORIES}
                      onChange={(n, v) => {
                        setFieldValue(n, v)
                        setTimeout(() => {
                          setFieldValue(
                            'orderDetails',
                            values.orderDetails.map((item) => ({ ...item, taxType: getTaxType(v) }))
                          )
                        }, 500)
                      }}
                      disabled={disabled}
                    />
                  </div>
                </Col>
              </Row>
              <Panel title="Order details">
                <FieldArray
                  name="orderDetails"
                  loaderCount={8}
                  defaultValues={{
                    ...DEFAULT_ORDER_DETAILS,
                    position: (values.orderDetails.length + 1) * 10,
                    tempTax: tax,
                    discount: defaultDiscount,
                    discountType,
                    tax,
                    taxType: getTaxType()
                  }}
                  additionalValues={{
                    type: 'sales',
                    ..._.pick(values, ['status', 'taxCategory', 'currency'])
                  }}
                  editable={!disabled}>
                  {SingleOrderDetails}
                </FieldArray>
              </Panel>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={12}>
                  <div className="form-field">
                    <Field as="rich-text" name="termsConditions" label="Terms and Conditions" />
                  </div>
                </Col>
                <Col xs={24} lg={12} className="text-right">
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Total Amount:</h3>
                    </Col>
                    <Col xs={12}>
                      <p>
                        {parseAmount(memoizedAmount, values.currency)} {values.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Total Discount:</h3>
                    </Col>
                    <Col xs={12}>
                      <p>
                        - {parseAmount(memoizedDiscount, values.currency)} {values.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Total Charge:</h3>
                    </Col>
                    <Col xs={12}>
                      <p>
                        + {parseAmount(memoizedCharge, values.currency)} {values.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Total Net Amount:</h3>
                    </Col>
                    <Col xs={12}>
                      <p>
                        {parseAmount(memoizedNetAmount, values.currency)} {values.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={24} sm={12}>
                      <h3>Total Tax Amount:</h3>
                    </Col>
                    <Col xs={24} sm={12}>
                      <p>
                        {parseAmount(memoizedTaxAmount, values.currency)} {values.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={24} sm={12}>
                      <h3 className="mb-0">Total Gross:</h3>
                      <div className="mb-2">(Amount includes VAT)</div>
                    </Col>
                    <Col xs={24} sm={12}>
                      <p>
                        {parseAmount(grossAmount, values.currency)} {values.currency}
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={[20, 10]}>
                    <Col xs={12}>
                      <h3>Total Amount to be paid:</h3>
                    </Col>
                    <Col xs={12}>
                      <p>
                        {parseAmount(grossAmount, values.currency)} {values.currency}
                      </p>
                      <div>
                        <p>
                          {amountToWords(
                            grossAmount,
                            currencies.find((cur) => cur.value === values.currency)
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[50]} justify="end">
                <Col xs={24} sm={12} md={4}>
                  <Button
                    className="btn-block"
                    variant="primary"
                    onClick={createOrder}
                    disabled={values.status !== 'Accepted' || orderNo?.sales}
                    success={orderNo?.sales}>
                    Create Sales Order <br />
                    {orderNo?.sales}
                  </Button>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <Button
                    className="btn-block"
                    variant="primary"
                    onClick={() => setShowModel(true)}
                    disabled={values.status !== 'Accepted' || orderNo?.purchase}
                    success={orderNo?.purchase}>
                    Create Purchase Order <br />
                    {orderNo?.purchase}
                  </Button>
                </Col>
              </Row>
            </Space>
          </PanelLayout>
          <FooterActions
            leftActions={[
              {
                prefix: 'flaticon-back',
                label: 'Back',
                onClick: () => history.goBack()
              }
            ]}
            centerActions={[
              {
                prefix: 'flaticon-writing',
                label: id ? 'Update' : 'Save',
                onClick: () => {
                  setFieldValue('submitting', false)
                  submitForm()
                }
              }
            ]}
            rightActions={[
              {
                prefix: 'flaticon-email-4',
                label: 'Update & Send Quotation',
                onClick: () => {
                  setFieldValue('submitting', true)
                  submitForm()
                },
                dontShow: !(id && values.status === 'Created')
              }
            ]}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    quotationDate: moment(),
    status: 'Created',
    customer: '',
    billingAddress: DEFAULT_ADDRESS_FIELDS,
    shippingAddress: DEFAULT_ADDRESS_FIELDS,
    currency: '',
    exchangeRate: '1.00',
    poNo: '',
    poDate: '',
    salesPerson: '',
    contactPerson: '',
    payTerm: '',
    template: 'Default',
    taxCategory: '',
    orderDetails: [],
    termsConditions: ''
  }),
  validationSchema: salesQuotationSchema,
  handleSubmit: (
    { tempStatus, ...data },
    {
      props: {
        match: {
          params: { id }
        },
        history
      },
      setFieldValue
    }
  ) => {
    data.template = data.template === 'Default' ? null : data.template
    data.quotationDate = moment(data.quotationDate).utc().startOf('day')
    data.amount = parseFloat(_.sumBy(data.orderDetails, 'amount'))
    data.discount = parseFloat(_.sumBy(data.orderDetails, 'discountValue'))
    data.charge = parseFloat(_.sumBy(data.orderDetails, 'chargeValue'))
    data.netAmount = parseFloat(_.sumBy(data.orderDetails, 'netAmount'))
    data.taxAmount = parseFloat(_.sumBy(data.orderDetails, 'taxAmount'))
    data.grossAmount = data.taxAmount + data.netAmount
    data.baseAmount = data.grossAmount * data.exchangeRate

    if (id) {
      apiClient.put(`sales-quotations/${id}`, data).then(({ status, data: resData }) => {
        if (status === 200) {
          if (data.submitting) {
            message.success('Quotation Sent')
          }

          setFieldValue('status', resData.status)
          history.replace(`/app/edit-sales-quotation/${id}`)
        }
      })
    } else {
      apiClient.post('sales-quotations', data).then(({ status, data }) => {
        if (status === 201) {
          setFieldValue('quotationNo', data.quotationNo)
          setFieldValue('status', data.status)
          history.replace(`/app/edit-sales-quotation/${data.id}`)
        }
      })
    }
  }
})(SalesQuotationForm)
