import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import ConfirmationBox from '../../../Components/ConfirmationBox/ConfirmationBox'
import apiClient from '../../../Util/apiClient'
import { sanitize } from '../../../Util/Util'

export default function InvoiceView(props) {
  const [templates, setTemplates] = useState({
    type: '',
    result: []
  })

  const getData = () => {
    if (props.match.params.id) {
      apiClient.get(`order-invoices/view/${props.match.params.id}`).then(({ data }) => {
        if (data && data.result) {
          setTemplates(data)
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const onDownload = () => {
    const ids = templates.result.map((val) => val.id)
    const exeFn = (printType) =>
      apiClient
        .post(`order-invoices/download/${props.match.params.id}`, { printType }, { responseType: 'blob' })
        .then(({ status, data, headers }) => {
          if (status === 200 && data) {
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(data)
            a.download = JSON.parse(headers['content-disposition'].split('filename=')[1].split(';')[0])
            document.body.appendChild(a)
            a.click()
            a.remove()
          }
        })

    if (ids.length > 1) {
      ConfirmationBox({
        title: 'Download Payslip',
        description: 'Select how you wants to download!',
        acceptText: 'Single',
        acceptText1: 'Multiple',
        canelText: 'Cancel',
        acceptFn: () => exeFn('Single'),
        acceptFn1: () => exeFn('Multiple')
      })
    } else {
      exeFn('Single')
    }
  }

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
        style={{ background: '#f3f3f3' }}>
        <div style={{ paddingBottom: 70 }}>
          {templates.result.map((t, i) => (
            <div
              key={i}
              style={{
                width: 'fit-content',
                margin: '15px auto',
                background: '#fff'
              }}>
              <div style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', transition: 0.3 }} {...sanitize(t)} />
            </div>
          ))}
        </div>
        {!props.noEdit && (
          <div className="fixed-action-area">
            <ButtonBox onClick={() => props.history.push(`/app/edit-invoice/${props.match.params.id}`)}>
              Edit
            </ButtonBox>
            <span>or</span>
            <ButtonBox onClick={onDownload}>Download</ButtonBox>
            <span>or</span>
            <Link to={`/app/order-invoices/${templates.type}`}>
              <ButtonBox type="gray">
                <ArrowLeftOutlined /> Back to {templates.type} invoices
              </ButtonBox>
            </Link>
          </div>
        )}
      </Col>
    </Row>
  )
}
