import { withFormik } from 'formik'
import React from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

function AssetFilter() {
  return (
    <Form>
      <div className="form-fields">
        <Field name="itemName" label="Item Name" />
      </div>
      <div className="form-fields">
        <Field as="select" name="itemCategory" label="Item Category" />
      </div>
      <div className="form-fields">
        <Field name="fromAndToDate" label="From and To Date" as="date-range" />
      </div>
      <div className="form-fields">
        <Field as="select" name="status" label="Status" />
      </div>
      <div className="form-fields">
        <Button variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" />
          Search
        </Button>
        <Button className="btn-block btn-glow search">Clear</Button>
      </div>
    </Form>
  )
}

export default withFormik({
  handleSubmit: () => null
})(AssetFilter)
