import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { getOrderNames } from '../../../Util/Options'
import { orderFilterSchema } from '../../../Util/validationSchema'

function CreateInvoiceFilter({ values, setValues, filterData }) {
  const { type } = useParams()
  const { isSales, clientName } = getOrderNames(type)

  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="client" label={`${clientName} no.`} />
        </div>
        <div className="form-fields">
          <Field name="clientName" label={`${clientName} Name`} />
        </div>
        <div className="form-fields">
          <Field
            as="select"
            name="docType"
            label="Ref doc type"
            options={
              isSales
                ? [
                    { label: 'Sales Order', value: 'orders' },
                    { label: 'Sales Delivery', value: 'order-deliveries' }
                  ]
                : [
                    { label: 'Purchase Order', value: 'orders' },
                    { label: 'Purchase Receipt', value: 'order-deliveries' }
                  ]
            }
          />
        </div>
        <div className="form-fields">
          <Field name="orderNo" label="Ref doc no" />
        </div>
        <div className="form-fields">
          <Field as="date" name="orderDateFrom" label="Ref doc date from" />
        </div>
        <div className="form-fields">
          <Field as="date" name="orderDateTo" label="Ref doc date to" />
        </div>
        <div className="form-fields">
          <Button type="submit" variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button type="reset" className="btn-block btn-glow search">
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    client: '',
    clientName: '',
    docType: 'orders',
    orderNo: '',
    orderDateFrom: '',
    orderDateTo: '',
    invoice: true
  }),
  validationSchema: orderFilterSchema,
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(CreateInvoiceFilter)
