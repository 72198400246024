import { Divider, Select as SelectField } from 'antd'
import _ from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import apiClient from '../../Util/apiClient'
import { convertSelectOptions } from '../../Util/Util'
import PaginationBox from '../Pagination/PaginationBox'
import { TSelect } from './types'

const { Option } = SelectField

function PagedSelect({
  label,
  error,
  required,
  withNone,
  style,
  onChange,
  onBlur,
  endPoint,
  hideLabel,
  optionLabel = 'name',
  optionValue = 'id',
  defaultOptions = [],
  params,
  ...props
}: TSelect) {
  const [options, setOptions] = useState<Array<{ [index: string]: string }>>([])
  const [pageData, setPageData] = useState({})
  const [selectedFlag, setSelectedFlag] = useState(true)
  const [loading, setLoading] = useState(true)

  const onOpen = () => {
    if (loading) {
      getOptionData()
    }
  }

  useEffect(() => {
    if (selectedFlag && props.value && props.value !== '') {
      getSelectedOption(props.value, options)
      setSelectedFlag(false)
    }
  }, [props.value])

  const getOptionData = (obj = {}) => {
    if (endPoint) {
      const queryParams = { ...obj, ...params }
      apiClient.get(endPoint, { params: queryParams }).then(({ data }) => {
        if (data && data.result) {
          setOptions([
            ...defaultOptions,
            ...convertSelectOptions(data.result || [], optionLabel, optionValue)
          ])
          setPageData(data.pageData)
        }

        setLoading(false)
      })
    }
  }

  const getSelectedOption = (v: unknown, oldData: Array<{ [index: string]: string }>) => {
    if (endPoint) {
      apiClient
        .get(endPoint, { params: { [optionValue === 'id' ? '_id' : optionValue]: v } })
        .then(({ data }) => {
          if (data && data.result) {
            const optionData = _.uniqBy(
              convertSelectOptions([...oldData, ...data.result], optionLabel, optionValue),
              (v: { [index: string]: string }) => v[optionValue]
            )
            setOptions(optionData)
          }
        })
    }
  }

  const onChangePage = (pageData: Record<string, unknown>) => {
    getOptionData(pageData)
    setSelectedFlag(false)
  }

  const onSearch = (v: string) => {
    getOptionData({ [optionLabel]: v })
  }

  return (
    <div>
      {label && !hideLabel && (
        <label>
          {label} {required && <span className="required">*</span>}
        </label>
      )}
      <SelectField
        showSearch
        style={{
          width: '100%',
          ...style
        }}
        filterOption={false}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            {!loading ? (
              <PaginationBox
                size="small"
                showSizeChanger={false}
                pageData={pageData}
                onChangePage={onChangePage}
              />
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 600 }}>Loading..</div>
            )}
          </div>
        )}
        onClick={onOpen}
        onChange={(value = '', option) => {
          onChange?.(props.name, value, option)
          setSelectedFlag(false)
        }}
        onBlur={() => onBlur?.(props.name, props.value || '')}
        onSearch={onSearch}
        listHeight={320}
        {...props}>
        {withNone && (
          <Option key="" value="">
            None
          </Option>
        )}
        {options.map((data) => (
          <Option key={data?.value} value={data?.value}>
            {data?.label}
          </Option>
        ))}
      </SelectField>
      {error && (
        <div style={{ fontSize: 10, color: 'red', textAlign: 'right' }}>
          {error.replace(props.name, label || props.placeholder || '')}
        </div>
      )}
    </div>
  )
}

export default memo(PagedSelect)
