import { Col, Radio, Row } from 'antd'
import { withFormik } from 'formik'
import React from 'react'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'

function Termination(props) {
  const [value, setValue] = React.useState(1)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 18 }} lg={{ span: 18 }}>
          <PanelLayout title="New Employee Terminations" className="mb-5">
            <Panel title="New Employee Terminations">
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                  <div className="form-fields">
                    <Field name="employeeNumber" label="Employee" as="select" mode="multiple" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-fields">
                    <Field name="terminationDate" label="Termination Date" as="date" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-fields">
                    <Field name="referenceNumber" label="Reference Number" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-fields">
                    <Field name="lastWorkingDate" label="Last Working Date" as="date" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                  <div className="form-fields">
                    <label>Termination Reason</label>
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>N/A</Radio>
                      <Radio value={2}>Termination of Contract</Radio>
                      <Radio value={3}>The Resignation of the worker</Radio>
                      <Radio value={4}>Termination of Employment with in 6 Months of Marriage contract</Radio>
                      <Radio value={5}>
                        A worker left the work as a Result of Force Meajeure-No Benefits
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                  <div className="form-fields">
                    <label>Termination Results</label>
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={6}>N/A</Radio>
                      <Radio value={7}>Exit</Radio>
                      <Radio value={8}>Out of Service</Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 24 }}>
                  <div className="form-fields">
                    <Field name="remarks" label="Remarks" as="textarea" />
                  </div>
                </Col>
              </Row>
            </Panel>
          </PanelLayout>
        </Col>
      </Row>

      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to Employee list',
            onClick: () => props.history.push('/app/employees')
          }
        ]}
        centerActions={[
          {
            prefix: 'flaticon-user-1',
            label: 'Terminate Now'
          }
        ]}
        rightActions={[
          {
            label: 'Cancel'
          }
        ]}
      />
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    employeeNumber: '',
    terminationDate: '',
    referenceNumber: '',
    lastWorkingDate: '',
    remarks: ''
  }),
  handleSubmit: () => null
  // validationSchema: Schema
})(Termination)
