import { EyeOutlined } from '@ant-design/icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import StockTransferFilter from './StockTransferFilter'

export default function StockTransfers() {
  const [stockTransfers, setStockTransfers] = useState([])
  const history = useHistory()

  const getData = (params) => {
    const filterCache = GET_DATA('stockTransfers.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('stock-transfers', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('stockTransfers.filterData', { ...params, ...data.pageData })
        setStockTransfers(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Transaction No',
      dataIndex: 'transactionNo'
    },
    {
      title: 'Transaction Date',
      dataIndex: 'createdAt',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks'
    },
    {
      ...(validateAccess('view-stock-transfer') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/view-stock-transfer/${row.id}`)}
              className="dropdown-toggle">
              <EyeOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Stock transfer',
        onClick: () => history.push('/app/add-stock-transfer'),
        access: 'add-stock-transfer'
      }}
      filterData={GET_DATA('stockTransfers.filterData')}
      filter={<StockTransferFilter onSubmit={getData} />}>
      <TableLayout title="Stocks Transfer Overview" exportUrl="stock-transfers/export">
        <TableBox
          dataSource={stockTransfers}
          columns={columns}
          pageData={GET_DATA('stockTransfers.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
