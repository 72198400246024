import { withFormik } from 'formik'
import React from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

function Filter() {
  //   const history = useHistory()

  //   const onSubmitForm = async () => {
  //     props.validateForm().then((err) => {
  //       if (_.isEmpty(err)) {
  //         props.onFilter(_(props.values).omitBy(_.isEmpty).value())
  //         SET_DATA('AbsenceManagement.filterData', props.values)
  //       }
  //     })
  //   }

  //   const onClear = () => {
  //     props.resetForm({})
  //     props.onFilter({})
  //   }

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="Date" label="Date" as="date-range" />
        </div>
        <div className="form-fields">
          <Field name="employeeName" label="Employee Name" />
        </div>
        <div className="form-fields">
          <Field as="select" name="leaveType" label="Leave Type" />
        </div>
        <div className="form-fields">
          <Field name="departent" label="Departent" as="select" />
        </div>
        <div className="form-fields">
          <Field name="designation" label="Designation" as="select" />
        </div>
        <div className="form-fields">
          <Field name="location" label="Location" as="select" />
        </div>
        <div className="form-fields">
          <Field name="leaveStatus" label="Show Leave with Status" as="select" />
        </div>
        <div className="form-fields">
          <Button variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button className="btn-block btn-glow search">Clear</Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  //   mapPropsToValues: () => {
  //     const filterObj = GET_DATA('AbsenceManagement.filterData')

  //     return {
  //       leaveDate: filterObj?.leaveDate || '',
  //       leaveType: filterObj?.leaveType || '',
  //       leaveBalance: filterObj?.leaveBalance || '',
  //       status: filterObj?.status || ''
  //     }
  //   },
  handleSubmit: () => null
})(Filter)
