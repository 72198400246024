import { Alert, Col, message, Row } from 'antd'
import { getIn } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { getOptionsByType, masterUpload } from '../../../Actions/UserAction'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import TableBox from '../../../Components/TableBox/TableBox'
import AppConfig from '../../../config'
import { sanitize } from '../../../Util/Util'
import UploadArea from './UploadArea'

const { API_URL } = AppConfig

class UploadMaterials extends React.Component {
  constructor() {
    super()
    this.state = {
      uploadData: [],
      errorField: false,
      loader: false,
      materialTypes: [],
      materialGroups: [],
      divisions: [],
      units: [],
      costingTypes: [],
      uploadErrors: []
    }
  }

  componentDidMount() {
    getOptionsByType({
      type: ['MaterialType', 'MaterialGroup', 'Division', 'UnitOfMeasurement', 'CostingType']
    }).then(
      ({
        MaterialType = [],
        MaterialGroup = [],
        Division = [],
        UnitOfMeasurement = [],
        CostingType = []
      }) => {
        this.setState({
          materialTypes: MaterialType,
          materialGroups: MaterialGroup,
          divisions: Division,
          units: UnitOfMeasurement,
          costingTypes: CostingType
        })
      }
    )
  }

  onUpload = (header, rowData) => {
    const validateFields = [
      { label: 'Material Code', value: 'basic.materialCode' },
      { label: 'Material Description', value: 'basic.materialDescription' },
      { label: 'Material Type', value: 'basic.materialType' },
      { label: 'Material Group', value: 'basic.materialGroup' },
      { label: 'Division', value: 'basic.division' },
      { label: 'UOM', value: 'basic.unit' },
      { label: 'Purchase UOM', value: 'stockInfo.purchaseUnit' },
      { label: 'Sales UOM', value: 'stockInfo.salesUnit' },
      { label: 'Sales Price', value: 'stockInfo.purchasePrice' },
      { label: 'Purchase Price', value: 'stockInfo.salesPrice' },
      { label: 'Costing Type', value: 'costInfo.type' },
      { label: 'Standard Cost', value: 'costInfo.cost' },
      { label: 'Moving Avg. Cost', value: 'costInfo.movingCost' }
    ]
    const rows = []
    let errorField = ''
    rowData.forEach((val, ind) => {
      const obj = { basic: {}, stockInfo: {}, costInfo: {} }
      header.forEach((col, i) => {
        switch (col) {
          case 'Material Code':
            obj.basic.materialCode = val[i]
            break
          case 'Material Description':
            obj.basic.materialDescription = val[i]
            break
          case 'Material Type':
            obj.basic.materialType = val[i]
            break
          case 'Material Group':
            obj.basic.materialGroup = val[i]
            break
          case 'Division':
            obj.basic.division = val[i]
            break
          case 'UOM':
            obj.basic.unit = val[i]
            break
          case 'Purchase UOM':
            obj.stockInfo.purchaseUnit = val[i]
            break
          case 'Sales UOM':
            obj.stockInfo.salesUnit = val[i]
            break
          case 'Sales Price':
            obj.stockInfo.purchasePrice = val[i]
            break
          case 'Purchase Price':
            obj.stockInfo.salesPrice = val[i]
            break
          case 'Costing Type':
            obj.costInfo.type = val[i]
            break
          case 'Standard Cost':
            obj.costInfo.cost = val[i]
            break
          case 'Moving Avg. Cost':
            obj.costInfo.movingCost = val[i]
            break
          default:
            break
        }
      })
      validateFields.forEach((field) => {
        if (!getIn(obj, field.value)) {
          errorField += `<div>${field.label} missing in line ${ind + 1}!</div>`
        }
      })
      rows.push(obj)
    })
    this.setState({
      uploadData: errorField === '' ? rows : [],
      errorField: errorField === '' ? false : errorField
    })
  }

  onUploadData = () => {
    const { uploadData, loader } = this.state

    if (uploadData.length > 0 && !loader) {
      this.setState({ loader: true }, () => {
        masterUpload({ uploadType: 'Materials', uploadData }).then((errors) => {
          this.setState({ loader: false, uploadData: [], uploadErrors: errors })
        })
      })
    } else {
      message.error('Please upload materials')
    }
  }

  onDowloadTemplate = () => {
    const dowloadUrl = `${API_URL}/assets/defaultTemplate/Material_Template.xlsx`
    const a = document.createElement('a')
    a.href = dowloadUrl
    a.download = 'Material_Template'
    a.click()
  }

  render() {
    const columns = [
      { title: 'Material Code', dataIndex: 'basic', render: (basic) => basic.materialCode },
      { title: 'Material Description', dataIndex: 'basic', render: (basic) => basic.materialDescription },
      { title: 'Material Type', dataIndex: 'basic', render: (basic) => basic.materialType },
      { title: 'Material Group', dataIndex: 'basic', render: (basic) => basic.materialGroup },
      { title: 'Division', dataIndex: 'basic', render: (basic) => basic.division },
      { title: 'UOM', dataIndex: 'basic', render: (basic) => basic.unit },
      {
        title: 'Purchase UOM',
        dataIndex: 'stockInfo',
        render: (stockInfo) => stockInfo.purchaseUnit
      },
      { title: 'Sales UOM', dataIndex: 'stockInfo', render: (stockInfo) => stockInfo.salesUnit },
      {
        title: 'Sales Price',
        dataIndex: 'stockInfo',
        render: (stockInfo) => stockInfo.purchasePrice
      },
      {
        title: 'Purchase Price',
        dataIndex: 'stockInfo',
        render: (stockInfo) => stockInfo.salesPrice
      },
      { title: 'Costing Type', dataIndex: 'costInfo', render: (costInfo) => costInfo.type },
      { title: 'Standard Cost', dataIndex: 'costInfo', render: (costInfo) => costInfo.cost },
      {
        title: 'Moving Avg. Cost',
        dataIndex: 'costInfo',
        render: (costInfo) => costInfo.movingCost
      }
    ]

    return (
      <Row justify="center" className="upload-invoice-expense">
        <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 22 }} lg={{ span: 24 }}>
          <Row>
            {this.state.uploadData.length === 0 && (
              <Col
                xs={{ span: 23 }}
                sm={{ span: 23 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                style={{ paddingRight: 10 }}>
                <UploadArea
                  onUpload={this.onUpload}
                  sheetName="Materials"
                  label="Click here or drag and drop file here to upload materials"
                />
              </Col>
            )}
            {this.state.uploadData.length === 0 && (
              <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    {this.state.errorField && (
                      <div
                        style={{ padding: 10, background: '#ffdfdf' }}
                        {...sanitize(this.state.errorField)}
                      />
                    )}
                    {this.state.uploadErrors && (
                      <div style={{ padding: 10 }}>
                        {this.state.uploadErrors.map((v, i) =>
                          Object.keys(v).map((item, i2) => (
                            <Alert
                              key={`${i}-${i2}`}
                              message={`LineNo ${item}: ${v[item].join(', ')}`}
                              type="error"
                              closable
                            />
                          ))
                        )}
                      </div>
                    )}
                    <div className="upload-invoice-expense-note">
                      <div className="heading">
                        <h2>Note:</h2>
                      </div>
                      <div className="sample-template">
                        <ButtonBox onClick={this.onDowloadTemplate}>
                          <i className="flaticon-download" /> Download Sample Template
                        </ButtonBox>
                      </div>
                      <ul>
                        <li>Upload material not exists in materials</li>
                        <li>Available Material Types:</li>
                        <ul>
                          {this.state.materialTypes.map((val, i) => (
                            <li key={i}>{val.value}</li>
                          ))}
                        </ul>
                        <li>Available Material Groups:</li>
                        <ul>
                          {this.state.materialGroups.map((val, i) => (
                            <li key={i}>{val.value}</li>
                          ))}
                        </ul>
                        <li>Available Divisions:</li>
                        <ul>
                          {this.state.divisions.map((val, i) => (
                            <li key={i}>{val.value}</li>
                          ))}
                        </ul>
                        <li>Available UOM/ Purchase UOM/ Sales UOM:</li>
                        <ul>
                          {this.state.units.map((val, i) => (
                            <li key={i}>{val.value}</li>
                          ))}
                        </ul>
                        <li>Available Costing Types:</li>
                        <ul>
                          {this.state.costingTypes.map((val, i) => (
                            <li key={i}>{val.value}</li>
                          ))}
                        </ul>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
            {this.state.uploadData.length > 0 && !this.state.loader && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                style={{ paddingRight: 10, paddingTop: 20 }}>
                <div style={{ display: 'inline-table', paddingRight: 10 }}>
                  <ButtonBox onClick={this.onUploadData}>
                    {this.state.loader ? 'Uploading' : 'Upload'}
                  </ButtonBox>
                </div>
                <div style={{ display: 'inline-table' }}>
                  <ButtonBox type="default" onClick={() => this.setState({ uploadData: [] })}>
                    Cancel
                  </ButtonBox>
                </div>
              </Col>
            )}
            {this.state.uploadData.length > 0 && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                style={{ marginTop: 20, paddingRight: 10 }}>
                <TableBox dataSource={this.state.uploadData} columns={columns} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.users.userInfo,
    companyInfo: state.users.companyInfo
  }
}

export default connect(mapStateToProps)(UploadMaterials)
