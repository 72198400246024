import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import ProjectAndEmployeeRateFilter from './ProjectAndEmployeeRateFilter'

export default function Projects() {
  const [projectAndEmployees, setProjectAndEmployees] = useState([])
  const history = useHistory()

  const onFilter = (params = {}) => {
    apiClient.get('projects/getAllProjectAndEmployees', { params }).then(({ data }) => {
      if (data.result) {
        SET_DATA('projectAndEmployees.filterData', { ...params, ...data.pageData })
        setProjectAndEmployees(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('projectAndEmployees.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('projectAndEmployees.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Employee',
      dataIndex: 'employeeName'
    },
    {
      title: 'Project',
      dataIndex: 'projectName'
    },
    {
      title: 'Client',
      dataIndex: 'clientName'
    },
    {
      title: 'Purchase Order',
      dataIndex: 'poNumber'
    },
    {
      title: 'Pay Type',
      dataIndex: 'payType'
    },
    {
      title: 'Rate',
      dataIndex: 'rate'
    },
    {
      title: 'Currency',
      dataIndex: 'currency'
    },
    {
      ...(validateAccess('edit-project-employee-rate') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/edit-project-employee-rate/${row.id}`)}
              className="btn-glow dropdown-toggle">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<ProjectAndEmployeeRateFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Project Overview</h2>
      </div>
      <TableBox
        dataSource={projectAndEmployees}
        columns={columns}
        pageData={GET_DATA('projectAndEmployees.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
