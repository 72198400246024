import { SettingOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import OptionFilter from './OptionFilter'

export default function Options() {
  const [options, setOptions] = useState([])
  const history = useHistory()

  const onFilter = (params = {}) => {
    apiClient.get('options/getAll', { params }).then(({ data }) => {
      if (data.result) {
        SET_DATA('options.filterData', { ...params, ...data.pageData })
        setOptions(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('options.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('options.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      render: (v, r) => (
        <div onClick={() => history.push(`/app/edit-option/${r.id}`)}>
          <a>{v}</a>
        </div>
      )
    },
    {
      title: 'Value',
      dataIndex: 'value'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },

    validateAccess('edit-option')
      ? {
          title: 'Action',
          dataIndex: 'custom_action',
          render: (text, row) => (
            <div className="btn-group">
              <button
                type="button"
                onClick={() => history.push(`/app/edit-option/${row.id}`)}
                className="btn glow dropdown-toggle">
                {' '}
                <SettingOutlined />
              </button>
            </div>
          )
        }
      : {}
  ]

  return (
    <FilterLayout filter={<OptionFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Value Help Overview</h2>
      </div>
      <TableBox
        dataSource={options}
        columns={columns}
        pageData={GET_DATA('options.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
