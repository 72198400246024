import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Attachments from '../../../Components/Attachments'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'

// const Schema = Yup.object().shape({
//   level: Yup.string().required(),
//   score: Yup.number().required(),
//   year: Yup.date().required(),
//   attachments: Yup.array()
// })

const EducationDetailsForm = (props) => {
  const {
    currentDetails,
    // values,
    // setValues,
    handleValueChange
  } = props
  const [options, setOptions] = useState({})

  const fetchDropdownValues = () => {
    apiClient
      .get('options/get-by-types', {
        params: { type: ['Level'] }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })
  }

  useEffect(() => {
    fetchDropdownValues()
    // setValues({
    //   ...values,
    //   level: !isEmpty(currentDetails) ? currentDetails.level : '',
    //   score: !isEmpty(currentDetails) ? currentDetails.score : '',
    //   year: !isEmpty(currentDetails) ? moment(currentDetails.year).format('yyyy-MM-DD') : '',
    //   attachments: !isEmpty(currentDetails) ? currentDetails.attachments : ''
    // })
  }, [])

  // useEffect(() => {
  //   handleValueChange(values)
  // }, [values?.level, values?.score, values?.year, values?.attachments])

  const onUploadAttachement = (key, attachments) => {
    handleValueChange({
      ...currentDetails,
      attachments: attachments.filter((attachment) => attachment !== '')
    })
  }

  return (
    <Form>
      <Row justify="left" gutter={(12, 10)}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="level" label="Level" as="select" options={options.Level || []} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="year" label="Year" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 24 }}>
          <div className="form-field">
            <Field name="score" label="GPA/Score" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="form-field">
            <label>Attachments</label>
            <Attachments
              name="educationAttachments"
              value={currentDetails?.attachments || []}
              title="Attach Files"
              description="You can upload a maximum of 3 files, 5MB each"
              acceptFile={['image', 'pdf']}
              onUpload={onUploadAttachement}
            />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default EducationDetailsForm
// withFormik({
//   mapPropsToValues: () => ({
//     level: '',
//     score: '',
//     year: '',
//     attachments: []
//   }),
//   handleSubmit: () => null,
//   validationSchema: Schema
// })(EducationDetailsForm)
