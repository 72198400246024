import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Address from '../../../../Components/Address/Address'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import FieldArray from '../../../../Components/Formik/FieldArray'
import Form from '../../../../Components/Formik/Form'
import Panel from '../../../../Layout/Panel'
import PanelLayout from '../../../../Layout/PanelLayout'
import apiClient from '../../../../Util/apiClient'
import { DEFAULT_PACKAGE_DETAILS } from '../../../../Util/Options'
import { convertSelectOptions } from '../../../../Util/Util'
import { jobCreationSchema } from '../../../../Util/validationSchema'
import SinglePackageDetails from '../Bookings/SinglePackageDetails'

function JobCreationForm({
  history,
  setFieldValue,
  submitForm,
  setValues,
  values,
  match: {
    params: { id }
  }
}) {
  const [clients, setClients] = useState([])
  const [templates, setTemplates] = useState([])

  const disabled = id && values.status === 'Posted'

  const getData = () => {
    apiClient
      .get('clients/getNames', { params: { type: 'Vendor', forwarder: true } })
      .then(({ status, data }) => {
        if (status === 200) {
          setClients(
            data.map((item) => ({
              label: item.name,
              value: item.clientNo
            }))
          )
        }
      })

    apiClient
      .get('customTemplates/getActive', { params: { type: 'Freight', for: 'Job Creation' } })
      .then(({ data }) => {
        if (data && data.result) {
          const templates = [
            { label: 'Default', value: 'Default' },
            ...convertSelectOptions(data.result, 'name', 'id')
          ]
          setTemplates(templates)
        }
      })

    if (id) {
      apiClient.get(`job-creations/${id}`).then(({ status, data }) => {
        if (status === 200) {
          data.template = data.template ? data.template : 'Default'
          setValues({ ...values, ...data })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={22}>
          <PanelLayout title="Job Creation Definition">
            <Row gutter={[10, 10]} className="pb-4 mb-4 border-bottom">
              <Col xs={24} sm={24} md={12}>
                <div style={{ padding: '10px 0' }}>
                  <h3>
                    <b>Client Name:</b> {values.clientName}
                  </h3>
                  <h4>
                    <b>Email:</b> {values.email}
                  </h4>
                  <h4>
                    <b>Phone:</b> {values.phone}
                  </h4>
                </div>
              </Col>
              <Col xs={24} sm={12} md={7}>
                <Address addressType="address" label="Address" disabled />
              </Col>
            </Row>
            <Panel title="Package details">
              <FieldArray
                name="packageList"
                loaderCount={8}
                additionalValues={{
                  options: { packageTypes: [], currencies: [], units: [] },
                  disabled: true
                }}
                viewOnly>
                {SinglePackageDetails}
              </FieldArray>
            </Panel>
            <Panel title="Job Reference">
              <Row gutter={[20, 10]}>
                {id && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field name="jobCreationNo" label="Job Creation No" disabled />
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="creationDate" label="Creation Date" />
                  </div>
                </Col>
                {values.type === 'Air' ? (
                  <>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="salesPerson" label="Sales Person" disabled />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="tos" label="TOS" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field as="select" name="forwarder" label="Origin agent" options={clients} />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="flightNo" label="Flight No" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="MAWBNo" label="MAWB No" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="POLPOD" label="P.O.L/P.O.D" disabled />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field as="date" name="departureDate" label="Departure Date" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field as="date" name="arrivalDate" label="Arrival Date" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="airline" label="Airline" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="bookingNo" label="Agreement No" disabled />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field as="textarea" name="shipper" label="Shipper" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field as="textarea" name="consignee" label="Consignee" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="commodity" label="Commodity" />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="idNo" label="ID No." />
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field as="date" name="issueDate" label="Issue Date" />
                      </div>
                    </Col>
                  </>
                ) : (
                  values.type === 'Sea' && (
                    <>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <div className="form-field">
                          <Field as="select" name="forwarder" label="Origin agent" options={clients} />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <div className="form-field">
                          <Field name="vessel" label="Vessel" />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <div className="form-field">
                          <Field name="vesselCode" label="Vessel Code" />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <div className="form-field">
                          <Field name="voyageNo" label="Voyage No" />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <div className="form-field">
                          <Field as="date" name="departureDate" label="Departure Date" />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <div className="form-field">
                          <Field as="date" name="arrivalDate" label="Arrival Date" />
                        </div>
                      </Col>
                    </>
                  )
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="operations" label="Operations" disabled />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Template">
              <Row>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <div className="form-field">
                    <Field as="select" name="template" label="Template" options={templates} />
                  </div>
                </Col>
              </Row>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            {!disabled ? (
              <>
                <Button
                  variant="primary"
                  onClick={() => {
                    setFieldValue('tempStatus', 'Created')
                    submitForm()
                  }}>
                  {id ? 'Update' : 'Save'}
                </Button>
                <span>or</span>
                {/* {id ? (
                  <Button
                    onClick={() => {
                      setFieldValue('tempStatus', 'Posted')
                      submitForm()
                    }}>
                    Update and Post
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setFieldValue('tempStatus', 'Posted')
                      submitForm()
                    }}>
                    Save and Post
                  </Button>
                )}
                <span>or</span> */}
                <Link to="#" onClick={() => history.goBack()}>
                  <Button>
                    <ArrowLeftOutlined /> Back
                  </Button>
                </Link>
              </>
            ) : (
              <Link to="/app/job-creations">
                <Button>
                  <ArrowLeftOutlined /> Back to job creations
                </Button>
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({
    history,
    location: { state: { type = '', bookingData = { packageList: [DEFAULT_PACKAGE_DETAILS] } } = '' },
    match: {
      params: { id }
    }
  }) => {
    if (!id && !bookingData) {
      history.goBack()
    }

    return {
      type,
      forwarder: '',
      creationDate: moment(),
      departureDate: moment(),
      arrivalDate: moment(),
      ...(type === 'Air'
        ? {
            tos: '',
            flightNo: '',
            MAWBNo: '',
            POLPOD: bookingData.POLPOD,
            airline: '',
            shipper: '',
            consignee: '',
            commodity: '',
            idNo: '',
            issueDate: moment()
          }
        : type === 'Sea'
        ? {
            vessel: '',
            vesselCode: '',
            voyageNo: ''
          }
        : {}),
      ..._.pick(bookingData, [
        'clientName',
        'phone',
        'email',
        'operations',
        'packageList',
        'salesPerson',
        'bookingNo'
      ]),
      address: _.pick(bookingData, ['street', 'city', 'state', 'postalCode', 'country']),
      template: 'Default'
    }
  },
  validationSchema: jobCreationSchema,
  handleSubmit: (
    { tempStatus, ...data },
    {
      props: {
        history,
        match: {
          params: { id }
        }
      }
    }
  ) => {
    data.template = data.template === 'Default' ? null : data.template
    data.creationDate = moment(data.creationDate).utc().startOf('day')
    data.departureDate = moment(data.departureDate).utc().startOf('day')
    data.arrivalDate = moment(data.arrivalDate).utc().startOf('day')

    if (data.type === 'Air') {
      data.issueDate = moment(data.issueDate).utc().startOf('day')
    }

    data.status = tempStatus

    if (id) {
      apiClient.put(`job-creations/${id}`, data).then(({ status }) => {
        if (status === 200) {
          history.push('/app/job-creations')
        }
      })
    } else {
      apiClient.post('job-creations', data).then(({ status }) => {
        if (status === 201) {
          history.push('/app/job-creations')
        }
      })
    }
  }
})(JobCreationForm)
