import { Col, Row } from 'antd'
import React from 'react'
import Attachments from '../../../Components/Attachments'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

// const Schema = Yup.object().shape({
//   companyName: Yup.string().required(),
//   jobTitle: Yup.string().required(),
//   from: Yup.date().required(),
//   to: Yup.date().required(),
//   comment: Yup.string().required(),
//   attachments: Yup.array()
// })

const WorkExperienceForm = (props) => {
  const {
    currentDetails,
    // values,
    handleValueChange
  } = props

  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     companyName: !isEmpty(currentDetails) ? currentDetails.companyName : '',
  //     jobTitle: !isEmpty(currentDetails) ? currentDetails.jobTitle : '',
  //     from: !isEmpty(currentDetails) ? moment(currentDetails.from).format('yyyy-MM-DD') : '',
  //     to: !isEmpty(currentDetails) ? moment(currentDetails.to).format('yyyy-MM-DD') : '',
  //     comment: !isEmpty(currentDetails) ? currentDetails.comment : '',
  //     attachments: !isEmpty(currentDetails) ? currentDetails.attachments : ''
  //   })
  // }, [])

  // useEffect(() => {
  //   handleValueChange(values)
  // }, [values?.companyName, values?.jobTitle, values?.from, values?.to, values?.comment, values?.attachments])

  const onUploadAttachement = (key, attachments) => {
    handleValueChange({
      ...currentDetails,
      attachments: attachments.filter((attachment) => attachment !== '')
    })
  }

  return (
    <Form>
      <Row justify="left" gutter={(12, 10)}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="form-field">
            <Field name="companyName" label="Company" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="jobTitle" label="Job Title" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="from" label="From Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="to" label="To Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="comment" label="Comment" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="form-field">
            <label>Attachments</label>
            <Attachments
              name="dependentsAttachments"
              value={currentDetails?.attachments || []}
              title="Attach Files"
              description="You can upload a maximum of 3 files, 5MB each"
              acceptFile={['image', 'pdf']}
              onUpload={onUploadAttachement}
            />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default WorkExperienceForm
// withFormik({
//   mapPropsToValues: () => ({
//     companyName: '',
//     jobTitle: '',
//     from: '',
//     to: '',
//     comment: '',
//     attachments: []
//   }),
//   handleSubmit: () => null,
//   validationSchema: Schema
// })(WorkExperienceForm)
