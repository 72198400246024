import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import Logs from '../../Logs/Logs'
import JournalVoucherFilter from './JournalVoucherFilter'

export default function JournalVouchers({ history }) {
  const [journalVouchers, setJournalVouchers] = useState([])
  const [viewLogs, setViewLogs] = useState(false)

  const getData = (params = {}) => {
    const filterCache = GET_DATA('journalVouchers.filterData') || {}
    params = { ...filterCache, ...params }

    if (params.documentDate) {
      params.documentDate = moment(params.documentDate).utc().startOf('day').toISOString()
    }

    apiClient.get('journal-vouchers', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('journalVouchers.filterData', { ...params, ...data.pageData })
        setJournalVouchers(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onDelete = (id) => {
    apiClient.delete(`journal-vouchers/${id}`).then(({ status }) => {
      if (status === 204) {
        setJournalVouchers(journalVouchers.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/edit-journal-voucher/${row.id}`}>
            <i className="flaticon-eye" /> View
          </Link>
        </li>
        {validateAccess('edit-journal-order') && row.status === 'Created' && (
          <li>
            <Link to={`/app/edit-journal-voucher/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-journal-order') && row.status === 'Created' && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
        {validateAccess('edit-journal-order') && row.status === 'Created' && (
          <li>
            <Link to={`/app/edit-journal-voucher/${row.id}`}>
              <i className="flaticon-tick-1" /> Approve
            </Link>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => setViewLogs(row)}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Transaction No',
      dataIndex: 'transactionNo'
    },
    {
      title: 'Document Date',
      dataIndex: 'documentDate',
      render: (value) => moment(value).format('YYYY-MM-DD')
    },
    {
      title: 'Reference',
      dataIndex: 'reference'
    },
    {
      title: 'Notes',
      dataIndex: 'notes'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Journal Voucher',
        onClick: () => history.push('/app/add-journal-voucher'),
        access: 'add-journal-voucher'
      }}
      filterData={GET_DATA('journalVouchers.filterData')}
      filter={<JournalVoucherFilter onSubmit={getData} />}>
      <ModalBox
        title="Journal Voucher Logs"
        visible={!!viewLogs}
        onCancel={() => setViewLogs(false)}
        footer={false}
        destroyOnClose>
        <Logs entityId={viewLogs?.id} entityType="Journal Voucher" />
      </ModalBox>
      <TableLayout title="Journal Vouchers Overview" exportUrl="journal-vouchers/export">
        <TableBox
          dataSource={journalVouchers}
          columns={columns}
          pageData={GET_DATA('journalVouchers.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
