import { message, Steps } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import expensesImg from '../../../assets/images/expenses.svg'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import IncomeForm from '../Incomes/IncomeForm'
import GenerateIncomeFilter from './GenerateIncomeFilter'
import './GenerateIncomeInvoice.scss'

const { Step } = Steps

export default function GenerateIncomeInvoice(props) {
  const [projects, setProjects] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedQuery, setSelectedQuery] = useState({})
  const [invoiceId, setInvoiceId] = useState(false)

  const onFilter = (params = {}) => {
    if (params.month) {
      params.year = moment(params.month).format('YYYY')
      params.month = moment(params.month).format('MM')
    }

    apiClient.get('projects/get-rates-by-client', { params }).then(({ data }) => {
      if (data && data.result) {
        setProjects(data.result)
        setSelectedQuery(params)

        if (data.result.length === 0) {
          message.info('No data found')
        }
      }
    })
  }

  const onGenerateInvoice = () => {
    const { month, year, client } = selectedQuery
    const poObj = {}
    const billingRates = []
    selectedRows.forEach((val) => {
      poObj[val.poNumber] = true
      billingRates.push(val.id)
    })
    const poNumberArr = Object.keys(poObj)

    if (poNumberArr.length > 1) {
      message.error('Muliple PO Number found in the selected list')

      return true
    }

    const issueDate = moment()
    const taxDate = moment()
    const [poNumber] = poNumberArr
    apiClient
      .post('incomes/generate-client-invoice', {
        month,
        year,
        client,
        poNo: poNumber,
        issueDate,
        taxDate,
        billingRates
      })
      .then(({ data }) => {
        if (data && data.result) {
          setInvoiceId(data.result.id)
          setActiveStep(1)
        }
      })
  }

  const onChangeStep = (currentStep) => {
    setActiveStep(currentStep)
  }

  const columns = [
    {
      title: 'Project',
      dataIndex: 'projectName'
    },
    {
      title: 'Employee',
      dataIndex: 'employeeName'
    },
    {
      title: 'Purchase Order',
      dataIndex: 'poNumber'
    },
    {
      title: 'Pay Type',
      dataIndex: 'payType'
    },
    {
      title: 'Emp Rate',
      dataIndex: 'rate',
      render: (v, r) => `${r.currency} ${v}`
    },
    {
      title: 'Total Hours',
      dataIndex: 'totalHours'
    },
    {
      title: 'Total Work Amount',
      dataIndex: 'totalWorkAmount',
      render: (v, r) => `${r.clientData?.currency} ${v}`
    },
    {
      title: 'Approved Work Amount',
      dataIndex: 'approvedWorkAmount',
      render: (v, r) => `${r.clientData?.currency} ${v}`
    },
    {
      title: 'Approved Hours',
      dataIndex: 'approvedHours'
    },
    {
      title: 'Total Exp Amount',
      dataIndex: 'totalExpAmount',
      render: (v, r) => `${r.clientData?.currency} ${v}`
    },
    {
      title: 'Approved Exp Amount',
      dataIndex: 'approvedExpAmount',
      render: (v, r) => `${r.clientData?.currency} ${v}`
    }
  ]

  return (
    <FilterLayout filter={<GenerateIncomeFilter onFilter={onFilter} />}>
      <div className="from-timesheet">
        <div className="top-filter-options">
          <h2>Create Invoice from Timesheet / Expense claim</h2>
        </div>
        <div className="process-steps">
          <Steps type="navigation" current={activeStep} className="site-navigation-steps" size="small">
            <Step status="process" title="Create Invoice" />
            <Step status="wait" title="Review Invoice" />
          </Steps>
        </div>
        <div className="create-invoice">
          {activeStep === 0 ? (
            <div className="search-timesheet-table">
              {projects.length === 0 ? (
                <section className="expenses">
                  <div className="empty-timesheet no-data">
                    <img src={expensesImg} alt="Expenses Entries" />
                    <h2>Select client and month to get rates and generate invoice</h2>
                  </div>
                </section>
              ) : (
                <TableBox
                  dataSource={projects}
                  columns={columns}
                  onSelect={(selected) => setSelectedRows(selected)}
                  selectedRows={selectedRows}
                />
              )}

              {selectedRows.length > 0 && (
                <div className="proceed-to-next">
                  <ButtonBox onClick={onGenerateInvoice}>create invoices</ButtonBox>
                </div>
              )}
            </div>
          ) : (
            <div clssName="review-invoice">
              {activeStep === 1 && (
                <IncomeForm {...props} match={{ params: { id: invoiceId } }} onChangeStep={onChangeStep} />
              )}
            </div>
          )}
        </div>
      </div>
    </FilterLayout>
  )
}
