import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getActiveClients } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'
import { INCOME_KIND_OPTIONS, INVOICE_STATUS_OPTIONS } from '../../../Util/Options'
import { convertSelectOptions, GET_DATA, SET_DATA } from '../../../Util/Util'

function Filter(props) {
  const [clients, setClients] = useState([])
  const [divisions, setDivisions] = useState([])

  const getData = () => {
    getActiveClients().then((clients) => {
      if (clients) {
        const clientOptions = []
        clients.forEach((val) => {
          if (val.type === 'Customer') {
            clientOptions.push({ label: val.name, value: val.id })
          }
        })
        setClients(clientOptions)
      }
    })

    if (props?.companyInfo?.configurations?.division === 'Yes') {
      apiClient.get('divisions/get-by-user').then(({ data }) => {
        if (data && data.result) {
          setDivisions(convertSelectOptions(data.result, 'name', 'id'))
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const onSubmitForm = async () => {
    props.validateForm().then((err) => {
      if (_.isEmpty(err)) {
        const params = { ..._(props.values).omitBy(_.isEmpty).value() }
        ;['issueDate', 'taxDate', 'paymentDate'].forEach((item) => {
          if (params[item]) {
            params[item][0] = moment(params[item][0]).format('YYYY-MM-DD')
            params[item][1] = moment(params[item][1]).format('YYYY-MM-DD')
          }
        })

        props.onFilter(params)
      }
    })
  }

  const onClear = () => {
    props.resetForm()
    props.onFilter()
    SET_DATA('incomes.filterData', {})
  }

  return (
    <Form>
      {props?.companyInfo?.configurations?.division === 'Yes' && (
        <div className="form-fields">
          <Field
            as="select"
            name="division"
            label="Division"
            options={[{ label: 'All', value: '' }, ...divisions]}
          />
        </div>
      )}
      <div className="form-fields">
        <Field name="invoiceNo" label="Doc No" />
      </div>
      <div className="form-fields">
        <Field
          as="select"
          name="kind"
          label="Kind"
          options={[{ label: 'All', value: '' }, ...INCOME_KIND_OPTIONS]}
        />
      </div>
      <div className="form-fields">
        <Field as="date-range" name="issueDate" label="Issue Date" />
      </div>
      <div className="form-fields">
        <Field as="date-range" name="taxDate" label="Tax Date" />
      </div>
      <div className="form-fields">
        <Field as="date-range" name="paymentDate" label="Payment Date" />
      </div>
      <div className="form-fields">
        <Field
          as="select"
          name="status"
          label="Status"
          options={[{ label: 'All', value: '' }, ...INVOICE_STATUS_OPTIONS]}
        />
      </div>
      <div className="form-fields">
        <Field
          as="select"
          name="client"
          label="Customer"
          options={[{ label: 'All', value: '' }, ...clients]}
        />
      </div>
      <div className="form-fields">
        <Button variant="secondary" className="search" onClick={onSubmitForm}>
          <i className="flaticon-search-interface-symbol" />
          Search
        </Button>
        <Button className="btn-block btn-glow search" onClick={onClear}>
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => {
    const filterObj = GET_DATA('incomes.filterData')

    return {
      invoiceNo: filterObj?.invoiceNo || '',
      kind: filterObj?.kind || '',
      issueDate: filterObj?.issueDate || '',
      taxDate: filterObj?.taxDate || '',
      paymentDate: filterObj?.paymentDate || '',
      status: filterObj?.status || '',
      client: filterObj?.client || ''
    }
  },
  handleSubmit: () => null
})(Filter)
