import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getActiveClients, getMasterCurrencies } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { customClearanceFilterSchema } from '../../../Util/validationSchema'

function CreateCustomsClearanceFilter({ values, setValues, filterData }) {
  const [clients, setClients] = useState([])
  const [currencies, setCurrencies] = useState([])

  const getData = () => {
    getMasterCurrencies().then((data) => setCurrencies(data))
    getActiveClients().then((clients) => {
      if (clients) {
        const clientOptions = []
        clients.forEach((val) => {
          if (val.type === 'Vendor') {
            clientOptions.push({ label: val.name, value: val._id })
          }
        })
        setClients(clientOptions)
      }
    })
  }

  useEffect(() => {
    setValues({ ...values, ...filterData })
    getData()
  }, [])

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="deliveryNo" label="Goods Receipt No" />
        </div>
        <div className="form-fields">
          <Field as="date" name="deliveryDate" label="Goods Receipt Date" />
        </div>
        <div className="form-fields">
          <Field as="select" name="currency" label="Currency" options={currencies} />
        </div>
        <div className="form-fields">
          <Field as="select" name="client" label="Vendor" options={clients} />
        </div>
        <div className="form-fields">
          <Button type="submit" variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button type="reset" className="btn-block btn-glow search">
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    deliveryNo: '',
    deliveryDate: '',
    currency: '',
    client: ''
  }),
  validationSchema: customClearanceFilterSchema,
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(CreateCustomsClearanceFilter)
