import React, { useEffect, useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import TopLoaderBox from './Components/LoaderBox/TopLoaderBox'
import './globals'
import Routes from './Routes/Routes'
import store from './store/store'
import './Style/styles.scss'

export const loderRef = { barRef: null }

export default function App() {
  const myRef = useRef(null)

  useEffect(() => {
    loderRef.barRef = myRef
  }, [])

  return (
    <Provider store={store}>
      <TopLoaderBox ref={myRef} />
      <DndProvider backend={HTML5Backend}>
        <Routes />
      </DndProvider>
    </Provider>
  )
}
