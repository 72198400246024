import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import Attachments from '../../../Components/Attachments'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

// const Schema = Yup.object().shape({
//   bankName: Yup.string().required(),
//   accNum: Yup.number().required(),
//   branchName: Yup.string().required(),
//   ifscCode: Yup.string().required(),
//   type: Yup.string().required(),
//   attachments: Yup.array()
// })

const BankDetailsForm = (props) => {
  const {
    currentDetails,
    // values,
    // setValues
    handleValueChange
  } = props

  useEffect(() => {
    // setValues({
    //   ...values,
    //   bankName: !isEmpty(currentDetails) ? currentDetails.bankName : '',
    //   accNum: !isEmpty(currentDetails) ? currentDetails.accNum : '',
    //   branchName: !isEmpty(currentDetails) ? currentDetails.branchName : '',
    //   ifscCode: !isEmpty(currentDetails) ? currentDetails.ifscCode : '',
    //   type: !isEmpty(currentDetails) ? currentDetails.type : '',
    //   attachments: !isEmpty(currentDetails) ? currentDetails.attachments : ''
    // })
  }, [])

  // useEffect(() => {
  //   handleValueChange(values)
  // }, [
  //   values?.bankName,
  //   values?.accNum,
  //   values?.branchName,
  //   values?.ifscCode,
  //   values?.type,
  //   values?.attachments
  // ])

  const onUploadAttachement = (key, attachments) => {
    handleValueChange({
      ...currentDetails,
      attachments: attachments.filter((attachment) => attachment !== '')
    })
  }

  return (
    <Form>
      <Row justify="left" gutter={(12, 10)}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field
              name="type"
              label="Bank Type"
              as="select"
              options={[
                { label: 'Salary Bank', value: 'Salary Bank' },
                { label: 'Alternate Bank', value: 'Alternate Bank' }
              ]}
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="bankName" label="Bank Name" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="accNum" label="A/C Number" type="number" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="branchName" label="Branch Name" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="ifscCode" label="IFSC Code" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="paymentElection" label="Payment Election %" type="number" />
          </div>
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="form-field">
            <label>Attachments</label>
            <Attachments
              name="educationAttachments"
              value={currentDetails?.attachments || []}
              title="Attach Files"
              description="You can upload a maximum of 3 files, 5MB each"
              acceptFile={['image', 'pdf']}
              onUpload={onUploadAttachement}
            />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default BankDetailsForm

// withFormik({
//   mapPropsToValues: () => ({
//     bankName: '',
//     accNum: '',
//     branchName: '',
//     ifscCode: '',
//     type: '',
//     attachments: []
//   }),
//   handleSubmit: () => null
//   // validationSchema: Schema
// })(BankDetailsForm)
