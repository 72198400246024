import { FieldArray, useFormikContext } from 'formik'
import _ from 'lodash'
import React, { memo, useEffect } from 'react'
import { getOptionsByType } from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'

function SalesText() {
  const {
    values: { salesText },
    setFieldValue
  } = useFormikContext()

  const getData = () => {
    getOptionsByType({
      type: 'LanguageCode'
    }).then(({ LanguageCode = [] }) => {
      setFieldValue(
        'salesText',
        _.unionBy(
          salesText,
          LanguageCode.map((item) => ({ languageCode: item.value, label: item.label })),
          'languageCode'
        ).map((item) => ({
          label: item.label,
          languageCode: item.languageCode,
          materialDescription: item.materialDescription || ''
        }))
      )
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      <FieldArray
        name="salesText"
        render={() => (
          <table cellPadding="10">
            <thead>
              <tr align="center">
                <th nowrap="nowrap">Language Code</th>
                <th width="80%">Material Desc.</th>
              </tr>
            </thead>
            <tbody>
              {salesText.map((item, i) => (
                <tr align="middle" key={i}>
                  <td>
                    <div className="form-field">
                      <label>{item.label}</label>
                    </div>
                  </td>
                  <td>
                    <div className="form-field">
                      <Field name={`salesText[${i}].materialDescription`} value={item.materialDescription} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      />
    </div>
  )
}

export default memo(SalesText)
