import moment from 'moment'
import React, { useEffect, useState } from 'react'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA } from '../../../Util/Util'
import IncomePaymentFilter from './IncomePaymentFilter'

export default function IncomePayments() {
  const [incomePayments, setIncomePayments] = useState([])

  const onFilter = (params = {}) => {
    apiClient.get('incomes/get-payments', { params }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('incomePayments.filterData', { ...params, ...data.pageData })
        setIncomePayments(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('incomePayments.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('incomePayments.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (v) => (v ? moment(v).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Amount',
      dataIndex: 'amount'
    },
    {
      title: 'Type Of Payment',
      dataIndex: 'typeOfPayment'
    },
    {
      title: 'Posting',
      dataIndex: 'posting'
    }
  ]

  return (
    <FilterLayout filter={<IncomePaymentFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Invoice Reciepts</h2>
      </div>
      <TableBox
        dataSource={incomePayments}
        columns={columns}
        pageData={GET_DATA('incomePayments.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
