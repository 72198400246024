import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import { useSelector } from '../../../Hooks/redux'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { DEFAULT_TRANSACTIONS } from '../../../Util/Options'
import { goodsIssueSchema } from '../../../Util/validationSchema'
import TransactionForm from './TransactionForm'

function GoodsIssueForm({
  values,
  setValues,
  match: {
    params: { id }
  }
}) {
  const { orderType } = values

  const { configurations: { warehouseLocations, warehouseRacks } = {} } = useSelector(
    (state) => state.users.companyInfo
  )

  const getData = () => {
    if (id) {
      apiClient.get(`goods-issues/${id}/transactions`).then(({ status, data }) => {
        if (status === 200) {
          data.transactions = data.transactions.map((item) => ({
            ...item,
            materialCodeDesc: _.compact([item.materialCode, item.materialDescription]).join(' - ')
          }))
          setValues({ ...values, ...data, transactionDate: moment(data.createdAt) })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="Goods Issues without reference">
            <Panel title="Issue Header">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="transactionDate" label="Transaction Date" disabled />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      name="orderType"
                      label="Order Type"
                      value={values.orderType?.capitalize()}
                      disabled
                    />
                  </div>
                </Col>
                {!orderType ||
                  (orderType !== 'adhoc' && (
                    <Col xs={24} sm={24} md={12} lg={8}>
                      <div className="form-field">
                        <Field name="orderNo" label="Order Reference" disabled={id} />
                      </div>
                    </Col>
                  ))}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="requester" label="Requester" disabled={id} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      name="amount"
                      label="Amount"
                      value={_.sumBy(values.transactions, 'amount')}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="currency" label="Currency" disabled />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="remarks" label="Remarks" disabled={id} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Issue Materials">
              <FieldArray
                name="transactions"
                additionalValues={{ orderType }}
                loaderCount={8}
                defaultValues={{ ...DEFAULT_TRANSACTIONS, warehouseLocations, warehouseRacks }}>
                {TransactionForm}
              </FieldArray>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            {!id && (
              <>
                <Button type="submit" variant="primary">
                  Save
                </Button>
                <span>or</span>
              </>
            )}
            <Link to="/app/goods-issues">
              <Button>
                <ArrowLeftOutlined /> Back to Goods Issues list
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({
    companyInfo: { currency, configurations: { warehouseLocations, warehouseRacks } = {} }
  }) => ({
    transactionDate: moment().utc().startOf('day'),
    currency,
    orderType: 'adhoc',
    orderNo: '',
    requester: '',
    remarks: '',
    transactions: [{ ...DEFAULT_TRANSACTIONS, warehouseLocations, warehouseRacks }]
  }),
  validationSchema: goodsIssueSchema,
  handleSubmit: (data, { props: { history } }) => {
    apiClient.post('goods-issues', data).then(({ status }) => {
      if (status === 201) {
        history.push('/app/goods-issues')
      }
    })
  }
})(GoodsIssueForm)
