import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import React, { memo } from 'react'
import { stockCheck } from '../../Actions/UserAction'
import Button from '../../Components/Button'
import TableBox from '../../Components/TableBox/TableBox'

function CheckAvailability({ orderDetails, setFieldValue }) {
  const checkAvailability = () => {
    stockCheck({
      materialCode: _.map(_.unionBy(orderDetails, 'materialCode'), 'materialCode')
    }).then((data) => {
      const sum = {}

      data.forEach((item) => {
        if (!sum[item.materialCode]) {
          sum[item.materialCode] = 0
        }

        sum[item.materialCode] += item.quantity
      })
      orderDetails.forEach((item, i) => {
        if (
          !sum[item.materialCode] ||
          data.length === 0 ||
          sum[item.materialCode] - item.orderedQuantity < 0
        ) {
          setFieldValue(`orderDetails[${i}].stockStatus`, 'Not OK')
          setFieldValue(
            `orderDetails[${i}].warehouseInfo`,
            data
              .filter((item2) => item2.materialCode === item.materialCode)
              .concat([
                {
                  warehouse: 'Blank',
                  quantity: item.orderedQuantity - sum[item.materialCode] || ''
                }
              ])
          )
        } else {
          sum[item.materialCode] -= item.orderedQuantity
          setFieldValue(`orderDetails[${i}].stockStatus`, 'OK')
          setFieldValue(
            `orderDetails[${i}].warehouseInfo`,
            data.filter((item2) => item2.materialCode === item.materialCode)
          )
        }
      })
    })
  }

  const columns = [
    {
      title: 'Position',
      dataIndex: 'position'
    },
    {
      title: 'Material',
      dataIndex: 'materialCode'
    },
    {
      title: 'Ordered Quantity',
      dataIndex: 'orderedQuantity',
      align: 'right'
    },
    {
      title: 'Requested Date',
      dataIndex: 'requestedDate',
      render: (date) => moment(date).format('DD.MM.YY')
    },
    {
      title: 'Stock Status',
      dataIndex: 'stockStatus',
      align: 'center',
      render: (status) => ({
        children: status === 'OK' ? <CheckOutlined /> : <CloseOutlined />,
        props: {
          className: clsx('text-white align-middle', status === 'OK' ? 'bg-success' : 'bg-danger')
        }
      })
    },
    {
      title: () => (
        <span className="d-flex">
          <span className="mr-4">Warehouse</span>
          <span className="ml-auto">Warehouse Quantity</span>
        </span>
      ),
      dataIndex: 'warehouseInfo',
      render: (warehouseInfo) => ({
        children: warehouseInfo ? (
          warehouseInfo.map((item, i2) => (
            <span className="d-flex" key={i2}>
              <span>{item.warehouse}</span>
              <span className="ml-auto">{item.quantity}</span>
            </span>
          ))
        ) : (
          <div className="text-right">0</div>
        ),
        props: {
          colSpan: 2
        }
      })
    }
  ]

  return (
    <>
      <Button className="mb-3" onClick={checkAvailability}>
        Check Availability
      </Button>
      {orderDetails.some((item) => item.stockStatus) && (
        <Button
          className="ml-2 mb-2"
          onClick={() =>
            Modal.info({
              width: 800,
              icon: null,
              content: (
                <TableBox columns={columns} dataSource={_.orderBy(orderDetails, 'orderedQuantity', 'desc')} />
              )
            })
          }>
          <InfoCircleOutlined />
        </Button>
      )}
    </>
  )
}

export default memo(CheckAvailability)
