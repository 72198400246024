import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React from 'react'
import pricingImage from '../../../assets/images/pricing.svg'
import DigitalSignature from '../../../assets/images/pricings/digital-signature.svg'
import eInvoicing from '../../../assets/images/pricings/e-invoicing.svg'
import financeManagement from '../../../assets/images/pricings/finance-management.svg'
import hrpayrollManagement from '../../../assets/images/pricings/hr-payroll-management.svg'
import logisticsManagement from '../../../assets/images/pricings/logistics-management.svg'
import serviceManagement from '../../../assets/images/pricings/service-management.svg'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import Form from '../../../Components/Formik/Form'
import Instructions from './Instructions'
import './Pricing.scss'

const Pricing = (props) => (
  <Form>
    {/* Breadcrum starts */}
    <section className="breadcrum-title">
      <div className="container-fluid">
        <Row justify="center">
          <Col span={21}>
            <Row justify="left">
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="breadcrum-content">
                  <h1>Accqrate Pricing</h1>
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <img src={pricingImage} alt="Accqrate Pricings" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
    {/* breadcrum ends here */}

    <section className="inner-pages pricing">
      <div className="container-fluid">
        <Row justify="center">
          <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 18 }}>
            <Row justify="center" gutter={[20]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <Instructions />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                {/* <div className="choose-user">
                  <h2>Choose the number of Users & Business Unit</h2>
                  <div className="quantity">
                    <div className="form-fields">
                      <Field name="usersCount" type="number" value="1" min="1" />
                    </div>
                    <span className="user">Users</span>
                    <span className="price">
                      <strong>$2.00 USD</strong>/user/month
                    </span>
                  </div>
                  <div className="quantity">
                    <div className="form-fields">
                      <Field name="businessUnit" type="number" value="1" min="1" />
                    </div>
                    <span className="user">Business Unit</span>
                    <span className="price">
                      <strong>$2.00 USD</strong>/Business/month
                    </span>
                  </div>
                </div> */}
                <div className="choose-modules">
                  {/* <h2>Choose your Modules</h2> */}
                  <div className="modules">
                    <Row justify="left" gutter={[20]}>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="list mb-3">
                          <div className="image">
                            <img src={eInvoicing} alt="E-Invoicing Solution" />
                          </div>
                          <div className="title-price">
                            <h4>E-Invoicing Solution</h4>
                            <div className="app-pricing">
                              <strong>
                                <span className="amount">$5.00</span> <span className="currency">USD</span>
                              </strong>{' '}
                              / month
                            </div>
                            {/* <Field name="serviceManagement" as="checkbox" /> */}
                          </div>
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="list mb-3">
                          <div className="image">
                            <img src={DigitalSignature} alt="Digital signatures" />
                          </div>
                          <div className="title-price">
                            <h4>Digital signatures</h4>
                            <div className="app-pricing">
                              <strong>
                                <span className="amount">$0.01</span> <span className="currency">USD</span>
                              </strong>{' '}
                              / month
                            </div>
                            {/* <Field name="eInvoicing" as="checkbox" /> */}
                          </div>
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="list mb-3">
                          <div className="image">
                            <img src={financeManagement} alt="Finance Management" />
                          </div>
                          <div className="title-price">
                            <h4>Finance Management</h4>
                            <div className="app-pricing">
                              <strong>
                                <span className="amount">$2.00</span> <span className="currency">USD</span>
                              </strong>{' '}
                              / month
                            </div>
                            {/* <Field name="financeManagement" as="checkbox" /> */}
                          </div>
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="list mb-3">
                          <div className="image">
                            <img src={serviceManagement} alt="service Management" />
                          </div>
                          <div className="title-price">
                            <h4>Service Management</h4>
                            <div className="app-pricing">
                              <strong>
                                <span className="amount">$2.00</span> <span className="currency">USD</span>
                              </strong>{' '}
                              / month
                            </div>
                            {/* <Field name="serviceManagement" as="checkbox" /> */}
                          </div>
                        </div>
                      </Col>

                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="list mb-3">
                          <div className="image">
                            <img src={logisticsManagement} alt="Logistics Management" />
                          </div>
                          <div className="title-price">
                            <h4>Logistics Management</h4>
                            <div className="app-pricing">
                              <strong>
                                <span className="amount">$5.00</span> <span className="currency">USD</span>
                              </strong>{' '}
                              / month
                            </div>
                            {/* <Field name="logistics" as="checkbox" /> */}
                          </div>
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="list mb-3">
                          <div className="image">
                            <img src={hrpayrollManagement} alt="HR & Payroll" />
                          </div>
                          <div className="title-price">
                            <h4>HR and Payroll</h4>
                            <div className="app-pricing">
                              <strong>
                                <span className="amount">$2.00</span> <span className="currency">USD</span>
                              </strong>{' '}
                              / month
                            </div>
                            {/* <Field name="hrpayrollManagement" as="checkbox" /> */}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <ButtonBox
                      onClick={() => props.history.push('contact-us')}
                      type="primary"
                      variable="standard">
                      Request for Quote
                    </ButtonBox>
                  </div>
                </div>
              </Col>
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <div className="total-pricing-summary">
                  <div className="billing-period">
                    <ButtonBox type="default" variable="standard" className="active">
                      Monthly
                    </ButtonBox>
                    <ButtonBox type="default" variable="standard">
                      Yearly
                    </ButtonBox>
                  </div>
                  <ul>
                    <li>
                      <span>1 Users</span>
                      <strong>$0.00</strong> USD
                    </li>
                    <li>
                      <span>
                        User Discount <sup>(1)</sup>
                      </span>{' '}
                      <strong>$0.00</strong> USD
                      <div className="discount">Apply Discount</div>
                    </li>

                    <li>
                      <span>1 Module</span>
                      <strong>$0.00</strong> USD
                    </li>
                    <li>
                      <span>
                        <strong>Total / month</strong> <sup>(2)</sup>
                      </span>{' '}
                      <strong>$0.00</strong> USD
                    </li>
                  </ul> 
                  <div className="checkout">
                    <ButtonBox
                      onClick={() => props.history.push('contact-us')}
                      type="primary"
                      variable="standard">
                      Request for Quote
                    </ButtonBox>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  </Form>
)
export default withFormik({
  mapPropsToValues: () => ({
    usersCount: '',
    businessUnit: '',
    serviceManagement: '',
    financeManagement: '',
    logistics: '',
    hrpayrollManagement: '',
    eInvoicing: ''
  }),
  handleSubmit: () => null
  // validationSchema: Schema
})(Pricing)
