import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Popconfirm, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import TableBox from '../../../Components/TableBox/TableBox'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { numberingSeriesSchema } from '../../../Util/validationSchema'

function NumberingSeries({ values, submitForm, validateForm, setFieldValue, setValues, resetForm }) {
  const [data, setData] = useState([])
  const [addingKey, setAddingKey] = useState('')
  const [editingKey, setEditingKey] = useState('')
  const [validToKey, setValidToKey] = useState('')
  const isAdding = (record) => record.id === addingKey
  const isEditing = (record) => record.id === editingKey
  const isValidToEditing = (record) => record.id === validToKey

  const getData = () => {
    apiClient.get('numbering-series').then(({ status, data }) => {
      if (status === 200) {
        setData(data)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const formData = (row) => {
    row.transactionNo = (row.prefix || '') + row.startNo + (row.suffix || '')
    row.seriesLength = row.startNo.length
    row.validFrom = moment(row.validFrom).utc().startOf('day')
    row.validTo = moment(row.validTo).utc().endOf('day')

    const index = data.findIndex((item) => item.id === row.id)

    if (index && moment(row.validFrom).isAfter(moment())) {
      row.prevData = {
        id: data[index - 1].id,
        validTo: moment(row.validFrom).subtract(1, 'day').utc().endOf('day')
      }
    }

    return row
  }

  const onRowAdd = (row) => {
    apiClient.post('numbering-series', formData(row)).then(({ status }) => {
      if (status === 201) {
        getData()
        resetForm()
        setAddingKey('')
      }
    })
  }

  const onRowUpdate = (id, row) => {
    apiClient.put(`numbering-series/${id}`, formData(row)).then(({ status }) => {
      if (status === 200) {
        getData()
        resetForm()
        setValidToKey('')
        setEditingKey('')
      }
    })
  }

  const edit = (record, validToEditing) => {
    if (record.validFrom) {
      record.validFrom = moment(record.validFrom).utc().startOf('day')
    }

    if (record.validTo) {
      record.validTo = moment(record.validTo).utc().endOf('day')
    }

    setValues({ ...values, ...record })

    if (validToEditing) {
      setValidToKey(record.id)
    } else {
      setEditingKey(record.id)
    }
  }

  const add = (record, index) => {
    record.validFrom = moment(record.validTo).add(1, 'day').utc().startOf('day')

    record.validTo = moment(record.validFrom).add(1, 'year').utc().endOf('day')

    record = _.pick(record, [
      'id',
      'network',
      'company',
      'module',
      'transaction',
      'code',
      'validFrom',
      'validTo'
    ])

    const arr = [...data]

    setValues({
      ...values,
      ...record
    })
    arr.splice(index, 0, {
      ...values,
      ...record
    })
    setData(arr)
    setAddingKey(record.id)
  }

  const save = async (id) => {
    await submitForm()

    validateForm().then((err) => {
      if (_.isEmpty(err)) {
        if (id) {
          onRowUpdate?.(id, values)
        } else {
          onRowAdd?.(values)
        }
      }
    })
  }

  const renderAdd = (record, i) => {
    const addable = isAdding(record)

    return addable ? (
      <div className="d-flex">
        <Button onClick={() => save()} className="btn-glow mr-2">
          <CheckOutlined />
        </Button>
        <Popconfirm
          title="Sure to cancel?"
          onConfirm={() => {
            setAddingKey('')
            const arr = [...data]

            arr.splice(record.id, 1)
            setData(arr)
            resetForm()
          }}>
          <Button className="btn-glow">
            <CloseOutlined />
          </Button>
        </Popconfirm>
      </div>
    ) : (
      <Button
        disabled={addingKey || validToKey}
        onClick={() => add({ ...record, id: i + 1 }, i + 1)}
        className="ml-2 btn-glow"
        success>
        <PlusOutlined />
      </Button>
    )
  }

  const renderEdit = (record, validToEditing) => {
    const editable = isEditing(record)
    const validToEditable = isValidToEditing(record)

    return editable || validToEditable ? (
      <div className="d-flex">
        <Button onClick={() => save(record.id)} className="btn-glow ml-auto mr-2">
          <CheckOutlined />
        </Button>
        <Popconfirm
          title="Sure to cancel?"
          onConfirm={() => {
            setValidToKey('')
            setEditingKey('')
            resetForm()
          }}>
          <Button className="btn-glow">
            <CloseOutlined />
          </Button>
        </Popconfirm>
      </div>
    ) : (
      <Button
        disabled={editingKey || validToKey}
        onClick={() => edit(record, validToEditing)}
        className="btn-glow">
        <EditOutlined />
      </Button>
    )
  }

  let sameKey
  const columns = [
    {
      title: 'Module',
      dataIndex: 'module',
      render: (value) => {
        const obj = { children: value, props: {} }

        if (!(sameKey !== value)) {
          obj.props.rowSpan = 0
        } else {
          sameKey = value
          obj.props.rowSpan = data.filter((item) => item.module === value).length
        }

        return obj
      }
    },
    { title: 'Transaction', dataIndex: 'transaction' },
    { title: 'Prefix', dataIndex: 'prefix', editable: true, dateFormat: true },
    { title: 'Start number', dataIndex: 'startNo', editable: true },
    { title: 'Suffix', dataIndex: 'suffix', editable: true, dateFormat: true },
    { title: 'Transaction number', dataIndex: 'transactionNo' },
    {
      title: 'Valid from',
      dataIndex: 'validFrom',
      render: (date) => (date ? moment(date).utc().format('yyyy-MM-DD') : ''),
      as: 'date',
      editable: true
    },
    {
      title: 'Valid to',
      dataIndex: 'validTo',
      render: (date) => (date ? moment(date).utc().format('yyyy-MM-DD') : ''),
      as: 'date',
      editable: true
    },
    { title: 'Last generated no.', dataIndex: 'lastGeneratedNo', render: (text) => text || '-' },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'right',
      render: (text, record, i) => (
        <>
          {!record.lastGeneratedNo ? !addingKey && renderEdit(record) : renderEdit(record, true)}
          {!editingKey &&
            ((data[i - 1]?.code === record.code && data[i + 1]?.code !== record.code) ||
              _.countBy(data, 'code')[record.code] === 1) &&
            renderAdd(record, i)}
        </>
      )
    }
  ].map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      render: (val, record, i) =>
        isAdding(record) || isEditing(record) || (isValidToEditing(record) && col.dataIndex === 'validTo') ? (
          col.dateFormat ? (
            <div className="d-flex align-items-center">
              <div>
                <Field
                  as="checkbox"
                  className="d-flex align-items-center middle"
                  name={`${col.dataIndex}IsDate`}
                  label="Date"
                  onChange={(n, v) => {
                    if (v) {
                      setFieldValue(col.dataIndex, '${YYYY-MM-DD}')
                    } else {
                      setFieldValue(col.dataIndex, '')
                    }

                    return setFieldValue(n, v)
                  }}
                />
              </div>
              <Field name={col.dataIndex} label={col.title} hideLabel />
            </div>
          ) : (
            <Field
              key={i}
              name={col.dataIndex}
              label={col.title}
              {..._.pick(col, ['as', 'type'])}
              {...(col.as === 'date' && {
                disabledDate: (current) => {
                  const filteredData = data.filter((item, i2) => item.code === record.code && i2 !== i)

                  if (filteredData.length === 0) {
                    return false
                  }

                  return (
                    (col.dataIndex === 'validFrom' && moment(current).isBefore(moment())) ||
                    (col.dataIndex === 'validTo' && moment(current).isBefore(record.validFrom)) ||
                    (col.dataIndex === 'validTo' &&
                      filteredData.some((item) => moment(current).isBetween(item.validFrom, item.validTo)))
                  )
                }
              })}
              hideLabel
            />
          )
        ) : (
          col.render?.(val, record, i) || val
        )
    }
  })

  return (
    <Row justify="center" className="inner-contents">
      <Col xs={22} sm={22} md={20} lg={20}>
        <PanelLayout title="Numbering Series">
          <Form>
            <TableBox columns={columns} dataSource={data} />
          </Form>
        </PanelLayout>
      </Col>
    </Row>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    prefixIsDate: false,
    prefix: '',
    startNo: '',
    suffixIsDate: false,
    suffix: '',
    validFrom: moment(),
    validTo: moment().add(1, 'year')
  }),
  validationSchema: numberingSeriesSchema,
  handleSubmit: () => null
})(NumberingSeries)
