import { FunnelPlotOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Row } from 'antd'
import React, { useEffect } from 'react'
import TableBox from '../../../Components/TableBox/TableBox'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, useStateCallback, validateAccess } from '../../../Util/Util'
import CategoryFilter from './CategoryFilter'

const { Sider, Content } = Layout

const Categoties = (props) => {
  const { history } = props
  const [state, setState] = useStateCallback({
    viewType: 'table',
    currencies: [],
    filterview: 'filter',
    currentPage: 1,
    limit: 10,
    filterFlag: false,
    filterQuery: {},
    total: ''
  })

  const { filterview, viewType } = state

  useEffect(() => {
    onFilter(GET_DATA('category.filterData'))
  }, [])

  // responsive filter starts
  const responsiveFilter = () => {
    const x = document.getElementById('mobile-sider-menu')

    if (x.style.display === 'block') {
      x.style.display = 'none'
      setState({ ...state, filterview: 'filter' })
    } else {
      x.style.display = 'block'
      setState({ ...state, filterview: 'filter' })
    }
  }

  const onFilter = (obj = {}) => {
    apiClient.get('expense-categories', { params: obj }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('category.filterData', { ...obj, ...data.pageData })
        setState({ currencies: data.result })
      }
    })
  }

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('category.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    validateAccess('edit-category')
      ? {
          title: 'Action',
          dataIndex: 'custom_action',
          render: (text, row) => (
            <div className="btn-group">
              <button
                type="button"
                onClick={() => history.push(`/app/edit-category/${row.id}`)}
                className="btn glow dropdown-toggle">
                <SettingOutlined />
              </button>
            </div>
          )
        }
      : {}
  ]

  return (
    <Layout className="income-sidebar">
      <div className="mobile-filter">
        <button type="button" className="btn btn-glow">
          {filterview === 'filter' ? (
            <FunnelPlotOutlined onClick={responsiveFilter} />
          ) : (
            <FunnelPlotOutlined onClick={responsiveFilter} />
          )}
        </button>
      </div>
      <Sider width={230} trigger={null} collapsible collapsed={false} id="mobile-sider-menu">
        {/* search filter starts  */}

        <CategoryFilter {...props} onFilter={onFilter} />

        {/* search filter ends */}
      </Sider>
      <Layout className="site-layout">
        <Content className="site-layout-background">
          <div className="top-filter-options">
            <Row>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}>
                <h2>Category Overview</h2>
              </Col>

              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}>
                <div className="exports-and-settings">
                  <ul>
                    <li>
                      <Button
                        type="standard"
                        className="ant-dropdown-link"
                        onClick={() => setState({ ...state, viewType: 'table' })}>
                        <i className="flaticon-table no-margin" />
                      </Button>
                    </li>
                    <li>
                      <Button
                        type="standard"
                        className="ant-dropdown-link"
                        onClick={() => setState({ ...state, viewType: 'card' })}>
                        <i className="flaticon-grid no-margin" />
                      </Button>
                    </li>

                    <li>
                      <Button type="standard" className="ant-dropdown-link">
                        <i className="flaticon-settings no-margin" />
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24, order: 3 }}
                sm={{ span: 24, order: 3 }}
                md={{ span: 24, order: 3 }}
                lg={{ span: 0, order: 3 }}>
                <div className="add-new-invoice-button">
                  <button
                    type="button"
                    onClick={() => history.push('/app/add-category')}
                    className="btn-glow btn-block primary">
                    Add new category
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <TableBox
            viewType={viewType}
            dataSource={state.currencies}
            columns={columns}
            pageData={GET_DATA('category.filterData')}
            onChangePage={onChangePage}
            actionIndex="custom_action"
            cardHeaderIndex="status"
            cardFirstLabelIndex="docno"
          />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Categoties
