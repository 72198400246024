import { Col, Row } from 'antd'
import _ from 'lodash'
import React, { memo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getLocationsByStock,
  getMaterial,
  getProduct,
  getRacksByStock,
  getWarehousesByStock
} from '../../../Actions/UserAction'
import Field from '../../../Components/Formik/Field'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import { TRANSACTION_STATUS } from '../../../Util/Options'

function TransactionForm({
  i,
  materialCode,
  warehouse,
  location,
  itemStatus,
  cost,
  quantity,
  setFieldValue,
  materialCodeAlt,
  materialDescriptionAlt,
  orderType
}) {
  const [materials, setMaterials] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [locations, setLocations] = useState([])
  const [racks, setRacks] = useState([])

  const { id } = useParams()

  useDidUpdateEffect(() => {
    if (id && materialCode) {
      getProduct({ materialCode }).then((data) => {
        if (data) {
          setFieldValue(`transactions[${i}].cost`, data?.costInfo?.cost)
        }
      })
    }
  }, [id, materialCode])

  const getMaterials = (material) => {
    getMaterial({ material }).then((data) => {
      setMaterials(data)
    })
  }

  const getWarehouses = (params) => {
    getWarehousesByStock(params).then((data) => {
      setWarehouses(data)
    })
  }

  const getLocations = (params) => {
    getLocationsByStock(params).then((data) => {
      setLocations(data)
    })
  }

  const getRacks = (params) => {
    getRacksByStock(params).then((data) => {
      setRacks(data)
    })
  }

  useDidUpdateEffect(() => {
    if (cost && quantity) {
      setFieldValue(`transactions[${i}].amount`, cost * quantity)
    } else {
      setFieldValue(`transactions[${i}].amount`, '')
    }
  }, [cost, quantity])

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} sm={24} md={8} lg={6}>
        <div className="form-field">
          <Field
            as="auto-complete"
            textArea
            rows={1}
            name={`transactions[${i}].materialCodeDesc`}
            label="Material / Description"
            onSearch={getMaterials}
            onChange={(n, v, o) => {
              setFieldValue(`transactions[${i}].materialCode`, o?.basic?.materialCode)
              setFieldValue(`transactions[${i}].materialDescription`, o?.basic?.materialDescription)
              setFieldValue(`transactions[${i}].materialCodeAlt`, o?.basic?.materialCodeAlt)
              setFieldValue(`transactions[${i}].materialDescriptionAlt`, o?.basic?.materialDescriptionAlt)
              setFieldValue(`transactions[${i}].unit`, o?.basic?.unit)
              setFieldValue(`transactions[${i}].warehouse`, o?.stockInfo?.defaultWarehouse)
              setFieldValue(`transactions[${i}].location`, o?.stockInfo?.defaultLocation)
              setFieldValue(`transactions[${i}].rack`, o?.stockInfo?.defaultRack)
              setFieldValue(`transactions[${i}].cost`, o?.costInfo?.cost)
              getWarehouses({ materialCode: v })

              return setFieldValue(n, v)
            }}
            options={materials}
            altInput
            altDisabled
            altValue={_.compact([materialCodeAlt, materialDescriptionAlt]).join(' - ')}
            disabled={id}
          />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3} xl={2}>
        <div className="form-field">
          <Field label="UOM" name={`transactions[${i}].unit`} disabled />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={3} xl={2}>
        <div className="form-field">
          <Field type="number" label="Quantity" name={`transactions[${i}].quantity`} disabled={id} />
        </div>
      </Col>
      {materialCode && (
        <Col xs={24} sm={24} md={8} lg={4} xl={3}>
          <div className="form-field">
            <Field
              key={materialCode}
              as="select"
              label="Warehouse"
              name={`transactions[${i}].warehouse`}
              options={warehouses}
              onChange={(n, v) => {
                setFieldValue(`transactions[${i}].location`, '')
                setFieldValue(`transactions[${i}].rack`, '')
                getLocations({
                  materialCode,
                  warehouse: v
                })

                return setFieldValue(n, v)
              }}
              disabled={id}
            />
          </div>
        </Col>
      )}
      {warehouse && (
        <Col xs={24} sm={24} md={8} lg={4} xl={3}>
          <div className="form-field">
            <Field
              key={warehouse}
              as="select"
              label="Location"
              name={`transactions[${i}].location`}
              options={locations}
              onChange={(n, v) => {
                setFieldValue(`transactions[${i}].rack`, '')
                getRacks({
                  materialCode,
                  warehouse,
                  location: v
                })

                return setFieldValue(n, v)
              }}
              disabled={id}
            />
          </div>
        </Col>
      )}
      {warehouse && location && racks.length > 0 && (
        <Col xs={24} sm={24} md={8} lg={4} xl={3}>
          <div className="form-field">
            <Field
              key={warehouse + location}
              as="select"
              label="Rack"
              name={`transactions[${i}].rack`}
              options={racks}
              disabled={id}
            />
          </div>
        </Col>
      )}
      <Col xs={12} sm={12} md={8} lg={4} xl={2}>
        <div className="form-field">
          <Field label="Standard rate" name={`transactions[${i}].cost`} disabled />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={4} xl={2}>
        <div className="form-field">
          <Field label="Line amount" name={`transactions[${i}].amount`} disabled />
        </div>
      </Col>
      <Col xs={12} sm={12} md={8} lg={4} xl={3}>
        <div className="form-field">
          <Field
            as="select"
            label="Status"
            name={`transactions[${i}].status`}
            options={TRANSACTION_STATUS}
            disabled={id}
          />
        </div>
      </Col>
      {id && orderType === 'sales' && (
        <>
          <Col xs={12} sm={12} md={8} lg={4} xl={3}>
            <div className="form-field">
              <Field label="Ref doc no" name={`transactions[${i}].refDocNo`} disabled={id} />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={3} xl={2}>
            <div className="form-field">
              <Field label="Ref position" name={`transactions[${i}].refPosition`} disabled={id} />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={4} xl={3}>
            <div className="form-field">
              <Field label="Parent doc" name={`transactions[${i}].parentDoc`} disabled={id} />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={3} xl={3}>
            <div className="form-field">
              <Field
                label="Parent doc position"
                name={`transactions[${i}].parentDocPosition`}
                disabled={id}
              />
            </div>
          </Col>
        </>
      )}
      {id && (
        <div className="mt-auto mb-3 pl-3">
          {itemStatus === 'approved' ? (
            <span>
              ✅<span className="pl-2">{itemStatus}</span>
            </span>
          ) : (
            <span>
              ❌<span className="pl-2 text-danger">{itemStatus}</span>
            </span>
          )}
        </div>
      )}
    </Row>
  )
}

export default memo(TransactionForm)
