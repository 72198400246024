import { withFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Field from '../../Components/Formik/Field'
import Form from '../../Components/Formik/Form'
import ModalBox from '../../Components/ModalBox/ModalBox'
import apiClient from '../../Util/apiClient'
import { STATUS } from '../../Util/Options'
import { convertSelectOptions } from '../../Util/Util'

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  status: Yup.string().required()
})

const DEFAULT_TEMPLATES = [
  { label: 'Default Daily Payroll', value: 'defaultPayrollDailyTemplate', type: 'Payroll' },
  { label: 'Default Monthly Payroll', value: 'defaultPayrollMonthlyTemplate', type: 'Payroll' },
  { label: 'Default Income', value: 'defaultIncomeInvoiceTemplate', type: 'Invoice' },
  { label: 'Default Expense', value: 'defaultExpenseInvoiceTemplate', type: 'Invoice' },
  { label: 'Default Timesheet', value: 'defaultTimesheetTemplate', type: 'Timesheet' },
  { label: 'Default Client Timesheet', value: 'defaultClientTimesheetTemplate', type: 'Timesheet' },
  { label: 'Default Sales Order', value: 'defaultSalesOrderTemplate', type: 'Order' },
  { label: 'Default Purchase Order', value: 'defaultPurchaseOrderTemplate', type: 'Order' },
  { label: 'Default Order Invoice', value: 'defaultOrderInvoiceTemplate', type: 'OrderInvoice' }
]

const INVOICE_FOR_OPTIONS = [
  { label: 'Income', value: 'Income' },
  { label: 'Expense', value: 'Expense' }
]

const FRIEGHT_FOR_OPTIONS = [
  { label: 'Offer', value: 'Offer' },
  { label: 'Booking', value: 'Booking' },
  { label: 'Job Creation', value: 'Job Creation' }
]

function TemplateForm(props) {
  const [templates, setTemplates] = useState([])
  const { edit, activeTab, submitForm, validateForm, values, onAdd, open, onCancel, resetForm, setValues } =
    props

  const onSubmitForm = async () => {
    await submitForm()
    validateForm().then((err) => {
      if (_.isEmpty(err)) {
        onAdd(values)
        resetForm()
      }
    })
  }

  useEffect(() => {
    if (edit) {
      setValues(edit)
    } else {
      apiClient.get('customTemplates/get-all-templates', { params: { type: activeTab } }).then(({ data }) => {
        if (data && data.result) {
          setTemplates(data.result)
        }
      })
    }
  }, [])
  const defaultOption = DEFAULT_TEMPLATES.filter((val) => val.type === activeTab)

  const FOR_OPTIONS = props.type === 'Freight' ? FRIEGHT_FOR_OPTIONS : INVOICE_FOR_OPTIONS

  return (
    <ModalBox
      className="add-new-report"
      title={`Add ${props.type} Template`}
      visible={open}
      onOk={onSubmitForm}
      okText={edit ? 'Update' : 'Add'}
      onCancel={() => {
        onCancel(false)
        resetForm()
      }}>
      <Form>
        <div className="form-fields">
          <Field name="name" label="Name" />
        </div>
        <div className="form-fields">
          <Field name="description" label="Description" />
        </div>
        {!edit && (
          <div className="form-fields">
            <Field
              name="copyFrom"
              label="Copy from"
              as="select"
              options={[...defaultOption, ...convertSelectOptions(templates, 'name', 'id')]}
            />
          </div>
        )}

        <div className="form-fields">
          <Field name="for" label="For" as="select" options={FOR_OPTIONS} />
        </div>

        <div className="form-fields">
          <Field name="status" label="Status" as="select" options={STATUS} />
        </div>
      </Form>
    </ModalBox>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    description: '',
    copyFrom: '',
    for: '',
    status: 'Active'
  }),
  handleSubmit: () => null,
  validationSchema: Schema
})(TemplateForm)
