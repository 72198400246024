import { Col, Row } from 'antd'
import React from 'react'
import './TermsandConditions.scss'

export default class TermsandConditions extends React.PureComponent {
  render() {
    return (
      <>
        {/* Breadcrum starts */}
        <section className="breadcrum-title only-heading">
          <div className="container-fluid">
            <Row justify="center">
              <Col span={21}>
                <Row justify="left">
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <div className="breadcrum-content">
                      <h1>Terms and Conditions</h1>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
        {/* breadcrum ends here */}

        <section className="inner-pages">
          <div className="container-fluid">
            <Row justify="center">
              <Col span={20}>
                <Row justify="left" gutter={[20]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <h1>Terms and Conditions for doing business with ACCQRATE</h1>
                    <p>TERMS OF BUSINESS</p>
                    <h3>1. The Agreement</h3>
                    <p>
                      This agreement shall constitute the whole contract between ACCQRATE and the Client to
                      the exclusion of all conditions and warranties statutory or otherwise which are
                      permitted by law to be excluded. Any variation to this agreement is only binding on
                      ACCQRATE if it is in writing and signed by a director of ACCQRATE.
                    </p>
                    <h3>2. Software Systems</h3>
                    <p>
                      ACCQRATE software system(s) (&quot;the System&quot;) consists of a pre-written program
                      application package, complete with description and documentation as appropriate.
                      ACCQRATE believes that the System(s) being furnished are accurate, reliable and
                      accomplish the results set out in the current application software description. ACCQRATE
                      shall have no obligation to make alterations to the design of the System(s) as described
                      except as under the terms of this agreement.
                    </p>
                    <p>
                      3. ACCQRATE offers a variety of payment options. Electronic Bank transfer, cheque or
                      card payment. &nbsp;We accept card payments via our provider in Sterling (&pound;GBP),
                      US Dollars ($US) , Euros &nbsp;(&euro;EU) and Australian Dollars ($AUS). &nbsp;These
                      payment options may incur a payment fee if applicable. All settlement terms are detailed
                      in each invoice provided) &nbsp;
                    </p>
                    <h3>4. Non-Specific Equipment Users</h3>
                    <p>
                      ACCQRATE reserve the right to make charges for any support or service activity resulting
                      from
                    </p>
                    <p>
                      (i) The use of software, hardware, peripherals, consumables, media or any other item,
                      addition or technique which is not installed approved or supplied by ACCQRATE.
                    </p>
                    <p>
                      (ii) Client personnel not being trained by ACCQRATE or its appointed agents to use the
                      System
                    </p>
                    <h3>5. Title to Software</h3>
                    <p>
                      Title to the ownership of the System(s) shall remain with ACCQRATE. The System must not
                      be copied (save for one back up), reproduced or in any way distributed without
                      permission in writing from ACCQRATE signed by a director of ACCQRATE.
                    </p>
                    <h3>6. Equipment</h3>
                    <p>
                      (i) The use of the System is specifically restricted to the computer equipment approved
                      by ACCQRATE. Any change in the equipment on which the System(s) is to operate must be
                      notified to ACCQRATE in writing.
                    </p>
                    <p>
                      (ii) A separate license is required for each separate computer on which the System(s) is
                      installed, or for each simultaneously operable user who may access the System upon a
                      separate network terminal.
                    </p>
                    <h3>7. Transfer</h3>
                    <p>
                      The Client agrees that granting of a license to use the system conveys to the Client
                      only a non-exclusive license for use of the System(s), at a specific location and that
                      this license may not be assigned, sublicensed, or otherwise transferred except that if
                      the Client is temporarily unable to use the System because of conditions beyond the
                      Client&apos;s control the license may be temporarily transferred to permit the Client to
                      use the System on another computer system.
                    </p>
                    <h3>8. Confidentiality</h3>
                    <p>
                      All information or data passed by the Client to ACCQRATE and any results arising
                      therefrom, which are of a confidential nature will be treated as such and ACCQRATE will
                      use all reasonable endeavour to procure that such information or data is not divulged to
                      any third party without the Client&apos;s authority.
                    </p>
                    <h3>9. Modifications to System</h3>
                    <p>
                      No modifications shall be made to the System except with the consent in writing of
                      ACCQRATE and using the software tools made available by ACCQRATE intended to allow the
                      Client to customize the reporting, analysis and printing functions of the System.
                    </p>
                    <h3>10. Warranty on System</h3>
                    <p>
                      ACCQRATE warrants that the System(s) will be capable of operating in conformity with the
                      current application software description. ACCQRATE undertakes to use all reasonable
                      endeavours to rectify any errors caused by the fault of ACCQRATE, if such defect is
                      notified in writing to ACCQRATE within three months of the date of installation. Any
                      modification or attempted modification of the System(s) by the Client or any other third
                      party shall void this warranty.
                    </p>
                    <h3>11. Database Amendments</h3>
                    <p>
                      (i) The System is not a simple database maintenance application and relies upon the
                      database reflecting its internal logic, so database amendments can have far reaching and
                      extremely serious consequences.
                    </p>
                    <p>
                      (ii) ACCQRATE can only provide support for the System if its database integrity remains
                      inviolate. It is imperative that users do not execute any SQL commands on the System
                      database or use any external tools to edit the database without first obtaining specific
                      clearance through SYNCHRO Support. ACCQRATE reserve the right to withdraw support if the
                      client executes any SQL commands without the prior approval of ACCQRATE
                    </p>
                    <p>
                      (iii) ACCQRATE reserve the right to charge for any work done to correct to its database
                      where such corruption has been caused by external influence including (but not limited
                      to) hardware failure, software failure, or virus (or any other malicious attack). Under
                      such circumstances ACCQRATE cannot guarantee the database in question will remain free
                      from defect.
                    </p>
                    <h3>12. License Fee</h3>
                    <p>
                      ACCQRATE will levy an annual or quarterly license fee (per operating site). The Client
                      shall sign a specific contract to this effect at the date of placing the initial order
                      for the System. It is a specific condition of acquiring the System that the Client shall
                      agree to continue to pay the license fee for so long as the Client continues using the
                      System.
                    </p>
                    <h3>13. Technical Support</h3>
                    <p>
                      (i) ACCQRATE will use all reasonable endeavours to provide a software support service at
                      no additional cost to the Client. During the period when support is in effect ACCQRATE
                      will make available an e-mail and voice message based support service available between
                      the hours of 9am CET to 5pm CET Monday to Friday with the exception of Public and
                      Company holidays.
                    </p>
                    <p>
                      (ii) The helpline shall be available to any duly instructed employee of the Client who
                      holds a user certificate issued by ACCQRATE (or an agent thereof) so long as the Client
                      has paid the appropriate license fee and any other non-disputed invoices from ACCQRATE
                      or its appointed agents.
                    </p>
                    <p>
                      (iii) Under certain situations technical support requires direct control over a computer
                      that has access to the software&apos;s database. This access is normally provided by a
                      remote desktop connection. It is a specific condition of technical support that a remote
                      link be setup to allow this type of access. Due to international time zones this access
                      may have to be available at unattended times.
                    </p>
                    <h3>14. Periodic Software Updates</h3>
                    <p>
                      ACCQRATE will at its discretion provide software updates. Such updates will contain a
                      range of amendments to the package based upon either customer requests during the
                      previous year or upon ACCQRATE design work.
                    </p>
                    <h3>15. Obligations</h3>
                    <p>
                      (i) Except for the express warranties set out under this agreement or where liability
                      may not be legally excluded ACCQRATE will not be under any liability whether in contract
                      loss or otherwise in respect of any consequential damage loss or injury arising out of
                      or in connection with the use of the System or its performance or the failure to supply
                      any equipment or services or any defects in the same whether arising from any suit
                      between ACCQRATE and the Client, the Client and any other part of ACCQRATE and any other
                      party.
                    </p>
                    <p>
                      (ii) Neither party shall be liable for any failure to perform or delay in performance of
                      its obligations hereunder, caused by circumstances beyond its reasonable control
                      including but not limited to fire, storm, flood, earthquake, accident, act of public
                      enemy, war, rebellion, insurrections, labour disputes, labour shortages, transportation
                      embargoes, inability to secure raw materials or machinery for the manufacture of
                      equipment or the development of the System, Act of God, Act of Government or any agency
                      thereof, judicial action and any other such external circumstances, provided that the
                      party seeking to rely on this clause shall have given due notice of the circumstances
                      and probable duration to the other party.
                    </p>
                    <h3>16. Payment Terms</h3>
                    <p>
                      Annual (or quarterly or monthly) license fees are payable immediately upon loading of
                      the software upon the Client&apos;s computer hardware and on each anniversary of this
                      date as long as the Client continues to use the software. All fees are quoted exclusive
                      of VAT, Sales, Purchase, or any other statutory taxes in force at the date of delivery
                      or acceptance. VAT and any other statutory tax will be added to each invoice issued at
                      the then current rate. All fees are due for payment within 30 days, unless otherwise
                      agreed in writing between ACCQRATE and the Client.
                    </p>
                    <p>
                      All Other Charges incurred for consulting work, system design, programming, report
                      writing, data conversion, and installation, and where agreed beforehand, expenses
                      incurred in travelling and local accommodation, will be invoiced monthly for settlement
                      within 30 days, unless otherwise agreed in writing between ACCQRATE and the Client.
                      Invoices or Pro-forma invoices may be issued in advance no line of credit can be
                      established.
                    </p>
                    <p>
                      It is a requirement that any creation or modification of system reports and/or any
                      programming work be inspected within 10 working days of delivery (quoted terms may
                      vary). If fault is found outside this period we reserve the right to charge for
                      correction and /or modification.
                    </p>
                    <p>
                      Failure to pay any non-disputed invoices from ACCQRATE or its appointed agents within
                      the above stated periods will entitle ACCQRATE to withhold all services until said
                      invoice is paid. ACCQRATE also reserves the right to charge a late payment fee for
                      non-disputed invoices.
                    </p>
                    <h3>17. Termination</h3>
                    <p>
                      If any sum payable to ACCQRATE under the terms of this agreement or for any other
                      services supplied is unpaid for fourteen days after it has become due or if there are
                      any other breaches of the contract on the part of the Client or if the Client be
                      adjudged bankrupt, make an assignment or composition with its creditors, or being a
                      company, go into liquidation, or have a receiver or manager of its business or
                      undertaking appointed, then ACCQRATE may, without prejudice to any of its other rights,
                      forthwith terminate the contract in writing to the Client.
                    </p>
                    <h3>18. Quotations</h3>
                    <p>
                      Prices quoted for commercial application software and services submitted to the Client
                      are valid for a period of 30 days from date of the quotation, unless otherwise stated in
                      the quotation under the signature of a Director of ACCQRATE.
                    </p>
                    <h3>19. Cancellation</h3>
                    <p>
                      An order once accepted by ACCQRATE is binding on the Client, and in the event of
                      cancellation ACCQRATE reserves the right to charge for any work done and for any
                      equipment or services provided (including any costs incurred for the purpose of the
                      order) prior to the date of receipt by ACCQRATE of written notice of cancellation.
                    </p>
                    <h3>20. Order Cancellation</h3>
                    <p>
                      The customer will be expected to refund all costs incurred on their behalf (including,
                      but not limited to, flight, hotel, &amp; car hire); in addition cancellation of on-site
                      time will incur a penalty of the total quoted amount as follows: 100% for less than 15
                      days&apos; notice, 75% for 15 to 28 days&apos; notice, 50% for 29 to 42 days&apos;
                      notice, 25% for 43 to 56 days&apos; notice.
                    </p>
                    <h3>21. General</h3>
                    <p>
                      The Client hereby undertakes not to make an offer of employment or any financial
                      inducement to any employee of ACCQRATE or its appointed agents during the course of the
                      contract with ACCQRATE or during a period of six months after its termination.
                    </p>
                    <p>
                      Unless otherwise specified in writing by the Client, ACCQRATE will have the right to use
                      the Client&apos;s name and company logo on publicity material together with the name of
                      the type of application involved.
                    </p>
                    <p>
                      A contract with ACCQRATE shall be considered as a contract made in US and subject to US
                      Law.
                    </p>
                    <p>
                      Differences between ACCQRATE and the Client arising out of this contract shall (except
                      where by its terms ACCQRATE&apos;s decision is to be final and binding) be referred to
                      the arbitration of two persons or their umpire in accordance with the provisions of the
                      arbitration act in force at the start of this contract.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      </>
    )
  }
}
