import { Space } from 'antd'
import _ from 'lodash'
import React, { memo, useMemo } from 'react'
import { useSelector } from '../../../Hooks/redux'
import { parseAmount } from '../../../Util/Util'

function SumFields({ orderDetails, currency, exchangeRate, type }) {
  const { currency: companyCurrency } = useSelector((state) => state.users.companyInfo)

  const amount = parseFloat(_.sumBy(orderDetails, 'amount'))
  const discount = parseFloat(_.sumBy(orderDetails, 'discountValue'))
  const charge = parseFloat(_.sumBy(orderDetails, 'chargeValue'))
  const netAmount = parseFloat(_.sumBy(orderDetails, 'netAmount'))
  const taxAmount = parseFloat(_.sumBy(orderDetails, 'taxAmount'))
  const memoizedAmount = useMemo(() => amount, [amount])
  const memoizedDiscount = useMemo(() => discount, [discount])
  const memoizedCharge = useMemo(() => charge, [charge])
  const memoizedNetAmount = useMemo(() => netAmount, [netAmount])
  const memoizedTaxAmount = useMemo(() => taxAmount, [taxAmount])
  const grossAmount = memoizedTaxAmount + memoizedNetAmount
  const baseAmount = grossAmount * exchangeRate

  return (
    <Space className="flex-wrap">
      <div className="py-1">
        <b>{`${type?.capitalize()} Amount`}: </b>{' '}
        <span className="text-nowrap">{parseAmount(memoizedAmount, currency)}</span>
      </div>
      <div className="py-1">➖</div>
      <div className="py-1">
        <b>Discount Amount: </b>{' '}
        <span className="text-nowrap">{parseAmount(memoizedDiscount, currency)}</span>
      </div>
      <div className="py-1">➕</div>
      <div className="py-1">
        <b>Charge Amount: </b> <span className="text-nowrap">{parseAmount(memoizedCharge, currency)}</span>
      </div>
      <div className="py-1">＝</div>
      <div className="py-1">
        <b>{`${type?.capitalize()} Net Amount`}: </b>{' '}
        <span className="text-nowrap">{parseAmount(memoizedNetAmount, currency)}</span>
      </div>
      <div className="py-1">➕</div>
      <div className="py-1">
        <b>Tax Amount: </b> <span className="text-nowrap">{parseAmount(memoizedTaxAmount, currency)}</span>
      </div>
      <div className="py-1">＝</div>
      <div className="py-1">
        <b>Gross Amount: </b> <span className="text-nowrap">{parseAmount(grossAmount, currency)}</span>
      </div>
      <div className="py-1">
        <b>Amount in base currency: </b>{' '}
        <span className="text-nowrap">{parseAmount(baseAmount, companyCurrency)}</span>
      </div>
    </Space>
  )
}

export default memo(SumFields)
