import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { getActiveOptions, getTimeEntryById, getUserByToken } from '../Actions/UserAction'
import LoaderBox from '../Components/LoaderBox/LoaderBox'
import Layout from '../Layout/Layout'
import ChangePassword from '../Screens/ChangePassword/ChangePassword'
import NewDashboard from '../Screens/Dashboard/NewDashboard'
import ExpensesEntries from '../Screens/ExpensesClaim/ExpensesEntries'
import AccountChartForm from '../Screens/FinanceManagement/AccountCharts/AccountChartForm'
import AccountsChart from '../Screens/FinanceManagement/AccountCharts/AccountCharts'
import AccountGroupForm from '../Screens/FinanceManagement/AccountGroups/AccountGroupForm'
import AccountGroups from '../Screens/FinanceManagement/AccountGroups/AccountGroups'
import AccountSetupForm from '../Screens/FinanceManagement/AccountSetups/AccountSetupForm'
import AccountSetups from '../Screens/FinanceManagement/AccountSetups/AccountSetups'
import FinancePostings from '../Screens/FinanceManagement/FinancePostings/FinancePostings'
import Finances from '../Screens/FinanceManagement/Finances/Finances'
import FinancialYears from '../Screens/FinanceManagement/FinancialYears/FinancialYears'
import JournalVoucherForm from '../Screens/FinanceManagement/JournalVouchers/JournalVoucherForm'
import JournalVouchers from '../Screens/FinanceManagement/JournalVouchers/JournalVouchers'
import BookingForm from '../Screens/FreightManagement/Export/Bookings/BookingForm'
import Bookings from '../Screens/FreightManagement/Export/Bookings/Bookings'
import BookingViewContent from '../Screens/FreightManagement/Export/Bookings/BookingViewContent'
import CreateJobCreations from '../Screens/FreightManagement/Export/JobCreations/CreateJobCreations'
import JobCreationForm from '../Screens/FreightManagement/Export/JobCreations/JobCreationForm'
import JobCreations from '../Screens/FreightManagement/Export/JobCreations/JobCreations'
import JobCreationView from '../Screens/FreightManagement/Export/JobCreations/JobCreationView'
import OfferForm from '../Screens/FreightManagement/Export/Offers/OfferForm'
import Offers from '../Screens/FreightManagement/Export/Offers/Offers'
import OfferViewContent from '../Screens/FreightManagement/Export/Offers/OfferViewContent'
import OperationForm from '../Screens/FreightManagement/Masters/Operations/OperationForm'
import Operations from '../Screens/FreightManagement/Masters/Operations/Operations'
import PackagesTypeForm from '../Screens/FreightManagement/Masters/PackagesTypes/PackagesTypeForm'
import PackagesTypes from '../Screens/FreightManagement/Masters/PackagesTypes/PackagesTypes'
import POLPOD from '../Screens/FreightManagement/Masters/POLPOD/POLPOD'
import POLPODForm from '../Screens/FreightManagement/Masters/POLPOD/POLPODForm'
import PriceChargeForm from '../Screens/FreightManagement/Masters/PriceCharges/PriceChargeForm'
import PriceCharges from '../Screens/FreightManagement/Masters/PriceCharges/PriceCharges'
import SalesPersonForm from '../Screens/FreightManagement/Masters/SalesPersons/SalesPersonForm'
import SalesPersons from '../Screens/FreightManagement/Masters/SalesPersons/SalesPersons'
import TermsConditionForm from '../Screens/FreightManagement/Masters/TermsConditions/TermsConditionForm'
import TermsConditions from '../Screens/FreightManagement/Masters/TermsConditions/TermsConditions'
import SalesCallEntries from '../Screens/FreightManagement/Quotation/SalesCallEntry/SalesCallEntries'
import SalesCallEntryForm from '../Screens/FreightManagement/Quotation/SalesCallEntry/SalesCallEntryForm'
import AbsenceManagement from '../Screens/HumanResources/AbsenceManagement/AbsenceManagement'
import ApplyLeave from '../Screens/HumanResources/AbsenceManagement/ApplyLeave'
import TeamAbsenceApplicationList from '../Screens/HumanResources/AbsenceManagement/TeamAbsenceApplicationList'
import Asset from '../Screens/HumanResources/Asset/Asset'
import AssetForm from '../Screens/HumanResources/Asset/AssetForm'
import ChangeCompensation from '../Screens/HumanResources/ChangeCompensation/ChangeCompensation'
import ChangeJob from '../Screens/HumanResources/ChangeJob/ChangeJob'
import employeeDetails from '../Screens/HumanResources/EmployeeDetails/EmployeeDetails'
import AppraisalReview from '../Screens/HumanResources/Perfomance/AppraisalReview'
import AppraisalReviewForm from '../Screens/HumanResources/Perfomance/AppraisalReviewForm'
import Goals from '../Screens/HumanResources/Perfomance/Goals'
import GoalsAssignment from '../Screens/HumanResources/Perfomance/GoalsAssignment'
import GoalsAssignmentForm from '../Screens/HumanResources/Perfomance/GoalsAssignmentForm'
import GoalsForm from '../Screens/HumanResources/Perfomance/GoalsForm'
import Termination from '../Screens/HumanResources/Termination/Termination'
import Transfer from '../Screens/HumanResources/Transfer/Transfer'
import ExpensePayments from '../Screens/InvoiceData/ExpensePayments/ExpensePayments'
import ExpenseDetailed from '../Screens/InvoiceData/Expenses/ExpenseDetailed'
import ExpenseForm from '../Screens/InvoiceData/Expenses/ExpenseForm'
import ExpenseOverview from '../Screens/InvoiceData/Expenses/ExpenseOverview'
import Expenses from '../Screens/InvoiceData/Expenses/Expenses'
import ExpenseView from '../Screens/InvoiceData/Expenses/ExpenseView'
import GenerateIncomeInvoice from '../Screens/InvoiceData/GenerateIncomeInvoice/GenerateIncomeInvoice'
import IncomePayments from '../Screens/InvoiceData/IncomePayments/IncomePayments'
import IncomeDetailed from '../Screens/InvoiceData/Incomes/IncomeDetailed'
import IncomeForm from '../Screens/InvoiceData/Incomes/IncomeForm'
import IncomeOverview from '../Screens/InvoiceData/Incomes/IncomeOverview'
import Incomes from '../Screens/InvoiceData/Incomes/Incomes'
import IncomeView from '../Screens/InvoiceData/Incomes/IncomeView'
import InboundTransmissions from '../Screens/InvoiceData/Transmissions/InboundTransmissions'
import InboundTransmissionView from '../Screens/InvoiceData/Transmissions/InboundTransmissionView'
import OutboundTransminssions from '../Screens/InvoiceData/Transmissions/OutboundTransminssions'
import OutboundTransmissionView from '../Screens/InvoiceData/Transmissions/OutboundTransmissionView'
import UploadExpenseInvoices from '../Screens/InvoiceData/UploadInvoices/UploadExpenseInvoices'
import UploadIncomeInvoices from '../Screens/InvoiceData/UploadInvoices/UploadIncomeInvoices'
import Categories from '../Screens/MasterData/Categories/Categories'
import CategoryForm from '../Screens/MasterData/Categories/CategoryForm'
import ClientForm from '../Screens/MasterData/Clients/ClientForm'
import Clients from '../Screens/MasterData/Clients/Clients'
import Companies from '../Screens/MasterData/Companies/Companies'
import CompanyConfigurations from '../Screens/MasterData/Companies/CompanyConfigurations'
import CompanyForm from '../Screens/MasterData/Companies/CompanyForm'
import SetCompany from '../Screens/MasterData/Companies/SetCompany'
import Countries from '../Screens/MasterData/Countries/Countries'
import CountryForm from '../Screens/MasterData/Countries/CountryForm'
import Currencies from '../Screens/MasterData/Currencies/Currencies'
import CurrencyForm from '../Screens/MasterData/Currencies/CurrencyForm'
import DivisionForm from '../Screens/MasterData/Divisions/DivisionForm'
import Divisions from '../Screens/MasterData/Divisions/Divisions'
import EmployeeForm from '../Screens/MasterData/Employees/EmployeeForm'
import Employees from '../Screens/MasterData/Employees/Employees'
import MasterUpload from '../Screens/MasterData/MasterUploads/MasterUploads'
import NumberingSeries from '../Screens/MasterData/NumberingSeries/NumberingSeries'
import OptionForm from '../Screens/MasterData/Options/OptionForm'
import Options from '../Screens/MasterData/Options/Options'
import ProductForm from '../Screens/MasterData/Products/ProductForm'
import Products from '../Screens/MasterData/Products/Products'
import Roles from '../Screens/MasterData/Roles/Roles'
import Users from '../Screens/MasterData/Users/Users'
import WarehouseProductForm from '../Screens/MasterData/WarehouseProduct/WarehouseProductForm'
import WarehouseProducts from '../Screens/MasterData/WarehouseProduct/WarehouseProducts'
import WarehouseForm from '../Screens/MasterData/Warehouses/WarehouseForm'
import Warehouses from '../Screens/MasterData/Warehouses/Warehouses'
import Notifications from '../Screens/Notifications/Notifications'
import CreateCustomsClearances from '../Screens/OrderManagement/CustomsClearances/CreateCustomsClearances'
import CustomsClearanceForm from '../Screens/OrderManagement/CustomsClearances/CustomsClearanceForm'
import CustomsClearances from '../Screens/OrderManagement/CustomsClearances/CustomsClearances'
import CustomsClearanceView from '../Screens/OrderManagement/CustomsClearances/CustomsClearanceView'
import CreateDelivery from '../Screens/OrderManagement/OrderDeliveries/CreateDelivery'
import DeliveryForm from '../Screens/OrderManagement/OrderDeliveries/DeliveryForm'
import DeliveryList from '../Screens/OrderManagement/OrderDeliveries/DeliveryList'
import CreateInvoice from '../Screens/OrderManagement/OrderInvoices/CreateInvoice'
import InvoiceForm from '../Screens/OrderManagement/OrderInvoices/InvoiceForm'
import InvoiceList from '../Screens/OrderManagement/OrderInvoices/InvoiceList'
import InvoiceView from '../Screens/OrderManagement/OrderInvoices/InvoiceView'
import OrderForm from '../Screens/OrderManagement/Orders/OrderForm'
import Orders from '../Screens/OrderManagement/Orders/Orders'
import OrderView from '../Screens/OrderManagement/Orders/OrderView'
import SalesQuotationContent from '../Screens/OrderManagement/SalesQuotations/SalesQuotationContent'
import SalesQuotationForm from '../Screens/OrderManagement/SalesQuotations/SalesQuotationForm'
import SalesQuotations from '../Screens/OrderManagement/SalesQuotations/SalesQuotations'
import Exchangerate from '../Screens/Payroll/Exchangerate/Exchangerate'
import ExchangerateForm from '../Screens/Payroll/Exchangerate/ExchangerateForm'
import GeneratePayroll from '../Screens/Payroll/GeneratePayroll/GeneratePayroll'
import Holidaycalendar from '../Screens/Payroll/Holidaycalendar/Holidaycalendar'
import HolidaycalendarForm from '../Screens/Payroll/Holidaycalendar/HolidaycalendarForm'
import Paymaster from '../Screens/Payroll/Paymaster/Paymaster'
import PaymasterFields from '../Screens/Payroll/Paymaster/PaymasterFields'
import PaymasterForm from '../Screens/Payroll/Paymaster/PaymasterForm'
import Payrolls from '../Screens/Payroll/Payrolls/Payrolls'
import PayrollView from '../Screens/Payroll/Payrolls/PayrollView'
import Taxdata from '../Screens/Payroll/Taxdata/Taxdata'
import TaxdataForm from '../Screens/Payroll/Taxdata/TaxdataForm'
import Profile from '../Screens/Profile/Profile'
import ProjectAndEmployeeRateForm from '../Screens/ProjectData/ProjectAndEmployeeRates/ProjectAndEmployeeRateForm'
import ProjectAndEmployeeRates from '../Screens/ProjectData/ProjectAndEmployeeRates/ProjectAndEmployeeRates'
import ProjectAndEmployeeForm from '../Screens/ProjectData/ProjectAndEmployees/ProjectAndEmployeeForm'
import ProjectAndEmployees from '../Screens/ProjectData/ProjectAndEmployees/ProjectAndEmployees'
import ProjectForm from '../Screens/ProjectData/Projects/ProjectForm'
import Projects from '../Screens/ProjectData/Projects/Projects'
import QrInfo from '../Screens/QrInfo/QrInfo'
import Schedules from '../Screens/Schedules/Schedules'
import MaterialReport from '../Screens/StandardReports/MaterialReport'
import StockReport from '../Screens/StandardReports/StockReport'
import GoodsIssueForm from '../Screens/StockManagement/GoodsIssues/GoodsIssueForm'
import GoodsIssues from '../Screens/StockManagement/GoodsIssues/GoodsIssues'
import GoodsReceiptForm from '../Screens/StockManagement/GoodsReceipts/GoodsReceiptForm'
import GoodsReceipts from '../Screens/StockManagement/GoodsReceipts/GoodsReceipts'
import StockChecks from '../Screens/StockManagement/StockChecks/StockChecks'
import StockTransferForm from '../Screens/StockManagement/StockTransfers/StockTransferForm'
import StockTransfers from '../Screens/StockManagement/StockTransfers/StockTransfers'
import CustomTemplateEditor from '../Screens/Templates/editor'
import CustomTemplates from '../Screens/Templates/Templates'
import TimeEntries from '../Screens/TimeSheet/TimeEntries/TimeEntries'
import TimeReports from '../Screens/TimeSheet/TimeReports/TimeReports'
import TimeReportView from '../Screens/TimeSheet/TimeReports/TimeReportView'
import Workflows from '../Screens/Workflows/Workflows'
import { getMasterOptions, setMasterOptions } from '../Util/Options'
import AsyncRoute from './AsyncRoute'
import NotFound from './NotFound'

class InnerRoutes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: !props.userInfo
    }
  }

  componentDidMount() {
    if (!this.props.userInfo) {
      this.props.dispatch(getUserByToken(localStorage.getItem('ACCOUNTING_USER'))).then((user) => {
        if (user) {
          if (user && !user.company) {
            this.redirectToCompany()
          }

          if (getMasterOptions().length === 0) {
            getActiveOptions().then((opt) => {
              if (opt) {
                setMasterOptions(opt)
              }
            })
          }

          this.checkTimer()
        }

        this.setState({ loader: false })
      })
    } else {
      if (this.props.userInfo && !this.props.userInfo.company) {
        this.redirectToCompany()
        this.setState({ loader: false })
      }

      this.checkTimer()
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'SET_TIMER_COUNT', payload: false })
  }

  checkTimer = () => {
    const { userInfo } = this.props

    if (userInfo.timeEntryId) {
      getTimeEntryById(userInfo.timeEntryId).then((entry) => {
        if (entry) {
          this.props.dispatch({ type: 'SET_TIMER_COUNT', payload: entry })
        }
      })
    }
  }

  redirectToCompany = () => {
    this.props.history.push('/app/manage-company')
  }

  render() {
    const {
      match: { url }
    } = this.props

    if (this.state.loader) {
      return (
        <div>
          <LoaderBox style={{ height: '100vh' }} loader="Loading.." />
        </div>
      )
    }

    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`${url}/dashboard`} component={NewDashboard} />
          <Route exact path={`${url}/changePassword`} component={ChangePassword} />
          <Route exact path={`${url}/profile`} component={Profile} />
          <Route exact path={`${url}/manage-company`} component={SetCompany} />
          <Route exact path={`${url}/notifications`} component={Notifications} />
          <Route exact path={`${url}/inbox`} component={Workflows} />
          <Route exact path={`${url}/generate-invoice`} component={Schedules} />

          <Route exact path={`${url}/master-upload`} component={MasterUpload} />
          <Route exact path={`${url}/qr-info/:type/:id`} component={QrInfo} />

          <Route
            exact
            path={`${url}/time-entries`}
            render={(props) => <AsyncRoute {...props} screen={TimeEntries} />}
          />
          <Route
            exact
            path={`${url}/time-reports`}
            render={(props) => <AsyncRoute {...props} screen={TimeReports} />}
          />
          <Route
            exact
            path={`${url}/time-reports-view`}
            render={(props) => <AsyncRoute {...props} screen={TimeReportView} />}
          />

          <Route
            exact
            path={`${url}/expense-claims`}
            render={(props) => <AsyncRoute {...props} screen={ExpensesEntries} />}
          />

          <Route
            exact
            path={`${url}/incomes`}
            render={(props) => <AsyncRoute {...props} screen={Incomes} />}
          />
          <Route
            exact
            path={`${url}/add-income/:kind`}
            render={(props) => <AsyncRoute {...props} screen={IncomeForm} />}
          />
          <Route
            exact
            path={`${url}/edit-income/:id`}
            render={(props) => <AsyncRoute {...props} screen={IncomeForm} />}
          />
          <Route
            exact
            path={`${url}/incomes/:id`}
            render={(props) => <AsyncRoute {...props} screen={IncomeView} />}
          />
          <Route
            exact
            path={`${url}/income-overview`}
            render={(props) => <AsyncRoute {...props} screen={IncomeOverview} />}
          />
          <Route
            exact
            path={`${url}/income-detailed`}
            render={(props) => <AsyncRoute {...props} screen={IncomeDetailed} />}
          />
          <Route
            exact
            path={`${url}/account-groups`}
            render={(props) => <AsyncRoute {...props} screen={AccountGroups} />}
          />

          <Route
            exact
            path={`${url}/add-account-group`}
            render={(props) => <AsyncRoute {...props} screen={AccountGroupForm} />}
          />
          <Route
            exact
            path={`${url}/edit-account-group/:id`}
            render={(props) => <AsyncRoute {...props} screen={AccountGroupForm} />}
          />

          <Route
            exact
            path={`${url}/account-charts`}
            render={(props) => <AsyncRoute {...props} screen={AccountsChart} />}
          />
          <Route
            exact
            path={`${url}/add-account-chart`}
            render={(props) => <AsyncRoute {...props} screen={AccountChartForm} />}
          />
          <Route
            exact
            path={`${url}/edit-account-chart/:id`}
            render={(props) => <AsyncRoute {...props} screen={AccountChartForm} />}
          />

          <Route
            exact
            path={`${url}/account-setups`}
            render={(props) => <AsyncRoute {...props} screen={AccountSetups} />}
          />
          <Route
            exact
            path={`${url}/add-account-setup`}
            render={(props) => <AsyncRoute {...props} screen={AccountSetupForm} />}
          />
          <Route
            exact
            path={`${url}/edit-account-setup/:id`}
            render={(props) => <AsyncRoute {...props} screen={AccountSetupForm} />}
          />

          <Route
            exact
            path={`${url}/expenses`}
            render={(props) => <AsyncRoute {...props} screen={Expenses} />}
          />
          <Route
            exact
            path={`${url}/add-expense/:kind`}
            render={(props) => <AsyncRoute {...props} screen={ExpenseForm} />}
          />
          <Route
            exact
            path={`${url}/edit-expense/:id`}
            render={(props) => <AsyncRoute {...props} screen={ExpenseForm} />}
          />
          <Route
            exact
            path={`${url}/expenses/:id`}
            render={(props) => <AsyncRoute {...props} screen={ExpenseView} />}
          />
          <Route
            exact
            path={`${url}/expense-overview`}
            render={(props) => <AsyncRoute {...props} screen={ExpenseOverview} />}
          />
          <Route
            exact
            path={`${url}/expense-detailed`}
            render={(props) => <AsyncRoute {...props} screen={ExpenseDetailed} />}
          />

          <Route
            exact
            path={`${url}/upload-income-invoices`}
            render={(props) => <AsyncRoute {...props} screen={UploadIncomeInvoices} />}
          />
          <Route
            exact
            path={`${url}/upload-expense-invoices`}
            render={(props) => <AsyncRoute {...props} screen={UploadExpenseInvoices} />}
          />
          <Route
            exact
            path={`${url}/from-timesheet`}
            render={(props) => <AsyncRoute {...props} screen={GenerateIncomeInvoice} />}
          />

          <Route
            exact
            path={`${url}/outbound-transmissions`}
            render={(props) => <AsyncRoute {...props} screen={OutboundTransminssions} />}
          />
          <Route
            exact
            path={`${url}/outbound-transmissions/:id`}
            render={(props) => <AsyncRoute {...props} screen={OutboundTransmissionView} />}
          />
          <Route
            exact
            path={`${url}/inbound-transmissions`}
            render={(props) => <AsyncRoute {...props} screen={InboundTransmissions} />}
          />
          <Route
            exact
            path={`${url}/inbound-transmissions/:id`}
            render={(props) => <AsyncRoute {...props} screen={InboundTransmissionView} />}
          />

          <Route
            exact
            path={`${url}/companies`}
            render={(props) => <AsyncRoute {...props} screen={Companies} />}
          />
          <Route
            exact
            path={`${url}/add-company`}
            render={(props) => <AsyncRoute {...props} screen={CompanyForm} />}
          />
          <Route
            exact
            path={`${url}/edit-company/:id`}
            render={(props) => <AsyncRoute {...props} screen={CompanyForm} />}
          />

          <Route
            exact
            path={`${url}/divisions`}
            render={(props) => <AsyncRoute {...props} screen={Divisions} />}
          />
          <Route
            exact
            path={`${url}/add-division`}
            render={(props) => <AsyncRoute {...props} screen={DivisionForm} />}
          />
          <Route
            exact
            path={`${url}/edit-division/:id`}
            render={(props) => <AsyncRoute {...props} screen={DivisionForm} />}
          />

          <Route exact path={`${url}/users`} render={(props) => <AsyncRoute {...props} screen={Users} />} />

          <Route
            exact
            path={`${url}/currencies`}
            render={(props) => <AsyncRoute {...props} screen={Currencies} />}
          />
          <Route
            exact
            path={`${url}/add-currency`}
            render={(props) => <AsyncRoute {...props} screen={CurrencyForm} />}
          />
          <Route
            exact
            path={`${url}/edit-currency/:id`}
            render={(props) => <AsyncRoute {...props} screen={CurrencyForm} />}
          />

          <Route
            exact
            path={`${url}/countries`}
            render={(props) => <AsyncRoute {...props} screen={Countries} />}
          />
          <Route
            exact
            path={`${url}/add-country`}
            render={(props) => <AsyncRoute {...props} screen={CountryForm} />}
          />
          <Route
            exact
            path={`${url}/edit-country/:id`}
            render={(props) => <AsyncRoute {...props} screen={CountryForm} />}
          />

          <Route
            exact
            path={`${url}/categories`}
            render={(props) => <AsyncRoute {...props} screen={Categories} />}
          />
          <Route
            exact
            path={`${url}/add-category`}
            render={(props) => <AsyncRoute {...props} screen={CategoryForm} />}
          />
          <Route
            exact
            path={`${url}/edit-category/:id`}
            render={(props) => <AsyncRoute {...props} screen={CategoryForm} />}
          />

          <Route
            exact
            path={`${url}/products`}
            render={(props) => <AsyncRoute {...props} screen={Products} />}
          />
          <Route
            exact
            path={`${url}/add-product`}
            render={(props) => <AsyncRoute {...props} screen={ProductForm} />}
          />
          <Route
            exact
            path={`${url}/edit-product/:id`}
            render={(props) => <AsyncRoute {...props} screen={ProductForm} />}
          />

          <Route
            exact
            path={`${url}/clients`}
            render={(props) => <AsyncRoute {...props} screen={Clients} />}
          />
          <Route
            exact
            path={`${url}/add-client`}
            render={(props) => <AsyncRoute {...props} screen={ClientForm} />}
          />
          <Route
            exact
            path={`${url}/edit-client/:id`}
            render={(props) => <AsyncRoute {...props} screen={ClientForm} />}
          />

          <Route
            exact
            path={`${url}/options`}
            render={(props) => <AsyncRoute {...props} screen={Options} />}
          />
          <Route
            exact
            path={`${url}/add-option`}
            render={(props) => <AsyncRoute {...props} screen={OptionForm} />}
          />
          <Route
            exact
            path={`${url}/edit-option/:id`}
            render={(props) => <AsyncRoute {...props} screen={OptionForm} />}
          />

          <Route
            exact
            path={`${url}/employees`}
            render={(props) => <AsyncRoute {...props} screen={Employees} />}
          />
          <Route
            exact
            path={`${url}/add-employee`}
            render={(props) => <AsyncRoute {...props} screen={EmployeeForm} />}
          />
          <Route
            exact
            path={`${url}/edit-employee/:id`}
            render={(props) => <AsyncRoute {...props} screen={EmployeeForm} />}
          />

          <Route exact path={`${url}/roles`} render={(props) => <AsyncRoute {...props} screen={Roles} />} />

          <Route
            exact
            path={`${url}/paymaster`}
            render={(props) => <AsyncRoute {...props} screen={Paymaster} />}
          />
          <Route
            exact
            path={`${url}/paymaster-fields`}
            render={(props) => <AsyncRoute {...props} screen={PaymasterFields} />}
          />
          <Route
            exact
            path={`${url}/add-paymaster`}
            render={(props) => <AsyncRoute {...props} screen={PaymasterForm} />}
          />
          <Route
            exact
            path={`${url}/edit-paymaster/:id`}
            render={(props) => <AsyncRoute {...props} screen={PaymasterForm} />}
          />

          <Route
            exact
            path={`${url}/taxdata`}
            render={(props) => <AsyncRoute {...props} screen={Taxdata} />}
          />
          <Route
            exact
            path={`${url}/add-taxdata`}
            render={(props) => <AsyncRoute {...props} screen={TaxdataForm} />}
          />

          <Route
            exact
            path={`${url}/exchange-rates`}
            render={(props) => <AsyncRoute {...props} screen={Exchangerate} />}
          />
          <Route
            exact
            path={`${url}/add-exchange-rate`}
            render={(props) => <AsyncRoute {...props} screen={ExchangerateForm} />}
          />

          <Route
            exact
            path={`${url}/edit-exchange-rate/:id`}
            render={(props) => <AsyncRoute {...props} screen={ExchangerateForm} />}
          />

          <Route
            exact
            path={`${url}/holiday-calendar`}
            render={(props) => <AsyncRoute {...props} screen={Holidaycalendar} />}
          />
          <Route
            exact
            path={`${url}/add-holiday-calendar`}
            render={(props) => <AsyncRoute {...props} screen={HolidaycalendarForm} />}
          />
          <Route
            exact
            path={`${url}/edit-holiday-calendar/:id`}
            render={(props) => <AsyncRoute {...props} screen={HolidaycalendarForm} />}
          />
          <Route
            exact
            path={`${url}/warehouses`}
            render={(props) => <AsyncRoute {...props} screen={Warehouses} />}
          />
          <Route
            exact
            path={`${url}/add-warehouse`}
            render={(props) => <AsyncRoute {...props} screen={WarehouseForm} />}
          />
          <Route
            exact
            path={`${url}/edit-warehouse/:id`}
            render={(props) => <AsyncRoute {...props} screen={WarehouseForm} />}
          />

          <Route
            exact
            path={`${url}/warehouse-products`}
            render={(props) => <AsyncRoute {...props} screen={WarehouseProducts} />}
          />
          <Route
            exact
            path={`${url}/add-warehouse-product`}
            render={(props) => <AsyncRoute {...props} screen={WarehouseProductForm} />}
          />
          <Route
            exact
            path={`${url}/edit-warehouse-product/:id`}
            render={(props) => <AsyncRoute {...props} screen={WarehouseProductForm} />}
          />
          <Route
            exact
            path={`${url}/goods-receipts`}
            render={(props) => <AsyncRoute {...props} screen={GoodsReceipts} />}
          />
          <Route
            exact
            path={`${url}/add-goods-receipt`}
            render={(props) => <AsyncRoute {...props} screen={GoodsReceiptForm} />}
          />
          <Route
            exact
            path={`${url}/view-goods-receipt/:id`}
            render={(props) => <AsyncRoute {...props} screen={GoodsReceiptForm} />}
          />
          <Route
            exact
            path={`${url}/goods-issues`}
            render={(props) => <AsyncRoute {...props} screen={GoodsIssues} />}
          />
          <Route
            exact
            path={`${url}/add-goods-issue`}
            render={(props) => <AsyncRoute {...props} screen={GoodsIssueForm} />}
          />
          <Route
            exact
            path={`${url}/view-goods-issue/:id`}
            render={(props) => <AsyncRoute {...props} screen={GoodsIssueForm} />}
          />
          <Route
            exact
            path={`${url}/stock-checks`}
            render={(props) => <AsyncRoute {...props} screen={StockChecks} />}
          />
          <Route
            exact
            path={`${url}/stock-transfers`}
            render={(props) => <AsyncRoute {...props} screen={StockTransfers} />}
          />
          <Route
            exact
            path={`${url}/add-stock-transfer`}
            render={(props) => <AsyncRoute {...props} screen={StockTransferForm} />}
          />
          <Route
            exact
            path={`${url}/view-stock-transfer/:id`}
            render={(props) => <AsyncRoute {...props} screen={StockTransferForm} />}
          />
          <Route
            exact
            path={`${url}/material-reports`}
            render={(props) => <AsyncRoute {...props} screen={MaterialReport} />}
          />
          <Route
            exact
            path={`${url}/stock-reports`}
            render={(props) => <AsyncRoute {...props} screen={StockReport} />}
          />

          <Route
            exact
            path={`${url}/projects`}
            render={(props) => <AsyncRoute {...props} screen={Projects} />}
          />
          <Route
            exact
            path={`${url}/add-project`}
            render={(props) => <AsyncRoute {...props} screen={ProjectForm} />}
          />
          <Route
            exact
            path={`${url}/edit-project/:id`}
            render={(props) => <AsyncRoute {...props} screen={ProjectForm} />}
          />
          <Route
            exact
            path={`${url}/project-employees`}
            render={(props) => <AsyncRoute {...props} screen={ProjectAndEmployees} />}
          />
          <Route
            exact
            path={`${url}/add-project-employee`}
            render={(props) => <AsyncRoute {...props} screen={ProjectAndEmployeeForm} />}
          />
          <Route
            exact
            path={`${url}/edit-project-employee/:id`}
            render={(props) => <AsyncRoute {...props} screen={ProjectAndEmployeeForm} />}
          />
          <Route
            exact
            path={`${url}/project-employee-rates`}
            render={(props) => <AsyncRoute {...props} screen={ProjectAndEmployeeRates} />}
          />
          <Route
            exact
            path={`${url}/add-project-employee-rate`}
            render={(props) => <AsyncRoute {...props} screen={ProjectAndEmployeeRateForm} />}
          />
          <Route
            exact
            path={`${url}/edit-project-employee-rate/:id`}
            render={(props) => <AsyncRoute {...props} screen={ProjectAndEmployeeRateForm} />}
          />

          <Route
            exact
            path={`${url}/generate-payroll`}
            render={(props) => <AsyncRoute {...props} screen={GeneratePayroll} />}
          />
          <Route
            exact
            path={`${url}/orders/:type`}
            render={(props) => <AsyncRoute {...props} screen={Orders} />}
          />
          <Route
            exact
            path={`${url}/orders/view/:id`}
            render={(props) => <AsyncRoute {...props} screen={OrderView} />}
          />
          <Route
            exact
            path={`${url}/add-order/:type`}
            render={(props) => <AsyncRoute {...props} screen={OrderForm} />}
          />
          <Route
            exact
            path={`${url}/edit-order/:id`}
            render={(props) => <AsyncRoute {...props} screen={OrderForm} />}
          />
          <Route
            exact
            path={`${url}/order-deliveries/:type`}
            render={(props) => <AsyncRoute {...props} screen={DeliveryList} />}
          />
          <Route
            exact
            path={`${url}/search-deliveries/:type`}
            render={(props) => <AsyncRoute {...props} screen={CreateDelivery} />}
          />
          <Route
            exact
            path={`${url}/add-delivery`}
            render={(props) => <AsyncRoute {...props} screen={DeliveryForm} />}
          />
          <Route
            exact
            path={`${url}/edit-delivery/:id`}
            render={(props) => <AsyncRoute {...props} screen={DeliveryForm} />}
          />
          <Route
            exact
            path={`${url}/payrolls`}
            render={(props) => <AsyncRoute {...props} screen={Payrolls} />}
          />
          <Route
            exact
            path={`${url}/payrolls/:ids`}
            render={(props) => <AsyncRoute {...props} screen={PayrollView} />}
          />
          <Route
            exact
            path={`${url}/custom-templates`}
            render={(props) => <AsyncRoute {...props} screen={CustomTemplates} />}
          />
          <Route
            exact
            path={`${url}/custom-templates/:id`}
            render={(props) => <AsyncRoute {...props} screen={CustomTemplateEditor} />}
          />
          <Route
            exact
            path={`${url}/order-invoices/:type`}
            render={(props) => <AsyncRoute {...props} screen={InvoiceList} />}
          />
          <Route
            exact
            path={`${url}/search-invoices/:type`}
            render={(props) => <AsyncRoute {...props} screen={CreateInvoice} />}
          />
          <Route
            exact
            path={`${url}/add-invoice`}
            render={(props) => <AsyncRoute {...props} screen={InvoiceForm} />}
          />
          <Route
            exact
            path={`${url}/edit-invoice/:id`}
            render={(props) => <AsyncRoute {...props} screen={InvoiceForm} />}
          />
          <Route
            exact
            path={`${url}/order-invoices/view/:id`}
            render={(props) => <AsyncRoute {...props} screen={InvoiceView} />}
          />

          {/* Accounting module Routes */}
          <Route
            exact
            path={`${url}/financial-years`}
            render={(props) => <AsyncRoute {...props} screen={FinancialYears} />}
          />
          <Route
            exact
            path={`${url}/finances`}
            render={(props) => <AsyncRoute {...props} screen={Finances} />}
          />
          <Route
            exact
            path={`${url}/finance-postings`}
            render={(props) => <AsyncRoute {...props} screen={FinancePostings} />}
          />
          <Route
            exact
            path={`${url}/numbering-series`}
            render={(props) => <AsyncRoute {...props} screen={NumberingSeries} />}
          />
          <Route
            exact
            path={`${url}/company-configurations`}
            render={(props) => <AsyncRoute {...props} screen={CompanyConfigurations} />}
          />
          <Route
            exact
            path={`${url}/journal-vouchers`}
            render={(props) => <AsyncRoute {...props} screen={JournalVouchers} />}
          />
          <Route
            exact
            path={`${url}/add-journal-voucher`}
            render={(props) => <AsyncRoute {...props} screen={JournalVoucherForm} />}
          />
          <Route
            exact
            path={`${url}/edit-journal-voucher/:id`}
            render={(props) => <AsyncRoute {...props} screen={JournalVoucherForm} />}
          />
          <Route
            exact
            path={`${url}/employee-details`}
            render={(props) => <AsyncRoute {...props} screen={employeeDetails} />}
          />
          <Route
            exact
            path={`${url}/sales-persons`}
            render={(props) => <AsyncRoute {...props} screen={SalesPersons} />}
          />
          <Route
            exact
            path={`${url}/add-sales-person`}
            render={(props) => <AsyncRoute {...props} screen={SalesPersonForm} />}
          />
          <Route
            exact
            path={`${url}/edit-sales-person/:id`}
            render={(props) => <AsyncRoute {...props} screen={SalesPersonForm} />}
          />
          <Route
            exact
            path={`${url}/pol-pod`}
            render={(props) => <AsyncRoute {...props} screen={POLPOD} />}
          />
          <Route
            exact
            path={`${url}/add-pol-pod`}
            render={(props) => <AsyncRoute {...props} screen={POLPODForm} />}
          />
          <Route
            exact
            path={`${url}/edit-pol-pod/:id`}
            render={(props) => <AsyncRoute {...props} screen={POLPODForm} />}
          />
          <Route
            exact
            path={`${url}/packages-types`}
            render={(props) => <AsyncRoute {...props} screen={PackagesTypes} />}
          />
          <Route
            exact
            path={`${url}/add-packages-type`}
            render={(props) => <AsyncRoute {...props} screen={PackagesTypeForm} />}
          />
          <Route
            exact
            path={`${url}/edit-packages-type/:id`}
            render={(props) => <AsyncRoute {...props} screen={PackagesTypeForm} />}
          />
          <Route
            exact
            path={`${url}/price-charges`}
            render={(props) => <AsyncRoute {...props} screen={PriceCharges} />}
          />
          <Route
            exact
            path={`${url}/add-price-charge`}
            render={(props) => <AsyncRoute {...props} screen={PriceChargeForm} />}
          />
          <Route
            exact
            path={`${url}/edit-price-charge/:id`}
            render={(props) => <AsyncRoute {...props} screen={PriceChargeForm} />}
          />
          <Route
            exact
            path={`${url}/operations`}
            render={(props) => <AsyncRoute {...props} screen={Operations} />}
          />
          <Route
            exact
            path={`${url}/add-operation`}
            render={(props) => <AsyncRoute {...props} screen={OperationForm} />}
          />
          <Route
            exact
            path={`${url}/edit-operation/:id`}
            render={(props) => <AsyncRoute {...props} screen={OperationForm} />}
          />
          <Route
            exact
            path={`${url}/terms-conditions`}
            render={(props) => <AsyncRoute {...props} screen={TermsConditions} />}
          />
          <Route
            exact
            path={`${url}/add-terms-condition`}
            render={(props) => <AsyncRoute {...props} screen={TermsConditionForm} />}
          />
          <Route
            exact
            path={`${url}/edit-terms-condition/:id`}
            render={(props) => <AsyncRoute {...props} screen={TermsConditionForm} />}
          />
          <Route
            exact
            path={`${url}/sales-call-entries`}
            render={(props) => <AsyncRoute {...props} screen={SalesCallEntries} />}
          />
          <Route
            exact
            path={`${url}/add-sales-call-entry`}
            render={(props) => <AsyncRoute {...props} screen={SalesCallEntryForm} />}
          />
          <Route
            exact
            path={`${url}/edit-sales-call-entry/:id`}
            render={(props) => <AsyncRoute {...props} screen={SalesCallEntryForm} />}
          />
          <Route exact path={`${url}/offers`} render={(props) => <AsyncRoute {...props} screen={Offers} />} />
          <Route
            exact
            path={`${url}/offers/:id`}
            render={(props) => <AsyncRoute {...props} screen={OfferViewContent} />}
          />
          <Route
            exact
            path={`${url}/add-offer`}
            render={(props) => <AsyncRoute {...props} screen={OfferForm} />}
          />
          <Route
            exact
            path={`${url}/edit-offer/:id`}
            render={(props) => <AsyncRoute {...props} screen={OfferForm} />}
          />

          <Route
            exact
            path={`${url}/bookings`}
            render={(props) => <AsyncRoute {...props} screen={Bookings} />}
          />
          <Route
            exact
            path={`${url}/bookings/:id`}
            render={(props) => <AsyncRoute {...props} screen={BookingViewContent} />}
          />
          <Route
            exact
            path={`${url}/add-booking/:offer`}
            render={(props) => <AsyncRoute {...props} screen={BookingForm} />}
          />
          <Route
            exact
            path={`${url}/edit-booking/:id`}
            render={(props) => <AsyncRoute {...props} screen={BookingForm} />}
          />

          <Route
            exact
            path={`${url}/absence-management`}
            render={(props) => <AsyncRoute {...props} screen={AbsenceManagement} />}
          />
          <Route
            exact
            path={`${url}/team-absence-application-list`}
            render={(props) => <AsyncRoute {...props} screen={TeamAbsenceApplicationList} />}
          />
          <Route
            exact
            path={`${url}/apply-leave`}
            render={(props) => <AsyncRoute {...props} screen={ApplyLeave} />}
          />
          <Route exact path={`${url}/asset`} render={(props) => <AsyncRoute {...props} screen={Asset} />} />
          <Route
            exact
            path={`${url}/add-asset`}
            render={(props) => <AsyncRoute {...props} screen={AssetForm} />}
          />
          <Route
            exact
            path={`${url}/termination`}
            render={(props) => <AsyncRoute {...props} screen={Termination} />}
          />
          <Route
            exact
            path={`${url}/transfer`}
            render={(props) => <AsyncRoute {...props} screen={Transfer} />}
          />
          <Route
            exact
            path={`${url}/change-job`}
            render={(props) => <AsyncRoute {...props} screen={ChangeJob} />}
          />
          <Route
            exact
            path={`${url}/change-compensation`}
            render={(props) => <AsyncRoute {...props} screen={ChangeCompensation} />}
          />
          <Route exact path={`${url}/goals`} render={(props) => <AsyncRoute {...props} screen={Goals} />} />
          <Route
            exact
            path={`${url}/goal-assignment`}
            render={(props) => <AsyncRoute {...props} screen={GoalsAssignment} />}
          />
          <Route
            exact
            path={`${url}/appraisal-review`}
            render={(props) => <AsyncRoute {...props} screen={AppraisalReview} />}
          />
          <Route
            exact
            path={`${url}/add-appraisal-review`}
            render={(props) => <AsyncRoute {...props} screen={AppraisalReviewForm} />}
          />
          <Route
            exact
            path={`${url}/add-goal`}
            render={(props) => <AsyncRoute {...props} screen={GoalsForm} />}
          />
          <Route
            exact
            path={`${url}/assign-goal`}
            render={(props) => <AsyncRoute {...props} screen={GoalsAssignmentForm} />}
          />
          <Route
            exact
            path={`${url}/customs-clearances`}
            render={(props) => <AsyncRoute {...props} screen={CustomsClearances} />}
          />
          <Route
            exact
            path={`${url}/customs-clearances/:id`}
            render={(props) => <AsyncRoute {...props} screen={CustomsClearanceView} />}
          />
          <Route
            exact
            path={`${url}/search-customs-clearance`}
            render={(props) => <AsyncRoute {...props} screen={CreateCustomsClearances} />}
          />
          <Route
            exact
            path={`${url}/add-customs-clearance`}
            render={(props) => <AsyncRoute {...props} screen={CustomsClearanceForm} />}
          />
          <Route
            exact
            path={`${url}/edit-customs-clearance/:id`}
            render={(props) => <AsyncRoute {...props} screen={CustomsClearanceForm} />}
          />
          <Route
            exact
            path={`${url}/job-creations`}
            render={(props) => <AsyncRoute {...props} screen={JobCreations} />}
          />
          <Route
            exact
            path={`${url}/job-creations/:id`}
            render={(props) => <AsyncRoute {...props} screen={JobCreationView} />}
          />
          <Route
            exact
            path={`${url}/search-job-creation`}
            render={(props) => <AsyncRoute {...props} screen={CreateJobCreations} />}
          />
          <Route
            exact
            path={`${url}/add-job-creation`}
            render={(props) => <AsyncRoute {...props} screen={JobCreationForm} />}
          />
          <Route
            exact
            path={`${url}/edit-job-creation/:id`}
            render={(props) => <AsyncRoute {...props} screen={JobCreationForm} />}
          />
          <Route
            exact
            path={`${url}/sales-quotations`}
            render={(props) => <AsyncRoute {...props} screen={SalesQuotations} />}
          />
          <Route
            exact
            path={`${url}/sales-quotations/:id`}
            render={(props) => <AsyncRoute {...props} screen={SalesQuotationContent} />}
          />
          <Route
            exact
            path={`${url}/add-sales-quotation`}
            render={(props) => <AsyncRoute {...props} screen={SalesQuotationForm} />}
          />
          <Route
            exact
            path={`${url}/edit-sales-quotation/:id`}
            render={(props) => <AsyncRoute {...props} screen={SalesQuotationForm} />}
          />
          <Route
            exact
            path={`${url}/invoice-receipts`}
            render={(props) => <AsyncRoute {...props} screen={IncomePayments} />}
          />
          <Route
            exact
            path={`${url}/invoice-payments`}
            render={(props) => <AsyncRoute {...props} screen={ExpensePayments} />}
          />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.users.userInfo,
    masterOptions: state.users.masterOptions
  }
}

export default connect(mapStateToProps)(InnerRoutes)
