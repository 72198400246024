import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'

// const Schema = Yup.object().shape({
//   payGrade: Yup.string().required(),
//   annualBasicPay: Yup.number().required(),
//   currency: Yup.string().required(),
//   earnings: Yup.string().required(),
//   comment: Yup.string().required(),
//   deductions: Yup.string().required()
// })

const SalaryHistoryForm = () => {
  // const { currentDetails, values, setValues, handleValueChange } = props
  const [options, setOptions] = useState({})

  const fetchDropdownValues = () => {
    apiClient
      .get('options/get-by-types', {
        params: { type: ['PayGrade'] }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })
  }

  useEffect(() => {
    fetchDropdownValues()
    // setValues({
    //   ...values,
    //   payGrade: !isEmpty(currentDetails) ? currentDetails.payGrade : '',
    //   annualBasicPay: !isEmpty(currentDetails) ? currentDetails.annualBasicPay : '',
    //   currency: !isEmpty(currentDetails) ? currentDetails.currency : '',
    //   earnings: !isEmpty(currentDetails) ? currentDetails.earnings : '',
    //   comment: !isEmpty(currentDetails) ? currentDetails.comment : '',
    //   attachments: !isEmpty(currentDetails) ? currentDetails.attachments : '',
    //   deductions: !isEmpty(currentDetails) ? currentDetails.deductions : ''
    // })
  }, [])

  // useEffect(() => {
  //   handleValueChange(values)
  // }, [values?.companyName, values?.jobTitle, values?.from, values?.to, values?.comment, values?.attachments])

  // const onUploadAttachement = (key, attachments) => {
  //   setValues({ ...values, attachments: attachments.filter((attachment) => attachment !== '') })
  // }

  return (
    <Form>
      <Row justify="left" gutter={(12, 10)}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="from" label="From Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="to" label="To Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="payGrade" label="Pay Grade" as="select" options={options.PayGrade || []} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="currency" label="Currency" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="annualBasicPay" label="Annual Basic Payment" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="earnings" label="Earnings" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="deductions" label="Deductions" type="number" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="comment" label="Comment" as="textarea" />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default SalaryHistoryForm
// withFormik({
//   mapPropsToValues: () => ({
//     payGrade: '',
//     annualBasicPay: '',
//     currency: '',
//     earnings: '',
//     comment: '',
//     deductions: ''
//   }),
//   handleSubmit: () => null,
//   validationSchema: Schema
// })(SalaryHistoryForm)
