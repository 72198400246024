import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { ORDER_TYPES, TRANSACTION_STATUS } from '../../../Util/Options'

function GoodsIssueFilter({ values, setValues, filterData }) {
  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field name="transactionNo" label="Transaction no" />
      </div>
      <div className="form-fields">
        <Field as="select" name="orderType" label="Order type" options={ORDER_TYPES} />
      </div>
      {!values.orderType ||
        (values.orderType !== 'adhoc' && (
          <div className="form-fields">
            <Field name="orderNo" label="Order no" />
          </div>
        ))}
      <div className="form-fields">
        <Field name="requester" label="Requester" />
      </div>
      <div className="form-fields">
        <Field as="select" name="status" label="Status" options={TRANSACTION_STATUS} />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" />
          Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    transactionNo: '',
    orderType: '',
    orderNo: '',
    requester: '',
    status: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(GoodsIssueFilter)
