import moment from 'moment'
import React, { useEffect, useState } from 'react'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA } from '../../../Util/Util'
import ExpensePaymentFilter from './ExpensePaymentFilter'

export default function ExpensePayments() {
  const [expensePayments, setExpensePayments] = useState([])

  const onFilter = (params = {}) => {
    apiClient.get('expenses/get-payments', { params }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('expensePayments.filterData', { ...params, ...data.pageData })
        setExpensePayments(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('expensePayments.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('expensePayments.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (v) => (v ? moment(v).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Amount',
      dataIndex: 'amount'
    },
    {
      title: 'Type Of Payment',
      dataIndex: 'typeOfPayment'
    },
    {
      title: 'Posting',
      dataIndex: 'posting'
    }
  ]

  return (
    <FilterLayout filter={<ExpensePaymentFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Invoice Payments</h2>
      </div>
      <TableBox
        dataSource={expensePayments}
        columns={columns}
        pageData={GET_DATA('expensePayments.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
