import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  getLocationsByWarehouse,
  getMaterial,
  getRacksByLocation,
  getWarehouseCodes
} from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { TRANSACTION_STATUS } from '../../../Util/Options'

function StockCheckFilter({ values, setFieldValue, setValues, filterData }) {
  const [materials, setMaterials] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [locations, setLocations] = useState([])
  const [racks, setRacks] = useState([])

  const getMaterials = (material) => {
    getMaterial({ material }).then((data) => {
      setMaterials(data)
    })
  }

  const getWarehouses = (warehouse) => {
    getWarehouseCodes({ warehouse }).then((data) => {
      setWarehouses(data)
    })
  }

  const getLocations = (params) => {
    getLocationsByWarehouse(params).then((data) => {
      setLocations(data)
    })
  }

  const getRacks = (params) => {
    getRacksByLocation(params).then((data) => {
      setRacks(data)
    })
  }

  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field
            as="auto-complete"
            name="materialCode"
            label="Material Code"
            onSearch={getMaterials}
            options={materials}
          />
        </div>
        <div className="form-fields">
          <Field
            as="auto-complete"
            label="Warehouse"
            name="warehouse"
            options={warehouses}
            onSearch={getWarehouses}
            onChange={(n, v) => {
              setFieldValue('location', '')
              setFieldValue('rack', '')

              return setFieldValue(n, v)
            }}
          />
        </div>
        {values.warehouse && (
          <div className="form-fields">
            <Field
              as="auto-complete"
              key={values.warehouse}
              label="Location"
              name="location"
              onSearch={(location) =>
                getLocations({
                  warehouse: values.warehouse,
                  location
                })
              }
              options={locations}
              onChange={(n, v) => {
                setFieldValue('rack', '')

                return setFieldValue(n, v)
              }}
            />
          </div>
        )}
        {values.warehouse && values.location && (
          <div className="form-fields">
            <Field
              as="auto-complete"
              key={values.warehouse + values.location}
              label="Rack"
              name="rack"
              onSearch={(rack) =>
                getRacks({
                  rack,
                  warehouse: values.warehouse,
                  location: values.location
                })
              }
              options={racks}
            />
          </div>
        )}
        <div className="form-fields">
          <Field as="select" name="stockStatus" label="Stock Status" options={TRANSACTION_STATUS} />
        </div>
        <div className="form-fields">
          <Button type="submit" variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" /> Search
          </Button>
          <Button type="reset" className="btn-block btn-glow search">
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    materialCode: '',
    warehouse: '',
    location: '',
    rack: '',
    stockStatus: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(StockCheckFilter)
