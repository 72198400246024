import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import logowhite from '../../../assets/images/logo-white.png'
import Button from '../../../Components/Button'
import InputBox from '../../../Components/InputBox/InputBox'
import '../CustomerOnboarding.scss'

export default class VerifyCode extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // type: '',
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: ''
    }
  }

  onChangeText = (value, type) => {
    this.setState({ [type]: value })
  }

  render() {
    return (
      <section className="customer-onboarding">
        <Row justify="center">
          <Col span={24}>
            <Row justify="left">
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }}>
                <div className="left-content-details">
                  <div className="logo-placeholder">
                    <img src={logowhite} alt="DLT Docs" />
                  </div>
                  <div className="quotes">
                    <div className="content">
                      <p>
                        We provide a seamless platform for managing customers, invoices, billing, timesheet
                        management, ecommerce ERP Integration, and more. create account to get more benefits
                        from DLT Docs
                      </p>
                    </div>
                  </div>
                  <div className="reach-us">
                    <h5>Reach us for More information</h5>
                    <div className="email-phone">
                      <span>
                        <a href="tel:+41-12345 67890">+41-12345 67890</a>
                      </span>
                      <span>
                        <a href="mailto:info@dltdocs.com">info@dltdocs.com</a>
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }}>
                <div className="right-field-details center-view">
                  <div className="heading">
                    <h3>
                      <Link to="./registration">
                        <i className="flaticon-arrow-pointing-to-right" /> Go Back
                      </Link>
                    </h3>
                    <p>
                      Already have a DLT Docs account? <Link>Sign In</Link>
                    </p>
                  </div>
                  <div className="registration-section ">
                    <div className="verify-code">
                      <h1>Verify the Authorisation Code</h1>
                      <p>sent to info@company.com</p>
                      <div className="fields">
                        <div className="form-fields">
                          <InputBox
                            id="code1"
                            value={this.state.code1}
                            onChangeText={this.onChangeText}
                            isSubmit={this.state.isSubmit}
                            type="number"
                          />
                          <InputBox
                            id="code2"
                            value={this.state.code2}
                            onChangeText={this.onChangeText}
                            isSubmit={this.state.isSubmit}
                            type="number"
                          />
                          <InputBox
                            id="code3"
                            value={this.state.code3}
                            onChangeText={this.onChangeText}
                            isSubmit={this.state.isSubmit}
                            type="number"
                          />
                          <InputBox
                            id="code4"
                            value={this.state.code4}
                            onChangeText={this.onChangeText}
                            isSubmit={this.state.isSubmit}
                            type="number"
                          />
                          <InputBox
                            id="code5"
                            value={this.state.code5}
                            onChangeText={this.onChangeText}
                            isSubmit={this.state.isSubmit}
                            type="number"
                          />
                          <InputBox
                            id="code6"
                            value={this.state.code6}
                            onChangeText={this.onChangeText}
                            isSubmit={this.state.isSubmit}
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="resend-code">
                        <Link>Resend Authorisation Code</Link> in 0 sec
                      </div>
                      <div className="enroll-buttons">
                        <Button className="ant-btn-primary" type="primary">
                          VERIFY NOW
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    )
  }
}
