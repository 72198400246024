import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { validateAccess } from '../Util/Util'
import NotFound from './NotFound'

class AsyncRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { match, screen: Comp } = this.props
    const defaultMetaobject = {
      title:
        'Online e-invoicing (fatoorah qr code) solution provider in fastest way with Zatca Guidelines in Saudi Arabia (KSA)',
      description:
        'Online e-invoicing (Fatoorah qr code) solution provider in Saudi Arabia (KSA) with step-by-step procedure using Zatca guidelines',
      keyword:
        'e-invoicing, Online invoice, zatca e invoicing,  einvoice solutions, e-invoice in Saudi, einvoice solutions, e-invoice in Saudi, step-by-step guideline for zatca solution provider, e-invoice guidelines for Zatca, Fastest electronic invoice solution, fatoorah ksa'
    }

    if (validateAccess(match.path, true)) {
      return (
        <div>
          <Helmet>
            <title>{defaultMetaobject.title}</title>
            <meta name="description" content={defaultMetaobject.description} />
            <meta name="keyword" content={defaultMetaobject.keyword} />
          </Helmet>
          <Comp {...this.props} />
        </div>
      )
    }

    return <NotFound />
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.users.userInfo,
    companyInfo: state.users.companyInfo,
    access: state.users.access
  }
}

export default connect(mapStateToProps)(AsyncRoute)
