import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getActiveClients } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { INCOME_KIND_OPTIONS, INVOICE_STATUS_OPTIONS } from '../../../Util/Options'
import { GET_DATA, SET_DATA } from '../../../Util/Util'

function Filter(props) {
  const [clients, setClients] = useState([])

  const getData = () => {
    getActiveClients().then((clients) => {
      if (clients) {
        const clientOptions = []
        clients.forEach((val) => {
          if (val.type === 'Customer' || val.type === 'Both') {
            clientOptions.push({ label: val.name, value: val.id })
          }
        })
        setClients(clientOptions)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onSubmitForm = async () => {
    props.validateForm().then((err) => {
      if (_.isEmpty(err)) {
        const params = { ..._(props.values).omitBy(_.isEmpty).value() }
        ;['issueDate', 'taxDate', 'paymentDate'].forEach((item) => {
          if (params[item]) {
            params[item][0] = moment(params[item][0]).utc().startOf('day').toISOString()
            params[item][1] = moment(params[item][1]).utc().endOf('day').toISOString()
          }
        })

        props.onFilter(params)
      }
    })
  }

  const onClear = () => {
    props.resetForm()
    props.onFilter()
    SET_DATA(`${props.cacheName}.filterData`, {})
  }

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="invoiceNo" label="Doc No" />
        </div>
        <div className="form-fields">
          <Field
            as="select"
            name="kind"
            label="Kind"
            options={[{ label: 'All', value: '' }, ...INCOME_KIND_OPTIONS]}
          />
        </div>
        <div className="form-fields">
          <Field as="date-range" name="issueDate" label="Issue Date" />
        </div>
        <div className="form-fields">
          <Field as="date-range" name="taxDate" label="Tax Date" />
        </div>
        <div className="form-fields">
          <Field as="date-range" name="paymentDate" label="Payment Date" />
        </div>
        <div className="form-fields">
          <Field
            as="select"
            name="status"
            label="Status"
            options={[{ label: 'All', value: '' }, ...INVOICE_STATUS_OPTIONS]}
          />
        </div>
        <div className="form-fields">
          <Field
            as="select"
            name="client"
            label="Customer"
            options={[{ label: 'All', value: '' }, ...clients]}
          />
        </div>
        <div className="form-fields">
          <Button variant="secondary" className="search" onClick={onSubmitForm}>
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button className="btn-block btn-glow search" onClick={onClear}>
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: (props) => {
    const filterObj = GET_DATA(`${props.cacheName}.filterData`)

    return {
      invoiceNo: filterObj?.invoiceNo || '',
      kind: filterObj?.kind || '',
      issueDate: filterObj?.issueDate || '',
      taxDate: filterObj?.taxDate || '',
      paymentDate: filterObj?.paymentDate || '',
      status: filterObj?.status || '',
      client: filterObj?.client || ''
    }
  },
  handleSubmit: () => null
})(Filter)
