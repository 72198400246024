import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'
import { STATUS } from '../../../../Util/Options'

function POLPODFilter({ values, setValues, filterData }) {
  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field name="code" label="Code" />
      </div>
      <div className="form-fields">
        <Field name="description" label="Description" />
      </div>
      <div className="form-fields">
        <Field as="select" name="status" label="Status" options={STATUS} allowClear />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" /> Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    code: '',
    description: '',
    status: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(POLPODFilter)
