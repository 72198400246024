import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ConfirmationBox from '../../../../Components/ConfirmationBox/ConfirmationBox'
import FooterActions from '../../../../Components/FooterActions'
import TemplateView from '../../../../Components/TemplateView/TemplateView'
import apiClient from '../../../../Util/apiClient'
import { downloadPrintPDF } from '../../../../Util/Util'

export default function Bookings(props) {
  const [bookings, setBookings] = useState([])

  const getData = () => {
    if (props.match.params.id) {
      apiClient.get(`bookings/view-content/${props.match.params.id}`).then(({ data }) => {
        if (data.result) {
          setBookings(data.result)
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const onDownload = (isPrint = false) => {
    const ids = bookings.map((val) => val.id)
    const exeFn = (printType) =>
      downloadPrintPDF(`bookings/download/${props.match.params.id}`, { printType }, isPrint)

    if (ids.length > 1) {
      ConfirmationBox({
        title: 'Download Booking',
        description: 'Select how you wants to download!',
        acceptText: 'Single',
        acceptText1: 'Multiple',
        canelText: 'Cancel',
        acceptFn: () => exeFn('Single'),
        acceptFn1: () => exeFn('Multiple')
      })
    } else {
      exeFn('Single')
    }
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} style={{ background: '#f3f3f3' }}>
        <div style={{ paddingBottom: 70 }}>
          <TemplateView contents={bookings} />
        </div>
        {!props.noEdit && (
          <FooterActions
            leftActions={[
              {
                prefix: 'flaticon-back',
                label: 'Back',
                onClick: () => props.history.push('/app/bookings')
              }
            ]}
            centerActions={[
              {
                prefix: 'flaticon-edit-1',
                label: 'Edit',
                onClick: () => props.history.push(`/app/edit-booking/${props.match.params.id}`)
              }
            ]}
            rightActions={[
              {
                prefix: 'flaticon-printer-1',
                label: 'Print',
                onClick: () => onDownload(true)
              },
              {
                prefix: 'flaticon-download',
                label: 'Download',
                onClick: () => onDownload()
              }
            ]}
          />
        )}
      </Col>
    </Row>
  )
}
