import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { getOrderNames } from '../../../Util/Options'
import { orderDeliverySchema } from '../../../Util/validationSchema'
import CheckAvailability from '../CheckAvailability'
import SingleDelivery from './SingleDelivery'

function SaveDelivery({
  history,
  setValues,
  setFieldValue,
  submitForm,
  location,
  match: {
    params: { id }
  },
  companyInfo,
  values
}) {
  const [contactPersons, setContactPersons] = useState([])

  const { type, billingAddress, shippingAddress, currency } = values
  const { isSales, isPurchase, typeName, clientName, deliveryName, deliver, getStatus } = getOrderNames(type)

  const disabled = id && values.status === 'Delivered'

  const getAddress = (address) =>
    address &&
    _.compact([address.street, address.city, address.state, address.country, address.postalCode]).join(', ')

  const getData = () => {
    if (id) {
      apiClient.get(`order-deliveries/${id}`).then(({ status, data }) => {
        if (status === 200) {
          data.orderDetails = data.orderDetails.map((item) => ({
            disabled: id && data.status === 'Delivered',
            ...item
          }))

          setContactPersons(
            data.clientData.contactPersons.map((item) => ({
              value: item._id,
              label: _.compact([item.contactName, item.contactEmail, item.contactPhone]).join(', '),
              ...item
            }))
          )

          setValues({ ...values, ...data })
        }
      })
    } else {
      const { state: { orderDetails = [], orderInfo: { clientInfo, orderNo } = '' } = '' } = location
      const { configurations: { warehouseLocations, warehouseRacks } = {} } = companyInfo

      setContactPersons(
        clientInfo?.contactPersons.map((item) => ({
          value: item._id,
          label: _.compact([item.contactName, item.contactEmail, item.contactPhone]).join(', '),
          ...item
        }))
      )

      setFieldValue(
        'orderDetails',
        orderDetails.map((item, i) => ({
          client: clientInfo?._id,
          orderNo,
          deliveryPosition: (i + 1) * 10,
          deliveryQuantity: '',
          orderDeliveryQuantity: item.toBeDeliveredQuantity,
          warehouse: '',
          location: '',
          rack: '',
          warehouseLocations,
          warehouseRacks,
          disabled: false,
          ...item
        }))
      )
    }
  }

  useEffect(() => {
    getData()
  }, [id || type])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout
            title={
              <Row justify="space-between">
                <Col>
                  <h2 className="panel-title">{`${typeName} ${deliveryName}`}</h2>
                </Col>
                {id && (
                  <Col>
                    <b>
                      {`${typeName} ${deliveryName} Status`}:
                      <span className="ml-2">{getStatus(values.status)}</span>
                    </b>
                  </Col>
                )}
              </Row>
            }>
            <Panel title={`${typeName} ${deliveryName}`}>
              <Row gutter={[20, 10]}>
                {id && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field name="deliveryNo" label={`${deliveryName} number`} disabled />
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="deliveryDate" label={`${deliveryName} date`} disabled />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="remarks" label="Remarks" disabled={disabled} />
                  </div>
                </Col>
                {isPurchase && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field name="poType" label="PO Type" disabled />
                    </div>
                  </Col>
                )}
              </Row>
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      name="clientData.name"
                      label={`${clientName} name`}
                      altInput
                      altValue={values.clientAlt}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      name="billingAddress"
                      label={`${clientName} address`}
                      value={getAddress(billingAddress)}
                      disabled
                    />
                  </div>
                </Col>
                {isSales ? (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field
                        name="shippingAddress"
                        label="Delivery address"
                        value={getAddress(shippingAddress)}
                        disabled
                      />
                    </div>
                  </Col>
                ) : (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field
                        as="select"
                        name="contactPerson"
                        label="Contact Person"
                        options={contactPersons}
                        disabled
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Panel>
            {isSales && (
              <CheckAvailability orderDetails={values.orderDetails} setFieldValue={setFieldValue} />
            )}
            <Panel title="Order Details">
              <FieldArray
                name="orderDetails"
                additionalValues={{ type, disabled, currency }}
                loaderCount={8}
                viewOnly>
                {SingleDelivery}
              </FieldArray>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            {!disabled ? (
              <>
                <Button
                  variant="primary"
                  onClick={() => {
                    setFieldValue('tempStatus', 'Draft')
                    submitForm()
                  }}>
                  {id ? 'Update' : 'Save'}
                </Button>
                <span>or</span>
                {id ? (
                  <Button
                    onClick={() => {
                      setFieldValue('tempStatus', 'Delivered')
                      submitForm()
                    }}>
                    Update and {deliver}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setFieldValue('tempStatus', 'Delivered')
                      submitForm()
                    }}>
                    Save and {deliver}
                  </Button>
                )}
                <span>or</span>
                <Link to="#" onClick={() => history.goBack()}>
                  <Button>
                    <ArrowLeftOutlined /> Back
                  </Button>
                </Link>
              </>
            ) : (
              <Link to={`/app/order-deliveries/${type}`}>
                <Button>
                  <ArrowLeftOutlined /> Back to {type} {deliveryName.toLowerCase()} list
                </Button>
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({
    history,
    location: {
      state: {
        type,
        orderDetails = [],
        orderInfo: {
          clientAlt,
          clientInfo,
          poType,
          currency,
          exchangeRate,
          billingAddress,
          shippingAddress,
          contactPerson
        } = ''
      } = ''
    },
    match: {
      params: { id }
    }
  }) => {
    if (!id && (!type || orderDetails.length === 0)) {
      history.goBack()
    }

    return {
      type,
      deliveryDate: moment().utc().startOf('day'),
      remarks: '',
      currency,
      poType,
      exchangeRate,
      clientData: clientInfo,
      client: clientInfo?._id,
      clientAlt,
      contactPerson,
      billingAddress,
      shippingAddress,
      orderDetails: []
    }
  },
  validationSchema: orderDeliverySchema,
  handleSubmit: (
    { tempStatus, ...data },
    {
      props: {
        match: {
          params: { id }
        },
        history
      },
      setFieldValue
    }
  ) => {
    const { goods, typeName, deliveryName } = getOrderNames(data.type)
    data.status = tempStatus

    if (id) {
      apiClient.put(`order-deliveries/${id}`, data).then(({ status, data }) => {
        if (status === 200) {
          setFieldValue('status', data.status)

          if (
            data.type === 'purchase'
              ? data.poType === 'Standard' && data.status === 'Delivered'
              : data.status === 'Delivered'
          ) {
            Modal.success({
              title: `${typeName} ${deliveryName} delivered successfully`,
              content: `Stock ${goods} no: ${data.goodsInfo?.transactionNo}`
            })
            history.replace(`/app/edit-delivery/${data.id}`)
          } else {
            history.push(`/app/order-deliveries/${data.type}`)
          }
        }
      })
    } else {
      apiClient.post('order-deliveries', data).then(({ status, data }) => {
        if (status === 201) {
          setFieldValue('status', data.status)

          if (
            data.type === 'purchase'
              ? data.poType === 'Standard' && data.status === 'Delivered'
              : data.status === 'Delivered'
          ) {
            Modal.success({
              title: `${typeName} ${deliveryName} created successfully`,
              content: (
                <div>
                  <div>{`${deliveryName} created with ${deliveryName} no: ${data.deliveryNo}`}</div>
                  <div>{`Stock ${goods} no: ${data.goodsInfo.transactionNo}`}</div>
                </div>
              )
            })
            history.replace(`/app/edit-delivery/${data.id}`)
          } else {
            Modal.success({
              title: `${typeName} ${deliveryName} created successfully`,
              content: `${deliveryName} created with ${deliveryName} no: ${data.deliveryNo}`
            })
            history.replace(`/app/edit-delivery/${data.id}`)
          }
        }
      })
    }
  }
})(SaveDelivery)
