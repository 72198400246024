import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Attachments from '../../../Components/Attachments'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'

// const Schema = Yup.object().shape({
//   effectiveDate: Yup.date().required(),
//   endDate: Yup.date().required(),
//   jobTitle: Yup.string().required(),
//   employmentStatus: Yup.string().required(),
//   jobCategory: Yup.string().required(),
//   location: Yup.string().required(),
//   comment: Yup.string().required(),
//   contractStartDate: Yup.date().required(),
//   contractEndDate: Yup.date().required(),
//   attachments: Yup.array()
// })

const JobHistoryForm = (props) => {
  const {
    currentDetails,
    // values,
    handleValueChange
  } = props
  const [options, setOptions] = useState({})

  const fetchDropdownValues = () => {
    apiClient
      .get('options/get-by-types', {
        params: { type: ['EmploymentStatus', 'JobCategory'] }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })
  }

  useEffect(() => {
    fetchDropdownValues()
    // setValues({
    //   ...values,
    //   effectiveDate: !isEmpty(currentDetails)
    //     ? moment(currentDetails.effectiveDate).format('yyyy-MM-DD')
    //     : '',
    //   endDate: !isEmpty(currentDetails) ? moment(currentDetails.endDate).format('yyyy-MM-DD') : '',
    //   jobTitle: !isEmpty(currentDetails) ? currentDetails.jobTitle : '',
    //   employmentStatus: !isEmpty(currentDetails) ? currentDetails.employmentStatus : '',
    //   jobCategory: !isEmpty(currentDetails) ? currentDetails.jobCategory : '',
    //   location: !isEmpty(currentDetails) ? currentDetails.location : '',
    //   contractStartDate: !isEmpty(currentDetails)
    //     ? moment(currentDetails.contractStartDate).format('yyyy-MM-DD')
    //     : '',
    //   contractEndDate: !isEmpty(currentDetails)
    //     ? moment(currentDetails.contractEndDate).format('yyyy-MM-DD')
    //     : '',
    //   comment: !isEmpty(currentDetails) ? currentDetails.comment : '',
    //   attachments: !isEmpty(currentDetails) ? currentDetails.attachments : ''
    // })
  }, [])

  // useEffect(() => {
  //   handleValueChange(values)
  // }, [
  //   values?.effectiveDate,
  //   values?.jobTitle,
  //   values?.endDate,
  //   values?.employmentStatus,
  //   values?.jobCategory,
  //   values?.location,
  //   values?.contractStartDate,
  //   values?.contractEndDate,
  //   values?.comment,
  //   values?.attachments
  // ])

  const onUploadAttachement = (key, attachments) => {
    handleValueChange({
      ...currentDetails,
      attachments: attachments.filter((attachment) => attachment !== '')
    })
  }

  return (
    <Form>
      <Row justify="left" gutter={(12, 10)}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="effectiveDate" label="Effective Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="endDate" label="End Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 24 }}>
          <div className="form-field">
            <Field name="jobTitle" label="Job Title" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field
              name="employmentStatus"
              label="Employment Status"
              as="select"
              options={options.EmploymentStatus || []}
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="jobCategory" label="Job Category" as="select" options={options.JobCategory || []} />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="location" label="Location" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="comment" label="Comment" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="contractStartDate" label="Contract Start Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="contractEndDate" label="Contract End Date" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="form-field">
            <label>Attachments</label>
            <Attachments
              name="dependentsAttachments"
              value={currentDetails?.attachments || []}
              title="Attach Files"
              description="You can upload a maximum of 3 files, 5MB each"
              acceptFile={['image', 'pdf']}
              onUpload={onUploadAttachement}
            />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default JobHistoryForm

// withFormik({
//   mapPropsToValues: () => ({
//     effectiveDate: '',
//     endDate: '',
//     jobTitle: '',
//     employmentStatus: '',
//     jobCategory: '',
//     location: '',
//     comment: '',
//     contractStartDate: '',
//     contractEndDate: '',
//     attachments: []
//   }),
//   handleSubmit: () => null,
//   validationSchema: Schema
// })(JobHistoryForm)
