import { Col, message, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  getInsuranceDetailsByEmployeeID,
  updateInsuranceDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import Attachments from '../../../Components/Attachments'
import FooterActions from '../../../Components/FooterActions'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'

const Schema = Yup.object().shape({
  insuranceNum: Yup.string().required(),
  insuranceName: Yup.string().required(),
  companyInsuranceExpriry: Yup.date().required(),
  selfCardNum: Yup.string().required(),
  sposeCardNum: Yup.string().required(),
  fatherCardNum: Yup.string().required(),
  motherCardNum: Yup.string().required(),
  childOneCardNum: Yup.string().required(),
  childTwoCardNum: Yup.string().required(),
  childThreeCardNum: Yup.string().required(),
  attachments: Yup.array(),
  company: Yup.string().required(),
  employee: Yup.string().required()
  // _id: Yup.string().required()
})

const InsuranceDetails = (props) => {
  const [editable, setEditable] = useState(false)
  const { currentEmployee, values, setValues, submitForm, insuranceDetails, dispatch, errors } = props

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getInsuranceDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const onEdit = () => {
    if (currentEmployee?.id) {
      setEditable(true)
      setValues({
        ...values,
        employee: currentEmployee?.id,
        company: currentEmployee?.company,
        insuranceNum: insuranceDetails?.insuranceNum,
        insuranceName: insuranceDetails?.insuranceName,
        companyInsuranceExpriry: moment(insuranceDetails?.companyInsuranceExpriry).format('yyyy-MM-DD'),
        selfCardNum: insuranceDetails?.selfCardNum,
        sposeCardNum: insuranceDetails?.sposeCardNum,
        fatherCardNum: insuranceDetails?.fatherCardNum,
        motherCardNum: insuranceDetails?.motherCardNum,
        childOneCardNum: insuranceDetails?.childOneCardNum,
        childTwoCardNum: insuranceDetails?.childTwoCardNum,
        childThreeCardNum: insuranceDetails?.childThreeCardNum,
        attachments: insuranceDetails?.attachments
      })
    } else {
      message.error('Please select and employee to edit')
    }
  }

  const onUploadAttachement = (key, attachments) => {
    setValues({ ...values, attachments: attachments.filter((attachment) => attachment !== '') })
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setEditable(false)
    }
  }

  return (
    <Form>
      <PanelLayout>
        <Panel title="Insurance Details">
          {!editable && (
            <div className="panel-with-border">
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Insurance No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.insuranceNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Insurance Name</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.insuranceName : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Company Insurance Expiry</span>

                  <p>
                    {!isEmpty(insuranceDetails)
                      ? moment(insuranceDetails.companyInsuranceExpriry).format('DD-MMM-YYYY')
                      : 'N/A'}
                  </p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Self Card No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.selfCardNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Spouse Card No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.sposeCardNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Father Card No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.fatherCardNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Mother Card No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.motherCardNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Child One Card No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.childOneCardNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Child Two Card No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.childTwoCardNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Child Three Card No</span>
                  <p>{!isEmpty(insuranceDetails) ? insuranceDetails.childThreeCardNum : 'N/A'}</p>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <span>Attachments</span>
                  <div className="attachments-preview">
                    <a>
                      {!isEmpty(insuranceDetails)
                        ? insuranceDetails.attachments.map((attachment) => attachment.name).join(', ')
                        : 'N/A'}
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {editable && (
            <div>
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="insuranceNum" label="Insurance No" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="insuranceName" label="Insurance Name" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="companyInsuranceExpriry" label="Company Insurance Expiry" as="date" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="selfCardNum" label="Self Card No" as="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="sposeCardNum" label="Spouse Card No" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="fatherCardNum" label="Father Card No" type="text" />
                  </div>
                </Col>
              </Row>
              <Row justify="left" gutter={(12, 10)}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="motherCardNum" label="Mother Card No" type="text" />
                  </div>
                  <div className="form-field">
                    <Field name="childOneCardNum" label="Child One Card No" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="childTwoCardNum" label="Child Two Card No" type="text" />
                  </div>
                  <div className="form-field">
                    <Field name="childThreeCardNum" label="Child Three Card No" type="text" />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <label>Attachments</label>
                    <Attachments
                      name="attachments"
                      value={values.attachments}
                      title="Attach Files"
                      description="You can upload a maximum of 3 files, 5MB each"
                      acceptFile={['image', 'pdf']}
                      onUpload={onUploadAttachement}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Panel>
      </PanelLayout>
      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
        centerActions={[
          {
            prefix: 'flaticon-play',
            label: 'Save',
            dontShow: !editable,
            onClick: onSave
          }
        ]}
        rightActions={[
          {
            prefix: 'flaticon-edit-1',
            label: 'Edit',
            dontShow: editable,
            onClick: onEdit
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  insuranceDetails: state.employee.employeeDetails.insuranceDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      insuranceNum: '',
      insuranceName: '',
      companyInsuranceExpriry: '',
      selfCardNum: '',
      sposeCardNum: '',
      fatherCardNum: '',
      motherCardNum: '',
      childOneCardNum: '',
      childTwoCardNum: '',
      childThreeCardNum: '',
      attachments: []
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      dispatch(updateInsuranceDetailsByEmployeeID(currentEmployee.id, values))
    },
    validationSchema: Schema
  })(InsuranceDetails)
)
