import { SettingOutlined } from '@ant-design/icons'
import { message, Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import AccountSetupFilter from './AccountSetupFilter'

const expandedRowRender = (row) => (
  <table style={{ width: '90%', margin: 'auto' }}>
    {row.accounts.map((v, i) => (
      <tr key={i}>
        <td>
          <b>{v.accountCode}</b>
        </td>
        <td>{v.accountData?.name}</td>
        <td>{v.accountData?.accountGroup}</td>
        <td>{v.transactionType}</td>
        <td>{v.from}</td>
      </tr>
    ))}
  </table>
)

export default function AccountSetups(props) {
  const [accountSetups, setAccountSetups] = useState([])

  const onFilter = (params = {}) => {
    apiClient.get('account-setups/get', { params }).then(({ data }) => {
      if (data.result) {
        SET_DATA('accountSetups.filterData', { ...params, ...data.pageData })
        setAccountSetups(data.result)
      }
    })
  }

  useEffect(() => {
    onFilter(GET_DATA('accountSetups.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('accountSetups.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const onDelete = (id) => {
    apiClient.delete(`account-setups/delete/${id}`).then(({ data }) => {
      if (data && data.result) {
        onFilter(GET_DATA('accountSetups.filterData'))
        message.success('Account Setup Deleted')
      }
    })
  }

  const tableContent = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/edit-account-setup/${row.id}`}>
            <i className="flaticon-edit-1" /> Edit
          </Link>
        </li>
        {row.removable === 'Yes' && (
          <li>
            <Link to="#" onClick={() => onDelete(row.id)}>
              <i className="flaticon-delete-2" /> Delete
            </Link>
          </li>
        )}
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      render: (v, r) => (
        <div onClick={() => props.history.push(`/app/edit-account-setup/${r.id}`)}>
          <a>{v}</a>
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      dontShow: !validateAccess('edit-account-setup'),
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableContent(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout filter={<AccountSetupFilter onFilter={onFilter} />}>
      <TableLayout title="Account Setup Overview" exportUrl="account-setups/export">
        <TableBox
          dataSource={accountSetups}
          columns={columns}
          pageData={GET_DATA('accountSetups.filterData')}
          onChangePage={onChangePage}
          expandable={{ expandedRowRender }}
        />
      </TableLayout>
    </FilterLayout>
  )
}
