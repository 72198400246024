import { Col, Row, Space } from 'antd'
import _ from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import Address from '../../../Components/Address/Address'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import Panel from '../../../Layout/Panel'
import { DEFAULT_FORWARDER_CHARGES } from '../../../Util/Options'
import { parseAmount } from '../../../Util/Util'
import SingleForwarderCharge from './SingleForwarderCharge'

function ClientCharges({ name, values, setFieldValue, disabled, charges, clients, companyInfo }) {
  const value = values[name]
  const label = name.capitalize?.()
  const [contactPersons, setContactPersons] = useState([])

  const handleClient = (n, v, option = { contactPersons: [] }) => {
    const primaryContacts = option.contactPersons

    setFieldValue(`${name}.clientAlt`, option.nameAlt)
    setFieldValue(`${name}.billingAddress`, option.billingAddress)
    setFieldValue(`${name}.shippingAddress`, option.shippingAddress)

    setFieldValue(`${name}.contactPerson`, primaryContacts.length === 1 ? primaryContacts[0].value : '')

    return setFieldValue(n, v)
  }

  useEffect(() => {
    if (clients.length > 0 && value.client) {
      const { contactPersons } = clients.find((item) => item.value === value.client)

      setContactPersons(contactPersons)
    }
  }, [value.client, clients.length])

  const totalCharges = parseFloat(_.sumBy(value.forwarderCharges, 'chargeValue'))

  useDidUpdateEffect(() => {
    if (totalCharges) {
      setFieldValue(`${name}.totalCharges`, totalCharges)
    }
  }, [totalCharges])

  return (
    <div>
      <h2>{label} Details</h2>
      <Row gutter={[20, 10]}>
        <Col xs={24} sm={24} md={8}>
          <Space size="large" direction="vertical" className="w-100">
            <div className="form-field">
              <Field
                as="select"
                name={`${name}.client`}
                label={label}
                onChange={handleClient}
                options={clients}
                altInput
                disabled={disabled || name === 'supplier'}
              />
            </div>
            <Address
              addressType={`${name}.billingAddress`}
              label={`${label} Address`}
              disabled={disabled || name === 'supplier'}
            />
            <div className="form-field">
              <Field
                as="select"
                name={`${name}.contactPerson`}
                label="Contact Person"
                options={contactPersons}
                disabled={disabled || name === 'supplier'}
              />
            </div>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={16}>
          <Panel title={`${label} Charges`}>
            <FieldArray
              name={`${name}.forwarderCharges`}
              defaultValues={DEFAULT_FORWARDER_CHARGES}
              additionalValues={{ name, charges, disabled }}
              editable={!disabled}>
              {SingleForwarderCharge}
            </FieldArray>
          </Panel>
          <Row gutter={[20, 20]}>
            <Col xs={15} className="text-right">
              <h3>Total {name === 'forwarder' ? 'Forwarding' : label} Charges:</h3>
            </Col>
            <Col xs={9} className="text-right">
              <p>
                {parseAmount(value.totalCharges, companyInfo.currency)} {companyInfo.currency}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default memo(ClientCharges)
