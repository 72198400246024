import { SettingOutlined } from '@ant-design/icons'
import { Col, message, Popconfirm, Popover, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  addJobHistoryDetails,
  getJobHistoryDetailsByEmployeeID,
  updateJobHistoryDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import FooterActions from '../../../Components/FooterActions'
import Form from '../../../Components/Formik/Form'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import JobHistoryForm from './JobHistoryForm'

const Schema = Yup.object().shape({
  effectiveDate: Yup.date().required(),
  endDate: Yup.date().required(),
  jobTitle: Yup.string().required(),
  employmentStatus: Yup.string().required(),
  jobCategory: Yup.string().required(),
  location: Yup.string().required(),
  comment: Yup.string().required(),
  contractStartDate: Yup.date().required(),
  contractEndDate: Yup.date().required(),
  attachments: Yup.array()
})

const JobHistory = (props) => {
  const [toggle, setToggle] = useState(false)
  const [isAddType, setIsAddType] = useState(true)
  const { currentEmployee, jobHistory, dispatch, values, setValues, submitForm, errors } = props

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getJobHistoryDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const handleAddNewDetails = () => {
    if (currentEmployee?.id) {
      setToggle(true)
      setIsAddType(true)
      setValues({
        effectiveDate: '',
        endDate: '',
        jobTitle: '',
        employmentStatus: '',
        jobCategory: '',
        location: '',
        comment: '',
        contractStartDate: '',
        contractEndDate: '',
        attachments: [],
        isAddType: true
      })
    } else {
      message.error('Please select an employee to continue!')
    }
  }

  const handleEditRow = (row) => () => {
    setToggle(true)
    setValues({ ...row, isAddType: false })
    setIsAddType(false)
  }

  const handleValueChange = (val) => {
    setValues({ ...values, ...val })
  }

  const deleteRow = (row) => () => {
    dispatch(updateJobHistoryDetailsByEmployeeID(row.id, { status: 'Inactive' }))
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setToggle(false)
    }
  }

  const columns = [
    {
      title: 'Effective Date',
      dataIndex: 'effectiveDate',
      render: (text) => moment(text).format('DD-MMM-YYYY')
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (text) => moment(text).format('DD-MMM-YYYY')
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle'
    },
    {
      title: 'Employment Status',
      dataIndex: 'employmentStatus'
    },
    {
      title: 'Job Category',
      dataIndex: 'jobCategory'
    },
    {
      title: 'Location',
      dataIndex: 'location'
    },
    {
      title: 'Comment',
      dataIndex: 'comment'
    },
    {
      title: 'Contract Start Date',
      dataIndex: 'contractStartDate',
      render: (text) => moment(text).format('DD-MMM-YYYY')
    },
    {
      title: 'Contract End Date',
      dataIndex: 'contractEndDate',
      render: (text) => moment(text).format('DD-MMM-YYYY')
    },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      render: (text) => text.map((attachment) => attachment.name).join(', ')
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              {' '}
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li onClick={handleEditRow(row)}>
          <i className="flaticon-edit-1" /> Edit
        </li>
        <li>
          <Popconfirm title="Sure to delete?" onConfirm={deleteRow(row)}>
            <i className="flaticon-delete-2" /> Delete
          </Popconfirm>
        </li>
      </ul>
    </div>
  )

  return (
    <Form>
      <PanelLayout>
        <Panel title="Job History">
          <div className="panel-with-border">
            <Row justify="left" gutter={(12, 10)}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="mb-3 align-right">
                  <ButtonBox style={{ marginRight: 10 }} type="success" onClick={handleAddNewDetails}>
                    <i className="flaticon-plus" /> Add
                  </ButtonBox>
                  {/* <ButtonBox type="primary">
                    {' '}
                    <i className="flaticon-delete-3" /> Delete{' '}
                  </ButtonBox> */}
                </div>
                <div className="table-view">
                  <TableBox
                    columns={columns}
                    actionIndex="custom_action"
                    cardHeaderIndex="status"
                    cardFirstLabelIndex="docno"
                    dataSource={jobHistory}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Panel>
      </PanelLayout>

      <ModalBox
        title={`${isAddType ? 'Add' : 'Edit'} Job History Details`}
        visible={toggle}
        onCancel={() => setToggle(false)}
        width={700}
        okText="Save"
        onOk={onSave}
        destroyOnClose>
        <JobHistoryForm currentDetails={values} handleValueChange={handleValueChange} />
      </ModalBox>

      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  jobHistory: state.employee.employeeDetails.jobHistory
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      effectiveDate: '',
      endDate: '',
      jobTitle: '',
      employmentStatus: '',
      jobCategory: '',
      location: '',
      comment: '',
      contractStartDate: '',
      contractEndDate: '',
      attachments: []
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      const { id, isAddType, ...rest } = values
      const payload = {
        effectiveDate: rest.effectiveDate,
        endDate: rest.endDate,
        jobTitle: rest.jobTitle,
        employmentStatus: rest.employmentStatus,
        jobCategory: rest.jobCategory,
        location: rest.location,
        comment: rest.comment,
        contractStartDate: rest.contractStartDate,
        contractEndDate: rest.contractEndDate,
        employee: currentEmployee.id,
        attachments: rest.attachments.filter((attachment) => attachment !== '')
      }

      if (isAddType) {
        dispatch(addJobHistoryDetails(payload))
      } else {
        dispatch(updateJobHistoryDetailsByEmployeeID(id, payload))
      }
    },
    validationSchema: Schema
  })(JobHistory)
)
