import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Attachments from '../../../Components/Attachments'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'

// const Schema = Yup.object().shape({
//   id: Yup.string().required(),
//   name: Yup.string().required(),
//   relationship: Yup.string().required(),
//   dateOfBirth: Yup.date().required(),
//   attachments: Yup.array()
// })

const DependentsForm = (props) => {
  const {
    currentDetails,
    // values,
    handleValueChange
  } = props
  const [options, setOptions] = useState({})

  const fetchDropdownValues = () => {
    apiClient
      .get('options/get-by-types', {
        params: { type: ['Relationship'] }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })
  }

  useEffect(() => {
    fetchDropdownValues()
    // setValues({
    //   ...values,
    //   name: !isEmpty(currentDetails) ? currentDetails.name : '',
    //   relationship: !isEmpty(currentDetails) ? currentDetails.relationship : '',
    //   dateOfBirth: !isEmpty(currentDetails) ? moment(currentDetails.dateOfBirth).format('yyyy-MM-DD') : '',
    //   attachments: !isEmpty(currentDetails) ? currentDetails.attachments : ''
    // })
  }, [])

  // useEffect(() => {
  //   handleValueChange(values)
  // }, [values?.name, values?.relationship, values?.dateOfBirth, values?.attachments])

  const onUploadAttachement = (key, attachments) => {
    handleValueChange({
      ...currentDetails,
      attachments: attachments.filter((attachment) => attachment !== '')
    })
  }

  return (
    <Form>
      <Row justify="left" gutter={(12, 10)}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="form-field">
            <Field name="name" label="Name" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field
              name="relationship"
              label="Relationship"
              as="select"
              options={options.Relationship || []}
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="dateOfBirth" label="Date Of Birth" as="date" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <div className="form-field">
            <label>Attachments</label>
            <Attachments
              name="attachments"
              value={currentDetails?.attachments || []}
              title="Attach Files"
              description="You can upload a maximum of 3 files, 5MB each"
              acceptFile={['image', 'pdf']}
              onUpload={onUploadAttachement}
            />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default DependentsForm

// withFormik({
//   mapPropsToValues: () => ({
//     name: '',
//     relationship: '',
//     dateOfBirth: '',
//     attachments: []
//   }),
//   handleSubmit: () => null,
//   validationSchema: Schema
// })(DependentsForm)
