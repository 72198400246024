import { Col, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import BlockChainFlow from '../../../Components/BlockExplorerBox/BlockChainFlow'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import apiClient from '../../../Util/apiClient'
import { sanitize } from '../../../Util/Util'

export default function InBoundTransmissionView(props) {
  const [transmission, setTransmission] = useState([])

  const getData = () => {
    if (props.match.params.id) {
      apiClient.get(`incomes/get-inbound-transmissions/${props.match.params.id}`).then(({ data }) => {
        if (data && data.result) {
          setTransmission(data.result)
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const onApprove = () => {
    apiClient.put(`incomes/approve-transmission/${props.match.params.id}`).then(({ data }) => {
      if (data && data.result) {
        message.success('Transmission Approved')
        setTransmission({ ...transmission, ...data.result })
      }
    })
  }

  const onReject = () => {
    apiClient.put(`incomes/reject-transmission/${props.match.params.id}`).then(({ data }) => {
      if (data && data.result) {
        message.success('Transmission Rejected')
        setTransmission(...transmission, ...data.result)
      }
    })
  }

  const addToExpenses = () => {
    apiClient.post(`incomes/add-to-expenses/${props.match.params.id}`).then(({ data }) => {
      if (data && data.result) {
        message.success('Added to expenses')
      }
    })
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={16} lg={18}>
        <div style={{ paddingBottom: 70 }}>
          {transmission && (
            <div
              style={{
                width: 'fit-content',
                margin: '15px auto',
                background: '#fff'
              }}>
              <div
                style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', transition: 0.3 }}
                {...sanitize(transmission.htmlContent)}
              />
            </div>
          )}
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} lg={6}>
        {transmission && transmission.transmissionSentId && (
          <div className="vertical-view">
            <div className="heading">
              <h2>Transmission details</h2>
            </div>
            <div className="more-details">
              {transmission.transmissionStatus === 'Sent' && (
                <div style={{ textAlign: 'center' }}>
                  <ButtonBox
                    style={{ background: '#1a8d08', borderColor: '#1a8d08', marginRight: 10 }}
                    onClick={onApprove}>
                    Approve
                  </ButtonBox>
                  <ButtonBox onClick={onReject}>Reject</ButtonBox>
                </div>
              )}
              {transmission.transmissionStatus === 'Approved' && (
                <div style={{ textAlign: 'center' }}>
                  <ButtonBox
                    style={{ background: '#1a8d08', borderColor: '#1a8d08', marginRight: 10 }}
                    onClick={addToExpenses}>
                    Add to expenses
                  </ButtonBox>
                </div>
              )}
              <br />
              <BlockChainFlow blockData={transmission} />
            </div>
          </div>
        )}
      </Col>
    </Row>
  )
}
