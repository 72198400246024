import { SettingOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import DivisionFilter from './DivisionFilter'

export default function Divisions(props) {
  const history = useHistory()
  const [divisions, setDivisions] = useState([])

  const onFilter = (params = {}) => {
    apiClient.get('divisions/get', { params }).then(({ data }) => {
      if (data.result) {
        SET_DATA('divisions.filterData', { ...params, ...data.pageData })
        setDivisions(data.result)
      }
    })
  }

  useEffect(() => {
    if (props?.companyInfo?.configurations?.division !== 'Yes') {
      history.push('/app/dashboard')
    }

    onFilter(GET_DATA('divisions.filterData'))
  }, [])

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('divisions.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const tableContent = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/edit-division/${row.id}`}>
            <i className="flaticon-edit-1" /> Edit
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (v, r) => (
        <div onClick={() => history.push(`/app/edit-division/${r.id}`)}>
          <a>{v}</a>
        </div>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      ...(validateAccess('edit-division') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <Popover placement="bottomRight" content={tableContent(row)} trigger="click">
            <div className="btn-group">
              <button type="button" className="btn glow dropdown-toggle">
                {' '}
                <SettingOutlined /> <span className="caret" />
              </button>
            </div>
          </Popover>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<DivisionFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <h2>Divisions Overview</h2>
      </div>
      <TableBox
        dataSource={divisions}
        columns={columns}
        pageData={GET_DATA('divisions.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
