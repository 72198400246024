import { Col, Row } from 'antd'
import React from 'react'

export default class DemoVideo extends React.PureComponent {
  render() {
    return (
      <>
        {/* Breadcrum starts */}
        <section className="breadcrum-title only-heading">
          <div className="container-fluid">
            <Row justify="center">
              <Col span={21}>
                <div className="breadcrum-content">
                  <h1>Demo Videos</h1>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        {/* breadcrum ends here */}

        <section className="inner-pages about-us">
          <div className="container-fluid">
            <Row justify="center">
              <Col span={21}>
                <Row justify="center" gutter={[20]}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 19 }}>
                    <h3>Check the demo of the apps </h3>
                    <iframe
                      width="100%"
                      height="480"
                      style={{ border: 'none' }}
                      src="https://www.youtube.com/embed/8CENKuxzg3k"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      </>
    )
  }
}
