import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'
import { getOperationTypes, OPERATION_TYPES } from '../../../../Util/Options'

function OperationFilter({ values, setValues, setFieldValue, filterData }) {
  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field
          as="select"
          name="type"
          label="Type"
          options={OPERATION_TYPES}
          onChange={(e, v) => {
            setFieldValue(e, v)

            return setFieldValue('value', '')
          }}
        />
      </div>
      <div className="form-fields">
        <Field as="select" name="value" label="Value" options={getOperationTypes(values.type)} />
      </div>
      <div className="form-fields">
        <Field name="description" label="Description" />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" /> Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    type: '',
    value: '',
    description: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(OperationFilter)
