import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'
import Panel from '../../../../Layout/Panel'
import PanelLayout from '../../../../Layout/PanelLayout'
import apiClient from '../../../../Util/apiClient'
import { salesCallEntrySchema } from '../../../../Util/validationSchema'

function SalesCallEntryForm({
  values,
  setValues,
  match: {
    params: { id }
  }
}) {
  const [salesPersons, setSalesPersons] = useState([])

  const getData = () => {
    apiClient.get('sales-persons/list').then(({ status, data }) => {
      if (status === 200) {
        setSalesPersons(
          data.map((item) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName} (${item.email})`,
            ...item
          }))
        )
      }
    })

    if (id) {
      apiClient.get(`sales-call-entries/${id}`).then(({ status, data }) => {
        if (status === 200) {
          setValues({ ...values, ...data })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="Sales Call Entry Definition">
            <Panel title="Sales Call Entry">
              <Row gutter={[20, 10]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="clientName" label="Client Name" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="contactPerson" label="Contact Person" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="street" label="Street" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="city" label="City" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="state" label="State" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="postalCode" label="Postal Code" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="country" label="Country" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="phone" label="Phone" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="email" label="Email" />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="select" name="salesPerson" label="Sales Person" options={salesPersons} />
                  </div>
                </Col>
              </Row>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>
            <span>or</span>
            <Link to="/app/sales-call-entries">
              <Button>
                <ArrowLeftOutlined /> Back to sales call entries
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    clientName: '',
    contactPerson: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phone: '',
    email: '',
    salesPerson: ''
  }),
  validationSchema: salesCallEntrySchema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`sales-call-entries/${id}`, data).then(({ status }) => {
        if (status === 200) {
          history.push('/app/sales-call-entries')
        }
      })
    } else {
      apiClient.post('sales-call-entries', data).then(({ status }) => {
        if (status === 201) {
          history.push('/app/sales-call-entries')
        }
      })
    }
  }
})(SalesCallEntryForm)
