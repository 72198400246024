import React from 'react'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import TeamAbsenceApplicationListFilter from './TeamAbsenceApplicationListFilter'

export default function TeamAbsenceApplicationList() {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date'
    },
    {
      title: 'Employee Name',
      dataIndex: 'employeeName'
    },
    {
      title: 'Leave Type',
      dataIndex: 'leaveType'
    },
    {
      title: 'Number of Days',
      dataIndex: 'numberOfDays'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      dataIndex: 'action'
    }
  ]

  return (
    <FilterLayout filter={<TeamAbsenceApplicationListFilter />}>
      <TableLayout title="Team Absence Application List" exportUrl="asset/export">
        <TableBox
          columns={columns}
          actionIndex="custom_action"
          cardHeaderIndex="status"
          cardFirstLabelIndex="docno"
        />
      </TableLayout>
    </FilterLayout>
  )
}
