import { EditOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import ExchangerateFilter from './ExchangerateFilter'

export default function ExchangeRates() {
  const history = useHistory()

  const [exchangeRates, setExchangeRates] = useState([])

  useEffect(() => {
    onFilter(GET_DATA('exchangeRates.filterData'))
  }, [])

  const onFilter = (obj = {}) => {
    apiClient.get('exchange-rates/get', { params: obj }).then(({ data }) => {
      if (data && data.result) {
        SET_DATA('exchangeRates.filterData', { ...obj, ...data.pageData })
        setExchangeRates(data.result)
      }
    })
  }

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('exchangeRates.filterData')
    onFilter({ ...(filterCache || {}), ...pageData })
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (v) => (v ? moment(v).format('DD-MMM-YYYY') : '')
    },
    {
      title: 'Base Currency',
      dataIndex: 'baseCurrency'
    },
    {
      title: 'To Currency',
      dataIndex: 'toCurrency'
    },
    {
      title: 'Rate',
      dataIndex: 'rate'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      ...(validateAccess('edit-exchange-rate') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button
              onClick={() => history.push(`/app/edit-exchange-rate/${row.id}`)}
              className="btn glow dropdown-toggle">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<ExchangerateFilter onFilter={onFilter} />}>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="top-filter-options">
            <h2>Exchange Rates</h2>
          </div>
        </Col>
      </Row>
      <TableBox
        dataSource={exchangeRates}
        columns={columns}
        pageData={GET_DATA('exchangeRates.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
