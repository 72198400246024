import moment from 'moment'

// eslint-disable-next-line import/no-mutable-exports
export let MASTER_OPTIONS = []

export const setMasterOptions = (options) => {
  MASTER_OPTIONS = options
}

export const getMasterOptions = (type) => {
  const options = MASTER_OPTIONS.filter((val) => val.type === type)

  return type ? options : MASTER_OPTIONS
}

export const TAX_OPTIONS = [
  { label: 'VAT', value: 'VAT' },
  { label: 'GST', value: 'GST' }
]

export const INVOICE_ENTRY_ITEM = [
  { label: 'Product', value: 'Product' },
  { label: 'Description', value: 'Description' }
]

export const KIND_OPTIONS = [
  { label: 'Invoice', value: 'INV' },
  { label: 'Proforma Invoice', value: 'PRO' },
  { label: 'Inter Company', value: 'INC' },
  { label: 'Credit Notes', value: 'CRD' },
  { label: 'Debit Notes', value: 'DBT' }
]

export const INCOME_KIND_OPTIONS = [
  { label: 'Invoice', value: 'Invoice' },
  { label: 'Credit Note', value: 'Credit-Note' },
  { label: 'Debit Note', value: 'Debit-Note' }
]

export const EXPENSE_KIND_OPTIONS = [{ label: 'Invoice', value: 'Invoice' }]

export const MASTER_OPTION_TYPES = [
  { label: 'Income Type', value: 'IncomeType' },
  { label: 'Expense Type', value: 'ExpenseType' },
  { label: 'Account Type', value: 'AccountType' },
  { label: 'Payment Type', value: 'PaymentType' },
  { label: 'Unit Of Measurement', value: 'UnitOfMeasurement' },
  { label: 'Material Type', value: 'MaterialType' },
  { label: 'Material Group', value: 'MaterialGroup' },
  { label: 'Division', value: 'Division' },
  { label: 'Weight Unit', value: 'WeightUnit' },
  { label: 'Volume Unit', value: 'VolumeUnit' },
  { label: 'Material Status', value: 'MaterialStatus' },
  { label: 'Language Code', value: 'LanguageCode' },
  { label: 'Costing Type', value: 'CostingType' },
  { label: 'Sales Order Type', value: 'SalesOrderType' },
  { label: 'Purchase Order Type', value: 'PurchaseOrderType' },
  { label: 'Pay Term', value: 'PayTerm' },
  { label: 'Blood Group', value: 'BloodGroup' },
  { label: 'Nationality', value: 'Nationality' },
  { label: 'Marital Status', value: 'MaritalStatus' },
  { label: 'Gender', value: 'Gender' },
  { label: 'Type Of Visa', value: 'TypeOfVisa' },
  { label: 'Relationship', value: 'Relationship' },
  { label: 'Job Category', value: 'JobCategory' },
  { label: 'Pay Grade', value: 'PayGrade' },
  { label: 'Location', value: 'Location' },
  { label: 'Customs', value: 'Customs' },
  { label: 'Supplier Charges', value: 'SupplierCharges' }
]

export const INVOICE_STATUS_OPTIONS = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Sent', value: 'Sent' },
  { label: 'Partially Paid', value: 'PartiallyPaid' },
  { label: 'Paid', value: 'Paid' }
]

export const CLIENT_TYPE_OPTIONS = [
  { label: 'Customer', value: 'Customer' },
  { label: 'Vendor', value: 'Vendor' }
]

export const WAGE_MODE = [
  { label: 'Hourly', value: 'Hourly' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Monthly', value: 'Monthly' }
]

export const WAGE_TYPE = [
  { label: 'Basic', value: 'Basic' },
  { label: 'BasicDeduction', value: 'BasicDeduction' },
  { label: 'Allowance', value: 'Allowance' },
  { label: 'Addition', value: 'Addition' },
  { label: 'Calculation', value: 'Calculation' },
  { label: 'CalculateExempt', value: 'CalculateExempt' },
  { label: 'Deduction', value: 'Deduction' },
  { label: 'DeductionExempt', value: 'DeductionExempt' },
  { label: 'SubTotal', value: 'SubTotal' },
  { label: 'SubTotalEmployer', value: 'SubTotalEmployer' },
  { label: 'SubTotalBoth', value: 'SubTotalBoth' },
  { label: 'Tax', value: 'Tax' },
  { label: 'Total', value: 'Total' },
  { label: 'Header', value: 'Header' },
  { label: 'CalcEmployerContribution', value: 'CalcEmployerContribution' },
  { label: 'BaseWorkDays', value: 'BaseWorkDays' }
]

export const RATE_UNIT = [{ label: '%', value: '%' }]

export const BASE_FROM = [
  { label: 'AccidentInsUVG', value: 'AccidentInsUVG' },
  { label: 'AccidentInsUVGZ', value: 'AccidentInsUVGZ' },
  { label: 'Advance', value: 'Advance' },
  { label: 'AgencyDeduction', value: 'AgencyDeduction' },
  { label: 'ChildAllowance', value: 'ChildAllowance' },
  { label: 'DailyAllowance', value: 'DailyAllowance' },
  { label: 'DeductDailyAllowance', value: 'DeductDailyAllowance' },
  { label: 'ExpenseClaim', value: 'ExpenseClaim' },
  { label: 'LiabilityInsurance', value: 'LiabilityInsurance' },
  { label: 'LossOfPay', value: 'LossOfPay' },
  { label: 'LossOfPayDays ', value: 'LossOfPayDays' },
  { label: 'Mileage ', value: 'Mileage' },
  { label: 'NoOfChild ', value: 'NoOfChild' },
  { label: 'PensionFund ', value: 'PensionFund' },
  { label: 'PensionFundEmp ', value: 'PensionFundEmp' },
  { label: 'Rate ', value: 'Rate' },
  { label: 'SicknessPayInsurance ', value: 'SicknessPayInsurance' },
  { label: 'Others', value: 'Others' }
]

export const STATUS = [
  { label: 'Active', value: 'Active' },
  { label: 'InActive', value: 'InActive' }
]

export const USER_TYPE = [
  { label: 'Admin', value: 'Admin' },
  { label: 'User', value: 'User' }
]

export const INVOICE_ENTRY_TYPE = [
  { label: 'Product', value: 'Product' },
  { label: 'Description', value: 'Description' }
]

export const TAX_TYPE = [
  { label: 'VAT', value: 'VAT' },
  { label: 'GST', value: 'GST' }
]

export const CANTON_TYPE = [
  { label: 'Zurich', value: 'Zurich' },
  { label: 'Berne', value: 'Berne' },
  { label: 'Uri', value: 'Uri' },
  { label: 'Basel-Stadt', value: 'Basel-Stadt' }
]

export const ACCESS_ARR = [
  'add-company',
  'edit-company',
  'roles',
  'add-role',
  'edit-role',
  'users',
  'add-user',
  'edit-user',
  'currencies',
  'add-currency',
  'edit-currency',
  'exchange-rates',
  'add-exchange-rate',
  'edit-exchange-rate',
  'categories',
  'add-category',
  'edit-category',
  'options',
  'add-option',
  'edit-option',
  'custom-templates',
  'add-custom-template',
  'edit-custom-template',
  'master-upload',
  'numbering-series',
  'company-configurations',
  'clients',
  'add-client',
  'edit-client',
  'products',
  'add-product',
  'edit-product',
  'projects',
  'add-project',
  'edit-projects',
  'project-employee-rates',
  'add-project-employee-rate',
  'edit-project-employee-rate',
  'time-entries',
  'expense-claims',
  'time-reports',
  'time-reports-all',
  'warehouses',
  'add-warehouse',
  'edit-warehouse',
  'warehouse-products',
  'add-warehouse-product',
  'edit-warehouse-product',
  'sales-orders',
  'add-sales-order',
  'edit-sales-order',
  'add-sales-deliveries',
  'sales-invoices',
  'add-sales-invoice',
  'edit-sales-invoice',
  'purchase-orders',
  'add-purchase-order',
  'edit-purchase-order',
  'add-purchase-receipt',
  'purchase-invoices',
  'add-purchase-invoice',
  'edit-purchase-invoice',
  'goods-receipts',
  'add-goods-receipt',
  'edit-goods-receipt',
  'goods-issues',
  'add-goods-issue',
  'edit-goods-issue',
  'stock-checks',
  'add-stock-check',
  'edit-stock-check',
  'stock-transfers',
  'add-stock-transfer',
  'edit-stock-transfer',
  'material-reports',
  'add-material-report',
  'edit-material-report',
  'stock-reports',
  'add-stock-report',
  'edit-stock-report',
  'financial-years',
  'account-groups',
  'account-charts',
  'finances',
  'finance-postings',
  'journal-vouchers',
  'add-journal-voucher',
  'edit-journal-voucher',
  'incomes',
  'add-income',
  'edit-income',
  'from-timesheet',
  'expenses',
  'add-expense',
  'edit-expense',
  'upload-income-invoices',
  'upload-expense-invoices',
  'transmissions',
  'customer-transmissions',
  'employees',
  'add-employee',
  'edit-employee',
  'taxdata',
  'holiday-calendar',
  'MWST',
  'yearlyPayroll',
  'paymaster',
  'payrolls'
]

export const ORDER_TYPES = [
  { label: 'Adhoc', value: 'adhoc' },
  { label: 'Purchase', value: 'purchase' },
  { label: 'Sales', value: 'sales' }
]

export const TRANSACTION_STATUS = [
  { label: 'Accepted', value: 'Accepted' },
  { label: 'Rejected', value: 'Rejected' }
]

export const DEFAULT_TRANSACTIONS = {
  materialCode: '',
  materialDescription: '',
  unit: '',
  quantity: '',
  warehouse: '',
  location: '',
  rack: '',
  status: 'Accepted'
}
export const ACCOUNT_TYPE = [
  { label: 'Assets', value: 'Assets' },
  { label: 'Liabilities', value: 'Liabilities' },
  { label: 'Equity', value: 'Equity' },
  { label: 'Revenue', value: 'Revenue' },
  { label: 'Cost of Goods Sold', value: 'Cost of Goods Sold' },
  { label: 'Operating Expenses', value: 'Operating Expenses' },
  { label: 'Taxes Paid', value: 'Taxes Paid' },
  { label: 'Other Expenses', value: 'Other Expenses' },
  { label: 'Depreciation/Tax/Interest', value: 'Depreciation-Tax-Interest' },
  { label: 'Fixed Assets', value: 'Fixed Assets' },
  { label: 'Intangibles', value: 'Intangibles' },
  { label: 'Investments', value: 'Investments' },
  { label: 'Trade and Other Debtors', value: 'Trade and Other Debtors' },
  { label: 'Bank and cash', value: 'Bank and cash' },
  { label: 'Trade and other creditors < 1 year', value: 'Trade and other creditors < 1 year' },
  { label: 'Creditors > 1 year', value: 'Creditors > 1 year' },
  { label: 'Provision for liabilities & charges', value: 'Provision for liabilities & charges' },
  { label: 'Share Capital', value: 'Share Capital' },
  { label: 'Reserves', value: 'Reserves' }
]

export const FINNACIAL_REPORT = [
  { label: 'Balance Sheet', value: 'Balance Sheet' },
  { label: 'Profit & Loss', value: 'Profit & Loss' }
]

export const NUMERIC_RANGE = [
  { label: '100-199', value: '100-199' },
  { label: '200-299', value: '200-299' },
  { label: '300-399', value: '300-399' },
  { label: '400-499', value: '400-499' },
  { label: '500-599', value: '500-599' },
  { label: '600-699', value: '600-699' },
  { label: '700-799', value: '700-799' },
  { label: '800-899', value: '800-899' }
]

export const getDefaultStockTransfer = (props) => ({
  materialCode: '',
  materialDescription: '',
  unit: '',
  quantity: '',
  status: 'Accepted',
  from: {
    warehouse: '',
    location: '',
    rack: '',
    ...props
  },
  to: {
    warehouse: '',
    location: '',
    rack: '',
    ...props
  }
})

export const TITLE = [
  { label: 'Mr', value: 'Mr' },
  { label: 'Ms', value: 'Ms' }
]

export const DEFAULT_ADDRESS_FIELDS = {
  street: '',
  city: '',
  state: '',
  postalCode: '',
  country: ''
}

export const DEFAULT_CONTACT_PERSONS = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  primary: false
}

export const DEFAULT_ORDER_DETAILS = {
  position: 10,
  materialCode: '',
  materialDescription: '',
  unit: '',
  orderedQuantity: '',
  requestedDate: '',
  price: '',
  amount: 0,
  discountType: '%',
  discount: '',
  chargeType: '%',
  charge: '',
  netAmount: '',
  taxType: '',
  tax: '',
  taxAmount: '',
  deliveryStatus: 'Open',
  invoiceStatus: 'Open',
  deliveredQuantity: 0,
  invoicedQuantity: 0,
  toBeDeliveredQuantity: '',
  toBeInvoicedQuantity: ''
}

export const MONTHS = [
  { label: 'Jan', value: 'Jan' },
  { label: 'Feb', value: 'Feb' },
  { label: 'Mar', value: 'Mar' },
  { label: 'Apr', value: 'Apr' },
  { label: 'May', value: 'May' },
  { label: 'Jun', value: 'Jun' },
  { label: 'Jul', value: 'Jul' },
  { label: 'Aug', value: 'Aug' },
  { label: 'Sep', value: 'Sep' },
  { label: 'Oct', value: 'Oct' },
  { label: 'Nov', value: 'Nov' },
  { label: 'Dec', value: 'Dec' }
]

export const ORDER_INVOICE_STATUS_OPTIONS = [
  { label: 'Created', value: 'Created' },
  { label: 'Partially Paid', value: 'PartiallyPaid' },
  { label: 'Paid', value: 'Paid' }
]

export const INVOICE_BASE_OPTIONS = [
  { label: 'Sales Order', value: 'Sales Order' },
  { label: 'Delivery', value: 'Delivery' }
]

export const DEFAULT_JOURNAL_ACCOUNTS = {
  cr: {
    position: 10,
    code: '',
    name: '',
    type: 'CR',
    amount: ''
  },
  dr: {
    position: 10,
    code: '',
    name: '',
    type: 'DR',
    amount: ''
  }
}

export const ORDER_DELIVERY_STATUS_OPTIONS = [
  { label: 'Draft', value: 'Draft' },
  { label: 'Delivered', value: 'Delivered' }
]

export const DEFAULT_WAREHOUSES = {
  warehouse: '',
  description: '',
  location: '',
  locationDescription: '',
  rack: '',
  rackDescription: ''
}

export const getOrderNames = (type) => {
  const isSales = type === 'sales'
  const isPurchase = type === 'purchase'

  return {
    type,
    isSales,
    isPurchase,
    typeName: type?.capitalize(),
    deliveryName: isSales ? 'Delivery' : isPurchase ? 'Goods Receipt' : '',
    deliveryShortName: isSales ? 'Delivery' : isPurchase ? 'Receipt' : '',
    deliver: isSales ? 'Deliver' : isPurchase ? 'Receive' : '',
    delivered: isSales ? 'Delivered' : isPurchase ? 'Received' : '',
    clientName: isSales ? 'Customer' : isPurchase ? 'Vendor' : '',
    poNo: isSales ? 'PO no./Ref no.' : isPurchase ? 'Reference no.' : '',
    poDate: isSales ? 'PO Date' : isPurchase ? 'Reference Date' : '',
    salesPerson: isSales ? 'Sales Person' : isPurchase ? 'Buyer' : '',
    orderAddress: isSales ? 'Order Address' : isPurchase ? 'Supplier Address' : '',
    deliveryAddress: isSales ? 'Delivery Address' : isPurchase ? 'Delivered To' : '',
    so: isSales ? 'SO' : isPurchase ? 'PO' : '',
    del: isSales ? 'Del' : isPurchase ? "Rec'd" : '',
    goods: isSales ? 'Issue' : isPurchase ? 'Receipt' : '',
    getStatus: (status) => (isSales ? status : isPurchase ? status?.replace(/Delivered/g, 'Received') : '')
  }
}

export const TAX_CATEGORIES = [
  {
    label: 'Standard',
    value: 'Standard'
  },
  {
    label: 'Export',
    value: 'Export'
  },
  {
    label: 'Intercompany',
    value: 'Intercompany'
  }
]

export const TAX_TYPES = [
  {
    label: 'Normal VAT',
    value: 'Normal VAT',
    categories: ['Standard']
  },
  {
    label: 'Exempt',
    value: 'Exempt',
    categories: ['Standard']
  },
  {
    label: 'Zero VAT',
    value: 'Zero VAT',
    categories: ['Standard', 'Export', 'Intercompany']
  }
]

export const TIMEZONES = moment.tz
  .names()
  .map((item) => ({
    value: item,
    label: `(GMT${moment.tz(item).format('Z')}) ${new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZone: item, timeZoneName: 'long' })
      .substring(4)} (${item})`
  }))
  .sort((a, b) => {
    const [ahh, amm] = a.label.split('GMT')[1].split(')')[0].split(':')
    const [bhh, bmm] = b.label.split('GMT')[1].split(')')[0].split(':')

    return +ahh * 60 + amm - (+bhh * 60 + bmm)
  })

export const PRICE_TYPES = [
  {
    label: 'Price',
    value: 'Price'
  },
  {
    label: 'Charge',
    value: 'Charge'
  }
]

export const OPERATION_TYPES = [
  {
    label: 'Air',
    value: 'Air'
  },
  {
    label: 'Sea',
    value: 'Sea'
  },
  {
    label: 'Land',
    value: 'Land'
  }
]

export const AIR_OPERATIONS = [
  {
    label: 'Back to Back',
    value: 'Back to Back'
  },
  {
    label: 'Direct',
    value: 'Direct'
  },
  {
    label: 'Deconsolidation',
    value: 'Deconsolidation'
  }
]

export const SEA_OPERATIONS = [
  {
    label: 'FCL',
    value: 'FCL'
  },
  {
    label: 'LCL',
    value: 'LCL'
  },
  {
    label: 'RORO',
    value: 'RORO'
  },
  {
    label: 'BULK',
    value: 'BULK'
  },
  {
    label: 'Others',
    value: 'Others'
  }
]

export const LAND_OPERATIONS = [
  {
    label: 'Overland (FTL, LTL)',
    value: 'Overland (FTL, LTL)'
  },
  {
    label: 'Inland (Containers, General Cargo)',
    value: 'Inland (Containers, General Cargo)'
  }
]

export const getOperationTypes = (type) => {
  switch (type) {
    case 'Air':
      return AIR_OPERATIONS
    case 'Sea':
      return SEA_OPERATIONS
    case 'Land':
      return LAND_OPERATIONS
    default:
      return []
  }
}

export const TERMS_CONDITIONS_DOCUMENT_TYPES = [
  {
    label: 'Offer',
    value: 'Offer'
  },
  {
    label: 'Booking',
    value: 'Booking'
  },
  {
    label: 'Bill of Landing',
    value: 'Bill of Landing'
  },
  {
    label: 'Job Creation',
    value: 'Job Creation'
  },
  {
    label: 'Sales Quotation',
    value: 'Sales Quotation'
  }
]

export const ACCOUNT_SETUP_TYPES = [
  {
    label: 'Invoice',
    value: 'Invoice'
  },
  {
    label: 'Credit Note',
    value: 'Credit-Note'
  },
  {
    label: 'Debit Note',
    value: 'Debit-Note'
  },
  {
    label: 'Expense',
    value: 'Expense'
  }
]

export const DEFAULT_PACKAGE_ITEMS = {
  description: '',
  quantity: '',
  unit: '',
  price: '',
  amount: 0,
  netAmount: '',
  currency: '',
  chargeType: '%',
  charge: '',
  taxType: '',
  tax: '',
  taxAmount: ''
}

export const DEFAULT_PACKAGE_DETAILS = {
  position: 10,
  packageType: '',
  length: '',
  breadth: '',
  height: '',
  unit: '',
  weight: '',
  weightUnit: '',
  volume: '',
  volumeUnit: '',
  items: [DEFAULT_PACKAGE_ITEMS]
}

export const LBH_UNITS = [
  {
    label: 'MT',
    value: 'MT'
  },
  {
    label: 'CM',
    value: 'CM'
  },
  {
    label: 'MM',
    value: 'MM'
  }
]

export const WEIGHT_UNITS = [
  {
    label: 'KG',
    value: 'KG'
  },
  {
    label: 'GM',
    value: 'GM'
  },
  {
    label: 'TO',
    value: 'TO'
  }
]

export const VOLUME_UNITS = [
  {
    label: 'M3',
    value: 'M3'
  },
  {
    label: 'CM3',
    value: 'CM3'
  },
  {
    label: 'MM3',
    value: 'MM3'
  }
]

export const PO_TYPES = [
  { label: 'Standard', value: 'Standard' },
  { label: 'Import', value: 'Import' }
]

export const DEFAULT_FORWARDER_CHARGES = {
  chargeType: '',
  chargeValue: ''
}

export const TYPE_OF_PAYMENT = [
  { label: 'Payment', value: 'Payment' },
  { label: 'Advance', value: 'Advance' }
]
