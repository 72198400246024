import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getMasterCurrencies } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import { useSelector } from '../../../Hooks/redux'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { DEFAULT_JOURNAL_ACCOUNTS } from '../../../Util/Options'
import { journalVoucherSchema } from '../../../Util/validationSchema'
import SingleVoucher from './SingleVoucher'

function JournalVouchers({
  values,
  setValues,
  setFieldValue,
  submitForm,
  match: {
    params: { id }
  }
}) {
  const [currencies, setCurrencies] = useState([])
  const history = useHistory()
  const { companyInfo } = useSelector((state) => state.users)

  const disabled = id && values.status !== 'Created'

  const getData = () => {
    getMasterCurrencies().then((data) => setCurrencies(data))

    if (id) {
      apiClient.get(`journal-vouchers/${id}`).then(({ status, data }) => {
        if (status === 200) {
          setValues({ ...values, ...data })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (values.currency && companyInfo.currency && !disabled) {
      apiClient
        .get('exchange-rates/get-rate', {
          params: { baseCurrency: values.currency, toCurrency: companyInfo.currency }
        })
        .then(({ data }) => {
          if (data && data.result) {
            setFieldValue('exchangeRate', data.result.rate || values.exchangeRate || 1)
          }
        })
    }
  }, [values.currency])

  const handleApprove = () => {
    apiClient.put(`journal-vouchers/approve/${id}`).then(({ status }) => {
      if (status === 200) {
        history.replace('/app/journal-vouchers')
      }
    })
  }

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="Journal Voucher">
            <Panel title="Journal Voucher">
              <Row gutter={[20, 10]}>
                {id && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field name="transactionNo" label="JV Number" disabled />
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="documentDate" label="Document Date" disabled />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="postingDate" label="Posting Date" disabled />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="currency"
                      label="Currency"
                      options={currencies}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="exchangeRate" label="ExchangeRate" options={[]} disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="reference" label="Reference" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="notes" label="Notes" disabled={disabled} />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Journal Voucher Lists">
              <FieldArray
                name="accountList"
                defaultValues={DEFAULT_JOURNAL_ACCOUNTS}
                additionalValues={{ disabled }}>
                {SingleVoucher}
              </FieldArray>
            </Panel>
          </PanelLayout>
          <div className="save-changes">
            {!disabled && (
              <>
                <Button type="submit" variant="primary">
                  {id ? 'Update' : 'Save'}
                </Button>
                <span>or</span>
                {id ? (
                  <Button onClick={handleApprove}>Approve</Button>
                ) : (
                  <Button
                    onClick={() => {
                      setFieldValue('status', 'Approved')
                      submitForm()
                    }}>
                    Save and Approve
                  </Button>
                )}
                <span>or</span>
              </>
            )}
            <Link to="/app/journal-vouchers">
              <Button>
                <ArrowLeftOutlined /> Back to journal list
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    documentDate: moment(),
    postingDate: moment(),
    currency: '',
    exchangeRate: '',
    reference: '',
    notes: '',
    accountList: [DEFAULT_JOURNAL_ACCOUNTS]
  }),
  validationSchema: journalVoucherSchema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    data.documentDate = moment(data.documentDate).utc().startOf('day')
    data.postingDate = moment(data.postingDate).utc().startOf('day')

    if (id) {
      apiClient.put(`journal-vouchers/${id}`, data).then(({ status }) => {
        if (status === 200) {
          history.push('/app/journal-vouchers')
        }
      })
    } else {
      apiClient.post('journal-vouchers', data).then(({ status }) => {
        if (status === 201) {
          history.replace('/app/journal-vouchers')
        }
      })
    }
  }
})(JournalVouchers)
