import { ArrowLeftOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { getOrderNames } from '../../../Util/Options'
import { GET_DATA, SET_DATA } from '../../../Util/Util'
import CreateInvoiceFilter from './CreateInvoiceFilter'

export default function CreateInvoice() {
  const [orderDetails, setOrderDetails] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedResultsKeys, setSelectedResultsKeys] = useState([])
  const [orderInfo, setOrderInfo] = useState('')
  const { type } = useParams()
  const { typeName, orderAddress, clientName } = getOrderNames(type)

  const history = useHistory()

  const handleSearch = ({ docType, ...params }) => {
    Object.keys(_.pick(params, ['orderDateFrom', 'orderDateTo'])).forEach((key) => {
      if (params[key]) {
        params[key] = moment(params[key]).utc().startOf('day').toISOString()
      }
    })
    params.type = type

    apiClient.get(`${docType}/search`, { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA(`create${typeName}Invoice.filterData`, { docType, ...params })
        setSearchResults(data)
        setOrderDetails([])
        setSelectedRowKeys([])
        setSelectedResultsKeys([])
      }
    })
  }

  useEffect(() => {
    const filterCache = GET_DATA(`create${typeName}Invoice.filterData`)

    if (filterCache) {
      handleSearch(filterCache)
    }
  }, [])

  const searchResultColumns = [
    {
      title: `${clientName} no`,
      dataIndex: 'clientNo'
    },
    {
      title: `${clientName} Name`,
      dataIndex: 'clientInfo',
      render: (clientInfo) => clientInfo.name
    },
    {
      title: `${typeName} order`,
      dataIndex: 'orderNo'
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType'
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: `${orderAddress}`,
      dataIndex: 'billingAddress',
      render: (value) =>
        _.compact([value.street, value.city, value.state, value.postalCode, value.country]).join(', ')
    }
  ]

  const orderDetailsColumns = [
    {
      title: `${clientName} no`,
      dataIndex: 'clientNo'
    },
    {
      title: `${typeName} order`,
      dataIndex: 'orderNo'
    },
    {
      title: 'Position',
      dataIndex: 'position'
    },
    {
      title: 'Delivery details',
      dataIndex: 'deliveryDetails'
    },
    {
      title: 'Material',
      dataIndex: 'materialCode'
    },
    {
      title: 'Material Desc',
      dataIndex: 'materialDescription'
    },
    {
      title: 'UOM',
      dataIndex: 'unit'
    },
    {
      title: 'Open Qty',
      dataIndex: 'toBeInvoicedQuantity'
    },
    {
      title: 'Price',
      dataIndex: 'price'
    },
    {
      title: 'Amount',
      dataIndex: 'amount'
    },
    {
      title: 'Req. Date',
      dataIndex: 'requestedDate',
      render: (date) => moment(date).format('YYYY-MM-DD')
    },
    {
      title: 'Disc Amt',
      dataIndex: 'discount',
      render: (text, row) => (row.discountType === '%' ? `${text}%` : text || '-')
    },
    {
      title: 'Charge Amt',
      dataIndex: 'charge',
      render: (text, row) => (row.chargeType === '%' ? `${text}%` : text || '-')
    },
    {
      title: `Net ${typeName} Amt`,
      dataIndex: 'netAmount'
    }
  ]

  return (
    <FilterLayout
      filterData={GET_DATA(`create${typeName}Invoice.filterData`)}
      filter={<CreateInvoiceFilter key={type} onSubmit={handleSearch} />}>
      <div className="inner-contents px-4">
        <PanelLayout title={`Create ${typeName} Invoice`}>
          <h2 className="title my-3">Search Results</h2>
          <TableBox
            rowSelection={{
              selectedRowKeys: selectedResultsKeys,
              onChange: (rowIds) => {
                const selectedRows = []

                searchResults
                  .filter((item) => rowIds.includes(item.id))
                  .forEach((searchResult) => {
                    const arr = []

                    searchResult.orderDeliveries.forEach((delivery) => {
                      delivery.orderDetails.forEach((orderDetail) => {
                        arr.push({
                          position: orderDetail.position,
                          deliveryDetail: `${delivery.deliveryNo}#${orderDetail.deliveryPosition}`
                        })
                      })
                    })

                    searchResult.orderDetails.forEach((orderDetail) => {
                      selectedRows.push({
                        ..._.pick(searchResult, ['client', 'clientNo', 'clientAlt', 'orderNo']),
                        ..._.omit(orderDetail, ['id', '_id']),
                        deliveryDetails: arr.find((item) => item.position === orderDetail.position)
                          ? arr.map((item) => item.deliveryDetail).join(', ')
                          : '',
                        id: orderDetail._id
                      })
                    })
                  })
                setOrderInfo(searchResults.find((item) => rowIds.includes(item.id)))
                setOrderDetails(selectedRows)
                setSelectedResultsKeys(rowIds)
              }
            }}
            columns={searchResultColumns}
            dataSource={searchResults}
          />
          <h2 className="title my-3">Order Details</h2>
          <TableBox
            rowSelection={{
              selectedRowKeys,
              onChange: setSelectedRowKeys
            }}
            columns={orderDetailsColumns}
            dataSource={orderDetails}
          />
        </PanelLayout>
        <div className="save-changes">
          <Button
            variant="primary"
            disabled={selectedRowKeys.length === 0}
            onClick={() => {
              history.push({
                pathname: '/app/add-invoice',
                state: {
                  type,
                  orderInfo,
                  orderDetails: orderDetails.filter((val) => selectedRowKeys.includes(val.id))
                }
              })
            }}>
            Create Invoice
          </Button>
          <Link to={`/app/order-invoices/${type}`}>
            <Button>
              <ArrowLeftOutlined /> Back to {type} invoices
            </Button>
          </Link>
        </div>
      </div>
    </FilterLayout>
  )
}
