import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'

const Schema = Yup.object().shape({
  name: Yup.string().required(),
  unit: Yup.string().required(),
  unitPrice: Yup.number().required(),
  currency: Yup.string().required()
})

function ProductForm({
  setValues,
  values,
  match: {
    params: { id }
  }
}) {
  const getData = () => {
    if (id) {
      apiClient.get(`products/get/${id}`).then(({ data }) => {
        if (data && data.result) {
          setValues({ ...values, ...data.result })
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const selectAfter = (
    <Field
      as="select"
      name="taxFormat"
      options={[
        { label: '%', value: '%' },
        { label: '№', value: '' }
      ]}
      className="select-after"
      showArrow={false}
    />
  )

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 20 }}>
          <PanelLayout title="Product">
            <Panel title="Product Details">
              <Row gutter={[20, 10]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="name" label="Name" altInput />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                  <div className="form-field">
                    <Field name="description" label="Description" as="textarea" rows={1} altInput />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 4 }}>
                  <div className="form-field">
                    <Field
                      name="unit"
                      label="Unit"
                      as="paged-select"
                      endPoint="/options/getAllActive"
                      params={{ type: 'UnitOfMeasurement' }}
                      optionLabel="label"
                      optionValue="value"
                    />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 4 }}>
                  <div className="form-field">
                    <Field name="unitPrice" label="Unit Price" />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Panel title="Other Details">
              <Row gutter={[20, 10]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                  <div className="form-field">
                    <Field name="discount" label="Discount" addonAfter={selectAfter} />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                  <div className="form-field">
                    <Field name="charge" label="Charge" addonAfter={selectAfter} />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                  <div className="form-field">
                    <Field name="tax" label="Tax" addonAfter={selectAfter} />
                  </div>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                  <div className="form-field">
                    <Field
                      name="currency"
                      label="Currency"
                      as="paged-select"
                      endPoint="/currencies/getAllActive"
                      optionValue="code"
                    />
                  </div>
                </Col>
              </Row>
            </Panel>
          </PanelLayout>

          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>
            <span>or</span>
            <Link to="/app/products">
              <Button>
                <ArrowLeftOutlined /> Back
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    unit: '',
    unitPrice: '',
    discount: '',
    discountFormat: '%',
    charge: '',
    chargeFormat: '%',
    tax: '',
    taxFormat: '%',
    currency: ''
  }),
  validationSchema: Schema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    if (id) {
      apiClient.put(`products/update/${id}`, data).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/products')
        }
      })
    } else {
      apiClient.post('products/add', data).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/products')
        }
      })
    }
  }
})(ProductForm)
