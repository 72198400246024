import { ArrowLeftOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA } from '../../../Util/Util'
import CreateCustomsClearanceFilter from './CreateCustomsClearanceFilter'

export default function CreateCustomsClearances() {
  const [customsClearances, setCustomsClearances] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('createCustomsClearances.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('order-deliveries/customs', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('createCustomsClearances.filterData', params)
        setCustomsClearances(data)
      }
    })
  }

  useEffect(() => {
    const filterCache = GET_DATA('createCustomsClearances.filterData')

    if (filterCache) {
      getData(filterCache)
    }
  }, [])

  const columns = [
    {
      title: 'Goods Receipt No',
      dataIndex: 'deliveryNo'
    },
    {
      title: 'Vendor',
      dataIndex: 'clientName'
    },
    {
      title: 'Goods Receipt Date',
      dataIndex: 'deliveryDate',
      render: (value) => moment(value).format('YYYY-MM-DD')
    },
    {
      title: 'Supplier Address',
      dataIndex: 'billingAddress',
      render: (value) =>
        _.compact([value.street, value.city, value.state, value.postalCode, value.country]).join(', ')
    }
  ]

  return (
    <FilterLayout
      filterData={GET_DATA('createCustomsClearances.filterData')}
      filter={<CreateCustomsClearanceFilter onSubmit={getData} />}>
      <div className="inner-contents px-4">
        <PanelLayout title="Create Customs Clearances">
          <TableBox
            dataSource={customsClearances}
            columns={columns}
            onSelect={setSelectedRows}
            selectedRows={selectedRows}
          />
        </PanelLayout>
        <div className="save-changes">
          <Button
            variant="primary"
            disabled={selectedRows.length === 0}
            onClick={() => {
              const arr = []

              selectedRows.forEach((delivery) => {
                delivery.orderDetails.forEach((orderDetail) => {
                  arr.push({
                    ..._.pick(delivery, [
                      'deliveryNo',
                      'client',
                      'clientAlt',
                      'remarks',
                      'currency',
                      'exchangeRate',
                      'createdBy',
                      'clientName',
                      'deliveryDate',
                      'billingAddress',
                      'shippingAddress',
                      'contactPerson'
                    ]),
                    ..._.omit(orderDetail, ['id', '_id']),
                    id: orderDetail._id
                  })
                })
              })

              const totalDeliveryValue = _.sumBy(arr, 'deliveryValue')

              history.push({
                pathname: '/app/add-customs-clearance',
                state: {
                  currency: GET_DATA('createCustomsClearances.filterData').currency,
                  deliveries: _.sortBy(
                    arr.map((item) => ({
                      ...item,
                      disabled: false,
                      clearanceRate: item.deliveryValue / totalDeliveryValue
                    })),
                    'orderNo'
                  )
                }
              })
            }}>
            Create Customs Clearance
          </Button>
          <Link to="/app/customs-clearances">
            <Button>
              <ArrowLeftOutlined /> Back to customs clearance list
            </Button>
          </Link>
        </div>
      </div>
    </FilterLayout>
  )
}
