import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { FINNACIAL_REPORT, STATUS } from '../../../Util/Options'

const Schema = Yup.object().shape({
  rangeStart: Yup.number().required(),
  rangeEnd: Yup.number().moreThan(Yup.ref('rangeStart')).required(),
  name: Yup.string().required(),
  financialReport: Yup.string().required(),
  status: Yup.string().required()
})

function AccountGroupForm({
  setValues,
  match: {
    params: { id }
  },
  history
}) {
  const getData = () => {
    if (id) {
      apiClient.get(`account-groups/get/${id}`).then(({ data }) => {
        if (data && data.result) {
          setValues(data.result)
        }
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Form>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout title="Account Group">
            <Panel title="Account Group">
              <Row gutter={[10, 10]}>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="form-field">
                    <Field label="Range Start" name="rangeStart" disabled={!!id} />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="form-field">
                    <Field label="Range End" name="rangeEnd" disabled={!!id} />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field label="Group Name" name="name" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      label="Financial Report"
                      name="financialReport"
                      as="select"
                      options={FINNACIAL_REPORT}
                      disabled={!!id}
                    />
                  </div>
                </Col>
              </Row>
            </Panel>
            <Row>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="form-field">
                  <Field label="Status" name="status" as="select" options={STATUS} />
                </div>
              </Col>
            </Row>
          </PanelLayout>

          <div className="save-changes">
            <Button type="submit" variant="primary">
              {id ? 'Update' : 'Save'}
            </Button>

            <Link to="#" onClick={() => history.goBack()}>
              <Button>
                <ArrowLeftOutlined /> Back
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    rangeStart: '',
    rangeEnd: '',
    accountType: '',
    financialReport: '',
    status: 'Active'
  }),
  validationSchema: Schema,
  handleSubmit: (
    data,
    {
      props: {
        match: {
          params: { id }
        },
        history
      }
    }
  ) => {
    data.range = `${data.rangeStart}-${data.rangeEnd}`

    if (id) {
      apiClient.put(`account-groups/update/${id}`, { ...data }).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/account-groups')
        }
      })
    } else {
      apiClient.post('account-groups/add', data).then(({ data }) => {
        if (data && data.result) {
          history.push('/app/account-groups')
        }
      })
    }
  }
})(AccountGroupForm)
