import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'

function JobCreationFilter({ values, setValues, filterData }) {
  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field name="jobCreationNo" label="Job Creation No" />
      </div>
      <div className="form-fields">
        <Field as="date" name="creationDate" label="Creation Date" />
      </div>
      <div className="form-fields">
        <Field name="bookingNo" label="Booking No" />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" />
          Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    jobCreationNo: '',
    creationDate: '',
    bookingNo: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(JobCreationFilter)
