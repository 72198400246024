import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import TableBox from '../../../../Components/TableBox/TableBox'
import FilterLayout from '../../../../Layout/FilterLayout'
import TableLayout from '../../../../Layout/TableLayout'
import apiClient from '../../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../../Util/Util'
import PackagesTypeFilter from './PackagesTypeFilter'

export default function PackagesTypes() {
  const [packagesTypes, setPackagesTypes] = useState([])
  const history = useHistory()

  const getData = (params = {}) => {
    const filterCache = GET_DATA('packagesTypes.filterData') || {}
    params = { ...filterCache, ...params }

    apiClient.get('packages-types', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA('packagesTypes.filterData', { ...params, ...data.pageData })
        setPackagesTypes(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const onDelete = (id) => {
    apiClient.delete(`packages-types/${id}`).then(({ status }) => {
      if (status === 204) {
        setPackagesTypes(packagesTypes.filter((item) => item.id !== id))
      }
    })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        {validateAccess('edit-packages-type') && (
          <li>
            <Link to={`/app/edit-packages-type/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess('edit-packages-type') && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'L,B,H Unit',
      dataIndex: 'unit'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: 'Packages Type',
        onClick: () => history.push('/app/add-packages-type'),
        access: 'add-packages-type'
      }}
      filterData={GET_DATA('packagesTypes.filterData')}
      filter={<PackagesTypeFilter onSubmit={getData} />}>
      <TableLayout title="Packages Types Overview" exportUrl="packages-types/export">
        <TableBox
          dataSource={packagesTypes}
          columns={columns}
          pageData={GET_DATA('packagesTypes.filterData')}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
