export const lang = { lang: 'en' }

const translations = {
  'Contact Us': {
    ar: 'اتصل بنا'
  },
  'About Us': {
    ar: 'معلومات عنا'
  },
  Pricing: {
    ar: 'التسعير'
  },
  Modules: {
    ar: 'الوحدات'
  },
  Login: {
    ar: 'الوحدات'
  },
  'Get your Business enabled with E-Invoicing solutions in 2 hours for $22 per month.': {
    ar: 'احصل على تجارتك مع حلول الفواتير الإلكترونية في ساعتين مقابل 22 ﷼ دولارات شهريا.'
  },
  'A compact and easily configurable solution to use': {
    ar: 'حل مضغوط و سهل التكوين للاستخدام'
  },
  'End-to-end invoicing solutions for your Business': {
    ar: 'إذا كان لديك تخطيط موارد المشاريع (ERP) بالفعل ، فقم بدمج حل الفواتير الإلكترونية الخاص بنا بسرعة'
  },
  'If you already have an ERP, integrate our e-invoicing solutions quickly': {
    ar: ' إذا كان لدیك تخطیط موارد المشاریع (ERP) بالفعل ، فقم بدمج حل الفواتیر الإلكترونیة الخاص بنا بسرعة'
  },
  'Automatic upload of invoices possible': {
    ar: 'إمكانية التحميل التلقائي للفواتير'
  },
  'Zero infrastructure costs': {
    ar: 'تكاليف البنية التحتية صفر'
  },
  'Get Quote': {
    ar: 'الحصول على الاقتباس'
  },
  'Its easy and simple': {
    ar: 'إنه سهل و بسيط'
  },
  'Our solutions to your Business': {
    ar: 'م حل'
  },
  'Cloud ERP Solutions': {
    ar: 'حلول تخطیط موارد المؤسسات السحابیة'
  },
  'Pay as you use.': {
    ar: ' ادفع كما تستخدم'
  },
  'Zero infrastructure cost': {
    ar: ' تكلفة البنیة التحتیة صفر'
  },
  'On Premises': {
    ar: 'في أماكن العمل'
  },
  'ERP in your infrastructure': {
    ar: ' تخطیط موارد المؤسسات في البنیة التحتیة الخاصة بكم'
  },
  'Training and support': {
    ar: 'التدریب و الدعم'
  },
  'Interface with your ERP': {
    ar: 'واجھة مع نظام تخطیط موارد المؤسسات الخاص بكم'
  },
  'If you have ERP, we can integrate our solutions': {
    ar: 'إذا كان لدیك نظام تخطیط موارد المؤسسات (ERP)، (فیمكننا دمج حلولنا'
  },
  'SAP, Navision, Dynamics': {
    ar: ' الأنظمة و التطبیقات و المنتجات في معالجةالبیانات (SAP ،(نافیجن ، دینامیكس'
  },
  'Discover the Easy-to-Use Cloud ERP Software Built for Small and Medium Enterprise Companies': {
    ar: 'اكتشف برنامج تخطیط موارد المؤسسات السحابیة سھل الاستخدام المصمم لشركات المؤسسات لصغیرة و المتوسطة'
  },
  'See how you can manage costs in real-time with the incredibly easy-to-use ERP designed for mobile and desktop users. Built for the cloud and industry ready, get the power of unlimited office and field teams connecting with financials, inventory, dashboards, and more.':
    {
      ar: 'أنظر على كیفیة إدارة التكالیف في الوقت الفعلي باستخدام تخطیط موارد المؤسسات سھل الاستخدام بشكل لا یصدق و المصمم لمستخدمي الأجھزة المحمولة و سطح المكتب'
    },
  'Free up time, focus on growth': {
    ar: 'و على ركز ، الوقت ح'
  },
  'Accqrate streamlines your business and automates your Business processes. Scale your business efficiently through a pick and pack model. Pay per usage with zero risks.':
    {
      ar: 'یبسط تجارتك و أتمتة عملیات تجارتكم.'
    },
  'E-Invoicing Solution': {
    ar: 'حل الفواتیز الإلكترونیة'
  },
  'Create e-invoices for your Business partners and tax authorities. We also integrate with your existing ERP':
    {
      ar: 'إنشاء فواتیر إلكترونیة لشركائكم التجاریین والسلطات الضریبیة.'
    },
  'See more details': {
    ar: 'أنظر المزید من التفاصیل'
  },
  'Digital signatures': {
    ar: 'التوقیعات الرقمیة'
  },
  'Digital signature through blockchain, send invoices to partners or tax authorities, integration with SAP':
    {
      ar: 'التوقیع الرقمي من خلال سلسلة الكتل ، إرسال الفواتیر إلى الشركاء أو السلطات الضریبیة ، و التكامل مع الأنظمة و التطبیقات و المنتجات في معالجة البیانات (SAP)'
    },
  'Finance Management': {
    ar: 'ادارة مالیة'
  },
  'Chart of accounts, Account Setup, Financial books, Accounts receivable and payable, General ledger and Dashboard':
    {
      ar: 'دلیل الحسابات ، إعدادات الحساب ، الدفاتر المالیة ،الذمم المدینة و الدائنة ، دفتر الأستاذ العام ولوحةالمعلومات'
    },
  'Service Management': {
    ar: 'إدارة الخدمات'
  },
  'Create your services, projects, timesheets, approvals, invoices, expense claims, finance books, reports and dashboard':
    {
      ar: 'قم بإنشاء الخدمات و المشاریع و الجداول الزمنیة و الموافقات و الفواتیر و مطالبات النفقات و الدفاتر المالیة و التقاریر ولوحة المعلومات'
    },
  'Logistics Management': {
    ar: 'إدارة اللوجستیات'
  },
  'Order to cash, procure to pay, Materials management, Inventory Management, Availability checks': {
    ar: 'الأمر بالنقد ، و التدبیر للدفع ، و إدارة المواد ، و إدارة المخزون ، و شیكات التوفر'
  },
  'HR and Payroll': {
    ar: 'الموارد البشریة و الرواتب '
  },
  'Employee hire to retire, flexible payroll configuration, manage payslips, leave management, workflows': {
    ar: 'تعیین الموظفین للتقاعد ، تكوین كشوف المرتبات المرن ، إدارة كشوف المرتبات ، إدارة الإجازات سیر العمل'
  },
  'Book a live demo, tailored to you': {
    ar: 'حجز عرضًا توضیحیًا مباشرًا مصممًا لك'
  },
  'Fill in the form for the demo of the application with our experts. We can help you decide whether Accqrate is the right choice for your business':
    {
      ar: 'املأ نموذج العرض التوضیحي للتطبیق مع خبرائنا'
    },
  'By completing this form, I agree that Accqrate may keep me informed of its products, services and offerings. To view our privacy policy':
    {
      ar: 'أوافق على أن Accqrate قد تبقیني على اطلاع بمنتجاتھا و خدماتھا و عروضھا . لعرض سیاسة الخصوصیة الخاصة بنا ،'
    },
  'click here': {
    ar: 'انقر ھنا.'
  },
  'Book a Demo': {
    ar: 'ض ح'
  },
  'A modern ERP application for startups, small and medium businesses.': {
    ar: 'تطبیق ( تخطیط موارد المؤسسات ) ERP حدیث للشركات الناشئة و الشركات الصغیرة و المتوسطة .'
  },
  'Get the Cloud ERP solution that helps rapid-growth companies thrive in the digital economy': {
    ar: 'احصل على حل تخطیط موارد المؤسسات السحابیة الذي یساعد الشركات سریعة النمو على الازدھار في الاقتصاد الرقمي'
  },
  'Get quote': {
    ar: 'الحصول على الاقتباس'
  },
  'Pricings & Subscription': {
    ar: 'الأسعار و الاشتراك '
  },
  "Choose a plan that's right for you.": {
    ar: 'اختر الخطة المناسبة لك .'
  },
  'Try free trial': {
    ar: 'جرب الإصدار التجریبي المجاني'
  },
  'Per user Per company': {
    ar: 'لكل مستخدم لكل شركة '
  },
  'Billed Monthly': {
    ar: 'تدفع شھریا'
  },
  'Digital invoices with tax compliance': {
    ar: ' الفواتیر الرقمیة مع الامتثال الضریبي'
  },
  'QR code integration': {
    ar: ' تكامل رمز الاستجابة السریعة'
  },
  'Custom templates and easy to use': {
    ar: ' قوالب مخصصة و سھلة الاستخدام'
  },
  'Upload of invoices from other ERP': {
    ar: ' تحمیل الفواتیر من أنظمة تخطیط موارد المؤسسات الأخرى'
  },
  'Direct integration with other ERPs': {
    ar: ' التكامل المباشر مع أنظمة تخطیط موارد المؤسسات الأخرى'
  },
  '$ 0.01 / invoice': {
    ar: '1.0 دولار / فاتورة'
  },
  '1 to 100 Users': {
    ar: 'من 1 إلى 100 مستخدم'
  },
  'Digitally signed invoices and pdf': {
    ar: ' الفواتیر الموقعة رقمیا و بي دي إف'
  },
  'Integrate with your existing ERP': {
    ar: ' التكامل مع نظام تخطیط موارد المؤسسات الموجود لدیك'
  },
  'Outbound transmission to partners': {
    ar: ' الإرسال للخارج للشركاء'
  },
  'Inbound transmission to partners': {
    ar: ' انتقال داخلي إلى الشركاء'
  },
  'Blockchain integrated solution': {
    ar: ' حل متكامل من سلسلة الكتل'
  },
  'Financial Year, Account setups': {
    ar: ' السنة المالیة ، إعدادات الحساب'
  },
  'Account groups & Chart of accounts': {
    ar: ' مجموعات الحساب و مخطط الحسابات'
  },
  'Invoices and Expenses': {
    ar: ' الفواتیر و المصاریف'
  },
  'Invoice and Expense upload': {
    ar: ' تحمیل الفاتورة و المصروفات'
  },
  'Finance book, JV, P&L': {
    ar: 'JV ، P&L ، التمویل كتاب'
  },
  'Business Partners': {
    ar: ' شركاء العمل'
  },
  'Service details': {
    ar: ' تفاصیل الخدمة'
  },
  'Project & Project rates': {
    ar: ' أسعار المشروع و المشروع'
  },
  'Project Management': {
    ar: ' إدارة مشروع '
  },
  'Timesheet & Time reports': {
    ar: ' تقاریر الجدول الزمني و الوقت'
  },
  'Warehouse Management': {
    ar: ' إدارة المستودعات'
  },
  'Inventory Management': {
    ar: ' إدارة المخزون'
  },
  'Order to Cash process': {
    ar: ' من أجل عملیة الدفع النقدي'
  },
  'Procure to pay process': {
    ar: ' عملیة الشراء للدفع'
  },
  'Stock reports and checks': {
    ar: ' تقاریر و فحوصات المخزون'
  },
  'HR & Payroll': {
    ar: 'الموارد البشریة و الرواتب '
  },
  Employees: {
    ar: ' الموظفین'
  },
  'Tax data': {
    ar: ' البیانات الضریبیة'
  },
  'Holiday calendar': {
    ar: ' تقویم العطلة'
  },
  'Employee paymaster': {
    ar: ' موظف رواتب'
  },
  Payrolls: {
    ar: ' كشوف المرتبات'
  },
  'Check the demo of the apps': {
    ar: 'ق التوضیحي العرض من ت'
  },
  'View Demo video': {
    ar: 'مشاھدة الفیدیو التوضیحي'
  },
  'Our Partners': {
    ar: 'شركاؤنا'
  },
  'Our Portfolio': {
    ar: 'لدینا محفظة'
  }
}

export const setLanguage = (l) => {
  lang.lang = l
}

export const t = (text) =>
  translations[text] && translations[text][lang.lang] ? translations[text][lang.lang] : text

export default function Translate(props) {
  const { children } = props

  return t(children)
}
