import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import Button from '../../../../Components/Button'
import Field from '../../../../Components/Formik/Field'
import Form from '../../../../Components/Formik/Form'
import { OPERATION_TYPES } from '../../../../Util/Options'

function OfferFilter({ values, setValues, filterData }) {
  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field name="offerNo" label="Offer No" />
      </div>
      <div className="form-fields">
        <Field as="select" name="offerType" label="Offer Type" options={OPERATION_TYPES} allowClear />
      </div>
      <div className="form-fields">
        <Field name="clientName" label="Client Name" />
      </div>
      <div className="form-fields">
        <Field name="email" label="Email" />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" /> Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    quotationNo: '',
    offerType: '',
    clientName: '',
    email: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(OfferFilter)
