import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getActiveClients } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'

function CustomsClearanceFilter({ values, setValues, filterData }) {
  const [clients, setClients] = useState([])

  const getData = () => {
    getActiveClients().then((clients) => {
      if (clients) {
        const clientOptions = []
        clients.forEach((val) => {
          if (val.type === 'Vendor') {
            clientOptions.push({ label: val.name, value: val._id, forwarder: val.forwarder })
          }
        })
        setClients(clientOptions)
      }
    })
  }

  useEffect(() => {
    setValues({ ...values, ...filterData })
    getData()
  }, [])

  return (
    <Form>
      <div className="form-fields">
        <Field name="clearanceNo" label="Clearance No" />
      </div>
      <div className="form-fields">
        <Field as="date" name="clearanceDate" label="Clearance Date" />
      </div>
      <div className="form-fields">
        <Field
          as="select"
          name="forwarderNo"
          label="Forwarder"
          options={[{ label: 'All', value: '' }, ...clients.filter((item) => item.forwarder)]}
        />
      </div>
      <div className="form-fields">
        <Field
          as="select"
          name="supplierNo"
          label="Supplier"
          options={[{ label: 'All', value: '' }, ...clients]}
        />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" />
          Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    clearanceNo: '',
    clearanceDate: '',
    forwarderNo: '',
    supplierNo: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(CustomsClearanceFilter)
