import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'

function FinanceFilter({ values, setValues, filterData }) {
  const [transactionCodes, setTransactionCodes] = useState([])

  const getTransactionCodes = () => {
    apiClient.get('finances/get-transaction-codes').then(({ status, data }) => {
      if (status === 200) {
        setTransactionCodes(
          data.map((item) => ({ label: item.transactionCode, value: item.transactionCode }))
        )
      }
    })
  }

  useEffect(() => {
    setValues({ ...values, ...filterData })
    getTransactionCodes()
  }, [])

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field
            as="select"
            name="transactionCode"
            label="Transaction Code"
            allowClear
            options={transactionCodes}
          />
        </div>
        <div className="form-fields">
          <Field name="transactionName" label="Transaction Name" />
        </div>
        <div className="form-fields">
          <Field name="accountName" label="Account Name" />
        </div>
        <div className="form-fields">
          <Field name="accountCode" label="Account Code" />
        </div>
        <div className="form-fields">
          <Button type="submit" variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button type="reset" className="btn-block btn-glow search">
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    transactionCode: '',
    transactionName: '',
    accountName: '',
    accountCode: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(FinanceFilter)
