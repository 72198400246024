import { withFormik } from 'formik'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { getOrderNames } from '../../../Util/Options'
import { orderFilterSchema } from '../../../Util/validationSchema'

function CreateDeliveryFilter({ values, setValues, filterData }) {
  const { type } = useParams()
  const { typeName, clientName, poNo } = getOrderNames(type)

  useEffect(() => {
    setValues({ ...values, ...filterData })
  }, [])

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="client" label={`${clientName} no.`} />
        </div>
        <div className="form-fields">
          <Field name="clientName" label={`${clientName} Name`} />
        </div>
        <div className="form-fields">
          <Field as="date" name="orderDateFrom" label="Order Date from" />
        </div>
        <div className="form-fields">
          <Field as="date" name="orderDateTo" label="Order date to" />
        </div>
        <div className="form-fields">
          <Field name="poNo" label={`${clientName} ${poNo} no.`} />
        </div>
        <div className="form-fields">
          <Field name="orderNo" label={`${typeName} Order no`} />
        </div>
        <div className="form-fields">
          <Button type="submit" variant="secondary" className="search">
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button type="reset" className="btn-block btn-glow search">
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    client: '',
    clientName: '',
    orderDateFrom: '',
    orderDateTo: '',
    poNo: '',
    orderNo: ''
  }),
  validationSchema: orderFilterSchema,
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(CreateDeliveryFilter)
