import { SettingOutlined } from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import TableLayout from '../../../Layout/TableLayout'
import apiClient from '../../../Util/apiClient'
import { getOrderNames } from '../../../Util/Options'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import Logs from '../../Logs/Logs'
import OrderFilter from './OrderFilter'

export default function Orders() {
  const [orders, setOrders] = useState([])
  const [viewLogs, setViewLogs] = useState(false)
  const history = useHistory()
  const { type } = useParams()
  const { orderAddress, typeName, clientName } = getOrderNames(type)

  const getData = (params = {}) => {
    const filterCache = GET_DATA(`${type}Orders.filterData`) || {}
    params = { ...filterCache, ...params }

    if (params.orderDate) {
      params.orderDate = moment(params.orderDate).utc().startOf('day').toISOString()
    }

    params.type = type

    apiClient.get('orders', { params }).then(({ status, data }) => {
      if (status === 200) {
        SET_DATA(`${type}Orders.filterData`, { ...params, ...data.pageData })
        setOrders(data.result)
      }
    })
  }

  useEffect(() => {
    getData()
  }, [type])

  const onDelete = (id) => {
    apiClient.delete(`orders/${id}`).then(({ status }) => {
      if (status === 204) {
        setOrders(orders.filter((item) => item.id !== id))
      }
    })
  }

  const onDuplicate = (row) => {
    history.push({ pathname: `/app/add-order/${type}`, state: { duplicateId: row.id } })
  }

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li>
          <Link to={`/app/orders/view/${row.id}`}>
            <i className="flaticon-eye" /> View PDF
          </Link>
        </li>
        {validateAccess(`edit-${type}-order`) && (
          <li>
            <Link to={`/app/edit-order/${row.id}`}>
              <i className="flaticon-eye" /> View Order
            </Link>
          </li>
        )}
        {validateAccess(`edit-${type}-order`) && row.status === 'Created' && (
          <li>
            <Link to={`/app/edit-order/${row.id}`}>
              <i className="flaticon-edit-1" /> Edit
            </Link>
          </li>
        )}
        {validateAccess(`edit-${type}-order`) && row.status === 'Created' && (
          <li>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(row.id)}>
              <Link to="#">
                <i className="flaticon-delete-2" /> Delete
              </Link>
            </Popconfirm>
          </li>
        )}
        {validateAccess(`add-${type}-order`) && (
          <li>
            <Link to="#" onClick={() => onDuplicate(row)}>
              <i className="flaticon-copy" /> Duplicate
            </Link>
          </li>
        )}
        {validateAccess(`edit-${type}-order`) && row.status === 'Created' && (
          <li>
            <Link to={`/app/edit-order/${row.id}`}>
              <i className="flaticon-tick-1" /> Approve
            </Link>
          </li>
        )}
        {validateAccess(`edit-${type}-order`) && row.status === 'Approved' && (
          <li>
            <Link to={`/app/edit-order/${row.id}`}>
              <i className="flaticon-tick-1" /> Shortclose
            </Link>
          </li>
        )}
        <li>
          <Link to="#" onClick={() => setViewLogs(row)}>
            <i className="flaticon-information" /> View Logs
          </Link>
        </li>
      </ul>
    </div>
  )

  const columns = [
    {
      title: 'Order No',
      dataIndex: 'orderNo',
      render: (v, r) => (
        <div onClick={() => history.push(`/app/orders/view/${r.id}`)}>
          <a>{v}</a>
        </div>
      )
    },
    {
      title: `${clientName} Name`,
      dataIndex: 'clientName'
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType'
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      render: (value) => moment(value).format('YYYY-MM-DD')
    },
    {
      title: orderAddress,
      dataIndex: 'billingAddress',
      render: (value) =>
        _.compact([value.street, value.city, value.state, value.postalCode, value.country]).join(', ')
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  return (
    <FilterLayout
      addButton={{
        title: `${typeName} order`,
        onClick: () => history.push(`/app/add-order/${type}`),
        access: 'add-order'
      }}
      filterData={GET_DATA(`${type}Orders.filterData`)}
      filter={<OrderFilter key={type} onSubmit={getData} />}>
      <ModalBox
        title={`${typeName} Order Logs`}
        visible={!!viewLogs}
        onCancel={() => setViewLogs(false)}
        footer={false}
        destroyOnClose>
        <Logs entityId={viewLogs?.id} entityType={`${typeName} Order`} />
      </ModalBox>
      <TableLayout title={`${typeName} Orders Overview`} detailed exportUrl="orders/export">
        <TableBox
          dataSource={orders}
          columns={columns}
          pageData={GET_DATA(`${type}Orders.filterData`)}
          onChangePage={getData}
        />
      </TableLayout>
    </FilterLayout>
  )
}
