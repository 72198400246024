import { withFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getActiveClients } from '../../../Actions/UserAction'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { getOrderNames, ORDER_DELIVERY_STATUS_OPTIONS, PO_TYPES } from '../../../Util/Options'

function DeliveryListFilter({ values, setValues, filterData }) {
  const { type } = useParams()
  const [clients, setClients] = useState([])
  const { clientName, deliveryName } = getOrderNames(type)

  const getData = () => {
    getActiveClients().then((clients) => {
      if (clients) {
        const clientOptions = []
        clients.forEach((val) => {
          if (val.type === clientName || val.type === 'Both') {
            clientOptions.push({ label: val.name, value: val._id })
          }
        })
        setClients(clientOptions)
      }
    })
  }

  useEffect(() => {
    setValues({ ...values, ...filterData })
    getData()
  }, [type])

  return (
    <Form>
      <div className="form-fields">
        <Field name="deliveryNo" label={`${deliveryName} No`} />
      </div>
      <div className="form-fields">
        <Field as="date" name="deliveryDate" label={`${deliveryName} Date`} />
      </div>
      <div className="form-fields">
        <Field as="select" name="poType" label="PO Type" options={PO_TYPES} allowClear />
      </div>
      <div className="form-fields">
        <Field
          as="select"
          name="status"
          label="Status"
          options={[{ label: 'All', value: '' }, ...ORDER_DELIVERY_STATUS_OPTIONS]}
          allowClear
        />
      </div>
      <div className="form-fields">
        <Field
          as="select"
          name="client"
          label={clientName}
          options={[{ label: 'All', value: '' }, ...clients]}
        />
      </div>
      <div className="form-fields">
        <Button type="submit" variant="secondary" className="search">
          <i className="flaticon-search-interface-symbol" />
          Search
        </Button>
        <Button type="reset" className="btn-block btn-glow search">
          Clear
        </Button>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: () => ({
    deliveryNo: '',
    deliveryDate: '',
    poType: '',
    status: '',
    client: ''
  }),
  handleSubmit: (values, { props: { onSubmit } }) => onSubmit(values)
})(DeliveryListFilter)
