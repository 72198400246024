import { Col, message, Row } from 'antd'
import React, { Component } from 'react'
import Attachments from '../../../../Components/Attachments'
import FooterActions from '../../../../Components/FooterActions'
import TemplateView from '../../../../Components/TemplateView/TemplateView'
import apiClient from '../../../../Util/apiClient'
import Header from '../../../Header/Header'

export default class BookingView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      attachmentRequiredFor: [],
      attachments: [],
      status: 'Received'
    }

    this.getBooking()
  }

  getBooking = () => {
    apiClient.get(`bookings/view/${this.props.match.params.token}`).then(({ status, data }) => {
      if (status === 200) {
        const { attachmentRequiredFor = [], attachments = [], status } = data.result?.booking || {}

        this.setState({ data: data.result.content, attachmentRequiredFor, attachments, status })
      } else {
        this.props.history.push('/')
      }
    })
  }

  statusUpdate = () => {
    let checkAttachments = true
    const errors = []
    const { attachmentRequiredFor, attachments } = this.state
    attachmentRequiredFor.map((v) => {
      if (!attachments.find((att) => att.attachmentType === v)) {
        checkAttachments = false
        errors.push(v)
      }

      return true
    })

    if (checkAttachments) {
      apiClient
        .post(`bookings/update-status/${this.props.match.params.token}`, { attachments })
        .then(({ status, data }) => {
          if (status === 200) {
            message.success(data.message)
            const { attachments = [], status } = data.result?.booking || {}

            this.setState({ attachments, status })
          }
        })
    } else {
      message.error(`Please add attachments for ${errors.join(', ')}`)
    }
  }

  onUploadAttachments = (n, v, f) => {
    const updatedAttachments = v.map((att) => {
      att.attachmentType = f

      return att
    })
    const oldAttachments = this.state.attachments.filter((att) => att.attachmentType !== f)
    this.setState({ attachments: [...oldAttachments, ...updatedAttachments] })
  }

  render() {
    return (
      <>
        <Header noMenu />
        <Row>
          <Col xs={18} style={{ background: '#f3f3f3' }}>
            <div style={{ paddingBottom: 70 }}>
              <TemplateView contents={this.state.data} />
            </div>

            <FooterActions
              centerActions={[
                {
                  prefix: 'flaticon-tick',
                  label: 'Confirm and send',
                  onClick: () => this.statusUpdate(),
                  dontShow: this.state.status === 'Received'
                }
              ]}
            />
          </Col>
          <Col xs={6}>
            {this.state.attachmentRequiredFor.length > 0 && (
              <div style={{ padding: 15 }}>
                <Row>
                  {this.state.attachmentRequiredFor.map((v, i) => (
                    <Col key={i} xs={24} sm={24} md={24} lg={24} style={{ paddingBottom: 10 }}>
                      <Attachments
                        name="attachments"
                        value={this.state.attachments.filter((val) => val.attachmentType === v)}
                        title={`Attach ${v}`}
                        description="You can upload a maximum of 3 files, 5MB each"
                        acceptFile={['image', 'pdf']}
                        onUpload={(n, val) => this.onUploadAttachments(n, val, v)}
                        endPoint={`bookings/upload-documents/${this.props.match.params.token}`}
                        disabled={this.state.status === 'Received'}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </>
    )
  }
}
