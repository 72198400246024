import { SettingOutlined } from '@ant-design/icons'
import { Col, message, Popconfirm, Popover, Row } from 'antd'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  addBankDetailsByEmployeeID,
  getBankDetailsByEmployeeID,
  updateBankDetailsByEmployeeID
} from '../../../Actions/EmployeeActions'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import FooterActions from '../../../Components/FooterActions'
import Form from '../../../Components/Formik/Form'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import TableBox from '../../../Components/TableBox/TableBox'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import BankDetailsForm from './BankDetailsForm'

const Schema = Yup.object().shape({
  bankName: Yup.string().required(),
  accNum: Yup.number().required(),
  branchName: Yup.string().required(),
  ifscCode: Yup.string().required(),
  type: Yup.string().required(),
  paymentElection: Yup.string().required(),
  attachments: Yup.array()
})

const BankDetails = (props) => {
  const [toggle, setToggle] = useState(false)
  const [isAddType, setIsAddType] = useState(true)
  const { currentEmployee, bankDetails, dispatch, values, setValues, submitForm, errors } = props

  useEffect(() => {
    if (currentEmployee?.id) {
      dispatch(getBankDetailsByEmployeeID(currentEmployee.id))
    }
  }, [currentEmployee?.id])

  const handleAddNewDetails = () => {
    if (currentEmployee?.id) {
      setToggle(true)
      setIsAddType(true)
      setValues({
        bankName: '',
        accNum: '',
        branchName: '',
        ifscCode: '',
        type: '',
        attachments: [],
        paymentElection: 0,
        isAddType: true
      })
    } else {
      message.error('Please select an employee to continue!')
    }
  }

  const handleEditRow = (row) => () => {
    setToggle(true)
    setValues({ ...row, isAddType: false })
    setIsAddType(false)
  }

  const handleValueChange = (val) => {
    setValues({ ...values, ...val })
  }

  const deleteRow = (row) => () => {
    dispatch(updateBankDetailsByEmployeeID(row.id, { status: 'Inactive' }))
  }

  const onSave = () => {
    submitForm()

    if (isEmpty(errors)) {
      setToggle(false)
    }
  }

  const columns = [
    {
      title: 'Bank Name',
      dataIndex: 'bankName'
    },
    {
      title: 'Account Number',
      dataIndex: 'accNum'
    },
    {
      title: 'Branch Name',
      dataIndex: 'branchName'
    },
    {
      title: 'IFSC Code',
      dataIndex: 'ifscCode'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Payment Election %',
      dataIndex: 'paymentElection'
    },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      render: (text) => text.map((attachment) => attachment.name).join(', ')
    },
    {
      title: 'Action',
      dataIndex: 'custom_action',
      render: (text, row) => (
        <Popover placement="bottomRight" content={tableActions(row)} trigger="click">
          <div className="btn-group">
            <button type="button" className="btn glow dropdown-toggle">
              {' '}
              <SettingOutlined /> <span className="caret" />
            </button>
          </div>
        </Popover>
      )
    }
  ]

  const tableActions = (row) => (
    <div className="action-buttons">
      <ul>
        <li onClick={handleEditRow(row)}>
          <i className="flaticon-edit-1" /> Edit
        </li>
        <li>
          <Popconfirm title="Sure to delete?" onConfirm={deleteRow(row)}>
            <i className="flaticon-delete-2" /> Delete
          </Popconfirm>
        </li>
      </ul>
    </div>
  )

  return (
    <Form>
      <PanelLayout>
        <Panel title="Bank Details">
          <div className="panel-with-border">
            <Row justify="left" gutter={(12, 10)}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <div className="mb-3 align-right">
                  <ButtonBox style={{ marginRight: 10 }} type="success" onClick={handleAddNewDetails}>
                    <i className="flaticon-plus" /> Add
                  </ButtonBox>
                  {/* <ButtonBox type="primary">
                    {' '}
                    <i className="flaticon-delete-3" /> Delete{' '}
                  </ButtonBox> */}
                </div>
                <div className="table-view">
                  <TableBox
                    columns={columns}
                    actionIndex="custom_action"
                    cardHeaderIndex="status"
                    cardFirstLabelIndex="docno"
                    dataSource={bankDetails}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Panel>
      </PanelLayout>

      <ModalBox
        title={`${isAddType ? 'Add' : 'Edit'} Bank Details`}
        visible={toggle}
        onCancel={() => setToggle(false)}
        width={700}
        okText="Save"
        onOk={onSave}
        destroyOnClose>
        <BankDetailsForm currentDetails={values} handleValueChange={handleValueChange} />
      </ModalBox>

      <FooterActions
        leftActions={[
          {
            prefix: 'flaticon-back',
            label: 'Back to employee list'
          }
        ]}
      />
    </Form>
  )
}

const mapStateToProps = (state) => ({
  bankDetails: state.employee.employeeDetails.bankDetails
})

export default connect(
  mapStateToProps,
  null
)(
  withFormik({
    mapPropsToValues: () => ({
      bankName: '',
      accNum: '',
      branchName: '',
      ifscCode: '',
      type: '',
      attachments: [],
      paymentElection: 0
    }),
    handleSubmit: async (values, { props: { dispatch, currentEmployee } }) => {
      const { id, isAddType, ...rest } = values
      const payload = {
        bankName: rest?.bankName,
        accNum: rest?.accNum,
        branchName: rest?.branchName,
        ifscCode: rest?.ifscCode,
        type: rest?.type,
        attachments: rest?.attachments.filter((attachment) => attachment !== ''),
        paymentElection: rest?.paymentElection,
        employee: currentEmployee?.id
      }

      if (isAddType) {
        dispatch(addBankDetailsByEmployeeID(payload))
      } else {
        dispatch(updateBankDetailsByEmployeeID(id, payload))
      }
    },
    validationSchema: Schema
  })(BankDetails)
)
