import { withFormik } from 'formik'
import React from 'react'
import './Pricing.scss'

const Instructions = () => (
  <div className="left-content-details">
    <div className="instructions">
      <h1>instructions</h1>
      <div className="content">
        <ul>
          <li>14 days free trial for the application</li>
          <li>At the end of the trial period, the billing will be done</li>
          <li>Choose your modules, users and number of companies to get a price estimation</li>
          <li>
            You can add or remove modules anytime and the new pricing will be applied from the next billing
            period
          </li>
          <li>
            Reconciliation of the no. of users, companies will be done at the time of billing and charged
          </li>
          <li>Invoice copy with the break up of costs will be sent to your registered mail id</li>
          <li>
            If you would like to have a demo of the ERP, please contact us at{' '}
            <a href="mailto:erp@accqrate.com">erp@accqrate.com</a>
          </li>
          <li>
            You will be guided through a Wizard for your Company onboarding process. This may take 30 to
            45min.
          </li>
        </ul>
      </div>
    </div>
  </div>
)
export default withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => null
  // validationSchema: Schema
})(Instructions)
