import { EditOutlined, SettingOutlined } from '@ant-design/icons'
import { Col, message, Row } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../Components/Button'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import TableBox from '../../../Components/TableBox/TableBox'
import FilterLayout from '../../../Layout/FilterLayout'
import apiClient from '../../../Util/apiClient'
import { GET_DATA, SET_DATA, validateAccess } from '../../../Util/Util'
import PaymasterFilter from './PaymasterFilter'

export default function EmployeePaymasters(props) {
  const [employeePaymasters, setEmployeePaymasters] = useState([])
  const [columns, setColumns] = useState([])
  const history = useHistory()

  const getData = () => {
    apiClient.get('payrollBase/getActive').then(({ data }) => {
      if (data && data.result) {
        setColumns(data.result || [])
      }
    })
  }

  useEffect(() => {
    const filterCache = GET_DATA('employeePaymaster.filterData')

    onFilter(filterCache || {}, 'Show')
    getData()
  }, [])

  const onFilter = (filterObj, type) => {
    const params = _.omit(filterObj, 'date')

    if (filterObj.date) {
      params.month = moment(filterObj.date).format('M')
      params.year = moment(filterObj.date).format('YYYY')
    }

    if (type === 'Show') {
      apiClient.get('employeePayMasters/getEmployeePayMasterByMonth', { params }).then(({ data }) => {
        if (data && data.result) {
          SET_DATA('employeePaymaster.filterData', { ...filterObj, ...data.pageData })
          setEmployeePaymasters(data.result)

          if (data.result.length === 0) {
            message.info('No data for this month')
          }
        }
      })
    }

    if (type === 'Sync') {
      apiClient.put('employeePayMasters/syncEmployeePayMaster', params).then(({ data }) => {
        if (data && data.result) {
          setEmployeePaymasters(data.result)
        }
      })
    }
  }

  const onChangePage = (pageData) => {
    const filterCache = GET_DATA('employeePaymaster.filterData')
    onFilter({ ...(filterCache || {}), ...pageData }, 'Show')
  }

  const newColumns = [
    {
      title: 'Employee ID',
      dataIndex: 'employeeNo',
      render: (v, r) => (
        <Link onClick={() => props.history.push(`/app/edit-paymaster/${r.id}`)}>
          <a>{v}</a>
        </Link>
      )
    },
    {
      title: 'Employee Name',
      dataIndex: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Month',
      dataIndex: 'month'
    },
    {
      title: 'Year',
      dataIndex: 'year'
    },
    {
      title: 'Tax Code',
      dataIndex: 'taxCode'
    },
    ...columns.map((val) => ({
      title: val.label,
      dataIndex: val.key,
      render: (v, row) => row.columns?.[val.key]
    })),
    {
      ...(validateAccess('edit-paymaster') && {
        title: 'Action',
        dataIndex: 'custom_action',
        render: (text, row) => (
          <div className="btn-group">
            <Button onClick={() => history.push(`/app/edit-paymaster/${row.id}`)} className="dropdown-toggle">
              <EditOutlined />
            </Button>
          </div>
        )
      })
    }
  ]

  return (
    <FilterLayout filter={<PaymasterFilter onFilter={onFilter} />}>
      <div className="top-filter-options">
        <Row>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 21, order: 1 }}>
            <h2>Employee Paymaster Overview</h2>
          </Col>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 12, order: 2 }}
            lg={{ span: 3, order: 2 }}>
            <ButtonBox onClick={() => props.history.push('/app/paymaster-fields')}>
              <SettingOutlined /> Config Fields
            </ButtonBox>
          </Col>
        </Row>
      </div>
      <TableBox
        dataSource={employeePaymasters}
        columns={newColumns}
        pageData={GET_DATA('employeePaymaster.filterData')}
        onChangePage={onChangePage}
      />
    </FilterLayout>
  )
}
