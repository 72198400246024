import { Alert, Col, message, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import ButtonBox from '../../../Components/ButtonBox/ButtonBox'
import TableBox from '../../../Components/TableBox/TableBox'
import AppConfig from '../../../config'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import { INCOME_KIND_OPTIONS, INVOICE_STATUS_OPTIONS } from '../../../Util/Options'
import { parseAmount } from '../../../Util/Util'
import UploadArea from '../../MasterData/MasterUploads/UploadArea'

const { API_URL } = AppConfig

const checkNaN = (v) => (!Number.isNaN(parseFloat(v)) ? parseFloat(v) : 0)

class UploadIncomes extends React.Component {
  constructor() {
    super()
    this.state = {
      uploadData: [],
      loader: false,
      uploadErrors: false
    }
  }

  readAllData = (header, content) => {
    const rows = []
    content.forEach((val) => {
      const obj = {}
      header.forEach((col, i) => {
        if (col === 'KeyNo') {
          obj.no = val[i]
        } else if (col === 'CustomerNo') {
          obj.clientNo = val[i]
        } else if (col === 'Kind') {
          obj.kind = val[i]
        } else if (col === 'DocNumber') {
          obj.invoiceNo = val[i]
        } else if (col === 'PONumber') {
          obj.poNumber = val[i]
        } else if (col === 'Currency') {
          obj.currency = val[i]
        } else if (col === 'IssueDate(YYYY-MM-DD)') {
          obj.issueDate = val[i]
        } else if (col === 'TaxDate(YYYY-MM-DD)') {
          obj.taxDate = val[i]
        } else if (col === 'TaxCategory') {
          obj.taxCategory = val[i]
        } else if (col === 'ItemType') {
          obj.type = val[i]
        } else if (col === 'ItemProduct/ItemDescription') {
          obj.product = val[i]
        } else if (col === 'Quantity') {
          obj.quantity = val[i]
        } else if (col === 'UOM') {
          obj.unit = val[i]
        } else if (col === 'UnitPrice') {
          obj.unitPrice = val[i]
        } else if (col === 'TAX') {
          obj.tax = val[i]
        } else if (col === 'TaxType') {
          obj.taxType = val[i]
        } else if (col === 'Discount') {
          obj.discount = val[i]
        } else if (col === 'Charge') {
          obj.charge = val[i]
        } else if (col === 'PaymentTerm(No of days)') {
          obj.paymentTerm = val[i]
        } else if (col === 'SentDate(YYYY-MM-DD)') {
          obj.sentDate = val[i]
        } else if (col === 'PaymentDueDate(YYYY-MM-DD)') {
          obj.paymentDueDate = val[i]
        } else if (col === 'PaymentType') {
          obj.paymentType = val[i]
        } else if (col === 'PaymentDate(YYYY-MM-DD)') {
          obj.paymentDate = val[i]
        } else if (col === 'PaidAmount') {
          obj.paidAmount = val[i] || 0
        } else if (col === 'PaymentStatus') {
          obj.status = val[i]
        } else if (col === 'ExchangeRate') {
          obj.exchangeRate = val[i] || 0
        } else if (col === 'BankAccountNo') {
          obj.bankAccountNo = val[i]
        }
      })
      rows.push(obj)
    })

    return rows
  }

  checkUploadErrors = (row) => {
    const kindOptions = ['Invoice', 'Credit-Note', 'Debit-Note']
    const PaymentStatusOptions = ['Pending', 'Sent', 'PartiallyPaid', 'Paid']
    const errorField = []
    const validateFields = [
      { label: 'KeyNo', value: 'no' },
      { label: 'CustomerNo', value: 'clientNo' },
      { label: 'Kind', value: 'kind' },
      { label: 'Currency', value: 'currency' },
      { label: 'IssueDate', value: 'issueDate' },
      { label: 'TaxDate', value: 'taxDate' },
      { label: 'TaxCategory', value: 'taxCategory' },
      { label: 'ItemProduct/ItemDescription', value: 'product' },
      { label: 'UOM', value: 'unit' },
      { label: 'UnitPrice', value: 'unitPrice' },
      { label: 'Tax', value: 'tax' },
      { label: 'TaxType', value: 'taxType' },
      { label: 'ExchangeRate', value: 'exchangeRate' }
    ]
    validateFields.forEach((val) => {
      /* eslint-disable-next-line */
      if (!row[val.value] && row[val.value] != 0) {
        errorField.push(`${val.label} missing`)
      } else if (val.label === 'Kind' && !kindOptions.includes(row.kind)) {
        errorField.push(`Invalid ${val.label}`)
      }
    })

    if (row.status && row.status !== '' && !PaymentStatusOptions.includes(row.status)) {
      errorField.push('Invalid PaymentStatus')
    }

    return errorField.length > 0 ? errorField.join(', ') : false
  }

  getItems = (v, position) => {
    const amount = checkNaN(v.quantity) * checkNaN(v.unitPrice)
    const taxFormat = `${v.tax}`.indexOf('%') >= 0 ? '%' : ''
    const discountFormat = `${v.discount}`.indexOf('%') >= 0 ? '%' : ''
    const chargeFormat = `${v.charge}`.indexOf('%') >= 0 ? '%' : ''
    const discount = checkNaN(v.discount || 0)
    const charge = checkNaN(v.charge || 0)
    const tax = checkNaN(v.tax || 0)
    const discAmount = discountFormat === '%' ? amount * (checkNaN(discount || 0) / 100) : checkNaN(discount)
    const chrgAmount = chargeFormat === '%' ? amount * (checkNaN(charge || 0) / 100) : checkNaN(charge)
    const netAmount = amount - (discAmount || 0) + (chrgAmount || 0)
    const tAmount = taxFormat === '%' ? netAmount * (checkNaN(tax || 0) / 100) : checkNaN(tax)
    const grossAmount = netAmount + tAmount

    return {
      position: position + 1,
      type: v.type,
      product: v.product,
      quantity: v.quantity,
      unit: v.unit,
      unitPrice: v.unitPrice,
      discount,
      charge,
      tax,
      discountFormat,
      chargeFormat,
      taxFormat,
      amount,
      discountAmount: discAmount,
      chargeAmount: chrgAmount,
      netAmount,
      taxAmount: tAmount,
      grossAmount,
      totalAmount: grossAmount
    }
  }

  getInvoiceTotals = (items) => {
    const totals = {
      amount: 0,
      discountAmount: 0,
      chargeAmount: 0,
      netAmount: 0,
      taxAmount: 0,
      grossAmount: 0,
      totalAmount: 0
    }
    items.forEach((v) => {
      totals.amount += checkNaN(v.amount)
      totals.discountAmount += checkNaN(v.discountAmount)
      totals.chargeAmount += checkNaN(v.chargeAmount)
      totals.netAmount += checkNaN(v.netAmount)
      totals.taxAmount += checkNaN(v.taxAmount)
      totals.grossAmount += checkNaN(v.grossAmount)
      totals.totalAmount += checkNaN(v.totalAmount)
    })

    return totals
  }

  onUpload = (header, rowData) => {
    this.setState(
      {
        uploadData: [],
        uploadErrors: false
      },
      () => {
        const rows = this.readAllData(header, rowData)

        const mergedRows = {}
        const uploadErrors = {}

        rows.forEach((v) => {
          const checkErr = this.checkUploadErrors(v)

          if (checkErr) {
            uploadErrors[v.no] = uploadErrors[v.no] ? `${`${uploadErrors[v.no]}, ${checkErr}`}` : checkErr
          }

          const currentRow = mergedRows[v.no]
          const items = this.getItems(v, mergedRows[v.no]?.items?.length || 0)

          if (currentRow) {
            mergedRows[v.no].items.push(items)
            mergedRows[v.no] = {
              ...mergedRows[v.no],
              ...this.getInvoiceTotals(mergedRows[v.no].items)
            }
          } else {
            mergedRows[v.no] = {
              no: v.no,
              clientNo: v.clientNo,
              kind: v.kind,
              invoiceNo: v.invoiceNo,
              poNumber: v.poNumber,
              currency: v.currency,
              issueDate: v.issueDate,
              taxDate: v.taxDate,
              paymentTerm: v.paymentTerm,
              sentDate: v.sentDate,
              paymentDueDate: v.paymentDueDate,
              paymentType: v.paymentType,
              paymentDate: v.paymentDate,
              paidAmount: checkNaN(v.paidAmount),
              status: v.status || 'Pending',
              approvalStatus: 'Created',
              exchangeRate: checkNaN(v.exchangeRate) || 1,
              items: [items],
              ...this.getInvoiceTotals([items])
            }
          }
        })
        const err = Object.values(uploadErrors).length > 0
        this.setState({
          uploadData: !err ? Object.values(mergedRows) : [],
          uploadErrors: err ? uploadErrors : false
        })
      }
    )
  }

  onUploadData = () => {
    const { uploadData, loader } = this.state

    if (uploadData.length > 0 && !loader) {
      this.setState({ loader: true }, () => {
        apiClient.post('incomes/mass-upload', { invoices: uploadData }).then(({ data }) => {
          if (data && data.result) {
            message.success(`${data.result.success} success, ${data.result.failed} failed`)
            this.setState({ uploadData: [], loader: false, uploadErrors: data.result.uploadErrors })
          } else {
            this.setState({ loader: false })
          }
        })
      })
    } else {
      message.error('Please upload roles')
    }
  }

  onDowloadTemplate = () => {
    const dowloadUrl = `${API_URL}/assets/defaultTemplate/Income_Template.xlsx`
    const a = document.createElement('a')
    a.href = dowloadUrl
    a.download = 'Income_Template'
    a.click()
  }

  render() {
    const columns = [
      {
        title: 'Key No',
        dataIndex: 'no'
      },
      {
        title: 'Customer No',
        dataIndex: 'clientNo'
      },
      {
        title: 'Doc No',
        dataIndex: 'invoiceNo'
      },
      {
        title: 'Invoice/Issue Date',
        dataIndex: 'issueDate',
        render: (text) => (text ? moment(text).format('YYYY-MM-DD') : '')
      },

      {
        title: 'PO/Reference No',
        dataIndex: 'poNo'
      },
      {
        title: 'Net Amount',
        dataIndex: 'netAmount',
        render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
      },
      {
        title: 'Tax Amount',
        dataIndex: 'taxAmount',
        render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
      },
      {
        title: 'Gross Amount',
        dataIndex: 'grossAmount',
        render: (v, r) => `${parseAmount(v, r.currency)} ${r.currency}`
      },

      {
        title: 'Due Date',
        dataIndex: 'paymentDueDate',
        render: (v) => (v ? moment(v).format('YYYY-MM-DD') : '')
      },
      {
        title: 'Balance Amount',
        dataIndex: 'grossAmount',
        render: (v, r) =>
          `${parseAmount((r.grossAmount || 0) - (r.paidAmount || 0), r.currency)} ${r.currency}`
      }
    ]

    return (
      <Row justify="center" className="inner-contents">
        <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 22 }} lg={{ span: 18 }}>
          <PanelLayout title="Income Upload">
            <Row>
              {this.state.uploadData.length === 0 && (
                <Col
                  xs={{ span: 23 }}
                  sm={{ span: 23 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  style={{ paddingRight: 10 }}>
                  <UploadArea
                    onUpload={this.onUpload}
                    sheetName="Incomes"
                    label="Click here or drag and drop file here to upload incomes"
                  />
                </Col>
              )}
              {this.state.uploadData.length === 0 && (
                <Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 12 }} lg={{ span: 10 }}>
                  <Row justify="center">
                    {this.state.uploadData.length === 0 && (
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        {this.state.uploadErrors && (
                          <div style={{ padding: 10 }}>
                            {Object.keys(this.state.uploadErrors).map((v, i) => (
                              <Alert
                                key={i}
                                message={`KeyNo ${v}: ${this.state.uploadErrors[v]}`}
                                type="error"
                                closable
                              />
                            ))}
                          </div>
                        )}
                        <div className="upload-invoice-expense-note">
                          <div className="heading">
                            <h2>Note:</h2>
                          </div>
                          <div className="sample-template">
                            <ButtonBox onClick={this.onDowloadTemplate}>
                              <i className="flaticon-download" />
                              Download Sample Template
                            </ButtonBox>
                          </div>
                          <ul>
                            <li>
                              Column CustomerNo:
                              <ul>
                                <li>
                                  Enter business partner no. which exists in business partners and its type
                                  should be Customer
                                </li>
                              </ul>
                            </li>
                            <li>Column DocNumber (optional)</li>
                            <li>
                              Available Kind:
                              <ul>
                                {INCOME_KIND_OPTIONS.map((st) => (
                                  <li key={st}>{st.value}</li>
                                ))}
                              </ul>
                            </li>
                            <li>
                              Column BankAccountNo:
                              <ul>
                                <li>Enter BankAccountNo which exists in company</li>
                              </ul>
                            </li>
                            <li>
                              Available Payment Status:
                              <ul>
                                {INVOICE_STATUS_OPTIONS.map((st) => (
                                  <li key={st}>{st.value}</li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              {this.state.uploadData.length > 0 && !this.state.loader && (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  style={{ paddingRight: 10, paddingTop: 20 }}>
                  <div style={{ display: 'inline-table', paddingRight: 10 }}>
                    <ButtonBox onClick={this.onUploadData}>
                      {this.state.loader ? 'Uploading' : 'Upload'}
                    </ButtonBox>
                  </div>
                  <div style={{ display: 'inline-table' }}>
                    <ButtonBox type="default" onClick={() => this.setState({ uploadData: [] })}>
                      Cancel
                    </ButtonBox>
                  </div>
                </Col>
              )}
              {this.state.uploadData.length > 0 && (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  style={{ marginTop: 20, paddingRight: 10 }}>
                  <TableBox dataSource={this.state.uploadData} columns={columns} />
                </Col>
              )}
            </Row>
          </PanelLayout>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.users.userInfo,
    companyInfo: state.users.companyInfo
  }
}

export default connect(mapStateToProps)(UploadIncomes)
