import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, message, Modal, Row, Tooltip } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getMasterCurrencies, getOptionsByType } from '../../../Actions/UserAction'
import Address from '../../../Components/Address/Address'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import FieldArray from '../../../Components/Formik/FieldArray'
import Form from '../../../Components/Formik/Form'
import ModalBox from '../../../Components/ModalBox/ModalBox'
import { useSelector } from '../../../Hooks/redux'
import useDidUpdateEffect from '../../../Hooks/useDidUpdateEffect'
import Panel from '../../../Layout/Panel'
import PanelLayout from '../../../Layout/PanelLayout'
import apiClient from '../../../Util/apiClient'
import {
  DEFAULT_ADDRESS_FIELDS,
  DEFAULT_ORDER_DETAILS,
  getOrderNames,
  PO_TYPES,
  TAX_CATEGORIES,
  TAX_TYPES
} from '../../../Util/Options'
import { convertSelectOptions } from '../../../Util/Util'
import { orderSchema } from '../../../Util/validationSchema'
import IncomeEmail from '../../InvoiceData/Incomes/IncomeEmail'
import CheckAvailability from '../CheckAvailability'
import SingleOrderDetails from './SingleOrderDetails'
import SumFields from './SumFields'

const formData = (data) => {
  data.orderDate = moment(data.orderDate).utc().startOf('day')
  data.amount = parseFloat(_.sumBy(data.orderDetails, 'amount'))
  data.discount = parseFloat(_.sumBy(data.orderDetails, 'discountValue'))
  data.charge = parseFloat(_.sumBy(data.orderDetails, 'chargeValue'))
  data.netAmount = parseFloat(_.sumBy(data.orderDetails, 'netAmount'))
  data.taxAmount = parseFloat(_.sumBy(data.orderDetails, 'taxAmount'))
  data.grossAmount = data.taxAmount + data.netAmount
  data.baseAmount = data.grossAmount * data.exchangeRate

  return data
}

function OrderForm({
  values,
  setValues,
  setFieldValue,
  submitForm,
  location: { state: { duplicateId } = '' },
  match: {
    params: { id }
  }
}) {
  const [clients, setClients] = useState([])
  const [contactPersons, setContactPersons] = useState([])
  const [orderTypes, setOrderTypes] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [templates, setTemplates] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [clientEmail, setClientEmail] = useState('')
  const [defaultDiscount, setDefaultDiscount] = useState('')
  const [discountType, setDiscountType] = useState('')
  const {
    isSales,
    typeName,
    deliveryName,
    clientName,
    isPurchase,
    poNo,
    poDate,
    salesPerson,
    orderAddress,
    deliveryAddress
  } = getOrderNames(values.type)

  const { tax, ...companyInfo } = useSelector((state) => state.users.companyInfo)

  const disabled = id && values.status !== 'Created'

  const getData = async () => {
    getMasterCurrencies().then((data) => setCurrencies(data))
    apiClient.get('customTemplates/getActive', { params: { type: 'Order' } }).then(({ data }) => {
      if (data && data.result) {
        setTemplates(convertSelectOptions(data.result || [], 'name', 'id'))
      }
    })

    if (id || duplicateId) {
      apiClient.get(`orders/${id || duplicateId}`).then(({ status, data }) => {
        if (status === 200) {
          if (duplicateId) {
            data = _.omit(data, [
              'id',
              '_id',
              'orderNo',
              'quotationId',
              'status',
              'createdAt',
              'createdBy',
              'updatedAt',
              'updatedBy'
            ])
            data.orderDetails = data.orderDetails.map((order) => ({
              ...DEFAULT_ORDER_DETAILS,
              ..._.omit(order, [
                'id',
                '_id',
                'deliveryStatus',
                'invoiceStatus',
                'deliveredQuantity',
                'invoicedQuantity'
              ]),
              tempTax: order.tax,
              toBeDeliveredQuantity: order.orderedQuantity,
              toBeInvoicedQuantity: order.orderedQuantity,
              materialCodeDesc: _.compact([order.materialCode, order.materialDescription]).join(' - ')
            }))
          } else {
            data.orderDetails = data.orderDetails.map((order) => ({
              ...order,
              tempTax: order.tax,
              materialCodeDesc: _.compact([order.materialCode, order.materialDescription]).join(' - ')
            }))
          }

          setValues({ ...values, ...data })
          getClient(data)
        }
      })
    } else if (isSales) {
      const obj = {
        withDelivery: companyInfo.configurations.deliveryBasedOnSales === 'Yes',
        orderDetails: [{ ...DEFAULT_ORDER_DETAILS, tax, tempTax: tax }]
      }
      setValues({ ...values, ...obj })
    } else if (isPurchase) {
      const { street, stateName: state, countryName: country, cityName: city, postalCode } = companyInfo

      if (values.quotationId && values.supplierId) {
        setFieldValue('shippingAddress', {
          street,
          city,
          state,
          postalCode,
          country
        })
        getClient({ client: values.supplierId }).then((clients) => {
          handleClient(
            'client',
            values.supplierId,
            clients.find((client) => client.id === values.supplierId)
          )
        })
      } else {
        const obj = {
          shippingAddress: {
            street,
            city,
            state,
            postalCode,
            country
          },
          orderDetails: [{ ...DEFAULT_ORDER_DETAILS, tax, tempTax: tax }]
        }
        setValues({ ...values, ...obj })
      }
    }
  }

  const getClient = async ({ client, contactPerson }) =>
    apiClient.get(`clients/get/${client}`).then(({ data: { result: data } }) => {
      if (data) {
        data.contactPersons = data.contactPersons.map((item) => ({
          value: item._id,
          label: _.compact([item.contactName, item.contactEmail, item.contactPhone]).join(', '),
          ...item
        }))
        setDefaultDiscount(data.discount)
        setDiscountType(data.discountFormat)
        setClientEmail(data.contactPersons.find((item) => item._id === contactPerson)?.contactEmail)
        const clients = [
          {
            ...data,
            label: data.name,
            value: data._id
          }
        ]
        setClients(clients)
        setContactPersons(data.contactPersons)

        return clients
      }
    })

  useEffect(() => {
    if (values.type) {
      getOptionsByType({
        type: [`${typeName}OrderType`]
      }).then((orderType) => {
        setOrderTypes(orderType[`${typeName}OrderType`] || [])
      })
    }
  }, [values.type])

  useEffect(() => {
    getData()
  }, [id])

  useDidUpdateEffect(() => {
    if (values.currency && companyInfo.currency && !disabled) {
      apiClient
        .get('exchange-rates/get-rate', {
          params: { baseCurrency: values.currency, toCurrency: companyInfo.currency }
        })
        .then(({ data }) => {
          if (data && data.result) {
            setFieldValue('exchangeRate', data.result.rate || values.exchangeRate || 1)
          }
        })
    }
  }, [values.currency])

  const onSearch = (name) => {
    apiClient.get('clients/getNames', { params: { name, type: clientName } }).then(({ status, data }) => {
      if (status === 200) {
        setClients(
          data.map((item) => ({
            ...item,
            label: item.name,
            value: item._id,
            contactPersons: item.contactPersons.map((item) => ({
              value: item.id,
              label: _.compact([item.contactName, item.contactEmail, item.contactPhone]).join(', '),
              ...item
            }))
          }))
        )
      }
    })
  }

  const handleClient = (name, value, option = { contactPersons: [] }) => {
    console.log(name, value, option)

    const primaryContacts = option.contactPersons

    setDefaultDiscount(option.discount)
    setDiscountType(option.discountFormat)

    setFieldValue('clientAlt', option.nameAlt)
    setFieldValue('taxCategory', option.currency === companyInfo.currency ? 'Standard' : 'Export')
    setFieldValue('currency', option.currency)
    setFieldValue('payTerm', option.paymentTerm)
    setFieldValue('billingAddress', option.billingAddress)

    if (isSales) {
      setFieldValue('shippingAddress', option.shippingAddress)
    }

    if (option.discount || option.currency !== companyInfo.currency) {
      setFieldValue(
        'orderDetails',
        values.orderDetails.map((item) => ({
          ...item,
          ...(option.discount && {
            discount: option.discount,
            discountType: option.discountFormat
          }),
          ...(option.currency !== companyInfo.currency && { taxType: 'Zero VAT' })
        }))
      )
    }

    setContactPersons(primaryContacts)

    setFieldValue('contactPerson', primaryContacts.length === 1 ? primaryContacts[0].value : '')

    return setFieldValue(name, value)
  }

  const exportPdf = () => {
    apiClient
      .post(`orders/download/${id}`, { printType: 'Single' }, { responseType: 'blob' })
      .then(({ status, data, headers }) => {
        if (status === 200 && data) {
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(data)
          a.download = JSON.parse(headers['content-disposition'].split('filename=')[1].split(';')[0])
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
      })
  }

  const sendMail = (emailInfo) => {
    apiClient.post('orders/send-emails', { orderIds: [id], ...emailInfo }).then(({ status }) => {
      if (status === 200) {
        message.success('Mail Sent')
        setShowModal(false)
      }
    })
  }

  const getTaxType = (taxCategory = values.taxCategory) => {
    const taxTypes = TAX_TYPES.filter((item) => item.categories.includes(taxCategory))

    return taxTypes?.length === 1 ? taxTypes[0].value : ''
  }

  return (
    <Form>
      <ModalBox
        title="Send Email"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        destroyOnClose>
        <IncomeEmail onSend={sendMail} showEmail email={clientEmail} onCancel={() => setShowModal(false)} />
      </ModalBox>
      <Row justify="center" className="inner-contents">
        <Col xs={22} sm={22} md={20} lg={20}>
          <PanelLayout
            title={
              <Row justify="space-between">
                <Col>
                  <h2 className="panel-title">{typeName} Order</h2>
                </Col>
                <Col>
                  <b>
                    {`${typeName} Order Status`}:
                    <span className="ml-2">
                      {values.orderDetails.every((item) => item.deliveryStatus === 'Open') &&
                      values.orderDetails.every((item) => item.invoiceStatus === 'Open')
                        ? 'OPEN'
                        : values.orderDetails.every((item) => item.deliveryStatus === 'Delivered') &&
                          values.orderDetails.every((item) => item.invoiceStatus === 'Invoiced')
                        ? 'CLOSED'
                        : 'IN PROGRESS'}
                    </span>
                  </b>
                  {id && (
                    <>
                      <Tooltip title="Send PDF as mail">
                        <Button className="mx-2" onClick={() => setShowModal(true)}>
                          <i className="m-auto fa flaticon-email-4" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Download PDF" onClick={exportPdf}>
                        <Button>
                          <i className="m-auto fa flaticon-pdf" />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Col>
              </Row>
            }>
            <Panel title={`${typeName} Order`}>
              <Row gutter={[20, 10]}>
                {id && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field name="orderNo" label={`${typeName} order no.`} disabled />
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="orderType"
                      label="Order Type"
                      options={orderTypes}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="orderDate" label="Order Date" disabled />
                  </div>
                </Col>
                {isPurchase && (
                  <Col xs={24} sm={24} md={12} lg={8}>
                    <div className="form-field">
                      <Field
                        as="select"
                        name="poType"
                        label="PO Type"
                        options={PO_TYPES}
                        disabled={disabled}
                      />
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="auto-complete"
                      name="client"
                      label={isSales ? 'Ordering Customer' : 'Supplier'}
                      onSearch={onSearch}
                      onChange={handleClient}
                      options={clients}
                      disabled={disabled}
                      altInput
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="currency"
                      label="Currency"
                      options={currencies}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field type="number" name="exchangeRate" label="Exchange Rate" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="poNo" label={poNo} disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="date" name="poDate" label={poDate} disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field as="textarea" name="remarks" label="Remarks" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field type="number" name="payTerm" label="Pay Term" disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field name="salesPerson" label={salesPerson} disabled={disabled} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="contactPerson"
                      label="Contact Person"
                      options={contactPersons}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="template"
                      label="Template"
                      options={templates}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4}>
                  <div className="form-field">
                    <Field
                      as="select"
                      name="taxCategory"
                      label="Tax Category"
                      options={TAX_CATEGORIES}
                      onChange={(n, v) => {
                        setFieldValue(n, v)
                        setTimeout(() => {
                          setFieldValue(
                            'orderDetails',
                            values.orderDetails.map((item) => ({ ...item, taxType: getTaxType(v) }))
                          )
                        }, 500)
                      }}
                      disabled={disabled}
                    />
                  </div>
                </Col>
                {isSales && (
                  <Col xs={24} sm={24} md={12} lg={4}>
                    <div className="form-field mt-4 pt-1 mb-3">
                      <Field as="checkbox" name="withDelivery" label="With Delivery" disabled={disabled} />
                    </div>
                  </Col>
                )}
              </Row>
              <SumFields {...values} />
            </Panel>
            <Row gutter={[20, 20]}>
              <Col xs={12}>
                <Address addressType="billingAddress" label={orderAddress} disabled={disabled} />
              </Col>
              <Col xs={12}>
                <Address addressType="shippingAddress" label={deliveryAddress} disabled={disabled} />
              </Col>
            </Row>
            {isSales && (
              <CheckAvailability orderDetails={values.orderDetails} setFieldValue={setFieldValue} />
            )}
            <Panel title="Order details">
              <FieldArray
                name="orderDetails"
                loaderCount={8}
                defaultValues={{
                  ...DEFAULT_ORDER_DETAILS,
                  position: (values.orderDetails.length + 1) * 10,
                  tempTax: tax,
                  discount: defaultDiscount,
                  discountType,
                  tax,
                  taxType: getTaxType()
                }}
                additionalValues={_.pick(values, ['type', 'status', 'taxCategory', 'currency'])}
                editable={!disabled}>
                {SingleOrderDetails}
              </FieldArray>
            </Panel>
          </PanelLayout>

          {/* Product details ends */}

          <div className="save-changes">
            {!disabled && (
              <>
                <Button
                  variant="primary"
                  onClick={() => {
                    setFieldValue('tempStatus', 'Created')
                    submitForm()
                  }}>
                  {id ? 'Update' : 'Save'}
                </Button>
                <span>or</span>
                {id ? (
                  <Button
                    onClick={() => {
                      setFieldValue('tempStatus', 'Approved')
                      submitForm()
                    }}>
                    Update and Approve
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setFieldValue('tempStatus', 'Approved')
                      submitForm()
                    }}>
                    Save and Approve
                  </Button>
                )}
                <span>or</span>
              </>
            )}
            {id && values.status === 'Approved' && (
              <>
                <Button
                  onClick={() => {
                    setFieldValue('tempStatus', 'Shortclosed')
                    submitForm()
                  }}>
                  Shortclose
                </Button>
                {(values.type === 'purchase' || values.withDelivery) &&
                  !values.orderDetails.every((item) => item.deliveryStatus === 'Delivered') && (
                    <>
                      <span>or</span>
                      <Link
                        to={{
                          pathname: '/app/add-delivery',
                          state: {
                            type: values.type,
                            orderInfo: {
                              ...values,
                              clientInfo: clients.find((item) => item._id === values.client)
                            },
                            orderDetails: values.orderDetails.filter(
                              (orderDetail) => orderDetail.deliveryStatus !== 'Delivered'
                            )
                          }
                        }}>
                        <Button variant="primary">Create {deliveryName}</Button>
                      </Link>
                    </>
                  )}
                <span>or</span>
                {(values.type === 'purchase' || !values.withDelivery) &&
                  !values.orderDetails.every((item) => item.invoiceStatus === 'Invoiced') && (
                    <>
                      <Link
                        to={{
                          pathname: '/app/add-invoice',
                          state: {
                            type: values.type,
                            orderInfo: {
                              ...values,
                              clientInfo: clients.find((item) => item._id === values.client)
                            },
                            orderDetails: values.orderDetails.filter(
                              (orderDetail) => orderDetail.invoiceStatus !== 'Invoiced'
                            )
                          }
                        }}>
                        <Button variant="primary">Create Invoice</Button>
                      </Link>
                      <span>or</span>
                    </>
                  )}
              </>
            )}
            <Link to={`/app/orders/${values.type}`}>
              <Button>
                <ArrowLeftOutlined /> Back to {values.type} orders list
              </Button>
            </Link>
          </div>
          {/* Invoice Information ends */}
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues: ({ match, location: { state } }) => ({
    type: match?.params?.type || '',
    orderNo: '',
    orderType: '',
    orderDate: moment(),
    client: '',
    currency: '',
    exchangeRate: '1.00',
    poNo: '',
    poType: '',
    poDate: '',
    remarks: '',
    billingAddress: DEFAULT_ADDRESS_FIELDS,
    shippingAddress: DEFAULT_ADDRESS_FIELDS,
    payTerm: '',
    salesPerson: '',
    contactPerson: '',
    taxCategory: '',
    orderDetails: [],
    ...state
  }),
  validationSchema: orderSchema,
  handleSubmit: (
    { tempStatus, ...data },
    {
      props: {
        match: {
          params: { id }
        },
        history
      },
      setFieldValue
    }
  ) => {
    if (tempStatus === 'Shortclosed') {
      data.orderDetails = data.orderDetails.map((item) => ({
        ...item,
        deliveredQuantity: item.orderedQuantity,
        invoicedQuantity: item.orderedQuantity,
        toBeDeliveredQuantity: 0,
        toBeInvoicedQuantity: 0,
        deliveryStatus: 'Delivered',
        invoiceStatus: 'Invoiced'
      }))
    } else if (data.type === 'sales' && tempStatus === 'Approved' && !data.withDelivery) {
      data.orderDetails = data.orderDetails.map((item) => ({
        ...item,
        deliveredQuantity: item.orderedQuantity,
        toBeDeliveredQuantity: 0,
        deliveryStatus: 'Delivered'
      }))
    }

    data.status = tempStatus

    const bodyData = formData(data)

    if (id) {
      apiClient.put(`orders/${id}`, bodyData).then(({ status }) => {
        if (status === 200) {
          setFieldValue('status', data.status)

          if (['Approved', 'Shortclosed'].includes(data.status)) {
            Modal.success({
              title: `${bodyData.type?.capitalize()} order ${data.status} successfully`
            })
            history.push(`/app/edit-order/${data.id}`)
          } else {
            history.push(`/app/orders/${bodyData.type}`)
          }
        }
      })
    } else {
      if (bodyData.type === 'sales' && bodyData.status === 'Approved' && !bodyData.withDelivery) {
        bodyData.orderDetails = bodyData.orderDetails.map((item) => ({
          ...item,
          deliveredQuantity: item.orderedQuantity,
          toBeDeliveredQuantity: 0,
          deliveryStatus: 'Delivered'
        }))
      }

      apiClient.post('orders', bodyData).then(({ status, data }) => {
        if (status === 201) {
          Modal.success({
            title: `${bodyData.type?.capitalize()} order created successfully`,
            content: `Order created with order no: ${data.orderNo}`
          })
          history.replace(`/app/edit-order/${data.id}`)
        }
      })
    }
  }
})(OrderForm)
