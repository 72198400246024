import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import apiClient from '../../../Util/apiClient'

// const Schema = Yup.object().shape({
//   name: Yup.string().required(),
//   relationship: Yup.string().required(),
//   homeTelephone: Yup.string().required(),
//   mobile: Yup.string().required(),
//   workTelephone: Yup.string().required()
// })

const EmergencyDetailsForm = () => {
  // const {
  //   // currentDetails,
  //   // values
  //   // handleValueChange
  //   // setValues,
  // } = props
  const [options, setOptions] = useState({})

  const fetchDropdownValues = () => {
    apiClient
      .get('options/get-by-types', {
        params: { type: ['Relationship'] }
      })
      .then(({ data }) => {
        if (data && data.result) {
          setOptions(data.result)
        }
      })
  }

  useEffect(() => {
    fetchDropdownValues()
    // setValues({
    //   ...values,
    //   name: !isEmpty(currentDetails) ? currentDetails.name : '',
    //   relationship: !isEmpty(currentDetails) ? currentDetails.relationship : '',
    //   homeTelephone: !isEmpty(currentDetails) ? currentDetails.homeTelephone : '',
    //   mobile: !isEmpty(currentDetails) ? currentDetails.mobile : '',
    //   workTelephone: !isEmpty(currentDetails) ? currentDetails.workTelephone : ''
    // })
  }, [])

  // useEffect(() => {
  //   handleValueChange(values)
  // }, [values?.name, values?.relationship, values?.homeTelephone, values?.mobile, values?.workTelephone])

  return (
    <Form>
      <Row justify="left" gutter={(12, 10)}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="name" label="Name" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field
              name="relationship"
              label="Relationship"
              as="select"
              options={options.Relationship || []}
            />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="homeTelephone" label="Home Telephone" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="mobile" label="Mobile" type="text" />
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <div className="form-field">
            <Field name="workTelephone" label="Work Telephone" type="text" />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default EmergencyDetailsForm

// withFormik({
//   mapPropsToValues: () => ({
//     name: '',
//     relationship: '',
//     homeTelephone: '',
//     mobile: '',
//     workTelephone: ''
//   }),
//   handleSubmit: () => null,
//   validationSchema: Schema
// })(EmergencyDetailsForm)
