import { message } from 'antd'
import { withFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { GET_DATA, removeEmptyKeys, SET_DATA } from '../../../Util/Util'

function Filter(props) {
  const { validateForm, values, onFilter } = props

  const onSync = async (type) => {
    validateForm().then((err) => {
      if (_.isEmpty(err)) {
        const params = removeEmptyKeys(values)

        if (params.date) {
          onFilter(params, type)
          SET_DATA('payrolls.filterData', params)
        } else {
          message.error('Please select month to sync')
        }
      }
    })
  }

  const onSubmit = async (type) => {
    validateForm().then((err) => {
      if (_.isEmpty(err)) {
        const params = removeEmptyKeys(values)
        onFilter(params, type)
        SET_DATA('payrolls.filterData', params)
      }
    })
  }

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="date" label="Month" as="date" picker="month" />
        </div>
        <div className="form-fields">
          <Field name="employeeNo" label="Employee No" />
        </div>
        <div className="form-fields">
          <Field name="name" label="Name" />
        </div>
        <div className="form-fields">
          <Field name="email" label="Email" />
        </div>
        <div className="form-fields">
          <Button onClick={() => onSubmit('Show')} variant="primary" className="search">
            Show
          </Button>
          <Button onClick={() => onSync('Sync')} className="btn-block search">
            Sync
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => {
    const filterObj = GET_DATA('payrolls.filterData')

    return {
      date: filterObj?.date || '',
      employeeNo: filterObj?.employeeNo || '',
      name: filterObj?.name || '',
      email: filterObj?.email || ''
    }
  },
  handleSubmit: () => null
})(Filter)
