import { withFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import Button from '../../../Components/Button'
import Field from '../../../Components/Formik/Field'
import Form from '../../../Components/Formik/Form'
import { GET_DATA, removeEmptyKeys } from '../../../Util/Util'

function Filter(props) {
  const onSubmitForm = async () => {
    props.validateForm().then((err) => {
      if (_.isEmpty(err)) {
        const params = removeEmptyKeys(props.values)

        if (params.date) {
          params.date = [
            moment(params.date[0]).format('YYYY-MM-DD'),
            moment(params.date[1]).format('YYYY-MM-DD')
          ]
        }

        props.onFilter(params)
      }
    })
  }

  const onClear = () => {
    props.resetForm({})
    props.onFilter({})
  }

  return (
    <div className="filter-section">
      <Form>
        <div className="form-fields">
          <Field name="invoiceNo" label="Invoice No" />
        </div>
        <div className="form-fields">
          <Field name="date" label="Date" as="date-range" />
        </div>
        <div className="form-fields">
          <Field name="amount" label="Amount" />
        </div>
        <div className="form-fields">
          <Field name="typeOfPayment" label="Type of payment" />
        </div>
        <div className="form-fields">
          <Field name="posting" label="Posting" />
        </div>
        <div className="form-fields">
          <Button variant="secondary" className="search" onClick={onSubmitForm}>
            <i className="flaticon-search-interface-symbol" />
            Search
          </Button>
          <Button className="btn-block btn-glow search" onClick={onClear}>
            Clear
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default withFormik({
  mapPropsToValues: () => {
    const filterObj = GET_DATA('expensePayments.filterData')

    return {
      label: filterObj?.label || '',
      value: filterObj?.value || '',
      type: filterObj?.type || ''
    }
  },
  handleSubmit: () => null
})(Filter)
